import styled from 'styled-components';

import { colors } from '../../layout/styles';

export const SVideo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    h4 {
      color: ${colors.orange};
    }
  }

  h4 {
    font-family: 'Nunito Sans';
    font-size: 24px;
    font-weight: 900;
    text-transform: uppercase;
    color: ${colors.blue};
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .photo {
    width: 100%;
    height: 250px;
    background-size: cover;
  }
`;

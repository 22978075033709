import { Container } from 'react-bootstrap';
import styled from 'styled-components';

import { colors } from '../../../layout/styles';

export const SPageContent = styled(Container)`
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 800px);

  h2 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  h4 {
    display: inline-block;
    font-family: 'Nunito Sans';
    font-size: 19px;
    font-weight: 900;
    text-transform: uppercase;
    color: ${colors.white};
    background-color: ${colors.orange};
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 10px 10px;
    width: auto;
  }
`;

export const SNoPlayersData = styled.div`
  display: flex;
  flex: 1;
  height: 90px;
  justify-content: center;
  align-items: center;
  font-family: Nunito Sans;
  color: ${colors.blue};
`;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import renderHTML from 'react-render-html';
import { LinkContainer } from 'react-router-bootstrap';
import { NavLink } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons/faCommentDots';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons/faCalendarAlt';

import { AppState } from '../../../redux/reducers';
import { IMessageModel } from '../../../models/Message';
import { getLastPlanMessage } from '../../redux/actions/messagesActions';

import { SLastPlanMessage } from './styles';

const LastPlanMessage = () => {
  const loading = useSelector<AppState, boolean>((state) => state.frontend.messages.loadings.last);
  const lastPlan = useSelector<AppState, IMessageModel>((state) => state.frontend.messages.last);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getLastPlanMessage());
    }
  }, []);

  let component;
  if (!lastPlan) {
    component = (
      <>
        <h2>Plán</h2>
        <p>Není stanoven žádný další plán</p>
      </>
    );
  } else {
    component = (
      <>
        <h2>{lastPlan.name}</h2>
        {renderHTML(lastPlan.text)}
      </>
    );
  }

  return (
    <SLastPlanMessage>
      {component}
      <div className="quick-menu">
        <LinkContainer to={`/club/messages`}>
          <NavLink>
            <FontAwesomeIcon icon={faCommentDots} className="mr-2" />
            Všechny zprávy
          </NavLink>
        </LinkContainer>
        <LinkContainer to={`/club/calendar`}>
          <NavLink>
            <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
            Kalendář
          </NavLink>
        </LinkContainer>
      </div>
    </SLastPlanMessage>
  );
};

export default LastPlanMessage;

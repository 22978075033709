export const validateEmail = (mail) => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail);
};

export const arrayToChunks = (array: Array<any>, chunk: number = 10): Array<Array<any>> => {
  let i: number, j: number, tempArray: Array<any>;
  const chunks: Array<Array<any>> = [];
  for (i = 0, j = array.length; i < j; i += chunk) {
    tempArray = array.slice(i, i + chunk);
    chunks.push(tempArray);
  }
  return chunks;
};

import React from 'react';
import { Container } from 'react-bootstrap';

import { SPageContent, SNoData } from './styled';

const Events = () => {
  return (
    <SPageContent className="p-0 my-5">
      <h2>Pořádané akce</h2>
      <Container className="p-0">
        <SNoData>Tato sekce se připravuje</SNoData>
      </Container>
    </SPageContent>
  );
};

export default Events;

import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Category, Subcategory } from '../../../models/Category';
import { AppState } from '../../../redux/reducers';
import { getTeam, updateTeamPhoto } from '../../redux/actions/teamsActions';
import { ITeamModel } from '../../../models/Team';
import FileUpload from '../../components/FileUpload';
import { ASSETS_URL } from '../../../constants';

const TeamImageContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch<{ id: string; category: Category; subcategory?: Subcategory }>();
  const { id, category, subcategory } = params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loadingAction = useSelector<AppState, boolean>((state) => state.backend.teams.loadings.uploadPhoto);
  const error = useSelector<AppState, Error | undefined>((state) => state.backend.teams.errors.uploadPhoto);
  const loading = useSelector<AppState, boolean>((state) => state.backend.teams.loadings.one);
  const team = useSelector<AppState, ITeamModel>((state) => state.backend.teams.items[id]);

  const dispatch = useDispatch();

  const [isSent, setIsSent] = useState(false);
  const [files, setFiles] = useState<Array<File>>([]);

  useEffect(() => {
    if (!loading && id) {
      dispatch(getTeam(token, id));
    }
  }, []);

  useEffect(() => {
    if (isSent) {
      if (!loadingAction && !error) {
        history.push(`/admin/teams/${category}${subcategory ? `/${subcategory}` : ''}`);
      }
      setIsSent(false);
    }
  }, [isSent, loadingAction, error]);

  const handleSubmit = () => {
    dispatch(updateTeamPhoto(token, team.id, files[0]));
    setIsSent(true);
  };

  if (!team) return null;

  return (
    <Container className="p-0">
      <Card>
        <Card.Header>{`Změna loga týmu - ${team.name}`}</Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <h5>Nové logo</h5>
              <FileUpload type="previews" accept={['image/png']} maxFiles={1} onDrop={(acceptedFiles) => setFiles(acceptedFiles)} />
            </Col>
            <Col>
              <h5>Původní logo</h5>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img src={`${ASSETS_URL}/teams/${team.id}.png`} alt="logo týmu" />
              </div>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Button type="button" disabled={isSent} className="float-right" variant="primary" onClick={handleSubmit}>
            Změnit logo
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default TeamImageContainer;

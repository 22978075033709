import React, { useEffect } from 'react';
import { Card, Container } from 'react-bootstrap';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import renderHTML from 'react-render-html';

import { AppState } from '../../../redux/reducers';
import { getMessage } from '../../redux/actions/messagesActions';
import { IMessageModel } from '../../../models/Message';

const MessageFormContainer = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const { id } = params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loading = useSelector<AppState, boolean>((state) => state.backend.messages.loadings.one);
  const message = useSelector<AppState, IMessageModel>((state) => state.backend.messages.items[id]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && id) {
      dispatch(getMessage(token, id));
    }
  }, []);

  if (!message) return null;

  return (
    <Container className="p-0">
      <Card>
        <Card.Header>Náhled zprávy - {message.name}</Card.Header>
        <Card.Body>{renderHTML(message.text)}</Card.Body>
      </Card>
    </Container>
  );
};

export default MessageFormContainer;

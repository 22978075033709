import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Card, Modal } from 'react-bootstrap';
import { useEffect, useMemo, useState } from 'react';
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';

import Table from '../../components/Table';
import { ISponsorModel } from '../../../models/Sponsor';
import { getSponsors, removeSponsor } from '../../redux/actions/sponsorsActions';
import { AppState } from '../../../redux/reducers';
import { ASSETS_URL } from '../../../constants';

const SponsorListContainer = () => {
  const history = useHistory();
  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const sponsors = useSelector<AppState, { [key: string]: ISponsorModel }>((state) => state.backend.sponsors.items);
  const loading = useSelector<AppState, boolean>((state) => state.backend.sponsors.loadings.all);
  const loadingRemove = useSelector<AppState, boolean>((state) => state.backend.sponsors.loadings.remove);
  const errorRemove = useSelector<AppState, Error | undefined>((state) => state.backend.sponsors.errors.remove);

  const filteredSponsors = useMemo(() => Object.values(sponsors), [sponsors]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getSponsors(token));
    }
  }, []);

  useEffect(() => {
    if (!loadingRemove && !errorRemove) {
      setShowModal(false);
    }
  }, [loadingRemove, errorRemove]);

  const handleToggleDeleteModal = (sponsor?: ISponsorModel) => {
    setSponsor(sponsor);
    setShowModal(!showModal);
  };

  const handleRemove = () => {
    dispatch(removeSponsor(token, sponsor!.id));
  };

  const OptionCell = (row) => (
    <div className="d-flex justify-content-end">
      <Button variant="outline-dark" className="mr-2" onClick={() => history.push(`/admin/sponsors/image/${row.original.id}`)}>
        <FontAwesomeIcon icon={faImage} style={{ cursor: 'pointer' }} />
      </Button>
      <Button variant="outline-dark" className="mr-2" onClick={() => history.push(`/admin/sponsors/edit/${row.original.id}`)}>
        <FontAwesomeIcon icon={faEdit} style={{ cursor: 'pointer' }} />
      </Button>
      <Button variant="danger" onClick={() => handleToggleDeleteModal(row.original)}>
        <FontAwesomeIcon icon={faTrash} style={{ cursor: 'pointer' }} />
      </Button>
    </div>
  );

  const columns: Column[] = [
    {
      id: 'photo',
      Header: 'Fotka',
      accessor: (d: ISponsorModel) =>
        d.hasImage ? (
          <img
            key={`${new Date().getTime().toString()}`}
            style={{ maxWidth: '40px', maxHeight: '40px' }}
            src={`${ASSETS_URL}/sponsors/${d.id}.png`}
            alt="fotka sponzora"
          />
        ) : (
          <FontAwesomeIcon icon={faImage} size="2x" />
        ),
      width: 80,
      style: { textAlign: 'center' },
    },
    {
      id: 'name',
      Header: 'Název',
      accessor: (d: ISponsorModel) => d.name,
    },
    {
      id: 'web',
      Header: 'Web',
      accessor: (d: ISponsorModel) => d.web,
    },
    {
      id: 'phone',
      Header: 'Phone',
      accessor: (d: ISponsorModel) => d.phone,
    },
    {
      id: 'options',
      Header: 'Možnosti',
      Cell: OptionCell,
    },
  ];

  const options = {
    loadingText: 'Načítám sponzory...',
    noDataText: 'Žádní sponzoři k zobrazení',
  };

  const [showModal, setShowModal] = useState(false);
  const [sponsor, setSponsor] = useState<ISponsorModel | undefined>();

  return (
    <Container fluid className="p-0">
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <FontAwesomeIcon icon={faTable} />
              <span className="ml-2">Seznam sponzorů</span>
            </div>
            <Button color="primary" onClick={() => history.push(`/admin/sponsors/add`)}>
              <FontAwesomeIcon icon={faPlus} />
              <span className="ml-2">Přidat sponzora</span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Table
            columns={columns}
            data={filteredSponsors}
            options={{
              loading,
              ...options,
            }}
          />
        </Card.Body>
        <Card.Footer />
      </Card>
      {sponsor && (
        <Modal show={showModal} onHide={handleToggleDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Smazání sponzora</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`Opravdu chcete smazat sponzora: ${sponsor.name}?`}</p>
            <p>Pořádně si to rozmyslete. Tato akce je nenávratná!</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleToggleDeleteModal()}>
              Zrušit
            </Button>
            <Button variant="danger" onClick={() => handleRemove()}>
              Potvrdit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default SponsorListContainer;

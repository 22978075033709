import React, { useEffect, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../redux/reducers';
import { createMeeting, getMeeting, updateMeeting } from '../../redux/actions/meetingsActions';
import { IMeetingModel } from '../../../models/Meeting';
import MeetingForm from '../../forms/MeetingForm';

const MeetingFormContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch<{ id: string }>();
  const { id } = params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loadingAction = useSelector<AppState, boolean>((state) => state.backend.meetings.loadings.create || state.backend.meetings.loadings.update);
  const error = useSelector<AppState, Error | undefined>((state) => state.backend.meetings.errors.create || state.backend.meetings.errors.update);
  const loading = useSelector<AppState, boolean>((state) => state.backend.meetings.loadings.one);
  const meeting = useSelector<AppState, IMeetingModel>((state) => state.backend.meetings.items[id]);

  const dispatch = useDispatch();

  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (!loading && id) {
      dispatch(getMeeting(token, id));
    }
  }, []);

  useEffect(() => {
    if (isSent && !loadingAction && !error) {
      setIsSent(false);
      history.push(`/admin/meetings`);
    }
  }, [isSent, loadingAction, error]);

  const handleAdd = (values, { setSubmitting }) => {
    dispatch(createMeeting(token, values));
    setSubmitting(false);
    setIsSent(true);
  };

  const handleEdit = (values, { setSubmitting }) => {
    dispatch(updateMeeting(token, meeting.id, values));
    setSubmitting(false);
    setIsSent(true);
  };
  return (
    <Container className="p-0">
      <Card>
        <Card.Header>{`Formulář pro ${meeting ? 'úpravu' : 'přidání'} schůze`}</Card.Header>
        <Card.Body>
          <MeetingForm id="meeting-form" data={meeting} onSubmit={meeting ? handleEdit : handleAdd} />
        </Card.Body>
        <Card.Footer>
          <Button type="submit" form="meeting-form" disabled={isSent} className="float-right" variant="primary">
            {`${meeting ? 'Upravit' : 'Přidat'} schůzi`}
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default MeetingFormContainer;

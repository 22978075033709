import axios from 'axios';
import { notify } from 'reapop';

import { endpoints } from '../../../constants/API';
import {
  GALLERY_ALL,
  GALLERY_CREATE,
  GALLERY_ONE,
  GALLERY_PHOTO_REMOVE,
  GALLERY_PHOTO_UPDATE,
  GALLERY_REMOVE,
  GALLERY_UPDATE,
  GalleryAllAction,
  GalleryCreateAction,
  GalleryOneAction,
  GalleryPhotoRemoveAction,
  GalleryPhotoUpdateAction,
  GalleryRemoveAction,
  GalleryUpdateAction,
  IAPhotosFailure,
  IAPhotosLoading,
  PHOTO_FAILURE,
  PHOTO_LOADING,
} from '../types/photosTypes';
import { CategoryWithClub, Subcategory } from '../../../models/Category';
import { PhotoFormValues } from '../../forms/PhotoForm';
import { IGalleryModel, IPhotoModel } from '../../../models/Photo';

export const APhotosLoading = (name: string): IAPhotosLoading => ({
  type: PHOTO_LOADING,
  name,
});

const APhotosFailure = (name: string, error: Error): IAPhotosFailure => ({
  type: PHOTO_FAILURE,
  name,
  error,
});

const successGalleries = (galleries: { [key: number]: IGalleryModel }): GalleryAllAction => ({
  type: GALLERY_ALL,
  galleries,
});

const successGallery = (gallery: IGalleryModel): GalleryOneAction => ({
  type: GALLERY_ONE,
  gallery,
});

const successCreateGallery = (gallery: IGalleryModel): GalleryCreateAction => ({
  type: GALLERY_CREATE,
  gallery,
});

const successUpdateGallery = (galleryId: number, gallery: IGalleryModel): GalleryUpdateAction => ({
  type: GALLERY_UPDATE,
  galleryId,
  gallery,
});

const successRemoveGallery = (id): GalleryRemoveAction => ({
  type: GALLERY_REMOVE,
  galleryId: id,
});

const successUpdatePhoto = (galleryId: number, photoId: number, photo: IPhotoModel): GalleryPhotoUpdateAction => ({
  type: GALLERY_PHOTO_UPDATE,
  galleryId,
  photoId,
  photo,
});

const successRemovePhoto = (galleryId: number, photoId: number): GalleryPhotoRemoveAction => ({
  type: GALLERY_PHOTO_REMOVE,
  galleryId,
  photoId,
});

export const getGalleries =
  (token, category: CategoryWithClub = 'mA', subcategory: Subcategory = undefined) =>
  async (dispatch) => {
    const actionName = 'all';
    dispatch(APhotosLoading(actionName));
    try {
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
        params: {
          category,
          subcategory,
        },
      };
      const response = await axios.get(endpoints.photos, config);
      const data = response.data;
      const galleries = {};
      data.forEach((gallery) => {
        galleries[gallery.id] = gallery;
      });
      dispatch(successGalleries(galleries));
    } catch (e) {
      dispatch(APhotosFailure(actionName, e));
      console.error(e);
    }
  };

export const getGallery = (token: string, galleryId: string) => async (dispatch) => {
  const actionName = 'one';
  dispatch(APhotosLoading(actionName));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${endpoints.photos}/${galleryId}`, config);
    const gallery = response.data;
    dispatch(successGallery(gallery));
  } catch (e) {
    dispatch(APhotosFailure(actionName, e));
    console.error(e);
  }
};

export const createGallery = (token: string, values, photos: Array<File>) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'create';
  dispatch(APhotosLoading(actionName));
  try {
    const response = await axios.post(endpoints.photos, values, config);
    const gallery = response.data;
    dispatch(successCreateGallery(gallery));
    dispatch(notify('Galerie vytvořena.', 'success', { dismissible: false }));
    const { payload: notification } = dispatch(notify('Nahrávání fotek...', 'loading', { dismissible: false }));
    let index = 0;
    for (const photo of photos) {
      dispatch(uploadGalleryPhoto(token, gallery.id, photo, index, notification));
      index++;
    }
    notification.status = 'success';
    notification.message = 'Fotky nahrány.';
    notification.dismissible = true;
    notification.dismissAfter = 3000;
    dispatch(notify(notification));
  } catch (e) {
    dispatch(APhotosFailure(actionName, e));
    dispatch(notify('Galerii se nepodařilo přidat.', 'error'));
  }
};

export const updateGallery = (token: string, galleryId: number, values, photos: Array<File>) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'update';
  dispatch(APhotosLoading(actionName));
  try {
    const response = await axios.put(`${endpoints.photos}/${galleryId}`, values, config);
    const gallery = response.data;
    dispatch(successUpdateGallery(galleryId, gallery));
    dispatch(notify('Galerie upravena.', 'success'));
    const { payload: notification } = dispatch(notify('Nahrávání fotek...'), 'loading', { dismissible: false });
    let index = 0;
    for (const photo of photos) {
      await dispatch(uploadGalleryPhoto(token, gallery.id, photo, index, notification));
      index++;
    }
    notification.status = 'success';
    notification.message = 'Fotky nahrány.';
    notification.dismissible = true;
    notification.dismissAfter = 3000;
    dispatch(notify(notification));
  } catch (e) {
    dispatch(APhotosFailure(actionName, e));
    dispatch(notify('Galerii se nepodařilo upravit.', 'error'));
  }
};

export const removeGallery = (token: string, galleryId: number) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'remove';
  dispatch(APhotosLoading(actionName));
  try {
    await axios.delete(`${endpoints.photos}/${galleryId}`, config);
    dispatch(successRemoveGallery(galleryId));
  } catch (e) {
    dispatch(APhotosFailure(actionName, e));
    console.error(e);
  }
};

export const updatePhoto = (token: string, galleryId: number, photoId: number, values: PhotoFormValues) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'updatePhoto';
  dispatch(APhotosLoading(actionName));
  try {
    const response = await axios.put(`${endpoints.photos}/photo/${photoId}`, values, config);
    const photo = response.data;
    dispatch(successUpdatePhoto(galleryId, photoId, photo));
    dispatch(notify('Fotka upravena.', 'success'));
  } catch (e) {
    dispatch(APhotosFailure(actionName, e));
    dispatch(notify('Fotku se nepodařilo upravit.', 'error'));
  }
};

export const removePhoto = (token: string, galleryId: number, photoId: number) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'removePhoto';
  dispatch(APhotosLoading(actionName));
  try {
    await axios.delete(`${endpoints.photos}/photo/${photoId}`, config);
    dispatch(successRemovePhoto(galleryId, photoId));
    dispatch(notify('Fotka smazána.', 'success'));
  } catch (e) {
    dispatch(APhotosFailure(actionName, e));
    dispatch(notify('Fotku se nepodařilo smazat.', 'error'));
  }
};

export const updatePhotoOrder = (token: string, galleryId: number, photos: Array<IPhotoModel>) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'updatePhoto';
  try {
    let order = 1;
    for (const photo of photos) {
      if (photo.order !== order) {
        dispatch(APhotosLoading(actionName));
        const response = await axios.put(`${endpoints.photos}/photo/${photo.id}`, { order }, config);
        const p = response.data;
        dispatch(successUpdatePhoto(galleryId, p.id, p));
      }
      order++;
    }
    dispatch(notify('Pořadí fotek upraveno.', 'success'));
  } catch (e) {
    dispatch(APhotosFailure(actionName, e));
    dispatch(notify('Pořadí fotek se nepodařilo upravit.', 'error'));
  }
};

export const uploadGalleryPhoto =
  (token: string, galleryId: number, photo: File, index: number, notification: any = undefined) =>
  async (dispatch) => {
    const actionName = 'uploadPhoto';
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
      onUploadProgress: (progressEvent) => {
        notification.message = `Nahrávám fotka č. ${index + 1} ${Math.round(progressEvent.loaded / progressEvent.total)}%.`;
      },
    };
    dispatch(APhotosLoading(actionName));
    try {
      const fileUpload = new FormData();
      fileUpload.append('photo', photo, photo.name);
      await axios.post(`${endpoints.photos}/${galleryId}/photo`, fileUpload, config);
      if (notification) {
        notification.message = `Fotka č. ${index + 1} nahrána.`;
        dispatch(notify(notification));
      }
    } catch (e) {
      dispatch(APhotosFailure(actionName, e));
      dispatch(notify('Fotku se nepodařilo nahrát.', 'error'));
      console.error(e);
    }
  };

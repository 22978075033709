import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Column } from 'react-table';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Card, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';

import Table from '../../components/Table';
import { ICategoryModel } from '../../../models/Category';
import { AppState } from '../../../redux/reducers';
import { getCategories, removeCategory } from '../../redux/actions/categoriesActions';

const CategoriesListContainer = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loading = useSelector<AppState, boolean>((state) => state.backend.categories.loadings.all);
  const categories = useSelector<AppState, { [key: string]: ICategoryModel }>((state) => state.backend.categories.items);

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState<ICategoryModel>();

  useEffect(() => {
    if (!loading) {
      dispatch(getCategories(token));
    }
  }, []);

  const handleToggleDeleteModal = (category?: ICategoryModel) => {
    setShowModal(!showModal);
    setCategoryToDelete(category);
  };

  const handleRemove = () => {
    setShowModal(false);
    dispatch(removeCategory(token, categoryToDelete));
  };

  const OptionCell = (row: any) => (
    <div className="d-flex justify-content-end">
      <Button variant="outline-dark" className="mr-2" onClick={() => history.push(`${match.path}/edit/${row.original.id}`)}>
        <FontAwesomeIcon icon={faEdit} style={{ cursor: 'pointer' }} />
      </Button>
      <Button variant="danger" onClick={() => handleToggleDeleteModal(row.original)}>
        <FontAwesomeIcon icon={faTrash} style={{ cursor: 'pointer' }} />
      </Button>
    </div>
  );

  const columns: Column[] = [
    {
      id: 'id',
      Header: 'ID kategorie',
      accessor: (d: ICategoryModel) => d.id,
    },
    {
      id: 'name',
      Header: 'Název',
      accessor: (d: ICategoryModel) => d.name,
    },
    {
      id: 'subcategory',
      Header: 'Podkategorie',
      accessor: (d: ICategoryModel) => (d.hasSubCategory ? 'ano' : 'ne'),
    },
    {
      id: 'options',
      Header: 'Možnosti',
      Cell: OptionCell,
    },
  ];

  return (
    <Container fluid className="p-0">
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <FontAwesomeIcon icon={faTable} />
              <span className="ml-2">Seznam kategorií</span>
            </div>
            <Button color="primary" onClick={() => history.push(`${match.path}/add`)}>
              <FontAwesomeIcon icon={faPlus} />
              <span className="ml-2">Přidat kategorii</span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Table
            columns={columns}
            data={Object.values(categories)}
            options={{
              loading,
              loadingText: 'Načítám kategorie...',
              noDataText: 'Žádné kategorie k zobrazení',
            }}
          />
        </Card.Body>
        <Card.Footer />
      </Card>
      {categoryToDelete && (
        <Modal show={showModal} onHide={handleToggleDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Smazání kategorii</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`Opravdu chcete smazat kategorii: ${categoryToDelete.name}?`}</p>
            <p>Pořádně si to rozmyslete. Tato akce je nenávratná!</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleToggleDeleteModal()}>
              Zrušit
            </Button>
            <Button variant="primary" onClick={() => handleRemove()}>
              Potvrdit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default CategoriesListContainer;

import * as React from 'react';
import ReactTable, { Column, ReactTableDefaults } from 'react-table';

interface Props {
  data: any[];
  columns: Column[];
  options?: any;
  className?: string;
}

class Table extends React.Component<Props> {
  readonly tableOptions;
  constructor(props) {
    super(props);

    this.tableOptions = {
      ...ReactTableDefaults,
      showPagination: true,
      showPageSizeOptions: true,
      showPageJump: true,
      collapseOnSortingChange: true,
      collapseOnPageChange: true,
      collapseOnDataChange: true,
      freezeWhenExpanded: false,
      filterable: true,
      sortable: true,
      resizable: true,
      defaultPageSize: 10,

      // Texts
      previousText: 'Předchozí strana',
      nextText: 'Další strana',
      noDataText: 'Nenelazena žádná data',
      pageText: 'Strana',
      ofText: 'z',
      rowsText: 'řádků',
    };
  }

  render() {
    const { data, columns, options, className } = this.props;
    return <ReactTable {...this.tableOptions} {...options} data={data} columns={columns} className={className} />;
  }
}

export default Table;

import React, { useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { AppState } from '../../../../redux/reducers';
import { ISponsorModel } from '../../../../models/Sponsor';
import Sponsor from '../../../components/Sponsors/Sponsor';
import { arrayToChunks } from '../../../../utils';

import { SPageContent } from './styled';

const Sponsors = () => {
  const sponsorsItems = useSelector<AppState, { [key: string]: ISponsorModel }>((state) => state.frontend.sponsors.items);

  const sponsors = useMemo(
    () =>
      Object.values(sponsorsItems).sort((a, b) => {
        if (a.hasImage && !b.hasImage) return -1;
        else if (!a.hasImage && b.hasImage) return 1;
        return 0;
      }),
    [sponsorsItems],
  );

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <SPageContent className="p-0 my-5">
      <h2>Sponzoři</h2>
      <Container className="p-0">
        {isMobile
          ? sponsors.map((sponsor) => (
              <Col key={`sponsor_${sponsor.id}`} className="d-flex py-3 justify-content-center align-items-center">
                <Sponsor sponsorId={sponsor.id} />
              </Col>
            ))
          : arrayToChunks(sponsors, 4).map((row, i) => (
              <Row key={`row_${i}`} className="d-flex py-md-4 justify-content-between">
                {row.map((sponsor) => (
                  <Col key={`sponsor_${sponsor.id}`} className="d-flex justify-content-center">
                    <Sponsor sponsorId={sponsor.id} />
                  </Col>
                ))}
                {row.length < 4 ? <Col /> : null}
              </Row>
            ))}
      </Container>
    </SPageContent>
  );
};

export default Sponsors;

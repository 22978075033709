import { IGalleryModel } from '../../../models/Photo';
import { CategoryWithClub, Subcategory } from '../../../models/Category';

export const FRONTEND_PHOTO_LOADING = 'FRONTEND_PHOTO_LOADING';
export const FRONTEND_PHOTO_ERROR = 'FRONTEND_PHOTO_ERROR';
export const FRONTEND_PHOTO_ALL = 'FRONTEND_GALLERY_ALL';
export const FRONTEND_PHOTO_ONE = 'FRONTEND_PHOTO_ONE';

export interface IAPhotoLoading {
  type: typeof FRONTEND_PHOTO_LOADING;
  name: string;
}

export interface IAPhotoError {
  type: typeof FRONTEND_PHOTO_ERROR;
  name: string;
  error: Error;
}

export interface IAPhotoAll {
  type: typeof FRONTEND_PHOTO_ALL;
  category: CategoryWithClub;
  subcategory: Subcategory;
  galleries: { [key: string]: IGalleryModel };
}

export interface IAPhotoOne {
  type: typeof FRONTEND_PHOTO_ONE;
  gallery: IGalleryModel;
}

export type PhotoActionsTypes = IAPhotoLoading | IAPhotoError | IAPhotoAll | IAPhotoOne;

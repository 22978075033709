import { Formik, FormikActions } from 'formik';
import * as React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { Category, ICategoryModel, Subcategory } from '../../../models/Category';
import { ICompetitionModel } from '../../../models/Competition';
import { AppState } from '../../../redux/reducers';

import validationSchema from './validation';

export interface CompetitionFormValues {
  category: Category;
  subcategory: Subcategory;
  name: string;
  url: string;
}

type Props = {
  data?: ICompetitionModel;
  onSubmit: (values: CompetitionFormValues, actions: FormikActions<CompetitionFormValues>) => void;
};

const CompetitionForm = (props: Props) => {
  const { data, onSubmit } = props;

  const categories = useSelector<AppState, { [key: string]: ICategoryModel }>((state) => state.backend.categories.items);

  if (!Object.values(categories).length) return null;

  return (
    <Formik
      initialValues={{
        category: data ? data.category : 'mA',
        subcategory: data ? data.subcategory : '',
        name: data ? data.name : '',
        url: data ? data.url : '',
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values: CompetitionFormValues, actions: FormikActions<CompetitionFormValues>) => {
        onSubmit(values, actions);
      }}>
      {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} controlId="category">
              <Form.Label>Kategorie</Form.Label>
              <Form.Control
                as="select"
                name="category"
                placeholder="Zvolte kategorii"
                value={values.category}
                disabled={isSubmitting || !!data}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={errors.category && touched.category}>
                {Object.values(categories).map((category) => (
                  <option key={`category_${category.id}`} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.category}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          {categories[values.category].hasSubCategory && (
            <Form.Row>
              <Form.Group as={Col} controlId="subcategory">
                <Form.Label>Podkategorie</Form.Label>
                <Form.Control
                  as="select"
                  name="category"
                  placeholder="Zvolte podkategorii"
                  value={values.subcategory}
                  disabled={isSubmitting || !!data}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.subcategory && touched.subcategory}>
                  <option value="st">Starší</option>
                  <option value="ml">Mladší</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.subcategory}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          )}
          <Form.Row>
            <Form.Group as={Col} controlId="name">
              <Form.Label>Název soutěže</Form.Label>
              <Form.Control
                type="text"
                name="name"
                autoComplete="off"
                placeholder="Zadejte název soutěže"
                value={values.name}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={errors.name && touched.name}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="url">
              <Form.Label>URL soutěže na FAČR</Form.Label>
              <Form.Control
                type="text"
                name="url"
                autoComplete="off"
                placeholder="Zadejte URL soutěže na FAČR"
                value={values.url}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={errors.url && touched.url}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.url}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Row>
            <Col>
              <Button type="submit" disabled={isSubmitting} color="primary">
                {`${data ? 'Upravit' : 'Přidat'} soutěž`}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default CompetitionForm;

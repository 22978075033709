import { ICompetitionModel } from '../../../models/Competition';

export const FRONTEND_COMPETITIONS_LOADING = 'FRONTEND_COMPETITIONS_LOADING';
export const FRONTEND_COMPETITIONS_ERROR = 'FRONTEND_COMPETITIONS_ERROR';
export const FRONTEND_COMPETITIONS_ALL = 'FRONTEND_COMPETITIONS_ALL';

export interface IACompetitionsLoading {
  type: typeof FRONTEND_COMPETITIONS_LOADING;
  name: string;
}

export interface IACompetitionsError {
  type: typeof FRONTEND_COMPETITIONS_ERROR;
  name: string;
  error: Error;
}

export interface IACompetitionsAll {
  type: typeof FRONTEND_COMPETITIONS_ALL;
  competitions: { [key: string]: ICompetitionModel };
}

export type TCompetitionsActions = IACompetitionsLoading | IACompetitionsError | IACompetitionsAll;

import { IArticleModel } from '../../../models/Article';

export const ARTICLES_LOADING = 'ARTICLES_LOADING';
export const ARTICLES_ERROR = 'ARTICLES_ERROR';
export const ARTICLES_ALL = 'ARTICLES_ALL';
export const ARTICLES_ONE = 'ARTICLES_ONE';
export const ARTICLES_CREATE = 'ARTICLES_CREATE';
export const ARTICLES_UPDATE = 'ARTICLES_UPDATE';
export const ARTICLES_REMOVE = 'ARTICLES_REMOVE';

export interface ArticlesLoadingAction {
  type: typeof ARTICLES_LOADING;
  name: string;
}

export interface ArticlesErrorAction {
  type: typeof ARTICLES_ERROR;
  name: string;
  error: Error;
}

export interface ArticlesAllAction {
  type: typeof ARTICLES_ALL;
  articles: { [key: number]: IArticleModel };
}

export interface ArticlesOneAction {
  type: typeof ARTICLES_ONE;
  article: IArticleModel;
}

export interface ArticlesCreateAction {
  type: typeof ARTICLES_CREATE;
  article: IArticleModel;
}

export interface ArticlesUpdateAction {
  type: typeof ARTICLES_UPDATE;
  article: IArticleModel;
}

export interface ArticlesRemoveAction {
  type: typeof ARTICLES_REMOVE;
  articleId: string;
}

export interface ArticlesState {
  loadings: {
    getArticles?: boolean;
    getArticleDetail?: boolean;
    createArticle?: boolean;
    updateArticle?: boolean;
    removeArticle?: boolean;
  };
  errors: {
    getArticles?: Error;
    getArticleDetail?: Error;
    createArticle?: Error;
    updateArticle?: Error;
    removeArticle?: Error;
  };
  categories: {
    mA: object;
    mB: object;
    do: object;
    za: object;
    pr: object;
    sk: object;
  };
}

export type ArticlesActionTypes =
  | ArticlesErrorAction
  | ArticlesLoadingAction
  | ArticlesAllAction
  | ArticlesOneAction
  | ArticlesCreateAction
  | ArticlesUpdateAction
  | ArticlesRemoveAction;

import { UserModel } from '../../../models/User';

export const USERS_LOADING = 'USERS_LOADING';
export const USERS_FAILURE = 'USERS_FAILURE';
export const USERS_ALL = 'USERS_ALL';
export const USERS_ONE = 'USERS_ONE';
export const USERS_CREATE = 'USERS_CREATE';
export const USERS_UPDATE = 'USERS_UPDATE';
export const USERS_REMOVE = 'USERS_REMOVE';

export interface IAUsersLoading {
  type: typeof USERS_LOADING;
  name: string;
}

export interface IAUsersFailure {
  type: typeof USERS_FAILURE;
  name: string;
  error: Error;
}

export interface IAUsersAll {
  type: typeof USERS_ALL;
  users: UserModel[];
}

export interface IAUsersOne {
  type: typeof USERS_ONE;
  user: UserModel;
}

export interface IAUsersCreate {
  type: typeof USERS_CREATE;
  user: UserModel;
}

export interface IAUsersUpdate {
  type: typeof USERS_UPDATE;
  user: UserModel;
}

export interface IAUsersRemove {
  type: typeof USERS_REMOVE;
  userId: string;
}

export type UsersActionTypes = IAUsersLoading | IAUsersFailure | IAUsersAll | IAUsersOne | IAUsersCreate | IAUsersUpdate | IAUsersRemove;

import React from 'react';

import { Category, Subcategory } from '../../../models/Category';

import { SMatchesOverview } from './styles';
import FirstLastMatchTabs from './FirstLastMatchTabs';

type Props = {
  category: Category;
  subcategory: Subcategory;
};

const MatchesOverviewCategory = (props: Props) => {
  const { category, subcategory } = props;

  return (
    <SMatchesOverview>
      <h3>Zápasy</h3>
      <FirstLastMatchTabs category={category} subcategory={subcategory} />
    </SMatchesOverview>
  );
};

export default MatchesOverviewCategory;

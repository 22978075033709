import * as React from 'react';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, Container } from 'react-bootstrap';

import UserDetailContainer from './UserDetailContainer';
import UserFormContainer from './UserFormContainer';
import UserListContainer from './UserListContainer';

const UsersContainer = () => {
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <Container fluid className="p-0">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item active={location.pathname === match.path}>
          {location.pathname === match.path ? 'Správci' : <Link to={match.path}>Správci</Link>}
        </Breadcrumb.Item>
        {location.pathname.includes('/detail') && <Breadcrumb.Item active>Detail správce</Breadcrumb.Item>}
        {location.pathname.includes('/add') && <Breadcrumb.Item active>Nový správce</Breadcrumb.Item>}
        {location.pathname.includes('/edit') && <Breadcrumb.Item active>Úprava správce</Breadcrumb.Item>}
      </Breadcrumb>
      <Switch>
        <Route path={`${match.path}/add`} component={UserFormContainer} />
        <Route path={`${match.path}/edit/:id`} component={UserFormContainer} />
        <Route path={`${match.path}/detail/:id`} component={UserDetailContainer} />
        <Route exact path={match.path} component={UserListContainer} />
      </Switch>
    </Container>
  );
};

export default UsersContainer;

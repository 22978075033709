import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Column } from 'react-table';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Card, Modal } from 'react-bootstrap';
import { useEffect, useMemo, useState } from 'react';
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';

import Table from '../../components/Table';
import { Category, Subcategory } from '../../../models/Category';
import { ITeamModel } from '../../../models/Team';
import { getTeams, removeTeam } from '../../redux/actions/teamsActions';
import CategorySwitch from '../../components/CategorySwitch';
import { AppState } from '../../../redux/reducers';
import { ASSETS_URL } from '../../../constants';

const TeamListContainer = () => {
  const history = useHistory();
  const match = useRouteMatch<{ category: Category; subcategory: Subcategory }>();
  let { category, subcategory } = match.params;

  if (!category) category = 'mA';

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loading = useSelector<AppState, boolean>((state) => state.backend.teams.loadings.all);
  const teams = useSelector<AppState, { [key: string]: ITeamModel }>((state) => state.backend.teams.items);
  const loadingRemove = useSelector<AppState, boolean>((state) => state.backend.teams.loadings.remove);
  const errorRemove = useSelector<AppState, Error | undefined>((state) => state.backend.teams.errors.remove);

  const filteredTeams = useMemo(
    () => Object.values(teams).filter((team) => (subcategory ? team.category === category && team.subcategory === subcategory : team.category === category)),
    [category, subcategory, teams],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getTeams(token, category, subcategory));
    }
  }, [category, subcategory]);

  useEffect(() => {
    if (!loadingRemove && !errorRemove) {
      setShowModal(false);
    }
  }, [loadingRemove, errorRemove]);

  const handleToggleDeleteModal = (team?: ITeamModel) => {
    setTeam(team);
    setShowModal(!showModal);
  };

  const handleRemove = () => {
    dispatch(removeTeam(token, team!.id));
  };

  const OptionCell = (row) => (
    <div className="d-flex justify-content-end">
      <Button
        variant="outline-dark"
        className="mr-2"
        onClick={() => history.push(`/admin/teams/${category}${subcategory ? `/${subcategory}` : ''}/image/${row.original.id}`)}>
        <FontAwesomeIcon icon={faImage} style={{ cursor: 'pointer' }} />
      </Button>
      <Button
        variant="outline-dark"
        className="mr-2"
        onClick={() => history.push(`/admin/teams/${category}${subcategory ? `/${subcategory}` : ''}/edit/${row.original.id}`)}>
        <FontAwesomeIcon icon={faEdit} style={{ cursor: 'pointer' }} />
      </Button>
      <Button variant="danger" onClick={() => handleToggleDeleteModal(row.original)}>
        <FontAwesomeIcon icon={faTrash} style={{ cursor: 'pointer' }} />
      </Button>
    </div>
  );

  const columns: Column[] = [
    {
      id: 'logo',
      Header: 'Logo',
      accessor: (d: ITeamModel) => (
        <img
          key={`${new Date().getTime().toString()}`}
          style={{ maxWidth: '40px', maxHeight: '40px' }}
          src={`${ASSETS_URL}/teams/${d.id}.png`}
          alt="team logo"
        />
      ),
      width: 80,
      style: { textAlign: 'center' },
    },
    {
      id: 'name',
      Header: 'Název týmu',
      accessor: (d: ITeamModel) => d.name,
    },
    {
      id: 'nameFACR',
      Header: 'Název týmu na FAČR',
      accessor: (d: ITeamModel) => d.nameFACR,
    },
    {
      id: 'options',
      Header: 'Možnosti',
      Cell: OptionCell,
    },
  ];

  const options = {
    loadingText: 'Načítám týmy...',
    noDataText: 'Žádné týmy k zobrazení',
  };

  const [showModal, setShowModal] = useState(false);
  const [team, setTeam] = useState<ITeamModel | undefined>();

  return (
    <Container fluid className="p-0">
      <CategorySwitch />
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <FontAwesomeIcon icon={faTable} />
              <span className="ml-2">Seznam týmů</span>
            </div>
            <Button color="primary" onClick={() => history.push(`/admin/teams/${category}${subcategory ? `/${subcategory}` : ''}/add`)}>
              <FontAwesomeIcon icon={faPlus} />
              <span className="ml-2">Přidat tým</span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Table
            columns={columns}
            data={filteredTeams}
            options={{
              loading,
              ...options,
            }}
          />
        </Card.Body>
        <Card.Footer />
      </Card>
      {team && (
        <Modal show={showModal} onHide={handleToggleDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Smazání týmu</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`Opravdu chcete smazat tým: ${team.name}?`}</p>
            <p>Pořádně si to rozmyslete. Tato akce je nenávratná!</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleToggleDeleteModal()}>
              Zrušit
            </Button>
            <Button variant="danger" onClick={() => handleRemove()}>
              Potvrdit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default TeamListContainer;

import axios from 'axios';
import { Dispatch } from 'redux';

import { endpoints } from '../../../constants/API';
import { UserModel } from '../../../models/User';
import * as localStorage from '../../../storage/localStorage';
import * as sessionStorage from '../../../storage/sessionStorage';
import { LOADING_USER, LoadingUserAction, LOGIN_USER, LoginUserAction, LOGOUT_USER, LogOutUserAction, USER_ME, UserMeAction } from '../types/userTypes';
import { LoginFormValues } from '../../forms/LoginForm';

const loadingUser = (): LoadingUserAction => ({
  type: LOADING_USER,
});

const successLogIn = (userData: UserModel, token: string): LoginUserAction => ({
  type: LOGIN_USER,
  token,
  userData,
});

const successLogOut = (): LogOutUserAction => ({
  type: LOGOUT_USER,
});

const successMe = (userData: UserModel): UserMeAction => ({
  type: USER_ME,
  userData,
});

export const logIn = (values: LoginFormValues) => async (dispatch: Dispatch) => {
  try {
    dispatch(loadingUser());
    const response = await axios.post(endpoints.auth, {
      username: values.username,
      password: values.password,
    });
    const { token, user } = response.data;
    // uložení tokenu
    values.persistent ? localStorage.saveState('FDU:token', token) : sessionStorage.saveState('FDU:token', token);
    dispatch(successLogIn(user, token));
  } catch (e) {
    console.error(e);
  }
};

export const logOut = () => async (dispatch) => {
  sessionStorage.removeState('FDU:token');
  localStorage.removeState('FDU:token');
  dispatch(successLogOut());
};

export const getMe = (token) => async (dispatch) => {
  dispatch(loadingUser());
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${endpoints.users}/me`, config);
    const userData = response.data;
    dispatch(successMe(userData));
  } catch (e) {
    console.error(e);
  }
};

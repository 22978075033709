import styled from 'styled-components';

import { images } from '../../constants/assets';

import { colors } from './basicStyles';

interface MenuItemProps {
  active: boolean;
}

export const HomeWrapper = styled.div`
  font-family: 'Roboto', sans-serif;
`;

export const LoginBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  background: url(${images.backend.bg});
  background-size: cover;
  justify-content: center;
  align-items: center;
`;

export const LoginWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const MenuItem = styled.li`
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top: 0;
  border-right: 0;
  a {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    color: ${(props: MenuItemProps) => (props.active ? colors.blue : 'rgba(255, 255, 255, 0.5)')};
    &:hover {
      color: ${(props: MenuItemProps) => (props.active ? colors.blue : 'rgba(255, 255, 255, 0.75)')};
    }
  }
  i {
    width: 50px;
    text-align: center;
  }
`;

import axios from 'axios';

import { endpoints } from '../../../constants/API';
import { CategoryWithClub, Subcategory } from '../../../models/Category';
import {
  FRONTEND_VIDEO_ERROR,
  FRONTEND_VIDEO_LOADING,
  FRONTEND_VIDEO_ALL,
  IAVideoAll,
  IAVideoError,
  IAVideoLoading,
  IAVideoOne,
  FRONTEND_VIDEO_ONE,
} from '../types/videosTypes';
import { processVideos } from '../helpers/videosHelper';
import { IVideoModel } from '../../../models/Video';

export const AVideosLoading = (name: string): IAVideoLoading => ({
  type: FRONTEND_VIDEO_LOADING,
  name,
});

const AVideosError = (name: string, error: Error): IAVideoError => ({
  type: FRONTEND_VIDEO_ERROR,
  name,
  error,
});

const AVideosAll = (category: CategoryWithClub, subcategory: Subcategory, videos: { [key: string]: IVideoModel }): IAVideoAll => ({
  type: FRONTEND_VIDEO_ALL,
  category,
  subcategory,
  videos,
});

const AVideosOne = (video: IVideoModel): IAVideoOne => ({
  type: FRONTEND_VIDEO_ONE,
  video,
});

export const getVideos = (category: CategoryWithClub, subcategory?: Subcategory) => async (dispatch) => {
  const actionName = 'all';
  dispatch(AVideosLoading(actionName));
  try {
    const config = {
      params: {
        category,
        subcategory,
      },
    };
    const response = await axios.get(`${endpoints.videos}`, config);
    const data = response.data;
    const { all } = processVideos(data);
    dispatch(AVideosAll(category, subcategory, all));
  } catch (e) {
    dispatch(AVideosError(actionName, e));
    console.error(e);
  }
};

export const getVideo = (videoId: string) => async (dispatch) => {
  const actionName = 'one';
  dispatch(AVideosLoading(actionName));
  try {
    const config = {};
    const response = await axios.get(`${endpoints.videos}/${videoId}`, config);
    const data = response.data;
    dispatch(AVideosOne(data));
  } catch (e) {
    dispatch(AVideosError(actionName, e));
    console.error(e);
  }
};

import { ICategoryModel } from '../../../models/Category';
import {
  CATEGORIES_ALL,
  CATEGORIES_CREATE,
  CATEGORIES_ERROR,
  CATEGORIES_LOADING,
  CATEGORIES_ONE,
  CATEGORIES_REMOVE,
  CATEGORIES_UPDATE,
  CategoriesActionTypes,
} from '../types/categoriesTypes';

export interface CategoriesState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error | undefined };
  items: { [key: string]: ICategoryModel };
}

const init = (): CategoriesState => ({
  loadings: {},
  errors: {},
  items: {},
});

const categories = (currentState = init(), action: CategoriesActionTypes): CategoriesState => {
  let newState;
  switch (action.type) {
    case CATEGORIES_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
      };
    case CATEGORIES_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case CATEGORIES_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        items: {
          ...currentState.items,
          ...action.categories,
        },
      };
    case CATEGORIES_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        items: {
          ...currentState.items,
          [action.category.id]: {
            ...currentState.items[action.category.id],
            ...action.category,
          },
        },
      };
    case CATEGORIES_CREATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          create: false,
        },
        errors: {
          ...currentState.errors,
          create: undefined,
        },
        items: {
          ...currentState.items,
          [action.category.id]: action.category,
        },
      };
    case CATEGORIES_UPDATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          update: false,
        },
        errors: {
          ...currentState.errors,
          update: undefined,
        },
        items: {
          ...currentState.items,
          [action.category.id]: {
            ...currentState.items[action.category.id],
            ...action.category,
          },
        },
      };
    case CATEGORIES_REMOVE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          remove: false,
        },
        errors: {
          ...currentState.errors,
          remove: undefined,
        },
        items: {
          ...currentState.items,
        },
      };
      delete newState.items[action.categoryId];
      return newState;
    default:
      return currentState;
  }
};

export default categories;

import * as React from 'react';
import { Link } from 'react-router-dom';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { Navbar } from 'react-bootstrap';

import UserDropdown from '../components/UserDropdown';

class Header extends React.Component<{}, {}> {
  render() {
    return (
      <Navbar variant="dark" bg="dark" expand className="sb-topnav px-1">
        <Link to="/admin" className="navbar-brand">
          {/*<img className="ml-1 mr-2" src={logo} width={40} height={40} alt="logo" />*/}
          TJ Sokol Dolní Újezd
        </Link>
        <Button variant="link" className="btn-sm order-1 order-lg-0" id="sidebarToggle">
          <FontAwesomeIcon icon={faBars} />
        </Button>
        <Navbar variant="dark" className="navbar-nav ml-md-0 p-0 w-100 d-flex flex-row justify-content-end">
          <UserDropdown />
        </Navbar>
      </Navbar>
    );
  }
}

export default Header;

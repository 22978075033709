import React, { useCallback, useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import '@ckeditor/ckeditor5-build-classic/build/translations/cs';

interface Props {
  name: string;
  placeholder: string;
  value: string;
  disabled: boolean;
  isInvalid: boolean;
  onChange: (e: React.ChangeEvent) => void;
}

const TextEditor = (props: Props) => {
  const { name, placeholder, value, disabled, isInvalid, onChange } = props;

  let [editor, setEditor] = useState(undefined);

  const checkError = useCallback(
    (editor) => {
      editor.sourceElement.setAttribute('aria-invalid', isInvalid ? 'true' : 'false');
      editor.sourceElement.setAttribute('class', isInvalid ? 'is-invalid' : '');
    },
    [isInvalid],
  );

  useEffect(() => {
    if (editor) {
      checkError(editor);
    }
  }, [editor, checkError]);

  return (
    <CKEditor
      editor={ClassicEditor}
      config={{
        language: 'cs',
        placeholder: placeholder,
        name,
        disabled,
      }}
      data={value}
      onReady={(editor) => {
        setEditor(editor);
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        onChange(data);
      }}
    />
  );
};

export default TextEditor;

import {
  FRONTEND_TOURNAMENTS_ERROR,
  FRONTEND_TOURNAMENTS_LOADING,
  FRONTEND_TOURNAMENTS_FIRST_AND_LAST,
  TournamentsActionsTypes,
  FRONTEND_TOURNAMENTS_ALL,
} from '../types/tournamentsTypes';
import { ITournamentCategoriesRedux, ITournamentSubcategoriesRedux, ITournamentModel } from '../../../models/Tournament';

export interface ITournamentsState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error };
  items: { [key: string]: ITournamentModel };
  categories: ITournamentCategoriesRedux;
  subcategories: ITournamentSubcategoriesRedux;
}

const initialState: ITournamentsState = {
  loadings: {},
  errors: {},
  items: {},
  categories: {},
  subcategories: {},
};

const tournaments = (currentState: ITournamentsState = initialState, action: TournamentsActionsTypes): ITournamentsState => {
  let newState;
  switch (action.type) {
    case FRONTEND_TOURNAMENTS_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case FRONTEND_TOURNAMENTS_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case FRONTEND_TOURNAMENTS_ALL:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        items: {
          ...currentState.items,
          ...action.all,
        },
        categories: {
          ...currentState.categories,
        },
        subcategories: {
          ...currentState.subcategories,
        },
      };
      for (const category in action.categories) {
        if (action.categories[category]) {
          newState.categories[category] = {
            ...newState.categories[category],
            parts: {
              ...(newState.categories[category] ? newState.categories[category].parts : undefined),
              ...action.categories[category].parts,
            },
          };
        }
      }
      for (const category in action.subcategories) {
        if (action.subcategories[category]) {
          for (const subcategory in action.subcategories[category]) {
            if (action.subcategories[category][subcategory]) {
              newState.subcategories[category] = {
                ...newState.subcategories[category],
                [subcategory]: {
                  ...(newState.subcategories[category] ? newState.subcategories[category][subcategory] : undefined),
                  parts: {
                    ...(newState.subcategories[category] && newState.subcategories[category][subcategory]
                      ? newState.subcategories[category][subcategory].parts
                      : undefined),
                    ...action.subcategories[category][subcategory].parts,
                  },
                },
              };
            }
          }
        }
      }
      return newState;
    case FRONTEND_TOURNAMENTS_FIRST_AND_LAST:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          firstAndLast: false,
        },
        categories: {
          ...currentState.categories,
        },
        subcategories: {
          ...currentState.subcategories,
        },
      };
      if (action.category && action.subcategory) {
        newState.subcategories[action.category] = {
          ...currentState.subcategories[action.category],
          [action.subcategory]: {
            ...(currentState.subcategories[action.category] ? currentState.subcategories[action.category][action.subcategory] : undefined),
            first: action.first,
            last: action.last,
          },
        };
      } else {
        newState.categories[action.category] = {
          ...currentState.categories[action.category],
          first: action.first,
          last: action.last,
        };
      }
      return newState;
    default:
      return currentState;
  }
};

export default tournaments;

import { IArticleModel, IArticleBaseModel, IArticleCategoriesRedux, IArticleSubcategoriesRedux } from '../../../models/Article';

export const FRONTEND_ARTICLES_LOADING = 'FRONTEND_ARTICLES_LOADING';
export const FRONTEND_ARTICLES_ERROR = 'FRONTEND_ARTICLES_ERROR';
export const FRONTEND_ARTICLES_ALL = 'FRONTEND_ARTICLES_ALL';
export const FRONTEND_ARTICLES_ONE = 'FRONTEND_ARTICLES_ONE';
export const FRONTEND_ARTICLES_LAST = 'FRONTEND_ARTICLES_LAST';

export interface IAArticlesLoading {
  type: typeof FRONTEND_ARTICLES_LOADING;
  name: string;
}

export interface IAArticlesError {
  type: typeof FRONTEND_ARTICLES_ERROR;
  name: string;
  error: Error;
}

export interface IAArticlesAll {
  type: typeof FRONTEND_ARTICLES_ALL;
  all: { [key: string]: IArticleModel };
  categories: IArticleCategoriesRedux;
  subcategories: IArticleSubcategoriesRedux;
}
export interface IAArticlesOne {
  type: typeof FRONTEND_ARTICLES_ONE;
  article: IArticleModel;
}

export interface IAArticlesLast {
  type: typeof FRONTEND_ARTICLES_LAST;
  all: { [key: string]: IArticleModel };
  categories: { [key: string]: Array<IArticleBaseModel> };
  last: Array<IArticleBaseModel>;
}

export type ArticlesActionsTypes = IAArticlesLoading | IAArticlesError | IAArticlesAll | IAArticlesOne | IAArticlesLast;

import styled from 'styled-components';

export const Handle = styled.div`
  flex: none;
  width: 7.5px;
  height: 100%;
  cursor: grab;

  &::before {
    content: '';
    border-left: 4px dotted #ccc;
    display: block;
    height: 20px;
    margin: 15px 3px;
  }

  &:hover::before {
    border-color: #888;
  }
`;

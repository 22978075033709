import backendBg from '../backend/assets/bg.jpg';
import backendLogo from '../backend/assets/logo.png';
import backendSokol from '../backend/assets/sokol.png';
import frontendBg from '../frontend/assets/bg.png';
import frontendLogo from '../frontend/assets/logo.png';

export const images = {
  backend: {
    bg: backendBg,
    logo: backendLogo,
    sokol: backendSokol,
  },
  frontend: {
    bg: frontendBg,
    logo: frontendLogo,
  },
};

import { IMessageModel } from '../../../models/Message';

export const FRONTEND_MESSAGE_LOADING = 'FRONTEND_MESSAGE_LOADING';
export const FRONTEND_MESSAGE_ERROR = 'FRONTEND_MESSAGE_ERROR';
export const FRONTEND_MESSAGE_ALL = 'FRONTEND_MESSAGE_ALL';
export const FRONTEND_MESSAGE_ONE = 'FRONTEND_MESSAGE_ONE';
export const FRONTEND_MESSAGE_LAST = 'FRONTEND_MESSAGE_LAST';

export interface IAMessageLoading {
  type: typeof FRONTEND_MESSAGE_LOADING;
  name: string;
}

export interface IAMessageError {
  type: typeof FRONTEND_MESSAGE_ERROR;
  name: string;
  error: Error;
}

export interface IAMessageAll {
  type: typeof FRONTEND_MESSAGE_ALL;
  messages: { [key: string]: IMessageModel };
}

export interface IAMessageOne {
  type: typeof FRONTEND_MESSAGE_ONE;
  message: IMessageModel;
}

export interface IAMessageLast {
  type: typeof FRONTEND_MESSAGE_LAST;
  message: IMessageModel;
}

export type MessageActionsTypes = IAMessageLoading | IAMessageError | IAMessageAll | IAMessageOne | IAMessageLast;

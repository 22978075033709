import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

import { Category, Subcategory } from '../../../../models/Category';
import { getGalleries } from '../../../redux/actions/photosActions';
import { AppState } from '../../../../redux/reducers';
import { arrayToChunks } from '../../../../utils';
import Gallery from '../../../components/Photos/Gallery';

import { SPageContent, SNoData } from './styled';

type TProps = {
  category: Category;
  subcategory?: Subcategory;
};

const CategoryPhotos = (props: TProps) => {
  const { category, subcategory } = props;

  const isTablet = useMediaQuery({ query: '(max-width: 991px)' });

  const loading = useSelector<AppState>((state) => state.frontend.photos.loadings.all);
  const galleries = useSelector<AppState>((state) =>
    subcategory && state.frontend.photos.subcategories[category]
      ? state.frontend.photos.subcategories[category][subcategory]
      : state.frontend.photos.categories[category],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getGalleries(category, subcategory));
    }
  }, []);

  const galleriesArray = useMemo(
    () => (galleries ? Object.values(galleries).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) : []),
    [galleries],
  );

  return (
    <SPageContent className="p-0 my-5">
      <h2>Fotogalerie</h2>
      <Container className="p-0">
        {galleriesArray.length ? (
          arrayToChunks(galleriesArray, isTablet ? 2 : 3).map((chunk, index) => (
            <Row key={`photos_row_${index}`}>
              {chunk.length
                ? chunk.map((gallery) => (
                    <Col md={6} lg={4} key={`photos_row_col_${gallery.id}`}>
                      <Gallery galleryId={gallery.id} />
                    </Col>
                  ))
                : null}
            </Row>
          ))
        ) : (
          <SNoData>Žádné galerie k zobrazení</SNoData>
        )}
      </Container>
    </SPageContent>
  );
};

export default CategoryPhotos;

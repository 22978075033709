import {
  SPONSORS_ALL,
  SPONSORS_CREATE,
  SPONSORS_ERROR,
  SPONSORS_LOADING,
  SPONSORS_ONE,
  SPONSORS_REMOVE,
  SPONSORS_UPDATE,
  SponsorsActionTypes,
} from '../types/sponsorsTypes';

export interface SponsorsState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error | undefined };
  items: {};
}

const initialState: SponsorsState = {
  loadings: {},
  errors: {},
  items: {},
};

const sponsors = (currentState: SponsorsState = initialState, action: SponsorsActionTypes): SponsorsState => {
  let newState;
  switch (action.type) {
    case SPONSORS_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case SPONSORS_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case SPONSORS_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        errors: {
          ...currentState.errors,
          all: undefined,
        },
        items: {
          ...currentState.items,
          ...action.sponsors,
        },
      };
    case SPONSORS_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        errors: {
          ...currentState.errors,
          one: undefined,
        },
        items: {
          ...currentState.items,
          [action.sponsor.id]: action.sponsor,
        },
      };
    case SPONSORS_CREATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          create: false,
        },
        errors: {
          ...currentState.errors,
          create: undefined,
        },
        items: {
          ...currentState.items,
          [action.sponsor.id]: action.sponsor,
        },
      };
    case SPONSORS_UPDATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          update: false,
        },
        errors: {
          ...currentState.errors,
          update: undefined,
        },
        items: {
          ...currentState.items,
          [action.sponsor.id]: {
            ...currentState.items[action.sponsor.id],
            ...action.sponsor,
          },
        },
      };
    case SPONSORS_REMOVE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          remove: false,
        },
        errors: {
          ...currentState.errors,
          remove: undefined,
        },
        items: {
          ...currentState.items,
        },
      };
      if (newState.items[action.sponsorId]) {
        delete newState.items[action.sponsorId];
      }
      return newState;
    default:
      return currentState;
  }
};

export default sponsors;

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useEffect } from 'react';

import DashboardContainer from '../containers/DashboardContainer';
import UsersContainer from '../containers/UsersContainer';
import { getMe } from '../redux/actions/userActions';
import MatchesContainer from '../containers/MatchesContainer';
import MessagesContainer from '../containers/MessagesContainer';
import TeamsContainer from '../containers/TeamsContainer';
import ArticlesContainer from '../containers/ArticlesContainer';
import { AppState } from '../../redux/reducers';
import PlayersContainer from '../containers/PlayersContainer';
import PhotosContainer from '../containers/PhotosContainer';
import VideosContainer from '../containers/VideosContainer';
import CategoriesContainer from '../containers/CategoriesContainer';
import CompetitionsContainer from '../containers/CompetitionsContainer';
import TablesContainer from '../containers/TablesContainer';
import { getCategories } from '../redux/actions/categoriesActions';
import SponsorsContainer from '../containers/SponsorsContainer';
import MeetingsContainer from '../containers/MeetingsContainer';
import EventsContainer from '../containers/EventsContainer';
import TournamentsContainer from '../containers/TournamentsContainer';
import ArchiveContainer from '../containers/ArchiveContainer';

import Header from './Header';
import Sidebar from './Sidebar';

const Backend = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const loading = useSelector<AppState, boolean>((state) => state.backend.user.loading);
  const token = useSelector<AppState, string>((state) => state.backend.user.token);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      if (token) {
        dispatch(getMe(token));
        dispatch(getCategories(token));
      } else {
        history.push('/logIn');
      }
    }
  }, []);

  useEffect(() => {
    if (!token) {
      history.push('/logIn');
    }
  }, [token, history]);

  return (
    <>
      <Header />
      <div id="layoutSidenav">
        <Sidebar />
        <div id="layoutSidenav_content">
          <main>
            <Container fluid className="p-4">
              <Switch>
                <Route exact path={match.path} component={DashboardContainer} />
                <Route path={`${match.path}/archive`} component={ArchiveContainer} />
                <Route path={`${match.path}/articles`} component={ArticlesContainer} />
                <Route path={`${match.path}/categories`} component={CategoriesContainer} />
                <Route path={`${match.path}/competitions`} component={CompetitionsContainer} />
                <Route path={`${match.path}/dashboard`} component={DashboardContainer} />
                <Route path={`${match.path}/events`} component={EventsContainer} />
                <Route path={`${match.path}/matches`} component={MatchesContainer} />
                <Route path={`${match.path}/meetings`} component={MeetingsContainer} />
                <Route path={`${match.path}/messages`} component={MessagesContainer} />
                <Route path={`${match.path}/photos`} component={PhotosContainer} />
                <Route path={`${match.path}/players`} component={PlayersContainer} />
                <Route path={`${match.path}/sponsors`} component={SponsorsContainer} />
                <Route path={`${match.path}/tables`} component={TablesContainer} />
                <Route path={`${match.path}/tournaments`} component={TournamentsContainer} />
                <Route path={`${match.path}/teams`} component={TeamsContainer} />
                <Route path={`${match.path}/users`} component={UsersContainer} />
                <Route path={`${match.path}/videos`} component={VideosContainer} />
              </Switch>
            </Container>
          </main>
          <footer className="py-4 bg-light mt-auto">
            <Container fluid>
              <div className="d-flex align-items-center justify-content-between small">
                <div className="text-muted">Copyright © TJ Sokol Dolní Újezd {new Date().getFullYear()}</div>
              </div>
            </Container>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Backend;

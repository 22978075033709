import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

import { AppState } from '../../../redux/reducers';
import { IMatchModel } from '../../../models/Match';

type Props = {
  matchId: string;
};

const MatchRow = (props: Props) => {
  const { matchId } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const match = useSelector<AppState, IMatchModel>((state) => state.frontend.matches.items[matchId]);

  if (isMobile) {
    return (
      <>
        <tr>
          <td rowSpan={2} className="left">
            {moment(match.date).format('D. M.').toLocaleUpperCase()}
          </td>
          <td className="left bold">{match.home.name}</td>
          <td className="center bold">{match.fullTimeScore || '-'}</td>
        </tr>
        <tr className="no-border">
          <td className="left bold">{match.away.name}</td>
          <td className="center">{match.halfTimeScore ? `(${match.halfTimeScore})` : '-'}</td>
        </tr>
      </>
    );
  }

  return (
    <tr>
      <td className="left">{moment(match.date).format('dd D. M.').toLocaleUpperCase()}</td>
      <td className="right bold">{match.home.name}</td>
      <td>-</td>
      <td className="left bold">{match.away.name}</td>
      <td className="center bold">{match.fullTimeScore || '-'}</td>
      <td className="center">{match.halfTimeScore ? `(${match.halfTimeScore})` : '-'}</td>
    </tr>
  );
};

export default MatchRow;

import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import LoginForm from '../forms/LoginForm';
import { AppState } from '../../redux/reducers';
import { logIn } from '../redux/actions/userActions';

import { LoginBackground, LoginWrapper } from './styledComponents';

const Login = () => {
  const history = useHistory();

  const token = useSelector<AppState, string>((state) => state.backend.user.token);

  const dispatch = useDispatch();

  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (isSent) {
      if (token) {
        history.push('/admin');
      }
    }
  }, [isSent, token, history]);

  const handleLogin = (values, { setSubmitting }) => {
    dispatch(logIn(values));
    setSubmitting(false);
    setIsSent(true);
  };

  return (
    <LoginBackground>
      <LoginWrapper>
        <LoginForm onSubmit={handleLogin} />
      </LoginWrapper>
    </LoginBackground>
  );
};

export default Login;

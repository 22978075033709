import axios from 'axios';
import { notify } from 'reapop';

import { endpoints } from '../../../constants/API';
import { IMessageModel } from '../../../models/Message';
import {
  MessageLoadingAction,
  MessageErrorAction,
  MessageAllAction,
  MessageCreateAction,
  MessageUpdateAction,
  MessageRemoveAction,
  MessageOneAction,
  MESSAGES_LOADING,
  MESSAGES_ERROR,
  MESSAGES_ALL,
  MESSAGES_ONE,
  MESSAGES_CREATE,
  MESSAGES_UPDATE,
  MESSAGES_REMOVE,
} from '../types/messagesTypes';

export const loadingMessage = (name: string): MessageLoadingAction => ({
  type: MESSAGES_LOADING,
  name,
});

const errorMessage = (name: string, error: Error): MessageErrorAction => ({
  type: MESSAGES_ERROR,
  name,
  error,
});

const successMessages = (messages: { [key: number]: IMessageModel }): MessageAllAction => ({
  type: MESSAGES_ALL,
  messages,
});

const successMessage = (message: IMessageModel): MessageOneAction => ({
  type: MESSAGES_ONE,
  message,
});

const successCreateMessage = (message: IMessageModel): MessageCreateAction => ({
  type: MESSAGES_CREATE,
  message,
});

const successUpdateMessage = (message: IMessageModel): MessageUpdateAction => ({
  type: MESSAGES_UPDATE,
  message,
});

const successRemoveMessage = (messageId: number): MessageRemoveAction => ({
  type: MESSAGES_REMOVE,
  messageId,
});

export const getMessages = (token) => async (dispatch) => {
  const actionName = 'all';
  dispatch(loadingMessage(actionName));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(endpoints.messages, config);
    const data = response.data;
    const messages = {};
    data.forEach((message) => {
      messages[message.id] = message;
    });
    dispatch(successMessages(messages));
  } catch (e) {
    dispatch(errorMessage(actionName, e));
    console.error(e);
  }
};

export const getMessage = (token: string, messageId: string) => async (dispatch) => {
  const actionName = 'one';
  dispatch(loadingMessage(actionName));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${endpoints.messages}/${messageId}`, config);
    const message = response.data;
    dispatch(successMessage(message));
  } catch (e) {
    dispatch(errorMessage(actionName, e));
    console.error(e);
  }
};

export const createMessage = (token: string, values) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'create';
  dispatch(loadingMessage(actionName));
  try {
    const response = await axios.post(endpoints.messages, values, config);
    const message = response.data;
    dispatch(successCreateMessage(message));
    dispatch(notify('Zpráva přidána.', 'success'));
  } catch (e) {
    dispatch(errorMessage(actionName, e));
    dispatch(notify('Zprávu se nepodařilo přidat.', 'error'));
  }
};

export const updateMessage = (token: string, messageId: number, values) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'update';
  dispatch(loadingMessage(actionName));
  try {
    const response = await axios.put(`${endpoints.messages}/${messageId}`, values, config);
    const message = response.data;
    dispatch(successUpdateMessage(message));
    dispatch(notify('Zpráva upravena.', 'success'));
  } catch (e) {
    dispatch(errorMessage(actionName, e));
    dispatch(notify('Zprávu se nepodařilo upravit.', 'error'));
  }
};

export const removeMessage = (token: string, messageId: number) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'remove';
  dispatch(loadingMessage(actionName));
  try {
    await axios.delete(`${endpoints.messages}/${messageId}`, config);
    dispatch(successRemoveMessage(messageId));
    dispatch(notify('Zpráva odstraněna.', 'success'));
  } catch (e) {
    dispatch(errorMessage(actionName, e));
    dispatch(notify('Zprávu se nepodařilo odstranit.', 'error'));
    console.error(e);
  }
};

import React from 'react';
import { Link } from 'react-router-dom';

import { SBanner } from './styled';

const Banner = () => {
  return (
    <SBanner fluid>
      <h2>Novinka</h2>
      <p>
        Prohledněte si <Link to="/club/photos">fotky</Link> a <Link to="/club/videos">video</Link> z akce TJ Sokolní Dolní Újezd - 100 let fotbalu.
      </p>
    </SBanner>
  );
};

export default Banner;

import { ICompetitionModel } from '../../../models/Competition';

export const COMPETITIONS_LOADING = 'COMPETITIONS_LOADING';
export const COMPETITIONS_ERROR = 'COMPETITIONS_ERROR';
export const COMPETITIONS_ALL = 'COMPETITIONS_ALL';
export const COMPETITIONS_ONE = 'COMPETITIONS_ONE';
export const COMPETITIONS_CREATE = 'COMPETITIONS_CREATE';
export const COMPETITIONS_UPDATE = 'COMPETITIONS_UPDATE';
export const COMPETITIONS_REMOVE = 'COMPETITIONS_REMOVE';

export interface IACompetitionsLoading {
  type: typeof COMPETITIONS_LOADING;
  name: string;
}

export interface IACompetitionsError {
  type: typeof COMPETITIONS_ERROR;
  name: string;
  error: Error;
}

export interface IACompetitionsAll {
  type: typeof COMPETITIONS_ALL;
  competitions: { [key: string]: ICompetitionModel };
}

export interface IACompetitionsOne {
  type: typeof COMPETITIONS_ONE;
  competition: ICompetitionModel;
}

export interface IACompetitionsCreate {
  type: typeof COMPETITIONS_CREATE;
  competition: ICompetitionModel;
}

export interface IACompetitionsUpdate {
  type: typeof COMPETITIONS_UPDATE;
  competition: ICompetitionModel;
}

export interface IACompetitionsRemove {
  type: typeof COMPETITIONS_REMOVE;
  competitionId: string;
}

export type TCompetitionsActions =
  | IACompetitionsLoading
  | IACompetitionsError
  | IACompetitionsAll
  | IACompetitionsOne
  | IACompetitionsCreate
  | IACompetitionsUpdate
  | IACompetitionsRemove;

import { ICategoryModel } from '../../../models/Category';

export const CATEGORIES_LOADING = 'CATEGORIES_LOADING';
export const CATEGORIES_ERROR = 'CATEGORIES_ERROR';
export const CATEGORIES_ALL = 'CATEGORIES_ALL';

export interface IACategoriesLoading {
  type: typeof CATEGORIES_LOADING;
  name: string;
}

export interface IACategoriesError {
  type: typeof CATEGORIES_ERROR;
  name: string;
  error: Error;
}

export interface IACategoriesAll {
  type: typeof CATEGORIES_ALL;
  categories: { [key: string]: ICategoryModel };
}

export type CategoriesActionsTypes = IACategoriesLoading | IACategoriesError | IACategoriesAll;

import { IMatchModel, IMatchCategoriesRedux, IMatchSubcategoriesRedux } from '../../../models/Match';
import { Category, Subcategory } from '../../../models/Category';

export const FRONTEND_MATCHES_LOADING = 'FRONTEND_MATCHES_LOADING';
export const FRONTEND_MATCHES_ERROR = 'FRONTEND_MATCHES_ERROR';
export const FRONTEND_MATCHES_FIRST_AND_LAST = 'FRONTEND_MATCHES_FIRST_AND_LAST';
export const FRONTEND_MATCHES_ALL = 'FRONTEND_MATCHES_ALL';

export interface IAMatchesLoading {
  type: typeof FRONTEND_MATCHES_LOADING;
  name: string;
}

export interface IAMatchesError {
  type: typeof FRONTEND_MATCHES_ERROR;
  name: string;
  error: Error;
}

export interface IAMatchesFirstAndLast {
  type: typeof FRONTEND_MATCHES_FIRST_AND_LAST;
  category: Category;
  subcategory: Subcategory;
  first: IMatchModel;
  last: IMatchModel;
}

export interface IAMatchesAll {
  type: typeof FRONTEND_MATCHES_ALL;
  all: { [key: string]: IMatchModel };
  categories: IMatchCategoriesRedux;
  subcategories: IMatchSubcategoriesRedux;
}

export type MatchesActionsTypes = IAMatchesLoading | IAMatchesError | IAMatchesAll | IAMatchesFirstAndLast;

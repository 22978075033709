import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useRouteMatch } from 'react-router-dom';
import YouTube, { Options } from 'react-youtube';
import { useMediaQuery } from 'react-responsive';

import { AppState } from '../../../../redux/reducers';
import { IVideoModel } from '../../../../models/Video';
import { getVideo } from '../../../redux/actions/videosActions';

import { SPageContent } from './styled';

const VideosPreview = () => {
  const match = useRouteMatch<{ id: string }>();

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const videoId = match.params.id;

  const loading = useSelector<AppState, boolean>((state) => state.frontend.videos.loadings.one);
  const video = useSelector<AppState, IVideoModel>((state) => state.frontend.videos.items[videoId]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getVideo(videoId));
    }
  });

  if (!video) return null;

  const videoID = video.link.split('/')[3];
  const opts: Options = {
    width: isMobile ? '320' : '640',
    height: isMobile ? '180' : '360',
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <SPageContent className="p-0 my-5">
      <h2>{video.name}</h2>
      <Container className="p-0 d-flex justify-content-center align-items-center">
        <YouTube videoId={videoID} opts={opts} />;
      </Container>
    </SPageContent>
  );
};

export default VideosPreview;

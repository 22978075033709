import { IMeetingModel } from '../../../models/Meeting';

export const MEETINGS_LOADING = 'MEETINGS_LOADING';
export const MEETINGS_ERROR = 'MEETINGS_ERROR';
export const MEETINGS_ALL = 'MEETINGS_ALL';
export const MEETINGS_ONE = 'MEETINGS_ONE';
export const MEETINGS_CREATE = 'MEETINGS_CREATE';
export const MEETINGS_UPDATE = 'MEETINGS_UPDATE';
export const MEETINGS_REMOVE = 'MEETINGS_REMOVE';

export interface IAMeetingsLoading {
  type: typeof MEETINGS_LOADING;
  name: string;
}

export interface IAMeetingsError {
  type: typeof MEETINGS_ERROR;
  name: string;
  error: Error;
}

export interface IAMeetingsAll {
  type: typeof MEETINGS_ALL;
  meetings: { [key: number]: IMeetingModel };
}

export interface IAMeetingsOne {
  type: typeof MEETINGS_ONE;
  meeting: IMeetingModel;
}

export interface IAMeetingsCreate {
  type: typeof MEETINGS_CREATE;
  meeting: IMeetingModel;
}

export interface IAMeetingsUpdate {
  type: typeof MEETINGS_UPDATE;
  meeting: IMeetingModel;
}

export interface IAMeetingsRemove {
  type: typeof MEETINGS_REMOVE;
  meetingId: number;
}

export type TAMeetings = IAMeetingsError | IAMeetingsLoading | IAMeetingsAll | IAMeetingsOne | IAMeetingsCreate | IAMeetingsUpdate | IAMeetingsRemove;

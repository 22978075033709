import * as React from 'react';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, Container } from 'react-bootstrap';

import SponsorListContainer from './SponsorListContainer';
import SponsorImageContainer from './SponsorImageContainer';
import SponsorFormContainer from './SponsorFormContainer';

const SponsorsContainer = () => {
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <Container fluid className="p-0">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item active={location.pathname === match.path}>
          {location.pathname === match.path ? 'Sponzoři' : <Link to={match.path}>Sponzoři</Link>}
        </Breadcrumb.Item>
        {location.pathname.includes('/detail') && <Breadcrumb.Item active={true}>Detail sponzora</Breadcrumb.Item>}
        {location.pathname.includes('/add') && <Breadcrumb.Item active={true}>Nový sponzor</Breadcrumb.Item>}
        {location.pathname.includes('/edit') && <Breadcrumb.Item active={true}>Úprava sponzora</Breadcrumb.Item>}
      </Breadcrumb>
      <Switch>
        <Route exact path={`${match.path}/add`} component={SponsorFormContainer} />
        <Route exact path={`${match.path}/edit/:id`} component={SponsorFormContainer} />
        <Route exact path={`${match.path}/image/:id`} component={SponsorImageContainer} />
        <Route exact path={match.path} component={SponsorListContainer} />
      </Switch>
    </Container>
  );
};

export default SponsorsContainer;

import {
  MEETINGS_ALL,
  MEETINGS_CREATE,
  MEETINGS_ERROR,
  MEETINGS_LOADING,
  MEETINGS_ONE,
  MEETINGS_REMOVE,
  MEETINGS_UPDATE,
  TAMeetings,
} from '../types/meetingsTypes';

export interface MeetingsState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error | undefined };
  items: {};
}

const initialState: MeetingsState = {
  loadings: {},
  errors: {},
  items: {},
};

const meetings = (currentState: MeetingsState = initialState, action: TAMeetings): MeetingsState => {
  let newState;
  switch (action.type) {
    case MEETINGS_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case MEETINGS_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case MEETINGS_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        errors: {
          ...currentState.errors,
          all: undefined,
        },
        items: {
          ...currentState.items,
          ...action.meetings,
        },
      };
    case MEETINGS_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        errors: {
          ...currentState.errors,
          one: undefined,
        },
        items: {
          ...currentState.items,
          [action.meeting.id]: action.meeting,
        },
      };
    case MEETINGS_CREATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          create: false,
        },
        errors: {
          ...currentState.errors,
          create: undefined,
        },
        items: {
          ...currentState.items,
          [action.meeting.id]: action.meeting,
        },
      };
    case MEETINGS_UPDATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          update: false,
        },
        errors: {
          ...currentState.errors,
          update: undefined,
        },
        items: {
          ...currentState.items,
          [action.meeting.id]: {
            ...currentState.items[action.meeting.id],
            ...action.meeting,
          },
        },
      };
    case MEETINGS_REMOVE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          remove: false,
        },
        errors: {
          ...currentState.errors,
          remove: undefined,
        },
        items: {
          ...currentState.items,
        },
      };
      if (newState.items[action.meetingId]) {
        delete newState.items[action.meetingId];
      }
      return newState;
    default:
      return currentState;
  }
};

export default meetings;

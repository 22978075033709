import 'moment/locale/cs';
import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import NotificationsSystem, { wyboTheme, setUpNotifications, dismissNotification } from 'reapop';
import { useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';

import Login from './backend/layout/Login';
import Backend from './backend/layout';
import Frontend from './frontend/layout';
import { AppState } from './redux/reducers';

const App = () => {
  const route = useRouteMatch();

  const dispatch = useDispatch();
  const notifications = useSelector<AppState, any>((state) => state.notifications);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
  }, [route]);

  setUpNotifications({
    defaultProps: {
      position: 'top-right',
      dismissible: true,
      dismissAfter: 3000,
    },
  });

  return (
    <>
      <NotificationsSystem notifications={notifications} dismissNotification={(id) => dispatch(dismissNotification(id))} theme={wyboTheme} />
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/admin" component={Backend} />
        <Route path="/" component={Frontend} />
      </Switch>
    </>
  );
};

export default App;

import styled from 'styled-components';

export const SFileUpload = styled.section`
  cursor: pointer;
  p {
    margin: 0;
  }
`;

export const SThumbsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
`;

export const SThumb = styled.div`
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
`;

export const SThumbInner = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
`;

export const SImg = styled.img`
  display: block;
  width: auto;
  height: 100%;
`;

export const SDropzone = styled.div<{ isDragActive: boolean; isDragAccept: boolean; isDragReject: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => {
    if (props.isDragAccept) {
      return '#00e676';
    }
    if (props.isDragReject) {
      return '#ff1744';
    }
    if (props.isDragActive) {
      return '#2196f3';
    }
    return '#eeeeee';
  }};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../redux/reducers';
import { IGalleryModel } from '../../../models/Photo';
import { getGallery } from '../../redux/actions/photosActions';
import { SERVER_URL } from '../../../constants';

import { SCardImage } from './styles';

type Props = {
  galleryId: string;
  onSelect: (value: IGalleryModel) => void;
};

const Gallery = (props: Props) => {
  const { galleryId, onSelect } = props;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const gallery = useSelector<AppState, IGalleryModel>((state) => state.backend.photos.items[galleryId]);

  const dispatch = useDispatch();

  const [photoIndex, setPhotoIndex] = useState(0);

  useEffect(() => {
    dispatch(getGallery(token, galleryId));
  }, []);

  useEffect(() => {
    let interval;
    if (gallery.photos) {
      interval = setInterval(() => {
        if (photoIndex === gallery.photos.length - 1) {
          setPhotoIndex(0);
        } else {
          setPhotoIndex(photoIndex + 1);
        }
      }, 3000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [gallery, photoIndex]);

  if (!gallery.photos || !gallery.photos.length) return null;

  return (
    <Card>
      <SCardImage image={`${SERVER_URL}${gallery.photos[photoIndex].url}`} key={`photo_${gallery.photos[photoIndex].id}`} />
      <Card.Body>
        <Card.Title>{gallery.name}</Card.Title>
        <Button variant="primary" onClick={() => onSelect(gallery)}>
          Vybrat galerii
        </Button>
      </Card.Body>
    </Card>
  );
};

export default Gallery;

import React from 'react';
import { Container } from 'react-bootstrap';

import { Category, Subcategory } from '../../../../models/Category';

import { SPageContent } from './styled';

type TProps = {
  category: Category;
  subcategory?: Subcategory;
};

const CategoryCalendar = (props: TProps) => {
  const { category, subcategory } = props;

  let url;
  switch (category) {
    case 'mA':
      url = 'https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FPrague&src=Zm90YmFsZHVAZ21haWwuY29t&color=%233F51B5';
      break;
    case 'mB':
      url =
        'https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FPrague&src=NDR0NTk5ODBrMmg0OTVyZHFiNXYxNmMxODhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%237986CB';
      break;
    case 'do':
      url =
        'https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FPrague&src=YjltZTB2YWJzb3Y5NGhwaGRyNmticzUzNmtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23D50000';
      break;
    case 'za':
      switch (subcategory) {
        case 'st':
          url =
            'https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FPrague&src=YzkwamxhYmk1Z3RkOGR1c3E3MThydGJocm9AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%230B8043';
          break;
        case 'ml':
          url =
            'https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FPrague&src=cTJibTE5ZmkyYmRhb3QzcW8wdWUxOXJhdWdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%237CB342';
          break;
      }
      break;
    case 'pr':
      switch (subcategory) {
        case 'st':
          url =
            'https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FPrague&src=MWM3czA0a3I5bTZkOTZscjVsNmY3NHYxOXNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23F6BF26';
          break;
        case 'ml':
          url =
            'https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FPrague&src=ZzEzbmMxOWkzMnE4dTV2aGhtdWdlMGdpZTBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23F09300';
          break;
      }
      break;
  }

  return (
    <SPageContent className="p-0 my-5">
      <h2>Kalendář</h2>
      <Container className="p-0">
        <iframe title="calendar" src={url} width="100%" height="600" frameBorder="0" scrolling="no" />
      </Container>
    </SPageContent>
  );
};

export default CategoryCalendar;

import * as React from 'react';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, Container } from 'react-bootstrap';

import ArticleDetailContainer from './ArticleDetailContainer';
import ArticleFormContainer from './ArticleFormContainer';
import ArticleListContainer from './ArticleListContainer';
import ArticleImageContainer from './ArticleImageContainer';

const ArticlesContainer = () => {
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <Container fluid className="p-0">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item active={location.pathname === match.path}>
          {location.pathname === match.path ? 'Články' : <Link to={match.path}>Články</Link>}
        </Breadcrumb.Item>
        {location.pathname.includes(`/detail`) && <Breadcrumb.Item active>Detail článku</Breadcrumb.Item>}
        {location.pathname.includes(`/image`) && <Breadcrumb.Item active>Přiřazení fotky k článku</Breadcrumb.Item>}
        {location.pathname.includes(`/add`) && <Breadcrumb.Item active>Nový článek</Breadcrumb.Item>}
        {location.pathname.includes(`/edit`) && <Breadcrumb.Item active>Úprava článku</Breadcrumb.Item>}
      </Breadcrumb>
      <Switch>
        <Route exact path={`${match.path}/:category/add`} component={ArticleFormContainer} />
        <Route exact path={`${match.path}/:category/addByMatch/:matchId`} component={ArticleFormContainer} />
        <Route exact path={`${match.path}/:category/edit/:id`} component={ArticleFormContainer} />
        <Route exact path={`${match.path}/:category/image/:id`} component={ArticleImageContainer} />
        <Route exact path={`${match.path}/:category/detail/:id`} component={ArticleDetailContainer} />
        <Route exact path={`${match.path}/:category`} component={ArticleListContainer} />
        <Route exact path={`${match.path}/:category/:subcategory/add`} component={ArticleFormContainer} />
        <Route exact path={`${match.path}/:category/:subcategory/addByMatch/:matchId`} component={ArticleFormContainer} />
        <Route exact path={`${match.path}/:category/:subcategory/edit/:id`} component={ArticleFormContainer} />
        <Route exact path={`${match.path}/:category/:subcategory/image/:id`} component={ArticleImageContainer} />
        <Route exact path={`${match.path}/:category/:subcategory/detail/:id`} component={ArticleDetailContainer} />
        <Route exact path={`${match.path}/:category/:subcategory`} component={ArticleListContainer} />
        <Route exact path={match.path} component={ArticleListContainer} />
      </Switch>
    </Container>
  );
};

export default ArticlesContainer;

import React, { useMemo } from 'react';
import { Carousel, CarouselItem, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { arrayToChunks } from '../../../utils';
import { ASSETS_URL } from '../../../constants';
import { AppState } from '../../../redux/reducers';
import { ISponsorModel } from '../../../models/Sponsor';

import { SImage, SSponsors } from './styles';

const Sponsors = () => {
  const sponsorsItems = useSelector<AppState, { [key: string]: ISponsorModel }>((state) => state.frontend.sponsors.items);

  const sponsors = useMemo(() => Object.values(sponsorsItems).filter((sponsor) => sponsor.hasImage), [sponsorsItems]);

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <SSponsors fluid className="py-5">
      <Container className="d-flex justify-content-center align-items-center">
        <Carousel controls={false}>
          {arrayToChunks(sponsors, isMobile ? 1 : 5).map((chunk, chi) => (
            <CarouselItem key={`sponsors_chunk_${chi}`}>
              {chunk.map((sponsor) => (
                <SImage key={`sponsor_${sponsor.id}`} src={`${ASSETS_URL}/sponsors/${sponsor.id}.png`} alt="sponsor-image" />
              ))}
            </CarouselItem>
          ))}
        </Carousel>
      </Container>
    </SSponsors>
  );
};

export default Sponsors;

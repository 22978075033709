import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

import { Category, Subcategory } from '../../../../models/Category';
import { AppState } from '../../../../redux/reducers';
import { arrayToChunks } from '../../../../utils';
import { SNoData } from '../CategoryPhotos/styled';
import Video from '../../../components/Videos/Video';
import { getVideos } from '../../../redux/actions/videosActions';

import { SPageContent } from './styled';

type TProps = {
  category: Category;
  subcategory?: Subcategory;
};

const CategoryVideos = (props: TProps) => {
  const { category, subcategory } = props;

  const isTablet = useMediaQuery({ query: '(max-width: 991px)' });

  const loading = useSelector<AppState>((state) => state.frontend.videos.loadings.all);
  const videos = useSelector<AppState>((state) =>
    subcategory && state.frontend.videos.subcategories[category]
      ? state.frontend.videos.subcategories[category][subcategory]
      : state.frontend.videos.categories[category],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getVideos(category, subcategory));
    }
  }, []);

  const videosArray = videos ? Object.values(videos) : [];

  return (
    <SPageContent className="p-0 my-5">
      <h2>Video</h2>
      <Container className="p-0">
        {videosArray.length ? (
          arrayToChunks(videosArray, isTablet ? 2 : 3).map((chunk, index) => (
            <Row key={`photos_row_${index}`}>
              {chunk.length
                ? chunk.map((video) => (
                    <Col md={6} lg={4} key={`video_row_col_${video.id}`}>
                      <Video videoId={video.id} />
                    </Col>
                  ))
                : null}
            </Row>
          ))
        ) : (
          <SNoData>Žádná videa k zobrazení</SNoData>
        )}
      </Container>
    </SPageContent>
  );
};

export default CategoryVideos;

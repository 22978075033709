import React from 'react';
import { Table } from 'react-bootstrap';

type Props = {
  files: Array<File>;
};

const Files = (props: Props) => {
  const { files } = props;

  return (
    <Table striped responsive>
      <thead>
        <tr>
          <th colSpan={2}>Seznam souborů</th>
        </tr>
      </thead>
      <tbody>
        {files.map((file) => (
          // @ts-ignore
          <tr key={file.path}>
            {/* @ts-ignore*/}
            <td>{file.path}</td>
            <td>{file.size} byte</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default Files;

import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { AppState } from '../../../../redux/reducers';
import { ISponsorModel } from '../../../../models/Sponsor';
import { SImageSponsor } from '../../../components/Sponsors/styles';
import { ASSETS_URL } from '../../../../constants';

import { SPageContent, SSponsorView } from './styled';

const Sponsors = () => {
  const match = useRouteMatch<{ id: string }>();

  const sponsorId = match.params.id;

  const sponsor = useSelector<AppState, ISponsorModel>((state) => state.frontend.sponsors.items[sponsorId]);

  if (!sponsor) return null;

  return (
    <SPageContent className="p-0 my-5">
      <h2>Sponzor</h2>
      <SSponsorView className="p-0">
        {sponsor.hasImage ? <SImageSponsor key={`sponsor_${sponsorId}`} src={`${ASSETS_URL}/sponsors/${sponsorId}.png`} alt="sponsor-image" /> : null}
        <h3>{sponsor.name}</h3>
        <div className="my-5">
          {sponsor.web && (
            <p>
              <b>WWW: </b>
              <a href={sponsor.web} target="_blank" rel="noreferrer">
                {sponsor.web}
              </a>
            </p>
          )}
          {sponsor.mail && (
            <p>
              <b>E-mail: </b>
              {sponsor.mail}
            </p>
          )}
          {sponsor.phone && (
            <p>
              <b>Telefon: </b>
              {sponsor.phone}
            </p>
          )}
          {sponsor.address && (
            <p>
              <b>Adresa: </b>
              {sponsor.address}
            </p>
          )}
        </div>
      </SSponsorView>
    </SPageContent>
  );
};

export default Sponsors;

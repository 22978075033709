import { Formik, FormikActions } from 'formik';
import * as React from 'react';
import { Col, Form } from 'react-bootstrap';

import { IMeetingModel } from '../../../models/Meeting';
import TextEditor from '../../components/TextEditor';
import DatePicker from '../../components/Datepicker';

import validationSchema from './validation';

export interface MeetingFormValues {
  date: string;
  title: string;
  text: string;
}

type Props = {
  id: string;
  data?: IMeetingModel;
  onSubmit: (values: MeetingFormValues, actions: FormikActions<MeetingFormValues>) => void;
};

const MeetingForm = (props: Props) => {
  const { id, data, onSubmit } = props;

  return (
    <Formik
      initialValues={{
        date: data ? data.date : '',
        title: data ? data.title : '',
        text: data ? data.text : '',
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values: MeetingFormValues, actions: FormikActions<MeetingFormValues>) => {
        onSubmit(values, actions);
      }}>
      {({ handleSubmit, handleChange, handleBlur, values, touched, setFieldValue, errors, isSubmitting }) => (
        <Form id={id} noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Název schůze</Form.Label>
              <Form.Control
                type="text"
                name="title"
                placeholder="Zadejte název schůze"
                value={values.title}
                disabled={isSubmitting}
                isInvalid={errors.title && touched.title}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.title}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Datum schůze</Form.Label>
              <DatePicker
                name="date"
                placeholder="Zadejte datum schůze"
                value={values.date}
                disabled={isSubmitting}
                isInvalid={errors.date && touched.date}
                onChange={(value) => setFieldValue('date', value)}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.date}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Obsah schůze</Form.Label>
              <TextEditor
                name="text"
                placeholder="Zadejte obsah schůze"
                value={values.text}
                disabled={isSubmitting}
                isInvalid={errors.text && touched.text}
                onChange={(value) => setFieldValue('text', value)}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.text}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
};

export default MeetingForm;

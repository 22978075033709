import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { faEdit, faEye, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Card, Modal } from 'react-bootstrap';
import { useEffect, useMemo, useState } from 'react';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';

import Table from '../../components/Table';
import { AppState } from '../../../redux/reducers';
import { getMessages, removeMessage } from '../../redux/actions/messagesActions';
import { IMessageModel } from '../../../models/Message';

const MessageListContainer = () => {
  const history = useHistory();

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loading = useSelector<AppState, boolean>((state) => state.backend.messages.loadings.all);
  const loadingRemove = useSelector<AppState, boolean>((state) => state.backend.messages.loadings.remove);
  const errorRemove = useSelector<AppState, Error | undefined>((state) => state.backend.messages.errors.remove);
  const messages = useSelector<AppState, { [key: string]: IMessageModel }>((state) => state.backend.messages.items);

  const sortedMessages = useMemo(() => Object.values(messages).sort((a, b) => b.id - a.id), [messages]);

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState<IMessageModel | undefined>();

  useEffect(() => {
    if (!loading) {
      dispatch(getMessages(token));
    }
  }, []);

  useEffect(() => {
    if (!loadingRemove && !errorRemove) {
      setShowModal(false);
    }
  }, [loadingRemove, errorRemove]);

  const handleToggleDeleteModal = (message?: IMessageModel) => {
    setMessage(message);
    setShowModal(!showModal);
  };

  const handleRemove = () => {
    dispatch(removeMessage(token, message!.id));
  };

  const OptionCell = (row) => (
    <div className="d-flex justify-content-end">
      <Button variant="outline-dark" className="mr-2" onClick={() => history.push(`/admin/messages/detail/${row.original.id}`)}>
        <FontAwesomeIcon icon={faEye} style={{ cursor: 'pointer' }} />
      </Button>
      <Button variant="outline-dark" className="mr-2" onClick={() => history.push(`/admin/messages/edit/${row.original.id}`)}>
        <FontAwesomeIcon icon={faEdit} style={{ cursor: 'pointer' }} />
      </Button>
      <Button variant="danger" onClick={() => handleToggleDeleteModal(row.original)}>
        <FontAwesomeIcon icon={faTrash} style={{ cursor: 'pointer' }} />
      </Button>
    </div>
  );

  const columns: Column[] = [
    {
      id: 'name',
      Header: 'Název',
      accessor: (d: IMessageModel) => d.name,
    },
    {
      id: 'type',
      Header: 'Typ zprávy',
      accessor: (d: IMessageModel) => d.type,
    },
    {
      id: 'options',
      Header: 'Možnosti',
      Cell: OptionCell,
    },
  ];

  const options = {
    loadingText: 'Načítám zprávy z týmu...',
    noDataText: 'Žádné zprávy k zobrazení',
  };

  return (
    <Container fluid className="p-0">
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <FontAwesomeIcon icon={faTable} />
              <span className="ml-2">Seznam zpráv z týmu</span>
            </div>
            <Button color="primary" onClick={() => history.push(`/admin/messages/add`)}>
              <FontAwesomeIcon icon={faPlus} />
              <span className="ml-2">Přidat zprávu</span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Table
            columns={columns}
            data={sortedMessages}
            options={{
              loading,
              ...options,
            }}
          />
        </Card.Body>
        <Card.Footer />
      </Card>
      {message && (
        <Modal show={showModal} onHide={handleToggleDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Smazání zprávy z týmu</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`Opravdu chcete smazat zprávu: ${message.name}?`}</p>
            <p>Pořádně si to rozmyslete. Tato akce je nenávratná!</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleToggleDeleteModal()}>
              Zrušit
            </Button>
            <Button variant="danger" onClick={handleRemove}>
              Potvrdit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default MessageListContainer;

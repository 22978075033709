import React, { useEffect } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useRouteMatch, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { CategoryWithClub, ICategoryModel, Subcategory } from '../../../models/Category';
import { AppState } from '../../../redux/reducers';
import { getCategories } from '../../redux/actions/categoriesActions';

type Props = {
  withClub?: boolean;
};

const CategorySwitch = (props: Props) => {
  const { withClub } = props;

  const match = useRouteMatch<{ category: CategoryWithClub; subcategory: Subcategory }>();
  const params = useParams<{ category: CategoryWithClub; subcategory: Subcategory }>();
  const { category, subcategory } = params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loading = useSelector<AppState, boolean>((state) => state.backend.categories.loadings.all);
  const categories = useSelector<AppState, { [key: string]: ICategoryModel }>((state) => state.backend.categories.items);
  const activeCategory = useSelector<AppState, ICategoryModel>((state) => state.backend.categories.items[category || 'mA']);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getCategories(token));
    }
  }, []);

  if (!Object.values(categories).length) return null;

  const explodePath = match.path.split('/');

  const path =
    params && category && subcategory
      ? explodePath.slice(0, explodePath.length - 2).join('/')
      : params && category
      ? explodePath.slice(0, explodePath.length - 1).join('/')
      : match.path;

  return (
    <>
      {withClub && (
        <ButtonGroup className="mr-4 mb-4">
          <LinkContainer to={`${path}/klub`}>
            <Button variant="primary">Klub</Button>
          </LinkContainer>
        </ButtonGroup>
      )}
      <ButtonGroup className="mb-4">
        <LinkContainer to={`${path}/mA`}>
          <Button variant="primary">Muži A</Button>
        </LinkContainer>
        <LinkContainer to={`${path}/mB`}>
          <Button variant="primary">Muži B</Button>
        </LinkContainer>
        <LinkContainer to={`${path}/do`}>
          <Button variant="primary">Dorost</Button>
        </LinkContainer>
        <LinkContainer to={`${path}/za`}>
          <Button variant="primary">Žáci</Button>
        </LinkContainer>
        <LinkContainer to={`${path}/pr`}>
          <Button variant="primary">Přípravka</Button>
        </LinkContainer>
        <LinkContainer to={`${path}/sk`}>
          <Button variant="primary">Školička</Button>
        </LinkContainer>
      </ButtonGroup>
      {activeCategory && activeCategory.hasSubCategory ? (
        <ButtonGroup className="ml-4 mb-4">
          <LinkContainer to={`${path}/${params.category}/st`}>
            <Button variant="primary">Starší</Button>
          </LinkContainer>
          <LinkContainer to={`${path}/${match.params.category}/ml`}>
            <Button variant="primary">Mladší</Button>
          </LinkContainer>
        </ButtonGroup>
      ) : null}
    </>
  );
};

export default CategorySwitch;

import axios from 'axios';
import { notify } from 'reapop';

import { endpoints } from '../../../constants/API';
import { ICategoryModel } from '../../../models/Category';
import {
  CATEGORIES_LOADING,
  CATEGORIES_ERROR,
  CATEGORIES_ALL,
  CATEGORIES_ONE,
  CATEGORIES_CREATE,
  CATEGORIES_UPDATE,
  CATEGORIES_REMOVE,
  CategoriesLoadingAction,
  CategoriesErrorAction,
  CategoriesCreateAction,
  CategoriesRemoveAction,
  CategoriesUpdateAction,
  CategoriesAllAction,
} from '../types/categoriesTypes';
import { CategoryFormValues } from '../../forms/CategoryForm';

export const loadingCategories = (name: string): CategoriesLoadingAction => ({
  type: CATEGORIES_LOADING,
  name,
});

const errorCategories = (name: string, error: Error): CategoriesErrorAction => ({
  type: CATEGORIES_ERROR,
  name,
  error,
});

const successCategories = (categories: { [key: number]: ICategoryModel }): CategoriesAllAction => ({
  type: CATEGORIES_ALL,
  categories,
});

const successCategory = (category) => ({
  type: CATEGORIES_ONE,
  category,
});

const successCreateCategory = (category: ICategoryModel): CategoriesCreateAction => ({
  type: CATEGORIES_CREATE,
  category,
});

const successUpdateCategory = (category: ICategoryModel): CategoriesUpdateAction => ({
  type: CATEGORIES_UPDATE,
  category,
});

const successRemoveCategory = (categoryId): CategoriesRemoveAction => ({
  type: CATEGORIES_REMOVE,
  categoryId,
});

export const getCategories = (token: string) => async (dispatch) => {
  const actionName = 'all';
  dispatch(loadingCategories(actionName));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(endpoints.categories, config);
    const data = response.data;
    const categories = {};
    data.forEach((category) => {
      categories[category.id] = category;
    });
    dispatch(successCategories(categories));
  } catch (e) {
    dispatch(errorCategories(actionName, e));
    console.error(e);
  }
};

export const getCategory = (token: string, categoryId: string) => async (dispatch) => {
  const actionName = 'one';
  dispatch(loadingCategories(actionName));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${endpoints.categories}/${categoryId}`, config);
    const category = response.data;
    dispatch(successCategory(category));
  } catch (e) {
    dispatch(errorCategories(actionName, e));
    console.error(e);
  }
};

export const createCategory = (token: string, values: CategoryFormValues) => async (dispatch) => {
  const actionName = 'create';
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  dispatch(loadingCategories(actionName));
  try {
    const response = await axios.post(endpoints.categories, values, config);
    const category = response.data;
    dispatch(successCreateCategory(category));
    dispatch(notify({ title: 'Přidání kategorie', message: `Kategorie "${values.name}" byla přidána.`, status: 'success' }));
  } catch (e) {
    dispatch(errorCategories(actionName, e));
    dispatch(notify({ title: 'Přidání kategorie', message: `Kategorii "${values.name}" se nepodařilo přidat.\n${e.response.data.message}`, status: 'error' }));
  }
};

export const updateCategory = (token: string, categoryId: string, values: CategoryFormValues) => async (dispatch) => {
  const actionName = 'update';
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  dispatch(loadingCategories(actionName));
  try {
    const response = await axios.put(`${endpoints.categories}/${categoryId}`, values, config);
    const category = response.data;
    dispatch(successUpdateCategory(category));
    dispatch(notify({ title: 'Úprava kategorie', message: `Kategorie ${values.name} byla upravena.`, status: 'success' }));
  } catch (e) {
    dispatch(errorCategories(actionName, e));
    dispatch(notify({ title: 'Úprava kategorie', message: `Kategorii ${values.name} se nepodařilo upravit.`, status: 'error' }));
  }
};

export const removeCategory = (token: string, category: ICategoryModel) => async (dispatch) => {
  const actionName = 'remove';
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  dispatch(loadingCategories(actionName));
  try {
    await axios.delete(`${endpoints.categories}/${category.id}`, config);
    dispatch(successRemoveCategory(category.id));
    dispatch(notify({ title: 'Odstranění kategorie', message: `Kategorie ${category.name} byla odstraněna.`, status: 'success' }));
  } catch (e) {
    dispatch(errorCategories(actionName, e));
    dispatch(notify({ title: 'Odstranění kategorie', message: `Kategorii ${category.name} se nepodařilo odstranit.`, status: 'error' }));
  }
};

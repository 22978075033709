import { CategoryWithClub, Subcategory } from '../../../models/Category';
import { IVideoModel } from '../../../models/Video';

export const FRONTEND_VIDEO_LOADING = 'FRONTEND_VIDEO_LOADING';
export const FRONTEND_VIDEO_ERROR = 'FRONTEND_VIDEO_ERROR';
export const FRONTEND_VIDEO_ALL = 'FRONTEND_VIDEO_ALL';
export const FRONTEND_VIDEO_ONE = 'FRONTEND_VIDEO_ONE';

export interface IAVideoLoading {
  type: typeof FRONTEND_VIDEO_LOADING;
  name: string;
}

export interface IAVideoError {
  type: typeof FRONTEND_VIDEO_ERROR;
  name: string;
  error: Error;
}

export interface IAVideoAll {
  type: typeof FRONTEND_VIDEO_ALL;
  category: CategoryWithClub;
  subcategory: Subcategory;
  videos: { [key: string]: IVideoModel };
}

export interface IAVideoOne {
  type: typeof FRONTEND_VIDEO_ONE;
  video: IVideoModel;
}

export type VideoActionsTypes = IAVideoLoading | IAVideoError | IAVideoAll | IAVideoOne;

import styled from 'styled-components';

import { colors } from '../../layout/styles';

export const SMessage = styled.div`
  margin: 2rem 0;
  padding: 2rem;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.09);
`;

export const SLastPlanMessage = styled.div`
  display: block;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 270px;
  background-color: ${colors.blue};
  justify-content: center;
  align-items: center;
  color: white;
  padding: 2rem;

  h2 {
    text-align: center;
  }

  div.quick-menu {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;

    a {
      display: block;
      color: ${colors.white};

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

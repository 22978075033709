import { ISponsorModel } from '../../../models/Sponsor';

export const SPONSORS_LOADING = 'SPONSORS_LOADING';
export const SPONSORS_ERROR = 'SPONSORS_ERROR';
export const SPONSORS_ALL = 'SPONSORS_ALL';
export const SPONSORS_ONE = 'SPONSORS_ONE';
export const SPONSORS_CREATE = 'SPONSORS_CREATE';
export const SPONSORS_UPDATE = 'SPONSORS_UPDATE';
export const SPONSORS_REMOVE = 'SPONSORS_REMOVE';

export interface SponsorsLoadingAction {
  type: typeof SPONSORS_LOADING;
  name: string;
}

export interface SponsorsErrorAction {
  type: typeof SPONSORS_ERROR;
  name: string;
  error: Error;
}

export interface SponsorsAllAction {
  type: typeof SPONSORS_ALL;
  sponsors: { [key: number]: ISponsorModel };
}

export interface SponsorsOneAction {
  type: typeof SPONSORS_ONE;
  sponsor: ISponsorModel;
}

export interface SponsorsCreateAction {
  type: typeof SPONSORS_CREATE;
  sponsor: ISponsorModel;
}

export interface SponsorsUpdateAction {
  type: typeof SPONSORS_UPDATE;
  sponsor: ISponsorModel;
}

export interface SponsorsRemoveAction {
  type: typeof SPONSORS_REMOVE;
  sponsorId: number;
}

export type SponsorsActionTypes =
  | SponsorsErrorAction
  | SponsorsLoadingAction
  | SponsorsAllAction
  | SponsorsOneAction
  | SponsorsCreateAction
  | SponsorsUpdateAction
  | SponsorsRemoveAction;

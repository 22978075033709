import { ICompetitionModel } from '../../../models/Competition';
import {
  COMPETITIONS_ALL,
  COMPETITIONS_CREATE,
  COMPETITIONS_ERROR,
  COMPETITIONS_LOADING,
  COMPETITIONS_ONE,
  COMPETITIONS_REMOVE,
  COMPETITIONS_UPDATE,
  TCompetitionsActions,
} from '../types/competitionsTypes';

export interface CompetitionsState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error | undefined };
  items: { [key: string]: ICompetitionModel };
}

const init = (): CompetitionsState => ({
  loadings: {},
  errors: {},
  items: {},
});

const competitions = (currentState = init(), action: TCompetitionsActions): CompetitionsState => {
  let newState;
  switch (action.type) {
    case COMPETITIONS_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
      };
    case COMPETITIONS_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case COMPETITIONS_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        items: {
          ...currentState.items,
          ...action.competitions,
        },
      };
    case COMPETITIONS_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        items: {
          ...currentState.items,
          [action.competition.id]: {
            ...currentState.items[action.competition.id],
            ...action.competition,
          },
        },
      };
    case COMPETITIONS_CREATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          create: false,
        },
        errors: {
          ...currentState.errors,
          create: undefined,
        },
        items: {
          ...currentState.items,
          [action.competition.id]: action.competition,
        },
      };
    case COMPETITIONS_UPDATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          update: false,
        },
        errors: {
          ...currentState.errors,
          update: undefined,
        },
        items: {
          ...currentState.items,
          [action.competition.id]: {
            ...currentState.items[action.competition.id],
            ...action.competition,
          },
        },
      };
    case COMPETITIONS_REMOVE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          remove: false,
        },
        errors: {
          ...currentState.errors,
          remove: undefined,
        },
        items: {
          ...currentState.items,
        },
      };
      delete newState.items[action.competitionId];
      return newState;
    default:
      return currentState;
  }
};

export default competitions;

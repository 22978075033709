import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Column } from 'react-table';
import { faEdit, faNewspaper, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Card, Modal } from 'react-bootstrap';
import { useEffect, useState, useMemo } from 'react';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';

import Table from '../../components/Table';
import { IMatchModel } from '../../../models/Match';
import { getMatches, removeMatch } from '../../redux/actions/matchesActions';
import { Category, Subcategory } from '../../../models/Category';
import CategorySwitch from '../../components/CategorySwitch';
import { AppState } from '../../../redux/reducers';

const MatchListContainer = () => {
  const history = useHistory();
  const match = useRouteMatch<{ category: Category; subcategory: Subcategory }>();
  let { category, subcategory } = match.params;

  if (!category) category = 'mA';

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loading = useSelector<AppState, boolean>((state) => state.backend.matches.loadings.all);
  const loadingRemove = useSelector<AppState, boolean>((state) => state.backend.matches.loadings.remove);
  const errorRemove = useSelector<AppState, Error | undefined>((state) => state.backend.matches.errors.remove);
  const matches = useSelector<AppState, { [key: string]: IMatchModel }>((state) => state.backend.matches.items);

  const filteredMatches = useMemo(
    () =>
      Object.values(matches)
        .filter((match) => (subcategory ? match.category === category && match.subcategory === subcategory : match.category === category))
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
    [category, subcategory, matches],
  );

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [matchToDelete, setMatchToDelete] = useState<IMatchModel | undefined>();

  useEffect(() => {
    if (!loading) {
      dispatch(getMatches(token, match.params.category, match.params.subcategory));
    }
  }, [category, subcategory]);

  useEffect(() => {
    if (!loadingRemove && !errorRemove) {
      setShowModal(false);
    }
  }, [loadingRemove, errorRemove]);

  const handleToggleDeleteModal = (match?: IMatchModel) => {
    setShowModal(!showModal);
    setMatchToDelete(match);
  };

  const handleRemoveMatch = () => {
    dispatch(removeMatch(token, matchToDelete!.id));
  };

  const OptionCell = (row) => (
    <div className="d-flex justify-content-end">
      <Button
        className="mr-2"
        variant="outline-dark"
        onClick={() => history.push(`/admin/articles/${category}${subcategory ? `/${subcategory}` : ''}/addByMatch/${row.original.id}`)}
        style={{ cursor: 'pointer' }}>
        <FontAwesomeIcon icon={faNewspaper} />
      </Button>
      <Button
        className="mr-2"
        variant="outline-dark"
        onClick={() => history.push(`/admin/matches/${category}${subcategory ? `/${subcategory}` : ''}/edit/${row.original.id}`)}
        style={{ cursor: 'pointer' }}>
        <FontAwesomeIcon icon={faEdit} />
      </Button>
      <Button variant="danger" onClick={() => handleToggleDeleteModal(row.original)}>
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </div>
  );

  const columns: Column[] = [
    {
      id: 'date',
      Header: 'Datum zápasu',
      accessor: (d: IMatchModel) => d.date,
      sortMethod: (a, b) => {
        const aTime = new Date(a).getTime();
        const bTime = new Date(b).getTime();
        return aTime - bTime;
      },
      Cell: (row) => {
        return `${moment(row.value).format('ddd DD.MM.YYYY').toLocaleUpperCase()} ${row.original.time}`;
      },
    },
    {
      id: 'part',
      Header: 'Část sezóny',
      accessor: (d: IMatchModel) => d.part,
    },
    {
      id: 'home',
      Header: 'Domácí',
      accessor: (d: IMatchModel) => (d.home ? d.home.name : ''),
    },
    {
      id: 'away',
      Header: 'Hosté',
      accessor: (d: IMatchModel) => (d.away ? d.away.name : ''),
    },
    {
      id: 'score',
      Header: 'Výsledek',
      accessor: (d: IMatchModel) => (d.fullTimeScore ? `${d.fullTimeScore} (${d.halfTimeScore})` : '-:- (-:-)'),
    },
    {
      id: 'options',
      Header: 'Možnosti',
      Cell: OptionCell,
    },
  ];

  const options = {
    loadingText: 'Načítám zápasy...',
    noDataText: 'Žádné zápasy k zobrazení',
  };

  return (
    <Container fluid className="p-0">
      <CategorySwitch />
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <FontAwesomeIcon icon={faTable} />
              <span className="ml-2">Seznam zápasů</span>
            </div>
            <Button color="primary" onClick={() => history.push(`/admin/matches/${category}${subcategory ? `/${subcategory}` : ''}/add`)}>
              <FontAwesomeIcon icon={faPlus} />
              <span className="ml-2">Přidat zápas</span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Table
            columns={columns}
            data={filteredMatches}
            options={{
              loading,
              ...options,
            }}
          />
        </Card.Body>
        <Card.Footer />
      </Card>
      {matchToDelete && (
        <Modal show={showModal} onHide={handleToggleDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Smazání správce</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`Opravdu chcete smazat zápas: ${matchToDelete.home.name} vs. ${matchToDelete.away.name}?`}</p>
            <p>Pořádně si to rozmyslete. Tato akce je nenávratná!</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleToggleDeleteModal()}>
              Zrušit
            </Button>
            <Button variant="danger" onClick={() => handleRemoveMatch()}>
              Potvrdit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default MatchListContainer;

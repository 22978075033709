import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { IMatchModel } from '../../../models/Match';

import { SScheduleMatch } from './styles';
import FirstMatch from './FirstMatch';

type Props = {
  match: IMatchModel;
};

const ScheduleMatch = (props: Props) => {
  const { match } = props;
  const team = match.home.name.includes('Dolní Újezd') ? match.away : match.home;
  const isHomeMatch = match.home.name.includes('Dolní Újezd');

  const popover = (
    <Popover id={`matchPopover_${match.id}`} style={{ minWidth: '400px' }}>
      <Popover.Title>Náhled zápasu</Popover.Title>
      <Popover.Content>
        <FirstMatch match={match} />
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="hover" placement="auto" overlay={popover}>
      <SScheduleMatch isHomeMatch={isHomeMatch}>
        <span>{team.name}</span>
        <span>{match.time}</span>
      </SScheduleMatch>
    </OverlayTrigger>
  );
};

export default ScheduleMatch;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

import { Category, Subcategory } from '../../../../models/Category';
import { AppState } from '../../../../redux/reducers';
import Player from '../../../components/Players/Player';
import { getPlayers } from '../../../redux/actions/playersActions';
import { arrayToChunks } from '../../../../utils';

import { SPageContent, SNoPlayersData } from './styled';

type TProps = {
  category: Category;
  subcategory?: Subcategory;
};

const CategoryPlayers = (props: TProps) => {
  const { category, subcategory } = props;

  const isTablet = useMediaQuery({ query: '(max-width: 991px)' });

  const loading = useSelector<AppState>((state) => state.frontend.players.loadings.all);
  const roster = useSelector<AppState>((state) =>
    subcategory && state.frontend.players.subcategories[category]
      ? state.frontend.players.subcategories[category][subcategory]
      : state.frontend.players.categories[category],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getPlayers(category, subcategory));
    }
  }, []);

  const rosterArray = roster ? Object.values(roster) : [];

  const couches = rosterArray.filter((player) => ['trenér', 'vedoucí mužstva'].includes(player.position));
  const goalkeepers = rosterArray.filter((player) => player.position === 'brankář');
  const defenders = rosterArray.filter((player) => player.position === 'obránce');
  const midfielders = rosterArray.filter((player) => player.position === 'záložník');
  const attackers = rosterArray.filter((player) => player.position === 'útočník');
  const others = rosterArray.filter((player) => player.position === 'ostatní');

  return (
    <SPageContent className="p-0 my-5">
      <h2>Soupiska</h2>
      {!couches.length && !goalkeepers.length && !defenders.length && !midfielders.length && !attackers.length && !others.length ? (
        <SNoPlayersData>Bohužel pro tuto kategorii nebyla zadána soupiska.</SNoPlayersData>
      ) : null}
      {couches.length ? (
        <Container fluid>
          <div className="d-flex justify-content-center align-items-center">
            <h4>Vedení týmu</h4>
          </div>
          {arrayToChunks(couches, isTablet ? 2 : 4).map((row, index) => (
            <div key={`couches_${index}`} className="d-flex flex-column flex-md-row justify-content-center align-items-center">
              {row.map((player) => (
                <Player key={`player_${player.id}`} playerId={player.id} />
              ))}
            </div>
          ))}
        </Container>
      ) : null}
      {goalkeepers.length ? (
        <Container fluid>
          <div className="d-flex justify-content-center align-items-center">
            <h4>Brankáři</h4>
          </div>
          {arrayToChunks(goalkeepers, isTablet ? 2 : 4).map((row, index) => (
            <div key={`goalkeepers_${index}`} className="d-flex flex-column flex-md-row justify-content-center align-items-center">
              {row.map((player) => (
                <Player key={`player_${player.id}`} playerId={player.id} />
              ))}
            </div>
          ))}
        </Container>
      ) : null}
      {defenders.length ? (
        <Container fluid>
          <div className="d-flex justify-content-center align-items-center">
            <h4>Obrana</h4>
          </div>
          {arrayToChunks(defenders, isTablet ? 2 : 4).map((row, index) => (
            <div key={`defenders_${index}`} className="d-flex flex-column flex-md-row justify-content-center align-items-center">
              {row.map((player) => (
                <Player key={`player_${player.id}`} playerId={player.id} />
              ))}
            </div>
          ))}
        </Container>
      ) : null}
      {midfielders.length ? (
        <Container fluid>
          <div className="d-flex justify-content-center align-items-center">
            <h4>Záložníci</h4>
          </div>
          {arrayToChunks(midfielders, isTablet ? 2 : 4).map((row, index) => (
            <div key={`midfielders_${index}`} className="d-flex flex-column flex-md-row justify-content-center align-items-center">
              {row.map((player) => (
                <Player key={`player_${player.id}`} playerId={player.id} />
              ))}
            </div>
          ))}
        </Container>
      ) : null}
      {attackers.length ? (
        <Container fluid>
          <div className="d-flex justify-content-center align-items-center">
            <h4>Útočníci</h4>
          </div>
          {arrayToChunks(attackers, isTablet ? 2 : 4).map((row, index) => (
            <div key={`attackers_${index}`} className="d-flex flex-column flex-md-row justify-content-center align-items-center">
              {row.map((player) => (
                <Player key={`player_${player.id}`} playerId={player.id} />
              ))}
            </div>
          ))}
        </Container>
      ) : null}
      {others.length ? (
        <Container fluid>
          <div className="d-flex justify-content-center align-items-center">
            <h4>Ostatní</h4>
          </div>
          {arrayToChunks(others, isTablet ? 2 : 4).map((row, index) => (
            <div key={`others_${index}`} className="d-flex flex-column flex-md-row justify-content-center align-items-center">
              {row.map((player) => (
                <Player key={`player_${player.id}`} playerId={player.id} />
              ))}
            </div>
          ))}
        </Container>
      ) : null}
    </SPageContent>
  );
};

export default CategoryPlayers;

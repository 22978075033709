import { ITournamentModel, ITournamentCategoriesRedux, ITournamentSubcategoriesRedux } from '../../../models/Tournament';

export const processTournaments = (data: Array<ITournamentModel>) => {
  const all: { [key: string]: ITournamentModel } = {};
  const categories: ITournamentCategoriesRedux = {};
  const subcategories: ITournamentSubcategoriesRedux = {};
  data.forEach((tournament: ITournamentModel) => {
    all[tournament.id] = tournament;
    if (tournament.subcategory) {
      if (!subcategories[tournament.category]) {
        subcategories[tournament.category] = {};
      }
      if (!subcategories[tournament.category][tournament.subcategory]) {
        subcategories[tournament.category][tournament.subcategory] = {
          parts: {
            spring: {},
            autumn: {},
          },
        };
      }
      if (tournament.part === 'podzim') {
        subcategories[tournament.category][tournament.subcategory].parts.autumn[tournament.id] = {
          id: tournament.id,
          date: tournament.date,
        };
      }
      if (tournament.part === 'jaro') {
        subcategories[tournament.category][tournament.subcategory].parts.spring[tournament.id] = {
          id: tournament.id,
          date: tournament.date,
        };
      }
    } else {
      if (!categories[tournament.category]) {
        categories[tournament.category] = {
          parts: {
            spring: {},
            autumn: {},
          },
        };
      }
      if (tournament.part === 'podzim') {
        categories[tournament.category].parts.autumn[tournament.id] = {
          id: tournament.id,
          date: tournament.date,
        };
      }
      if (tournament.part === 'jaro') {
        categories[tournament.category].parts.spring[tournament.id] = {
          id: tournament.id,
          date: tournament.date,
        };
      }
    }
  });

  return {
    all,
    categories,
    subcategories,
  };
};

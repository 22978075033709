import React from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../../redux/reducers';
import { IPlayerModel } from '../../../models/Player';

import { SPlayer } from './styles';

type Props = {
  playerId: string;
};

const Player = (props: Props) => {
  const { playerId } = props;

  const player = useSelector<AppState, IPlayerModel>((state) => state.frontend.players.items[playerId]);

  return <SPlayer>{`${player.firstname} ${player.surname}`}</SPlayer>;
};

export default Player;

import { FRONTEND_MESSAGE_ALL, FRONTEND_MESSAGE_ERROR, FRONTEND_MESSAGE_LOADING, FRONTEND_MESSAGE_ONE, MessageActionsTypes } from '../types/messagesTypes';
import { IMessageModel } from '../../../models/Message';
import { FRONTEND_MESSAGE_LAST } from '../types/messagesTypes';

export interface IMessagesState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error };
  items: { [key: string]: IMessageModel };
  last?: IMessageModel;
}

const initialState: IMessagesState = {
  loadings: {},
  errors: {},
  items: {},
};

const messages = (currentState: IMessagesState = initialState, action: MessageActionsTypes): IMessagesState => {
  switch (action.type) {
    case FRONTEND_MESSAGE_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case FRONTEND_MESSAGE_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case FRONTEND_MESSAGE_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        items: {
          ...currentState.items,
          ...action.messages,
        },
      };
    case FRONTEND_MESSAGE_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        items: {
          ...currentState.items,
          [action.message.id]: action.message,
        },
      };
    case FRONTEND_MESSAGE_LAST:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          last: false,
        },
        items: {
          ...currentState.items,
          [action.message.id]: action.message,
        },
        last: action.message,
      };
    default:
      return currentState;
  }
};

export default messages;

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Column } from 'react-table';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Card, Modal } from 'react-bootstrap';
import { useEffect, useMemo, useState } from 'react';
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';

import Table from '../../components/Table';
import { Category, Subcategory } from '../../../models/Category';
import { IPlayerModel } from '../../../models/Player';
import { getPlayers, removePlayer } from '../../redux/actions/playersActions';
import CategorySwitch from '../../components/CategorySwitch';
import { AppState } from '../../../redux/reducers';
import { ASSETS_URL } from '../../../constants';

const PlayerListContainer = () => {
  const history = useHistory();
  const match = useRouteMatch<{ category: Category; subcategory: Subcategory }>();
  let { category, subcategory } = match.params;

  if (!category) category = 'mA';

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const players = useSelector<AppState, { [key: string]: IPlayerModel }>((state) => state.backend.players.items);
  const loading = useSelector<AppState, boolean>((state) => state.backend.players.loadings.all);
  const loadingRemove = useSelector<AppState, boolean>((state) => state.backend.players.loadings.remove);
  const errorRemove = useSelector<AppState, Error | undefined>((state) => state.backend.players.errors.remove);

  const filteredPlayers = useMemo(
    () =>
      Object.values(players).filter((player) =>
        subcategory ? player.category === category && player.subcategory === subcategory : player.category === category,
      ),
    [category, subcategory, players],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getPlayers(token, category, subcategory));
    }
  }, [category, subcategory]);

  useEffect(() => {
    if (!loadingRemove && !errorRemove) {
      setShowModal(false);
    }
  }, [loadingRemove, errorRemove]);

  const handleToggleDeleteModal = (player?: IPlayerModel) => {
    setPlayer(player);
    setShowModal(!showModal);
  };

  const handleRemove = () => {
    dispatch(removePlayer(token, player!.id));
  };

  const OptionCell = (row) => (
    <div className="d-flex justify-content-end">
      <Button
        variant="outline-dark"
        className="mr-2"
        onClick={() => history.push(`/admin/players/${category}${subcategory ? `/${subcategory}` : ''}/image/${row.original.id}`)}>
        <FontAwesomeIcon icon={faImage} style={{ cursor: 'pointer' }} />
      </Button>
      <Button
        variant="outline-dark"
        className="mr-2"
        onClick={() => history.push(`/admin/players/${category}${subcategory ? `/${subcategory}` : ''}/edit/${row.original.id}`)}>
        <FontAwesomeIcon icon={faEdit} style={{ cursor: 'pointer' }} />
      </Button>
      <Button variant="danger" onClick={() => handleToggleDeleteModal(row.original)}>
        <FontAwesomeIcon icon={faTrash} style={{ cursor: 'pointer' }} />
      </Button>
    </div>
  );

  const columns: Column[] = [
    {
      id: 'photo',
      Header: 'Fotka',
      accessor: (d: IPlayerModel) => (
        <img
          key={`${new Date().getTime().toString()}`}
          style={{ maxWidth: '40px', maxHeight: '40px' }}
          src={d.hasImage ? `${ASSETS_URL}/players/${d.id}.jpg` : `${ASSETS_URL}/players/0.jpg`}
          alt="fotka hráče"
        />
      ),
      width: 80,
      style: { textAlign: 'center' },
    },
    {
      id: 'surname',
      Header: 'Příjmení',
      accessor: (d: IPlayerModel) => d.surname,
    },
    {
      id: 'firstname',
      Header: 'Jméno',
      accessor: (d: IPlayerModel) => d.firstname,
    },
    {
      id: 'position',
      Header: 'Post',
      accessor: (d: IPlayerModel) => d.position,
    },
    {
      id: 'options',
      Header: 'Možnosti',
      Cell: OptionCell,
    },
  ];

  const options = {
    loadingText: 'Načítám hráče...',
    noDataText: 'Žádní hráči k zobrazení',
  };

  const [showModal, setShowModal] = useState(false);
  const [player, setPlayer] = useState<IPlayerModel | undefined>();

  return (
    <Container fluid className="p-0">
      <CategorySwitch />
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <FontAwesomeIcon icon={faTable} />
              <span className="ml-2">Seznam hráčů</span>
            </div>
            <Button color="primary" onClick={() => history.push(`/admin/players/${category}${subcategory ? `/${subcategory}` : ''}/add`)}>
              <FontAwesomeIcon icon={faPlus} />
              <span className="ml-2">Přidat hráče</span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Table
            columns={columns}
            data={filteredPlayers}
            options={{
              loading,
              ...options,
            }}
          />
        </Card.Body>
        <Card.Footer />
      </Card>
      {player && (
        <Modal show={showModal} onHide={handleToggleDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Smazání hráče</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`Opravdu chcete smazat hráče: ${player.firstname} ${player.surname}?`}</p>
            <p>Pořádně si to rozmyslete. Tato akce je nenávratná!</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleToggleDeleteModal()}>
              Zrušit
            </Button>
            <Button variant="danger" onClick={() => handleRemove()}>
              Potvrdit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default PlayerListContainer;

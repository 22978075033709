import axios from 'axios';
import { notify } from 'reapop';

import { endpoints } from '../../../constants/API';
import { IMatchModel } from '../../../models/Match';
import {
  MATCHES_LOADING,
  MATCHES_ERROR,
  MATCHES_ALL,
  MATCHES_ONE,
  MATCHES_CREATE,
  MATCHES_UPDATE,
  MATCHES_REMOVE,
  MatchesLoadingAction,
  MatchesErrorAction,
  MatchesCreateAction,
  MatchesRemoveAction,
  MatchesUpdateAction,
  MatchesAllAction,
} from '../types/matchesTypes';
import { Category, Subcategory } from '../../../models/Category';
import { processMatchFromForm } from '../helpers/matchesHelpers';

export const loadingMatches = (name: string): MatchesLoadingAction => ({
  type: MATCHES_LOADING,
  name,
});

const errorMatches = (name: string, error: Error): MatchesErrorAction => ({
  type: MATCHES_ERROR,
  name,
  error,
});

const successMatches = (matches: { [key: number]: IMatchModel }): MatchesAllAction => ({
  type: MATCHES_ALL,
  matches,
});

const successMatch = (match) => ({
  type: MATCHES_ONE,
  match,
});

const successCreateMatch = (match: IMatchModel): MatchesCreateAction => ({
  type: MATCHES_CREATE,
  match,
});

const successUpdateMatch = (match: IMatchModel): MatchesUpdateAction => ({
  type: MATCHES_UPDATE,
  match,
});

const successRemoveMatch = (id): MatchesRemoveAction => ({
  type: MATCHES_REMOVE,
  matchId: id,
});

export const getMatches =
  (token, category: Category = 'mA', subcategory: Subcategory = undefined) =>
  async (dispatch) => {
    const actionName = 'all';
    dispatch(loadingMatches(actionName));
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          category,
          subcategory,
        },
      };
      const response = await axios.get(endpoints.matches, config);
      const data = response.data;
      const matches = {};
      data.forEach((match) => {
        matches[match.id] = match;
      });
      dispatch(successMatches(matches));
    } catch (e) {
      dispatch(errorMatches(actionName, e));
      console.error(e);
    }
  };

export const getMatch = (token: string, matchId: string) => async (dispatch) => {
  dispatch(loadingMatches('getMatchDetail'));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${endpoints.matches}/${matchId}`, config);
    const match = response.data;
    dispatch(successMatch(match));
  } catch (e) {
    dispatch(errorMatches('getMatchDetail', e));
    console.error(e);
  }
};

export const createMatch = (token: string, values) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'create';
  dispatch(loadingMatches(actionName));
  try {
    const data = await processMatchFromForm(values);
    const response = await axios.post(endpoints.matches, data, config);
    const match = response.data;
    dispatch(successCreateMatch(match));
    dispatch(notify('Zápas přidán.', 'success'));
  } catch (e) {
    dispatch(errorMatches('createMatch', e));
    dispatch(notify('Zápas se nepodařilo přidat.', 'error'));
  }
};

export const updateMatch = (token: string, matchId: string, values) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'update';
  dispatch(loadingMatches(actionName));
  try {
    const data = await processMatchFromForm(values);
    const response = await axios.put(`${endpoints.matches}/${matchId}`, data, config);
    const match = response.data;
    dispatch(successUpdateMatch(match));
    dispatch(notify('Zápas upraven.', 'success'));
  } catch (e) {
    dispatch(errorMatches('updateMatch', e));
    dispatch(notify('Zápas se nepodařilo upravit.', 'error'));
  }
};

export const removeMatch = (token: string, matchId: string) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'remove';
  dispatch(loadingMatches(actionName));
  try {
    await axios.delete(`${endpoints.matches}/${matchId}`, config);
    dispatch(successRemoveMatch(matchId));
    dispatch(notify('Zápas odstraněn.', 'success'));
  } catch (e) {
    dispatch(errorMatches('removeMatch', e));
    dispatch(notify('Zápas se nepodařilo odstranit.', 'error'));
    console.error(e);
  }
};

import { Formik, FormikActions } from 'formik';
import * as React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import { Category, Subcategory } from '../../../models/Category';
import { IMatchModel } from '../../../models/Match';
import DatePicker from '../../components/Datepicker';
import { endpoints } from '../../../constants/API';
import { AppState } from '../../../redux/reducers';

import validationSchema from './validation';

export interface MatchFormValues {
  date: string;
  time: string;
  part: string;
  info: string;
  homeAway: 'home' | 'away' | '';
  teamId: string;
  fullTimeScore: string;
  halfTimeScore: string;
  category: Category;
  subcategory?: Subcategory;
}

type Props = {
  id: string;
  data?: IMatchModel | null;
  onSubmit: (values: MatchFormValues, actions: FormikActions<MatchFormValues>) => void;
};

const MatchForm = (props: Props) => {
  const { id, data, onSubmit } = props;
  const params = useParams<{ category: Category; subcategory: Subcategory }>();

  const token = useSelector<AppState>((state) => state.backend.user.token);

  const [teams, setTeams] = useState([]);
  const [myTeam, setMyTeam] = useState<IMatchModel | undefined>();

  const getTeams = async () => {
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
      params: {
        category: params.category,
        subcategory: params.subcategory,
      },
    };
    const response = await axios.get(endpoints.teams, config);
    const teams = response.data.filter((team) => !['Dolní Újezd', 'Dolní Újezd B'].includes(team.name));
    const team = response.data.find((team) => ['Dolní Újezd', 'Dolní Újezd B'].includes(team.name));
    setTeams(teams);
    setMyTeam(team);
  };

  useEffect(() => {
    getTeams();
  }, []);

  return (
    <Formik
      initialValues={{
        date: data ? data.date : '',
        time: data ? data.time : '',
        part: data ? data.part : '',
        info: data ? data.info : '',
        homeAway: data ? (myTeam && data.homeId === myTeam.id ? 'home' : 'away') : '',
        teamId: data ? (myTeam && data.homeId === myTeam.id ? data.awayId : data.homeId) : '',
        fullTimeScore: data ? data.fullTimeScore : '',
        halfTimeScore: data ? data.halfTimeScore : '',
        category: params.category,
        subcategory: params.subcategory,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values: MatchFormValues, actions: FormikActions<MatchFormValues>) => {
        onSubmit(values, actions);
      }}>
      {({ handleSubmit, handleChange, handleBlur, values, touched, setFieldValue, errors, isSubmitting }) => (
        <Form id={id} noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Datum zápasu</Form.Label>
              <DatePicker
                name="date"
                placeholder="Zadejte datum zápasu"
                value={values.date}
                disabled={isSubmitting}
                isInvalid={errors.date && touched.date}
                onChange={(value) => setFieldValue('date', value)}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.date}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Čas zápasu</Form.Label>
              <Form.Control
                type="time"
                name="time"
                placeholder="Zadejte čas zápasu"
                value={values.time}
                disabled={isSubmitting}
                isInvalid={errors.time && touched.time}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.time}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Část sezóny</Form.Label>
              <Form.Control
                name="part"
                as="select"
                value={values.part}
                disabled={isSubmitting}
                isInvalid={errors.part && touched.part}
                onChange={handleChange}
                onBlur={handleBlur}>
                <option value="">Vyberte část sezóny</option>
                <option value="podzim">podzim</option>
                <option value="jaro">jaro</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.part}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Domácí/hosté</Form.Label>
              <Form.Control
                name="homeAway"
                as="select"
                value={values.homeAway}
                disabled={isSubmitting}
                isInvalid={errors.homeAway && touched.homeAway}
                onChange={handleChange}
                onBlur={handleBlur}>
                <option value="">Vyberte, kde se bude hrát</option>
                <option value="home">doma</option>
                <option value="away">venku</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.homeAway}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Soupeř</Form.Label>
              <Form.Control
                name="teamId"
                as="select"
                value={values.teamId}
                disabled={isSubmitting}
                isInvalid={errors.teamId && touched.teamId}
                onChange={handleChange}
                onBlur={handleBlur}>
                <option value="">Vyberte soupeře</option>
                {teams
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((team) => (
                    <option key={`team_${team.id}`} value={team.id}>
                      {team.name}
                    </option>
                  ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.teamId}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row className="pt-4">
            <Col>
              <h5>Výsledek zápasu</h5>
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Výsledek na konci</Form.Label>
              <Form.Control
                name="fullTimeScore"
                placeholder={'-:-'}
                value={values.fullTimeScore}
                disabled={isSubmitting}
                isInvalid={errors.fullTimeScore && touched.fullTimeScore}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.fullTimeScore}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Výsledek v poločase</Form.Label>
              <Form.Control
                name="halfTimeScore"
                placeholder={'-:-'}
                value={values.halfTimeScore}
                disabled={isSubmitting}
                isInvalid={errors.halfTimeScore && touched.halfTimeScore}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
};

export default MatchForm;

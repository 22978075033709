import React from 'react';

import { SImg, SThumb, SThumbInner, SThumbsContainer } from './styled';

type Props = {
  files: File[];
};

const Previews = (props: Props) => {
  const { files } = props;
  return (
    <SThumbsContainer>
      {files.map((file) => (
        <SThumb key={file.name}>
          <SThumbInner>
            {/* @ts-ignore */}
            <SImg src={file.preview} alt="" />
          </SThumbInner>
        </SThumb>
      ))}
    </SThumbsContainer>
  );
};

export default Previews;

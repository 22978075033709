import { Container } from 'react-bootstrap';
import styled from 'styled-components';

import { colors } from '../../../layout/styles';

export const SPageContent = styled(Container)`
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 800px);

  h2 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  table {
    font-family: Nunito Sans;
    text-align: center;
    thead {
      font-weight: 900;
      color: ${colors.orange};
    }
    tbody {
      font-weight: 600;
      color: ${colors.blue};
    }
    tr.myTeam {
      color: ${colors.orange};
      font-weight: 800;
      border-bottom: 2px ${colors.orange} solid;
    }
    th,
    td {
      &.left {
        text-align: left;
      }
      &.bold {
        font-weight: 900;
      }
    }
  }
`;

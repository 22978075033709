import React, { useEffect } from 'react';
import { Card, Container } from 'react-bootstrap';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import YouTube, { Options } from 'react-youtube';

import { Category, Subcategory } from '../../../models/Category';
import { AppState } from '../../../redux/reducers';
import { getVideo } from '../../redux/actions/videosActions';
import { IVideoModel } from '../../../models/Video';

const VideoFormContainer = () => {
  const { params } = useRouteMatch<{ id: string; category: Category; subcategory?: Subcategory }>();
  const { id } = params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loading = useSelector<AppState, boolean>((state) => state.backend.videos.loadings.one);
  const video = useSelector<AppState, IVideoModel>((state) => state.backend.videos.items[id]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && id) {
      dispatch(getVideo(token, id));
    }
  }, []);

  if (!video) return null;

  const videoId = video.link.split('/')[3];
  const opts: Options = {
    height: '360',
    width: '640',
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <Container className="p-0">
      <Card>
        <Card.Header>Náhled videa - {video.name}</Card.Header>
        <Card.Body>
          <YouTube videoId={videoId} opts={opts} className="d-block m-auto" />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default VideoFormContainer;

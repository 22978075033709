import * as React from 'react';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, Container } from 'react-bootstrap';

import MeetingListContainer from './MeetingListContainer';
import MeetingFormContainer from './MeetingFormContainer';

const MeetingsContainer = () => {
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <Container fluid className="p-0">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item active={location.pathname === match.path}>
          {location.pathname === match.path ? 'Schůze' : <Link to={match.path}>Schůze</Link>}
        </Breadcrumb.Item>
        {location.pathname.includes('/detail') && <Breadcrumb.Item active={true}>Detail schůze</Breadcrumb.Item>}
        {location.pathname.includes('/add') && <Breadcrumb.Item active={true}>Nová schůze</Breadcrumb.Item>}
        {location.pathname.includes('/edit') && <Breadcrumb.Item active={true}>Úprava schůze</Breadcrumb.Item>}
      </Breadcrumb>
      <Switch>
        <Route exact path={`${match.path}/add`} component={MeetingFormContainer} />
        <Route exact path={`${match.path}/edit/:id`} component={MeetingFormContainer} />
        <Route exact path={match.path} component={MeetingListContainer} />
      </Switch>
    </Container>
  );
};

export default MeetingsContainer;

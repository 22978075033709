import { Formik, FormikActions } from 'formik';
import * as React from 'react';
import { Form } from 'react-bootstrap';
import { AutoResizer, ColumnShape } from 'react-base-table';
import { useEffect, useState } from 'react';

import { TableModel } from '../../../models/Team';
import DraggableTable from '../../components/DraggableTable';

import { Container } from './styled';

export interface TableFormValues {
  data: Array<TableModel>;
}

type Props = {
  id: string;
  data?: Array<TableModel>;
  onSubmit: (values: TableFormValues, actions: FormikActions<TableFormValues>) => void;
};

const TableForm = (props: Props) => {
  const { id, data, onSubmit } = props;

  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const handleChangeData = (data: Array<TableModel>) => {
    setTableData(data);
  };

  return (
    <Formik
      initialValues={{
        data: tableData
          ? tableData.map((team, index) => ({
              ...team,
              order: index + 1,
            }))
          : [],
      }}
      enableReinitialize
      onSubmit={(values: TableFormValues, actions: FormikActions<TableFormValues>) => {
        onSubmit(values, actions);
      }}>
      {({ handleSubmit, handleChange, handleBlur, values, isSubmitting }) => {
        const columns: ColumnShape<TableModel>[] = [
          {
            key: 'id',
            dataKey: 'id',
            title: 'ID',
            width: 0,
            flexGrow: 1,
            hidden: true,
            cellRenderer: ({ cellData }) => (
              <Form.Control type="hidden" name="id" value={cellData} disabled={isSubmitting} onChange={handleChange} onBlur={handleBlur} />
            ),
          },
          {
            key: 'order',
            dataKey: 'order',
            title: '#',
            width: 0,
            flexGrow: 1,
            cellRenderer: ({ cellData, rowIndex }) => (
              <div>
                {rowIndex + 1}
                <Form.Control
                  type="hidden"
                  name={`data[${rowIndex}].order`}
                  value={cellData}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            ),
          },
          { key: 'name', dataKey: 'name', title: 'Název', width: 0, flexGrow: 1 },
          {
            key: 'numberOfMatches',
            dataKey: 'numberOfMatches',
            title: 'Zápasy',
            width: 0,
            flexGrow: 1,
            cellRenderer: ({ cellData, rowIndex }) => (
              <Form.Control
                type="number"
                name={`data[${rowIndex}].numberOfMatches`}
                value={cellData}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            ),
          },
          {
            key: 'goalsScored',
            dataKey: 'goalsScored',
            title: 'Vstřelené branky',
            width: 0,
            flexGrow: 1,
            cellRenderer: ({ cellData, rowIndex }) => (
              <Form.Control
                type="number"
                name={`data[${rowIndex}].goalsScored`}
                value={cellData}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            ),
          },
          {
            key: 'goalsReceived',
            dataKey: 'goalsReceived',
            title: 'Obdržené branky',
            width: 0,
            flexGrow: 1,
            cellRenderer: ({ cellData, rowIndex }) => (
              <Form.Control
                type="number"
                name={`data[${rowIndex}].goalsReceived`}
                value={cellData}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            ),
          },
          {
            key: 'points',
            dataKey: 'points',
            title: 'Body',
            width: 0,
            flexGrow: 1,
            cellRenderer: ({ cellData, rowIndex }) => (
              <Form.Control
                type="number"
                name={`data[${rowIndex}].points`}
                value={cellData}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            ),
          },
        ];

        return (
          <Form id={id} noValidate onSubmit={handleSubmit}>
            <Container count={values.data ? values.data.length : 0}>
              <AutoResizer>
                {({ width, height }) => <DraggableTable width={width} height={height} columns={columns} data={values.data} onChange={handleChangeData} />}
              </AutoResizer>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TableForm;

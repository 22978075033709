import * as React from 'react';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, Container } from 'react-bootstrap';

import PlayerListContainer from './PlayerListContainer';
import PlayerFormContainer from './PlayerFormContainer';
import PlayerImageContainer from './PlayerImageContainer';

const PlayersContainer = () => {
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <Container fluid className="p-0">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item active={location.pathname === match.path}>
          {location.pathname === match.path ? 'Hráči' : <Link to={match.path}>Hráči</Link>}
        </Breadcrumb.Item>
        {location.pathname.includes('/detail') && <Breadcrumb.Item active={true}>Detail hráče</Breadcrumb.Item>}
        {location.pathname.includes('/add') && <Breadcrumb.Item active={true}>Nový hráč</Breadcrumb.Item>}
        {location.pathname.includes('/edit') && <Breadcrumb.Item active={true}>Úprava hráče</Breadcrumb.Item>}
      </Breadcrumb>
      <Switch>
        <Route exact path={`${match.path}/:category/add`} component={PlayerFormContainer} />
        <Route exact path={`${match.path}/:category/edit/:id`} component={PlayerFormContainer} />
        <Route exact path={`${match.path}/:category/image/:id`} component={PlayerImageContainer} />
        <Route exact path={`${match.path}/:category`} component={PlayerListContainer} />
        <Route exact path={`${match.path}/:category/:subcategory/add`} component={PlayerFormContainer} />
        <Route exact path={`${match.path}/:category/:subcategory/edit/:id`} component={PlayerFormContainer} />
        <Route exact path={`${match.path}/:category/:subcategory/image/:id`} component={PlayerImageContainer} />
        <Route exact path={`${match.path}/:category/:subcategory`} component={PlayerListContainer} />
        <Route exact path={match.path} component={PlayerListContainer} />
      </Switch>
    </Container>
  );
};

export default PlayersContainer;

import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Category, Subcategory } from '../../../models/Category';
import { AppState } from '../../../redux/reducers';
import FileUpload from '../../components/FileUpload';
import { ASSETS_URL } from '../../../constants';
import { getSponsor, updateSponsorPhoto } from '../../redux/actions/sponsorsActions';
import { ISponsorModel } from '../../../models/Sponsor';

const SponsorImageContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch<{ id: string; category: Category; subcategory?: Subcategory }>();
  const { id } = params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loadingAction = useSelector<AppState, boolean>((state) => state.backend.teams.loadings.uploadPhoto);
  const error = useSelector<AppState, Error | undefined>((state) => state.backend.teams.errors.uploadPhoto);
  const loading = useSelector<AppState, boolean>((state) => state.backend.teams.loadings.one);
  const sponsor = useSelector<AppState, ISponsorModel>((state) => state.backend.sponsors.items[id]);

  const dispatch = useDispatch();

  const [isSent, setIsSent] = useState(false);
  const [files, setFiles] = useState<Array<File>>([]);

  useEffect(() => {
    if (!loading && id) {
      dispatch(getSponsor(token, id));
    }
  }, []);

  useEffect(() => {
    if (isSent) {
      if (!loadingAction && !error) {
        history.push(`/admin/sponsors`);
      }
      setIsSent(false);
    }
  }, [isSent, loadingAction, error]);

  const handleSubmit = () => {
    dispatch(updateSponsorPhoto(token, sponsor.id, files[0]));
    setIsSent(true);
  };

  if (!sponsor) return null;

  return (
    <Container className="p-0">
      <Card>
        <Card.Header>{`Změna fotky sponzora - ${sponsor.name}`}</Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <h5>Nová fotka sponzora</h5>
              <FileUpload type="previews" accept={['image/png']} maxFiles={1} onDrop={(acceptedFiles) => setFiles(acceptedFiles)} />
            </Col>
            <Col>
              <h5>Původní fotka sponzora</h5>
              <div className="d-flex flex-column justify-content-center align-items-center">
                {sponsor.hasImage ? (
                  <img src={`${ASSETS_URL}/sponsors/${sponsor.id}.png`} alt="fotka sponzora" className="w-100 h-auto" />
                ) : (
                  <FontAwesomeIcon icon={faImage} size="8x" />
                )}
              </div>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Button type="button" disabled={isSent} className="float-right" variant="primary" onClick={handleSubmit}>
            Změnit fotku
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default SponsorImageContainer;

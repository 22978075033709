import { ISponsorModel } from '../../../models/Sponsor';

export const SPONSORS_LOADING = 'FRONTEND_SPONSORS_LOADING';
export const SPONSORS_ERROR = 'FRONTEND_SPONSORS_ERROR';
export const SPONSORS_ALL = 'FRONTEND_SPONSORS_ALL';

export interface SponsorsLoadingAction {
  type: typeof SPONSORS_LOADING;
  name: string;
}

export interface SponsorsErrorAction {
  type: typeof SPONSORS_ERROR;
  name: string;
  error: Error;
}

export interface SponsorsAllAction {
  type: typeof SPONSORS_ALL;
  sponsors: { [key: number]: ISponsorModel };
}

export type SponsorsActionTypes = SponsorsErrorAction | SponsorsLoadingAction | SponsorsAllAction;

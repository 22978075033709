import { Container } from 'react-bootstrap';
import styled from 'styled-components';

import { colors } from '../../../layout/styles';

export const SPageContent = styled(Container)`
  min-height: calc(100vh - 800px);

  h2 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .nav-tabs {
    justify-content: center;
    align-items: center;
    border-bottom: 1px ${colors.grey} solid;

    .nav-item {
      justify-content: center;
      align-items: center;
    }

    .tab {
      font-family: Nunito Sans;
      font-weight: 900;
      font-size: 16px;
      color: ${colors.grey};
      border: none;

      &.active {
        color: ${colors.blue};
        border-bottom: 4px ${colors.orange} solid;
      }

      &:hover {
        color: ${colors.blue};
      }
    }
  }
`;

import axios from 'axios';
import { notify } from 'reapop';

import { endpoints } from '../../../constants/API';
import { IPlayerModel } from '../../../models/Player';
import {
  PLAYERS_LOADING,
  PLAYERS_ERROR,
  PLAYERS_ALL,
  PLAYERS_ONE,
  PlayersLoadingAction,
  PlayersErrorAction,
  PlayersAllAction,
  PlayersCreateAction,
  PlayersUpdateAction,
  PlayersRemoveAction,
  PLAYERS_REMOVE,
  PLAYERS_UPDATE,
  PLAYERS_CREATE,
  PlayersOneAction,
} from '../types/playersTypes';
import { Category, Subcategory } from '../../../models/Category';
import { ASSETS_URL } from '../../../constants';

import { loadingTeams } from './teamsActions';

export const loadingPlayers = (name: string): PlayersLoadingAction => ({
  type: PLAYERS_LOADING,
  name,
});

const errorPlayers = (name: string, error: Error): PlayersErrorAction => ({
  type: PLAYERS_ERROR,
  name,
  error,
});

const successPlayers = (category: Category, subcategory: Subcategory, players: { [key: number]: IPlayerModel }): PlayersAllAction => ({
  type: PLAYERS_ALL,
  category,
  subcategory,
  players,
});

const successPlayer = (player: IPlayerModel): PlayersOneAction => ({
  type: PLAYERS_ONE,
  player,
});

const successCreatePlayer = (player: IPlayerModel): PlayersCreateAction => ({
  type: PLAYERS_CREATE,
  player,
});

const successUpdatePlayer = (player: IPlayerModel): PlayersUpdateAction => ({
  type: PLAYERS_UPDATE,
  player,
});

const successRemovePlayer = (id): PlayersRemoveAction => ({
  type: PLAYERS_REMOVE,
  playerId: id,
});

export const getPlayers =
  (token, category: Category = undefined, subcategory: Subcategory = undefined) =>
  async (dispatch) => {
    const actionName = 'all';
    dispatch(loadingPlayers(actionName));
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          category,
          subcategory,
        },
      };
      const response = await axios.get(endpoints.players, config);
      const data = response.data;
      const players = {};
      for (const player of data) {
        players[player.id] = {
          ...player,
          hasImage: (await fetch(`${ASSETS_URL}/players/${player.id}.jpg`, { method: 'HEAD' })).ok,
        };
      }
      dispatch(successPlayers(category, subcategory, players));
    } catch (e) {
      dispatch(errorPlayers(actionName, e));
      console.error(e);
    }
  };

export const getPlayer = (token: string, playerId: string) => async (dispatch) => {
  const actionName = 'one';
  dispatch(loadingPlayers(actionName));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${endpoints.players}/${playerId}`, config);
    const player = {
      ...response.data,
      hasImage: (await fetch(`${ASSETS_URL}/players/${playerId}.jpg`, { method: 'HEAD' })).ok,
    };
    dispatch(successPlayer(player));
  } catch (e) {
    dispatch(errorPlayers(actionName, e));
    console.error(e);
  }
};

export const createPlayer = (token: string, values) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'create';
  dispatch(loadingPlayers(actionName));
  try {
    const response = await axios.post(endpoints.players, values, config);
    const player = response.data;
    dispatch(successCreatePlayer(player));
    dispatch(notify('Hráč přidán.', 'success'));
  } catch (e) {
    dispatch(errorPlayers(actionName, e));
    dispatch(notify('Hráče se nepodařilo přidat.', 'error'));
  }
};

export const updatePlayer = (token: string, playerId: number, values) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'update';
  dispatch(loadingPlayers(actionName));
  try {
    const response = await axios.put(`${endpoints.players}/${playerId}`, values, config);
    const player = response.data;
    dispatch(successUpdatePlayer(player));
    dispatch(notify('Hráč upraven.', 'success'));
  } catch (e) {
    dispatch(errorPlayers(actionName, e));
    dispatch(notify('Hráče se nepodařilo upravit.', 'error'));
  }
};

export const removePlayer = (token: string, playerId: number) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'remove';
  dispatch(loadingPlayers(actionName));
  try {
    await axios.delete(`${endpoints.players}/${playerId}`, config);
    dispatch(successRemovePlayer(playerId));
    dispatch(notify('Hráč odstraněn.', 'success'));
  } catch (e) {
    dispatch(errorPlayers(actionName, e));
    dispatch(notify('Hráče se nepodařilo odstranit.', 'error'));
    console.error(e);
  }
};

export const updatePlayerPhoto = (token: string, playerId: number, file: File) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'updatePhoto';
  dispatch(loadingTeams(actionName));
  try {
    const fileUpload = new FormData();
    fileUpload.append('photo', file, file.name);
    await axios.put(`${endpoints.players}/${playerId}/photo`, fileUpload, config);
    dispatch(notify('Fotka hráče upravena.', 'success'));
  } catch (e) {
    dispatch(errorPlayers(actionName, e));
    dispatch(notify('Fotku hráče se nepodařilo upravit.', 'error'));
    console.error(e);
  }
};

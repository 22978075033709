import * as React from 'react';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, Container } from 'react-bootstrap';

import SeasonFormContainer from './SeasonFormContainer';
import SeasonListContainer from './SeasonListContainer';

const SeasonsContainer = () => {
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <Container fluid className="p-0">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item active={location.pathname === match.path}>
          {location.pathname === match.path ? 'Sezóny' : <Link to={match.path}>Sezóny</Link>}
        </Breadcrumb.Item>
        {location.pathname.includes(`/add`) && <Breadcrumb.Item active>Nová sezóna</Breadcrumb.Item>}
        {location.pathname.includes(`/edit`) && <Breadcrumb.Item active>Úprava sezóna</Breadcrumb.Item>}
      </Breadcrumb>
      <Switch>
        <Route exact path={`${match.path}/add`} component={SeasonFormContainer} />
        <Route exact path={`${match.path}/edit/:id`} component={SeasonFormContainer} />
        <Route exact path={match.path} component={SeasonListContainer} />
      </Switch>
    </Container>
  );
};

export default SeasonsContainer;

import { Formik, FormikActions } from 'formik';
import * as React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { Category, Subcategory } from '../../../models/Category';
import { ITeamModel } from '../../../models/Team';

import validationSchema from './validation';

export interface TeamFormValues {
  name: string;
  nameFACR: string;
  category: Category;
  subcategory?: Subcategory;
}

type Props = {
  id: string;
  data?: ITeamModel;
  onSubmit: (values: TeamFormValues, actions: FormikActions<TeamFormValues>) => void;
};

const TeamForm = (props: Props) => {
  const { id, data, onSubmit } = props;
  const params = useParams<{ category: Category; subcategory: Subcategory }>();

  return (
    <Formik
      initialValues={{
        name: data ? data.name : '',
        nameFACR: data ? data.nameFACR : '',
        category: params.category,
        subcategory: params.subcategory,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values: TeamFormValues, actions: FormikActions<TeamFormValues>) => {
        onSubmit(values, actions);
      }}>
      {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
        <Form id={id} noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Název týmu</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Zadejte název týmu"
                value={values.name}
                disabled={isSubmitting}
                isInvalid={errors.name && touched.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Název týmu na FAČR</Form.Label>
              <Form.Control
                type="text"
                name="nameFACR"
                placeholder="Zadejte název týmu na FAČR"
                value={values.nameFACR}
                disabled={isSubmitting}
                isInvalid={errors.nameFACR && touched.nameFACR}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.nameFACR}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
};

export default TeamForm;

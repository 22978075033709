import { Formik, FormikActions } from 'formik';
import * as React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { Category, Subcategory } from '../../../models/Category';
import { ITournamentModel } from '../../../models/Tournament';
import DatePicker from '../../components/Datepicker';
import TextEditor from '../../components/TextEditor';

import validationSchema from './validation';

export interface TournamentFormValues {
  date: string;
  time: string;
  part: string;
  name: string;
  info: string;
  category: Category;
  subcategory?: Subcategory;
}

type Props = {
  id: string;
  data?: ITournamentModel | null;
  onSubmit: (values: TournamentFormValues, actions: FormikActions<TournamentFormValues>) => void;
};

const TournamentForm = (props: Props) => {
  const { id, data, onSubmit } = props;
  const params = useParams<{ category: Category; subcategory: Subcategory }>();

  return (
    <Formik
      initialValues={{
        date: data ? data.date : '',
        time: data ? data.time : '',
        part: data ? data.part : '',
        name: data ? data.name : '',
        info: data ? data.info : '',
        category: params.category,
        subcategory: params.subcategory,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values: TournamentFormValues, actions: FormikActions<TournamentFormValues>) => {
        onSubmit(values, actions);
      }}>
      {({ handleSubmit, handleChange, handleBlur, values, touched, setFieldValue, errors, isSubmitting }) => (
        <Form id={id} noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Datum turnaje</Form.Label>
              <DatePicker
                name="date"
                placeholder="Zadejte datum turnaje"
                value={values.date}
                disabled={isSubmitting}
                isInvalid={errors.date && touched.date}
                onChange={(value) => setFieldValue('date', value)}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.date}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Čas turnaje</Form.Label>
              <Form.Control
                type="time"
                name="time"
                placeholder="Zadejte čas turnaje"
                value={values.time}
                disabled={isSubmitting}
                isInvalid={errors.time && touched.time}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.time}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Část sezóny</Form.Label>
              <Form.Control
                name="part"
                as="select"
                value={values.part}
                disabled={isSubmitting}
                isInvalid={errors.part && touched.part}
                onChange={handleChange}
                onBlur={handleBlur}>
                <option value="">Vyberte část sezóny</option>
                <option value="podzim">podzim</option>
                <option value="jaro">jaro</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.part}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Název turnaje</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Zadejte název turnaje"
                value={values.name}
                disabled={isSubmitting}
                isInvalid={errors.name && touched.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Další informace o turnaji</Form.Label>
              <TextEditor
                name="info"
                placeholder="Zadejte další informace o turnaji"
                value={values.info}
                disabled={isSubmitting}
                isInvalid={errors.info && touched.info}
                onChange={(value) => setFieldValue('info', value)}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.info}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
};

export default TournamentForm;

import axios from 'axios';

import { endpoints } from '../../../constants/API';
import { ISponsorModel } from '../../../models/Sponsor';
import { SPONSORS_LOADING, SPONSORS_ERROR, SPONSORS_ALL, SponsorsLoadingAction, SponsorsErrorAction, SponsorsAllAction } from '../types/sponsorsTypes';
import { ASSETS_URL } from '../../../constants';

export const loadingSponsors = (name: string): SponsorsLoadingAction => ({
  type: SPONSORS_LOADING,
  name,
});

const errorSponsors = (name: string, error: Error): SponsorsErrorAction => ({
  type: SPONSORS_ERROR,
  name,
  error,
});

const successSponsors = (sponsors: { [key: number]: ISponsorModel }): SponsorsAllAction => ({
  type: SPONSORS_ALL,
  sponsors,
});

export const getSponsors = () => async (dispatch) => {
  const actionName = 'all';
  dispatch(loadingSponsors(actionName));
  try {
    const config = {};
    const response = await axios.get(endpoints.sponsors, config);
    const data = response.data;
    const sponsors = {};
    for (const sponsor of data) {
      sponsors[sponsor.id] = {
        ...sponsor,
        hasImage: (await fetch(`${ASSETS_URL}/sponsors/${sponsor.id}.png`, { method: 'HEAD' })).ok,
      };
    }
    dispatch(successSponsors(sponsors));
  } catch (e) {
    dispatch(errorSponsors(actionName, e));
    console.error(e);
  }
};

import { IVideoModel } from '../../../models/Video';

export const VIDEOS_LOADING = 'VIDEOS_LOADING';
export const VIDEOS_ERROR = 'VIDEOS_ERROR';
export const VIDEOS_ALL = 'VIDEOS_ALL';
export const VIDEOS_ONE = 'VIDEOS_ONE';
export const VIDEOS_CREATE = 'VIDEOS_CREATE';
export const VIDEOS_UPDATE = 'VIDEOS_UPDATE';
export const VIDEOS_REMOVE = 'VIDEOS_REMOVE';

export interface VideoLoadingAction {
  type: typeof VIDEOS_LOADING;
  name: string;
}

export interface VideoErrorAction {
  type: typeof VIDEOS_ERROR;
  name: string;
  error: Error;
}

export interface VideoAllAction {
  type: typeof VIDEOS_ALL;
  videos: { [key: number]: IVideoModel };
}

export interface VideoOneAction {
  type: typeof VIDEOS_ONE;
  video: IVideoModel;
}

export interface VideoCreateAction {
  type: typeof VIDEOS_CREATE;
  video: IVideoModel;
}

export interface VideoUpdateAction {
  type: typeof VIDEOS_UPDATE;
  video: IVideoModel;
}

export interface VideoRemoveAction {
  type: typeof VIDEOS_REMOVE;
  videoId: number;
}

export type VideoActionTypes =
  | VideoErrorAction
  | VideoLoadingAction
  | VideoAllAction
  | VideoOneAction
  | VideoCreateAction
  | VideoUpdateAction
  | VideoRemoveAction;

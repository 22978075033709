import { combineReducers } from 'redux';
import { reducer as notificationsReducer } from 'reapop';

import backend, { BackendState } from '../backend/redux/reducers';
import frontend, { FrontendState } from '../frontend/redux/reducers';

export interface AppState {
  notifications: any;
  backend: BackendState;
  frontend: FrontendState;
}

const reduxApp = combineReducers<AppState>({
  notifications: notificationsReducer(),
  backend,
  frontend,
});

export default reduxApp;

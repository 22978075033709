import axios from 'axios';
import { notify } from 'reapop';

import { endpoints } from '../../../constants/API';
import { ITeamModel, TableModel } from '../../../models/Team';
import {
  TEAMS_ALL,
  TEAMS_CREATE,
  TEAMS_ERROR,
  TEAMS_LOADING,
  TEAMS_ONE,
  TEAMS_REMOVE,
  TEAMS_TABLE,
  TEAMS_TABLE_UPDATE,
  TEAMS_UPDATE,
  TeamsAllAction,
  TeamsCreateAction,
  TeamsErrorAction,
  TeamsLoadingAction,
  TeamsOneAction,
  TeamsRemoveAction,
  TeamsTableAction,
  TeamsTableUpdateAction,
  TeamsUpdateAction,
} from '../types/teamsTypes';
import { Category, Subcategory } from '../../../models/Category';
import { TableFormValues } from '../../forms/TableForm';

export const loadingTeams = (name: string): TeamsLoadingAction => ({
  type: TEAMS_LOADING,
  name,
});

const errorTeams = (name: string, error: Error): TeamsErrorAction => ({
  type: TEAMS_ERROR,
  name,
  error,
});

const successTeams = (teams: { [key: number]: ITeamModel }): TeamsAllAction => ({
  type: TEAMS_ALL,
  teams,
});

const successTeam = (team: ITeamModel): TeamsOneAction => ({
  type: TEAMS_ONE,
  team,
});

const successCreateTeam = (team: ITeamModel): TeamsCreateAction => ({
  type: TEAMS_CREATE,
  team,
});

const successUpdateTeam = (team: ITeamModel): TeamsUpdateAction => ({
  type: TEAMS_UPDATE,
  team,
});

const successRemoveTeam = (id): TeamsRemoveAction => ({
  type: TEAMS_REMOVE,
  teamId: id,
});

const successTable = (category: Category, subcategory: Subcategory, table: Array<TableModel>): TeamsTableAction => ({
  type: TEAMS_TABLE,
  category,
  subcategory,
  table,
});

const successTableUpdate = (category: Category, subcategory: Subcategory, table: Array<TableModel>): TeamsTableUpdateAction => ({
  type: TEAMS_TABLE_UPDATE,
  category,
  subcategory,
  table,
});

export const getTeams =
  (token, category: Category = 'mA', subcategory: Subcategory = undefined) =>
  async (dispatch) => {
    const actionName = 'all';
    dispatch(loadingTeams(actionName));
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          category,
          subcategory,
        },
      };
      const response = await axios.get(endpoints.teams, config);
      const data = response.data;
      const teams = {};
      data.forEach((team) => {
        teams[team.id] = team;
      });
      dispatch(successTeams(teams));
    } catch (e) {
      dispatch(errorTeams(actionName, e));
      console.error(e);
    }
  };

export const getTeam = (token: string, teamId: string) => async (dispatch) => {
  const actionName = 'one';
  dispatch(loadingTeams(actionName));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${endpoints.teams}/${teamId}`, config);
    const team = response.data;
    dispatch(successTeam(team));
  } catch (e) {
    dispatch(errorTeams(actionName, e));
    console.error(e);
  }
};

export const getTable =
  (token, category: Category = 'mA', subcategory: Subcategory = undefined) =>
  async (dispatch) => {
    const actionName = 'table';
    dispatch(loadingTeams(actionName));
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          category,
          subcategory,
        },
      };
      const response = await axios.get(`${endpoints.teams}/table`, config);
      const data = response.data;
      dispatch(successTable(category, subcategory, data));
    } catch (e) {
      dispatch(errorTeams(actionName, e));
      console.error(e);
    }
  };

export const createTeam = (token: string, values) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'create';
  dispatch(loadingTeams(actionName));
  try {
    const response = await axios.post(endpoints.teams, values, config);
    const team = response.data;
    dispatch(successCreateTeam(team));
    dispatch(notify('Tým přidán.', 'success'));
  } catch (e) {
    dispatch(errorTeams(actionName, e));
    dispatch(notify('Tým se nepodařilo přidat.', 'error'));
  }
};

export const updateTeam = (token: string, teamId: number, values) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'update';
  dispatch(loadingTeams(actionName));
  try {
    const response = await axios.put(`${endpoints.teams}/${teamId}`, values, config);
    const team = response.data;
    dispatch(successUpdateTeam(team));
    dispatch(notify('Tým upraven.', 'success'));
  } catch (e) {
    dispatch(errorTeams(actionName, e));
    dispatch(notify('Tým se nepodařilo upravit.', 'error'));
  }
};

export const removeTeam = (token: string, teamId: number) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'remove';
  dispatch(loadingTeams(actionName));
  try {
    await axios.delete(`${endpoints.teams}/${teamId}`, config);
    dispatch(successRemoveTeam(teamId));
    dispatch(notify('Tým odstraněn.', 'success'));
  } catch (e) {
    dispatch(errorTeams(actionName, e));
    dispatch(notify('Tým se nepodařilo odstranit.', 'error'));
    console.error(e);
  }
};

export const updateTable =
  (token: string, category: Category = 'mA', subcategory: Subcategory = undefined, values: TableFormValues) =>
  async (dispatch) => {
    const config = {
      params: {
        category,
        subcategory,
      },
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const actionName = 'updateTable';
    dispatch(loadingTeams(actionName));
    try {
      const response = await axios.put(`${endpoints.teams}/table/update`, values.data, config);
      const { data } = response;
      dispatch(successTableUpdate(category, subcategory, data));
      dispatch(notify('Tabulka upravena.', 'success'));
    } catch (e) {
      dispatch(errorTeams(actionName, e));
      dispatch(notify('Tabulku se nepodařilo upravit.', 'error'));
      console.error(e);
    }
  };

export const updateTeamPhoto = (token: string, teamId: number, file: File) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'updatePhoto';
  dispatch(loadingTeams(actionName));
  try {
    const fileUpload = new FormData();
    fileUpload.append('photo', file, file.name);
    await axios.put(`${endpoints.teams}/${teamId}/photo`, fileUpload, config);
    dispatch(notify('Logo týmu upraveno.', 'success'));
  } catch (e) {
    dispatch(errorTeams(actionName, e));
    dispatch(notify('Logo týmu se nepodařilo upravit.', 'error'));
    console.error(e);
  }
};

import * as React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { getMe } from '../../redux/actions/userActions';
import { colors } from '../../layout/basicStyles';
import { AppState } from '../../../redux/reducers';

import { StyledUser } from './styledComponents';

type Props = {
  showName?: boolean;
  showRole?: boolean;
  inNavbar?: boolean;
};

const User = (props: Props) => {
  const { showName, showRole, inNavbar } = props;
  const loading = useSelector<AppState, boolean>((state) => state.backend.user.loading);
  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const username = useSelector<AppState, string>((state) => state.backend.user.userData?.username);
  const role = useSelector<AppState, string>((state) => state.backend.user.userData?.role);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && (!username || !role)) {
      dispatch(getMe(token));
    }
  }, []);

  return (
    <SkeletonTheme baseColor={colors.bgDark} highlightColor={colors.blue}>
      <StyledUser className={inNavbar ? 'inNavbar' : ''}>
        {showName && <span className="name">{username || <Skeleton />}</span>}
        {showRole && <span className="role">{role || <Skeleton />}</span>}
      </StyledUser>
    </SkeletonTheme>
  );
};

export default User;

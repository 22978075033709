import axios from 'axios';

import { endpoints } from '../../../constants/API';
import { IATablesError, IATablesLoading, IATablesOne, TABLES_ERROR, TABLES_LOADING, TABLES_ONE } from '../types/tablesTypes';
import { Category, Subcategory } from '../../../models/Category';
import { TableModel } from '../../../models/Team';

export const ATablesLoading = (name: string): IATablesLoading => ({
  type: TABLES_LOADING,
  name,
});

const ATablesError = (name: string, error: Error): IATablesError => ({
  type: TABLES_ERROR,
  name,
  error,
});

const ATablesOne = (category: Category, subcategory: Subcategory, table: TableModel): IATablesOne => ({
  type: TABLES_ONE,
  category,
  subcategory,
  table,
});

export const getTable = (category: Category, subcategory: Subcategory) => async (dispatch) => {
  const actionName = 'table';
  dispatch(ATablesLoading(actionName));
  try {
    const config = {
      params: {
        category,
        subcategory,
      },
    };
    const response = await axios.get(`${endpoints.tables}`, config);
    const data = response.data;
    dispatch(ATablesOne(category, subcategory, data));
  } catch (e) {
    dispatch(ATablesError(actionName, e));
    console.error(e);
  }
};

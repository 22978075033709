import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Card, Container, Image } from 'react-bootstrap';

import { AppState } from '../../../redux/reducers';
import { IArticleModel } from '../../../models/Article';
import { getArticle, setArticlePhoto } from '../../redux/actions/articlesActions';
import { Category, Subcategory } from '../../../models/Category';
import PhotoPicker from '../../components/PhotoPicker';
import { SERVER_URL } from '../../../constants';

const ArticleImageContainer = () => {
  const history = useHistory();
  const match = useRouteMatch<{ id: string; category: Category; subcategory?: Subcategory }>();
  const { id, category, subcategory } = match.params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loading = useSelector<AppState, boolean>((state) => state.backend.articles.loadings.one);
  const loadingAction = useSelector<AppState, boolean>((state) => state.backend.articles.loadings.update);
  const error = useSelector<AppState, Error | undefined>((state) => state.backend.articles.errors.update);
  const article = useSelector<AppState, IArticleModel>((state) => state.backend.articles.items[id]);

  const dispatch = useDispatch();

  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (!loading && id) {
      dispatch(getArticle(token, id));
    }
  }, []);

  useEffect(() => {
    if (isSent) {
      if (!loadingAction && !error) {
        setIsSent(false);
        history.push(`/admin/articles/${category}${subcategory ? `/${subcategory}` : ''}`);
      }
    }
  }, [isSent, loadingAction, error, category, subcategory, history]);

  const handleSetPhoto = (photoId: number) => {
    dispatch(setArticlePhoto(token, article.id, photoId));
    setIsSent(true);
  };

  if (!article || !article.content) return null;

  return (
    <Container fluid className="p-0">
      {article.photo ? (
        <Container fluid className="p-0 d-flex justify-content-center align-items-center">
          <Card className="w-25 mb-5">
            <Card.Header>Přiřazená fotka k článku</Card.Header>
            <Card.Body className="d-flex justify-content-center align-items-center">
              <Image fluid src={`${SERVER_URL}${article.photo.url}`} />
            </Card.Body>
          </Card>
        </Container>
      ) : null}
      <PhotoPicker onSelect={handleSetPhoto} />
    </Container>
  );
};

export default ArticleImageContainer;

import React, { useState } from 'react';
import { Button, Collapse, Container, NavItem, NavLink } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useRouteMatch } from 'react-router-dom';

import { Category, Subcategory } from '../../../../models/Category';
import Icon from '../../../components/IcoMoon';
import { SSubmenu } from '../../../layout/styled';

type Props = {
  category: Category;
  subcategory: Subcategory;
};

const Submenu = (props: Props) => {
  const { category } = props;
  const match = useRouteMatch();

  const [open, setOpen] = useState(false);

  return (
    <SSubmenu fluid className="px-0">
      <Container className="px-0">
        <div className="py-1">
          <nav className="nav navbar navbar-light navbar-expand-md d-flex justify-content-between align-items-center">
            <Button className="navbar-toggler" aria-controls="submenuContent" aria-expanded={open} onClick={() => setOpen(!open)}>
              <Icon icon="menu" size={24} className="menu-icon" />
            </Button>
            <Collapse in={open}>
              <div className="navbar-collapse" id="submenuContent">
                <ul className="navbar-nav m-auto">
                  <NavItem>
                    <LinkContainer to={`${match.path}/overview`}>
                      <NavLink className="p-2">Přehled</NavLink>
                    </LinkContainer>
                  </NavItem>
                  <NavItem>
                    <LinkContainer to={`${match.path}/articles`}>
                      <NavLink className="p-2">Články</NavLink>
                    </LinkContainer>
                  </NavItem>
                  {!['pr', 'sk'].includes(category) ? (
                    <NavItem>
                      <LinkContainer to={`${match.path}/matches`}>
                        <NavLink className="p-2">Zápasy</NavLink>
                      </LinkContainer>
                    </NavItem>
                  ) : null}
                  {category === 'pr' ? (
                    <NavItem>
                      <LinkContainer to={`${match.path}/tournaments`}>
                        <NavLink className="p-2">Turnaje</NavLink>
                      </LinkContainer>
                    </NavItem>
                  ) : null}
                  {!['pr', 'sk'].includes(category) ? (
                    <NavItem>
                      <LinkContainer to={`${match.path}/table`}>
                        <NavLink className="p-2">Tabulka</NavLink>
                      </LinkContainer>
                    </NavItem>
                  ) : null}
                  <NavItem>
                    <LinkContainer to={`${match.path}/players`}>
                      <NavLink className="p-2">Soupiska</NavLink>
                    </LinkContainer>
                  </NavItem>
                  <NavItem>
                    <LinkContainer to={`${match.path}/photos`}>
                      <NavLink className="p-2">Fotogalerie</NavLink>
                    </LinkContainer>
                  </NavItem>
                  <NavItem>
                    <LinkContainer to={`${match.path}/videos`}>
                      <NavLink className="p-2">Video</NavLink>
                    </LinkContainer>
                  </NavItem>
                  {category !== 'sk' ? (
                    <NavItem>
                      <LinkContainer to={`${match.path}/calendar`}>
                        <NavLink className="p-2">Kalendář</NavLink>
                      </LinkContainer>
                    </NavItem>
                  ) : null}
                </ul>
              </div>
            </Collapse>
          </nav>
        </div>
      </Container>
    </SSubmenu>
  );
};

export default Submenu;

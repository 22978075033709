import { ITournamentModel } from '../../../models/Tournament';

export const TOURNAMENTS_LOADING = 'TOURNAMENTS_LOADING';
export const TOURNAMENTS_ERROR = 'TOURNAMENTS_ERROR';
export const TOURNAMENTS_ALL = 'TOURNAMENTS_ALL';
export const TOURNAMENTS_ONE = 'TOURNAMENTS_ONE';
export const TOURNAMENTS_CREATE = 'TOURNAMENTS_CREATE';
export const TOURNAMENTS_UPDATE = 'TOURNAMENTS_UPDATE';
export const TOURNAMENTS_REMOVE = 'TOURNAMENTS_REMOVE';

export interface TournamentsLoadingAction {
  type: typeof TOURNAMENTS_LOADING;
  name: string;
}

export interface TournamentsErrorAction {
  type: typeof TOURNAMENTS_ERROR;
  name: string;
  error: Error;
}

export interface TournamentsAllAction {
  type: typeof TOURNAMENTS_ALL;
  tournaments: { [key: number]: ITournamentModel };
}

export interface TournamentsOneAction {
  type: typeof TOURNAMENTS_ONE;
  tournament: ITournamentModel;
}

export interface TournamentsCreateAction {
  type: typeof TOURNAMENTS_CREATE;
  tournament: ITournamentModel;
}

export interface TournamentsUpdateAction {
  type: typeof TOURNAMENTS_UPDATE;
  tournament: ITournamentModel;
}

export interface TournamentsRemoveAction {
  type: typeof TOURNAMENTS_REMOVE;
  tournamentId: number;
}

export type TournamentsActionTypes =
  | TournamentsErrorAction
  | TournamentsLoadingAction
  | TournamentsAllAction
  | TournamentsOneAction
  | TournamentsCreateAction
  | TournamentsUpdateAction
  | TournamentsRemoveAction;

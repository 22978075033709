import styled from 'styled-components';

export const StyledUser = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  &.inNavbar {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 10px;
    span {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      padding: 0;
      margin: 0 0 0.1rem 0;
      &:last-of-type {
        margin-bottom: 0;
      }
      &.role {
        color: red;
        font-weight: bold;
      }
    }
  }
`;

import React from 'react';

import { SLoading } from './styles';

type Props = {
  text: string;
};

const Loading = (props: Props) => {
  const { text } = props;
  return (
    <SLoading>
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
      <p>{text}</p>
    </SLoading>
  );
};

export default Loading;

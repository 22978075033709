import React from 'react';
import { useSelector } from 'react-redux';

import Icon from '../IcoMoon';
import { colors } from '../../layout/styles';
import { SCompetition, SLeagueName } from '../../containers/CategoryPages/CategoryPage/styled';
import { Category, Subcategory } from '../../../models/Category';
import { AppState } from '../../../redux/reducers';
import { ICompetitionModel } from '../../../models/Competition';

type Props = {
  category: Category;
  subcategory: Subcategory;
};

const Competition = (props: Props) => {
  const { category, subcategory } = props;

  const competition = useSelector<AppState, ICompetitionModel>((state) =>
    subcategory && state.frontend.competitions.subcategories[category]
      ? state.frontend.competitions.subcategories[category][subcategory]
      : state.frontend.competitions.categories[category],
  );

  if (!competition) return null;

  return (
    <SCompetition>
      <Icon icon="cup" size={24} color={colors.orange} />
      <SLeagueName>{competition.name}</SLeagueName>
    </SCompetition>
  );
};

export default Competition;

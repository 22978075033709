import { IGalleryModel, IPhotoModel } from '../../../models/Photo';

export const PHOTO_LOADING = 'PHOTO_LOADING';
export const PHOTO_FAILURE = 'PHOTO_ERROR';
export const GALLERY_ALL = 'GALLERY_ALL';
export const GALLERY_ONE = 'GALLERY_ONE';
export const GALLERY_PHOTOS = 'GALLERY_PHOTOS';
export const GALLERY_CREATE = 'GALLERY_CREATE';
export const GALLERY_UPDATE = 'GALLERY_UPDATE';
export const GALLERY_REMOVE = 'GALLERY_REMOVE';
export const GALLERY_PHOTO_UPDATE = 'GALLERY_PHOTO_UPDATE';
export const GALLERY_PHOTO_REMOVE = 'GALLERY_PHOTO_REMOVE';

export interface IAPhotosLoading {
  type: typeof PHOTO_LOADING;
  name: string;
}

export interface IAPhotosFailure {
  type: typeof PHOTO_FAILURE;
  name: string;
  error: Error;
}

export interface GalleryAllAction {
  type: typeof GALLERY_ALL;
  galleries: { [key: number]: IGalleryModel };
}

export interface GalleryOneAction {
  type: typeof GALLERY_ONE;
  gallery: IGalleryModel;
}

export interface GalleryPhotosAction {
  type: typeof GALLERY_PHOTOS;
  galleryId: string;
  photos: Array<IPhotoModel>;
}

export interface GalleryCreateAction {
  type: typeof GALLERY_CREATE;
  gallery: IGalleryModel;
}

export interface GalleryUpdateAction {
  type: typeof GALLERY_UPDATE;
  galleryId: number;
  gallery: IGalleryModel;
}

export interface GalleryRemoveAction {
  type: typeof GALLERY_REMOVE;
  galleryId: number;
}

export interface GalleryPhotoUpdateAction {
  type: typeof GALLERY_PHOTO_UPDATE;
  galleryId: number;
  photoId: number;
  photo: IPhotoModel;
}

export interface GalleryPhotoRemoveAction {
  type: typeof GALLERY_PHOTO_REMOVE;
  galleryId: number;
  photoId: number;
}

export type PhotosActionTypes =
  | IAPhotosFailure
  | IAPhotosLoading
  | GalleryAllAction
  | GalleryOneAction
  | GalleryPhotosAction
  | GalleryCreateAction
  | GalleryUpdateAction
  | GalleryRemoveAction
  | GalleryPhotoUpdateAction
  | GalleryPhotoRemoveAction;

import { Formik, FormikActions } from 'formik';
import * as React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeEvent, useEffect } from 'react';

import { IArticleModel } from '../../../models/Article';
import DatePicker from '../../components/Datepicker';
import TextEditor from '../../components/TextEditor';
import { Category, Subcategory } from '../../../models/Category';
import { AppState } from '../../../redux/reducers';
import { IMatchModel } from '../../../models/Match';
import { getMatches } from '../../redux/actions/matchesActions';

import validationSchema from './validation';

export interface ArticleFormValues {
  title: string;
  date: string;
  intro: string;
  goals: string;
  roster: string;
  author: string;
  content: string;
  matchId?: string;
  category: Category;
  subcategory?: Subcategory;
}

type Props = {
  id: string;
  data?: IArticleModel | null;
  onSubmit: (values: ArticleFormValues, actions: FormikActions<ArticleFormValues>) => void;
};

const ArticleForm = (props: Props) => {
  const { id, data, onSubmit } = props;

  const params = useParams<{ category: Category; subcategory: Subcategory; matchId: string }>();

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const matches = useSelector<AppState, Array<IMatchModel>>((state) =>
    Object.values(state.backend.matches.items).filter((m) => m.category === params.category && (m.subcategory ? m.subcategory === params.subcategory : true)),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!matches || !matches.length) {
      dispatch(getMatches(token, params.category, params.subcategory));
    }
  }, []);

  const handleSelectMatch = (e: ChangeEvent<any>, setFieldValue) => {
    const matchId = e.target.value;
    const match = matches.find((m) => m.id.toString() === matchId);

    if (match) {
      setFieldValue('title', `${match.home.name} - ${match.away.name} ${match.fullTimeScore} (${match.halfTimeScore})`);
      setFieldValue('date', match.date);
      setFieldValue('matchId', matchId);
    } else {
      setFieldValue('title', '');
      setFieldValue('date', '');
      setFieldValue('matchId', '');
    }
  };

  const match = data && data.matchId ? matches.find((m) => m.id === data.matchId) : params.matchId ? matches.find((m) => m.id === params.matchId) : undefined;

  return (
    <Formik
      initialValues={{
        title: data ? data.title : match ? `${match.home.name} - ${match.away.name} ${match.fullTimeScore} (${match.halfTimeScore})` : '',
        date: data ? data.date : match ? match.date : '',
        intro: data ? data.intro : '',
        goals: data ? data.goals : '',
        roster: data ? data.roster : '',
        author: data ? data.author : '',
        content: data ? data.content : '',
        matchId: data ? data.matchId : match ? match.id : '',
        category: params.category,
        subcategory: params.subcategory,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {({ handleSubmit, handleChange, handleBlur, values, touched, setFieldValue, errors, isSubmitting }) => (
        <Form id={id} noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Zápas</Form.Label>
              <Form.Control
                name="matchId"
                as="select"
                value={values.matchId}
                disabled={isSubmitting}
                onChange={(e) => handleSelectMatch(e, setFieldValue)}
                onBlur={handleBlur}>
                <option value="">Žádný</option>
                <optgroup label="Podzimní část">
                  {matches
                    .filter((m) => m.part === 'podzim')
                    .map((m: IMatchModel) => (
                      <option key={`match_${m.id}`} value={m.id}>{`${m.home.name} - ${m.away.name}`}</option>
                    ))}
                </optgroup>
                <optgroup label="Jarní část">
                  {matches
                    .filter((m) => m.part === 'jaro')
                    .map((m: IMatchModel) => (
                      <option key={`match_${m.id}`} value={m.id}>{`${m.home.name} - ${m.away.name}`}</option>
                    ))}
                </optgroup>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="title">
              <Form.Label>Název článku</Form.Label>
              <Form.Control
                type="text"
                autoComplete="off"
                name="title"
                placeholder="Zadejte název článku"
                value={values.title}
                disabled={isSubmitting}
                isInvalid={errors.title && touched.title}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.title}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Datum článku</Form.Label>
              <DatePicker
                name="date"
                placeholder="Zadejte datum článku"
                value={values.date}
                disabled={isSubmitting}
                isInvalid={errors.date && touched.date}
                onChange={(value) => setFieldValue('date', value)}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.date}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="roster">
              <Form.Label>Úvod článku</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="intro"
                autoComplete="off"
                placeholder="Zadejte úvod článku"
                value={values.intro}
                disabled={isSubmitting}
                isInvalid={errors.intro && touched.intro}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="roster">
              <Form.Label>Sestava</Form.Label>
              <Form.Control
                type="text"
                name="roster"
                autoComplete="off"
                placeholder="Zadejte sestavu"
                value={values.roster}
                disabled={isSubmitting}
                isInvalid={errors.roster && touched.roster}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.roster}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="goals">
              <Form.Label>Branky</Form.Label>
              <Form.Control
                type="text"
                name="goals"
                autoComplete="off"
                placeholder="Zadejte branky"
                value={values.goals}
                disabled={isSubmitting}
                isInvalid={errors.goals && touched.goals}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.goals}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Obsah článku</Form.Label>
              <TextEditor
                name="content"
                placeholder="Zadejte obsah článku"
                value={values.content}
                disabled={isSubmitting}
                isInvalid={errors.content && touched.content}
                onChange={(value) => setFieldValue('content', value)}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.content}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="author">
              <Form.Label>Autor</Form.Label>
              <Form.Control
                type="text"
                name="author"
                autoComplete="off"
                placeholder="Zadejte autora"
                value={values.author}
                disabled={isSubmitting}
                isInvalid={errors.author && touched.author}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.author}
              </Form.Control.Feedback>
            </Form.Group>
            <Col />
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
};

export default ArticleForm;

import { Container } from 'react-bootstrap';
import styled from 'styled-components';

import { colors } from '../../../layout/styles';

export const SPageContent = styled(Container)`
  min-height: calc(100vh - 800px);
`;

export const SCategoryHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem 0;
  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

export const SCategoryName = styled.h1`
  font-family: 'Nunito Sans';
  font-weight: 900;
  font-size: 45px;
  color: ${colors.blue};
  margin: 0;
`;

export const SCompetition = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  @media screen and (min-width: 992px) {
    margin-top: 0;
  }
`;

export const SLeagueName = styled.span`
  font-family: 'Nunito Sans';
  font-weight: 800;
  font-size: 18px;
  color: ${colors.blue};
  margin-left: 10px;
  @media screen and (min-width: 992px) {
    font-size: 27px;
  }
`;

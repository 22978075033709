import { Formik, FormikActions } from 'formik';
import * as React from 'react';
import { Col, Form } from 'react-bootstrap';

import { IEventModel } from '../../../models/Event';
import TextEditor from '../../components/TextEditor';
import DatePicker from '../../components/Datepicker';

import validationSchema from './validation';

export interface EventFormValues {
  title: string;
  start: string;
  end: string;
  text: string;
}

type Props = {
  id: string;
  data?: IEventModel;
  onSubmit: (values: EventFormValues, actions: FormikActions<EventFormValues>) => void;
};

const EventForm = (props: Props) => {
  const { id, data, onSubmit } = props;

  return (
    <Formik
      initialValues={{
        title: data ? data.title : '',
        start: data ? data.start : '',
        end: data ? data.end : '',
        text: data ? data.text : '',
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values: EventFormValues, actions: FormikActions<EventFormValues>) => {
        onSubmit(values, actions);
      }}>
      {({ handleSubmit, handleChange, handleBlur, values, touched, setFieldValue, errors, isSubmitting }) => (
        <Form id={id} noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Název schůze</Form.Label>
              <Form.Control
                type="text"
                name="title"
                placeholder="Zadejte název schůze"
                value={values.title}
                disabled={isSubmitting}
                isInvalid={errors.title && touched.title}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.title}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Začátek události</Form.Label>
              <DatePicker
                name="start"
                placeholder="Zadejte začátek schůze"
                showTime={true}
                dateFormat="dd.MM.yyyy HH:mm"
                value={values.start}
                disabled={isSubmitting}
                isInvalid={errors.start && touched.start}
                onChange={(value) => setFieldValue('start', value)}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.start}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Konec události</Form.Label>
              <DatePicker
                name="date"
                placeholder="Zadejte konec schůze"
                showTime={true}
                dateFormat="dd.MM.yyyy HH:mm"
                value={values.end}
                disabled={isSubmitting}
                isInvalid={errors.end && touched.end}
                onChange={(value) => setFieldValue('end', value)}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.end}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Obsah události</Form.Label>
              <TextEditor
                name="text"
                placeholder="Zadejte obsah události"
                value={values.text}
                disabled={isSubmitting}
                isInvalid={errors.text && touched.text}
                onChange={(value) => setFieldValue('text', value)}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.text}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
};

export default EventForm;

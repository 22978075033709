import { Formik, FormikActions } from 'formik';
import * as React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { Category, Subcategory } from '../../../models/Category';
import { IVideoModel } from '../../../models/Video';

import validationSchema from './validation';

export interface VideoFormValues {
  name: string;
  link: string;
  category: Category;
  subcategory?: Subcategory;
}

type Props = {
  id: string;
  data?: IVideoModel;
  onSubmit: (values: VideoFormValues, actions: FormikActions<VideoFormValues>) => void;
};

const VideoForm = (props: Props) => {
  const { id, data, onSubmit } = props;
  const params = useParams<{ category: Category; subcategory: Subcategory }>();

  return (
    <Formik
      initialValues={{
        name: data ? data.name : '',
        link: data ? data.link : '',
        category: params.category,
        subcategory: params.subcategory,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values: VideoFormValues, actions: FormikActions<VideoFormValues>) => {
        onSubmit(values, actions);
      }}>
      {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
        <Form id={id} noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Název videa</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Zadejte název videa"
                value={values.name}
                disabled={isSubmitting}
                isInvalid={errors.name && touched.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Odkaz na video</Form.Label>
              <Form.Control
                type="text"
                name="link"
                placeholder="Zadejte odkaz na video"
                value={values.link}
                disabled={isSubmitting}
                isInvalid={errors.link && touched.link}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.link}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
};

export default VideoForm;

import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { Category, Subcategory } from '../../../../models/Category';
import MatchesOverviewCategory from '../../../components/Matches/MatchesOverviewCategory';
import LastArticlesCategory from '../../../components/Articles/LastArticlesCategory';
import TableCategory from '../../../components/Tables/TableCategory';
import ListCategoryArticles from '../../../components/Articles/ListCategoryArticles';
import TournamentsOverviewCategory from '../../../components/Tournaments/TournamentsOverviewCategory';

import { SPageContent } from './styled';

type TProps = {
  category: Category;
  subcategory?: Subcategory;
};

const CategoryOverview = (props: TProps) => {
  const { category, subcategory } = props;

  let content;
  switch (category) {
    case 'pr':
      content = (
        <Row>
          <Col lg={6} className="px-0 px-lg-2">
            <LastArticlesCategory category={category} subcategory={subcategory} />
          </Col>
          <Col lg={6} className="px-0 px-lg-2">
            <Row>
              <Col className="pt-5 pt-lg-0">
                <TournamentsOverviewCategory category={category} subcategory={subcategory} />
              </Col>
            </Row>
          </Col>
        </Row>
      );
      break;
    case 'sk':
      content = <ListCategoryArticles category="sk" />;
      break;
    default:
      content = (
        <Row>
          <Col lg={6} className="px-0 px-lg-2">
            <LastArticlesCategory category={category} subcategory={subcategory} />
          </Col>
          <Col lg={6} className="px-0 px-lg-2">
            <Row>
              <Col className="pt-5 pt-lg-0">
                <MatchesOverviewCategory category={category} subcategory={subcategory} />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <TableCategory category={category} subcategory={subcategory} />
              </Col>
            </Row>
          </Col>
        </Row>
      );
  }

  return <SPageContent className="p-0 my-5">{content}</SPageContent>;
};

export default CategoryOverview;

import { IMatchCategoriesRedux, IMatchModel, IMatchSubcategoriesRedux } from '../../../models/Match';
import { FRONTEND_COMPETITIONS_ALL, FRONTEND_COMPETITIONS_ERROR, FRONTEND_COMPETITIONS_LOADING, TCompetitionsActions } from '../types/competitionsTypes';

export interface ICompetitionsState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error };
  items: { [key: string]: IMatchModel };
  categories: IMatchCategoriesRedux;
  subcategories: IMatchSubcategoriesRedux;
}

const initialState: ICompetitionsState = {
  loadings: {},
  errors: {},
  items: {},
  categories: {},
  subcategories: {},
};

const competitions = (currentState: ICompetitionsState = initialState, action: TCompetitionsActions): ICompetitionsState => {
  let newState;
  switch (action.type) {
    case FRONTEND_COMPETITIONS_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case FRONTEND_COMPETITIONS_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case FRONTEND_COMPETITIONS_ALL:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        items: {
          ...currentState.items,
          ...action.competitions,
        },
        categories: {
          ...currentState.categories,
        },
        subcategories: {
          ...currentState.subcategories,
        },
      };
      for (const id in action.competitions) {
        if (action.competitions[id]) {
          const competition = action.competitions[id];
          if (competition.subcategory) {
            if (!newState.subcategories[competition.category]) {
              newState.subcategories[competition.category] = {};
            }
            newState.subcategories[competition.category][competition.subcategory] = competition;
          } else {
            newState.categories[competition.category] = competition;
          }
        }
      }
      return newState;
    default:
      return currentState;
  }
};

export default competitions;

import { Formik, FormikActions } from 'formik';
import * as React from 'react';
import { Button, Col, Form } from 'react-bootstrap';

import { UserModel } from '../../../models/User';

import { createUserScheme, updateUserScheme } from './validation';

// Props
interface Props {
  id: string;
  data?: UserModel | null;
  onSubmit: (values, callback) => void;
}

interface FormValues {
  username: string;
  role: string;
  password?: string;
  confirmPassword?: string;
}

const UserForm = (props: Props) => {
  const { id, data, onSubmit } = props;

  return (
    <Formik
      initialValues={{
        username: data ? data.username : '',
        role: data ? data.role : 'žádné',
      }}
      enableReinitialize
      validationSchema={data ? updateUserScheme : createUserScheme}
      onSubmit={(values: FormValues, actions: FormikActions<FormValues>) => {
        const sendData = { ...values };
        delete sendData.confirmPassword;
        onSubmit(sendData, actions);
      }}>
      {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
        <Form id={id} noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} controlId="username">
              <Form.Label>Uživatelské jméno</Form.Label>
              <Form.Control
                type="text"
                name="username"
                placeholder="Zadejte uživatelské jméno"
                value={values.username}
                autoComplete="off"
                disabled={isSubmitting}
                isInvalid={errors.username && touched.username}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.username}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="role">
              <Form.Label>Role</Form.Label>
              <Form.Control
                name="role"
                as="select"
                value={values.role}
                disabled={isSubmitting}
                isInvalid={errors.role && touched.role}
                onChange={handleChange}
                onBlur={handleBlur}>
                <option value="" disabled selected>
                  Vyberte roli
                </option>
                <option value="superadmin">superadmin</option>
                <option value="admin">admin</option>
                <option value="žádné">žádná</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          {!data ? (
            <Form.Row>
              <Form.Group as={Col} controlId="password">
                <Form.Label>Heslo</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Zadejte heslo"
                  value={values.password}
                  autoComplete="off"
                  disabled={isSubmitting}
                  isInvalid={errors.password && touched.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="confirmPassword">
                <Form.Label>Heslo znovu</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  placeholder="Zopakujte heslo"
                  value={values.confirmPassword}
                  autoComplete="off"
                  disabled={isSubmitting}
                  isInvalid={!!(errors.confirmPassword && touched.confirmPassword)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          ) : null}
          <Form.Row>
            <Col>
              <Button type="submit" disabled={isSubmitting} className="float-right" color="primary">
                {`${data ? 'Upravit' : 'Přidat'} správce`}
              </Button>
            </Col>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;

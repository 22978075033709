import { IMessageModel } from '../../../models/Message';

export const processMessages = (data: Array<IMessageModel>) => {
  const all: { [key: string]: IMessageModel } = {};
  data.forEach((message: IMessageModel) => {
    all[message.id] = message;
  });

  return {
    all,
  };
};

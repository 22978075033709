import { Container, Image } from 'react-bootstrap';
import styled from 'styled-components';

import { colors } from '../../layout/styles';

export const SSponsors = styled(Container)`
  display: flex;
  border-top: 1px solid ${colors.separator};
  justify-content: center;
  align-items: center;
`;

export const SImage = styled(Image)`
  max-width: 150px;
  max-height: 150px;
  margin: 0 1rem;
  filter: grayscale();
  opacity: 0.5;
`;

export const SSponsor = styled.div`
  display: flex;
  width: 200px;
  height: 200px;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
  box-shadow: 0 0 60px 3px rgba(0, 0, 0, 0.09);
`;

export const SImageSponsor = styled(Image)`
  max-width: 150px;
  max-height: 150px;
`;

export const SText = styled.p`
  font-family: 'Nunito Sans';
  font-size: 16px;
  padding: 0 1rem;
  text-align: center;
  margin: 0;
`;

import i18next, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

import cs from './langs/cs.json';

export const resources: { [key: string]: Resource } = {
  cs,
};

// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
i18next.use(initReactI18next).init({
  lng: 'cs',
  fallbackLng: 'cs',
  debug: true,
  interpolation: {
    escapeValue: false,
  },
  resources,
});

export default i18next;

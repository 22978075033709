import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';

import reduxApp from './reducers';

// @ts-ignore
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunkMiddleware];

const enhancer = applyMiddleware(...middleware);

export const store = createStore(reduxApp, composeEnhancer(enhancer));

import React from 'react';

import { Category, Subcategory } from '../../../models/Category';

import FirstLastTournamentsTabs from './FirstLastTournamentsTabs';
import { STournamentsOverview } from './styles';

type Props = {
  category: Category;
  subcategory: Subcategory;
};

const TournamentsOverviewCategory = (props: Props) => {
  const { category, subcategory } = props;

  return (
    <STournamentsOverview>
      <h3>Turnaje</h3>
      <FirstLastTournamentsTabs category={category} subcategory={subcategory} />
    </STournamentsOverview>
  );
};

export default TournamentsOverviewCategory;

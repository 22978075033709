import {
  SEASONS_ALL,
  SEASONS_ARCHIVE,
  SEASONS_CREATE,
  SEASONS_ERROR,
  SEASONS_LOADING,
  SEASONS_ONE,
  SEASONS_REMOVE,
  SEASONS_UPDATE,
  SeasonsActionTypes,
} from '../../types/archive/seasonsTypes';
import { ISeasonModel } from '../../../../models/Season';

export interface SeasonsState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error | undefined };
  items: { [key: string]: ISeasonModel };
}

const initialState: SeasonsState = {
  loadings: {},
  errors: {},
  items: {},
};

const seasonsReducer = (currentState: SeasonsState = initialState, action: SeasonsActionTypes): SeasonsState => {
  let newState;
  switch (action.type) {
    case SEASONS_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case SEASONS_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case SEASONS_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        errors: {
          ...currentState.errors,
          all: undefined,
        },
        items: {
          ...currentState.items,
          ...action.seasons,
        },
      };
    case SEASONS_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        errors: {
          ...currentState.errors,
          one: undefined,
        },
        items: {
          ...currentState.items,
          [action.season.id]: action.season,
        },
      };
    case SEASONS_CREATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          create: false,
        },
        errors: {
          ...currentState.errors,
          create: undefined,
        },
        items: {
          ...currentState.items,
          [action.season.id]: action.season,
        },
      };
    case SEASONS_UPDATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          update: false,
        },
        errors: {
          ...currentState.errors,
          update: undefined,
        },
        items: {
          ...currentState.items,
          [action.season.id]: {
            ...currentState.items[action.season.id],
            ...action.season,
          },
        },
      };
    case SEASONS_REMOVE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          remove: false,
        },
        errors: {
          ...currentState.errors,
          remove: undefined,
        },
        items: {
          ...currentState.items,
        },
      };
      if (newState.items[action.seasonId]) delete newState.items[action.seasonId];
      return newState;
    case SEASONS_ARCHIVE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          archive: false,
        },
        errors: {
          ...currentState.errors,
          archive: undefined,
        },
      };
    default:
      return currentState;
  }
};

export default seasonsReducer;

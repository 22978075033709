import React from 'react';
import { useSelector } from 'react-redux';
import renderHTML from 'react-render-html';

import { AppState } from '../../../redux/reducers';
import { IMessageModel } from '../../../models/Message';

import { SMessage } from './styles';

type Props = {
  messageId: number;
};

const Message = (props: Props) => {
  const { messageId } = props;

  const message = useSelector<AppState, IMessageModel>((state) => state.frontend.messages.items[messageId]);

  return (
    <SMessage>
      <h3>{message.name}</h3>
      {renderHTML(message.text)}
    </SMessage>
  );
};

export default Message;

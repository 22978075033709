import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-dnd';
import { FormikActions } from 'formik';

import { colors } from '../../layout/basicStyles';
import { SERVER_URL } from '../../../constants';
import PhotoForm, { PhotoFormValues } from '../../forms/PhotoForm';
import { IPhotoModel } from '../../../models/Photo';

type Props = {
  photos: Array<IPhotoModel>;
  handleUpdatePhoto: (photoId: number, values: PhotoFormValues, actions: FormikActions<PhotoFormValues>) => void;
  handleUpdateOrder: (photos: Array<IPhotoModel>) => void;
};

const PhotoGrid = (props: Props) => {
  const { photos, handleUpdatePhoto, handleUpdateOrder } = props;

  const [items, setItems] = useState(photos);

  useEffect(() => {
    setItems(photos);
  }, [photos]);

  // target id will only be set if dragging from one dropzone to another.
  function onChange(sourceId, sourceIndex, targetIndex) {
    const nextState = swap(items, sourceIndex, targetIndex);
    setItems(nextState);
    handleUpdateOrder(nextState);
  }

  return (
    <GridContextProvider onChange={onChange}>
      <GridDropZone id="items" boxesPerRow={4} rowHeight={250} style={{ height: `${Math.ceil(items.length / 4) * 250}px` }}>
        {items.map((photo) => (
          <GridItem key={photo.id}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: colors.grey,
              }}>
              <Image fluid src={`${SERVER_URL}${photo.url}`} style={{ maxWidth: '75%', maxHeight: '150px', pointerEvents: 'none' }} />
              <PhotoForm data={photo} onSubmit={handleUpdatePhoto} />
            </div>
          </GridItem>
        ))}
      </GridDropZone>
    </GridContextProvider>
  );
};

export default PhotoGrid;

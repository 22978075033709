import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Column } from 'react-table';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Card, Modal } from 'react-bootstrap';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';

import Table from '../../components/Table';
import { CategoryWithClub, Subcategory } from '../../../models/Category';
import { getGalleries, removeGallery } from '../../redux/actions/photosActions';
import CategorySwitch from '../../components/CategorySwitch';
import { AppState } from '../../../redux/reducers';
import { IGalleryModel } from '../../../models/Photo';

const PhotoListContainer = () => {
  const history = useHistory();
  const match = useRouteMatch<{ category: CategoryWithClub; subcategory: Subcategory }>();
  let { category, subcategory } = match.params;

  if (!category) category = 'klub';

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loading = useSelector<AppState, boolean>((state) => state.backend.photos.loadings.getGalleries);
  const loadingRemove = useSelector<AppState, boolean>((state) => state.backend.photos.loadings.remove);
  const errorRemove = useSelector<AppState, Error | undefined>((state) => state.backend.photos.errors.remove);
  const photos = useSelector<AppState, { [key: string]: IGalleryModel }>((state) => state.backend.photos.items);

  const filteredPhotos = useMemo(
    () =>
      Object.values(photos)
        .filter((photo) => (subcategory ? photo.category === category && photo.subcategory === subcategory : photo.category === category))
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
    [category, subcategory, photos],
  );

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [photo, setPhoto] = useState<IGalleryModel | undefined>();

  useEffect(() => {
    if (!loading) {
      dispatch(getGalleries(token, category, subcategory));
    }
  }, [category, subcategory]);

  useEffect(() => {
    if (!loadingRemove && !errorRemove) {
      setShowModal(false);
    }
  }, [loadingRemove, errorRemove]);

  const handleToggleDeleteModal = (photo?: IGalleryModel) => {
    setPhoto(photo);
    setShowModal(!showModal);
  };

  const handleRemove = () => {
    dispatch(removeGallery(token, photo!.id));
  };

  const OptionCell = (row) => (
    <div className="d-flex justify-content-end">
      <Button
        variant="outline-dark"
        className="mr-2"
        onClick={() => history.push(`/admin/photos/${category}${subcategory ? `/${subcategory}` : ''}/edit/${row.original.id}`)}>
        <FontAwesomeIcon icon={faEdit} style={{ cursor: 'pointer' }} />
      </Button>
      <Button variant="danger" onClick={() => handleToggleDeleteModal(row.original)}>
        <FontAwesomeIcon icon={faTrash} style={{ cursor: 'pointer' }} />
      </Button>
    </div>
  );

  const columns: Column[] = [
    {
      id: 'name',
      Header: 'Název',
      accessor: (d: IGalleryModel) => d.name,
    },
    {
      id: 'date',
      Header: 'Datum',
      accessor: (d: IGalleryModel) => d.date,
      sortMethod: (a, b) => {
        const aTime = new Date(a).getTime();
        const bTime = new Date(b).getTime();
        return aTime - bTime;
      },
      Cell: (row) => moment(row.value).format('DD.MM.YYYY').toLocaleUpperCase(),
    },
    {
      id: 'options',
      Header: 'Možnosti',
      Cell: OptionCell,
    },
  ];

  const options = {
    loadingText: 'Načítám galerie...',
    noDataText: 'Žádné galerie k zobrazení',
  };

  return (
    <Container fluid className="p-0">
      <CategorySwitch withClub />
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <FontAwesomeIcon icon={faTable} />
              <span className="ml-2">Seznam galerií</span>
            </div>
            <Button variant="primary" onClick={() => history.push(`/admin/photos/${category}${subcategory ? `/${subcategory}` : ''}/add`)}>
              <FontAwesomeIcon icon={faPlus} />
              <span className="ml-2">Přidat galerii</span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Table
            columns={columns}
            data={filteredPhotos}
            options={{
              loading,
              ...options,
            }}
          />
        </Card.Body>
        <Card.Footer />
      </Card>
      {photo && (
        <Modal show={showModal} onHide={handleToggleDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Smazání galerie</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`Opravdu chcete smazat tým: ${photo.name}?`}</p>
            <p>Pořádně si to rozmyslete. Tato akce je nenávratná!</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleToggleDeleteModal()}>
              Zrušit
            </Button>
            <Button variant="primary" onClick={() => handleRemove()}>
              Potvrdit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default PhotoListContainer;

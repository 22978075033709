import { IMatchModel, IMatchCategoriesRedux, IMatchSubcategoriesRedux } from '../../../models/Match';

export const processMatches = (data: Array<IMatchModel>) => {
  const all: { [key: string]: IMatchModel } = {};
  const categories: IMatchCategoriesRedux = {};
  const subcategories: IMatchSubcategoriesRedux = {};
  data.forEach((match: IMatchModel) => {
    all[match.id] = match;
    if (match.subcategory) {
      if (!subcategories[match.category]) {
        subcategories[match.category] = {};
      }
      if (!subcategories[match.category][match.subcategory]) {
        subcategories[match.category][match.subcategory] = {
          parts: {
            spring: {},
            autumn: {},
          },
        };
      }
      if (match.part === 'podzim') {
        subcategories[match.category][match.subcategory].parts.autumn[match.id] = {
          id: match.id,
          date: match.date,
        };
      }
      if (match.part === 'jaro') {
        subcategories[match.category][match.subcategory].parts.spring[match.id] = {
          id: match.id,
          date: match.date,
        };
      }
    } else {
      if (!categories[match.category]) {
        categories[match.category] = {
          parts: {
            spring: {},
            autumn: {},
          },
        };
      }
      if (match.part === 'podzim') {
        categories[match.category].parts.autumn[match.id] = {
          id: match.id,
          date: match.date,
        };
      }
      if (match.part === 'jaro') {
        categories[match.category].parts.spring[match.id] = {
          id: match.id,
          date: match.date,
        };
      }
    }
  });

  return {
    all,
    categories,
    subcategories,
  };
};

import axios from 'axios';

import { endpoints } from '../../../constants/API';
import { Category, CategoryWithClub, Subcategory } from '../../../models/Category';
import {
  FRONTEND_PHOTO_ALL,
  FRONTEND_PHOTO_ERROR,
  FRONTEND_PHOTO_LOADING,
  FRONTEND_PHOTO_ONE,
  IAPhotoAll,
  IAPhotoError,
  IAPhotoLoading,
  IAPhotoOne,
} from '../types/photosTypes';
import { processGalleries } from '../helpers/photosHelper';
import { IGalleryModel, IPhotoModel } from '../../../models/Photo';

export const APhotosLoading = (name: string): IAPhotoLoading => ({
  type: FRONTEND_PHOTO_LOADING,
  name,
});

const APhotosError = (name: string, error: Error): IAPhotoError => ({
  type: FRONTEND_PHOTO_ERROR,
  name,
  error,
});

const AGalleryAll = (category: CategoryWithClub, subcategory: Subcategory, galleries: { [key: string]: IGalleryModel }): IAPhotoAll => ({
  type: FRONTEND_PHOTO_ALL,
  category,
  subcategory,
  galleries,
});

const APhotosAll = (galleryId: string, gallery: IGalleryModel): IAPhotoOne => ({
  type: FRONTEND_PHOTO_ONE,
  gallery,
});

export const getGalleries = (category: CategoryWithClub, subcategory?: Subcategory) => async (dispatch) => {
  const actionName = 'all';
  dispatch(APhotosLoading(actionName));
  try {
    const config = {
      params: {
        category,
        subcategory,
      },
    };
    const response = await axios.get(`${endpoints.photos}`, config);
    const data = response.data;
    const { all } = processGalleries(data);
    dispatch(AGalleryAll(category, subcategory, all));
  } catch (e) {
    dispatch(APhotosError(actionName, e));
    console.error(e);
  }
};

export const getPhotos = (galleryId: string) => async (dispatch) => {
  const actionName = 'photos';
  dispatch(APhotosLoading(actionName));
  try {
    const config = {};
    const response = await axios.get(`${endpoints.photos}/${galleryId}`, config);
    const data = response.data;
    dispatch(APhotosAll(galleryId, data));
  } catch (e) {
    dispatch(APhotosError(actionName, e));
    console.error(e);
  }
};

export const getRandomPhoto = async (category?: Category, subcategory?: Subcategory): Promise<IPhotoModel> => {
  try {
    const config = {
      params: {
        category,
        subcategory,
      },
    };
    const response = await axios.get(`${endpoints.photos}/random`, config);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const getLastRandomPhoto = async (category?: Category, subcategory?: Subcategory): Promise<IPhotoModel> => {
  try {
    const config = {
      params: {
        category,
        subcategory,
      },
    };
    const response = await axios.get(`${endpoints.photos}/lastRandom`, config);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

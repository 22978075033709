import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

import { AppState } from '../../../redux/reducers';
import { getLastArticles } from '../../redux/actions/articlesActions';
import { IArticleBaseModel } from '../../../models/Article';

import ArticleTile from './ArticleTile';
import { SLastThreeArticles, SNoData } from './styled';

const LastThreeArticles = () => {
  const isTablet = useMediaQuery({ query: '(max-width: 991px)' });

  const loading = useSelector<AppState>((state) => state.frontend.articles.loadings.last);
  const lastArticles = useSelector<AppState, Array<IArticleBaseModel>>((state) => state.frontend.articles.last);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && !lastArticles) {
      dispatch(getLastArticles(null, null, 0, 3));
    }
  }, []);

  const lastThreeArticles = lastArticles ? Object.values(lastArticles).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) : undefined;

  if (!lastThreeArticles) {
    return (
      <SNoData className="d-flex mt-5">
        <p>Žádné články k zobrazení</p>
      </SNoData>
    );
  }

  if (isTablet) {
    return (
      <SLastThreeArticles>
        <Col>
          {lastThreeArticles[0] && (
            <Row className="p-0">
              <Col className="p-0 one">
                <ArticleTile type="small" articleId={lastThreeArticles[0].id} />
              </Col>
            </Row>
          )}
          {lastThreeArticles[1] && (
            <Row className="p-0">
              <Col className="p-0 one">
                <ArticleTile type="small" articleId={lastThreeArticles[1].id} />
              </Col>
            </Row>
          )}
          {lastThreeArticles[2] && (
            <Row className="p-0">
              <Col className="p-0 one">
                <ArticleTile type="small" articleId={lastThreeArticles[2].id} />
              </Col>
            </Row>
          )}
        </Col>
      </SLastThreeArticles>
    );
  }

  return (
    <SLastThreeArticles>
      <Col md={8} className="p-0 large">
        {lastThreeArticles[0] && <ArticleTile type="large" articleId={lastThreeArticles[0].id} />}
      </Col>
      <Col md={4}>
        <Row className="h-50 small">
          <Col className="p-0">{lastThreeArticles[1] && <ArticleTile type="small" articleId={lastThreeArticles[1].id} />}</Col>
        </Row>
        <Row className="h-50 small">
          <Col className="p-0">{lastThreeArticles[2] && <ArticleTile type="small" articleId={lastThreeArticles[2].id} />}</Col>
        </Row>
      </Col>
    </SLastThreeArticles>
  );
};

export default LastThreeArticles;

import React, { useEffect, useState } from 'react';
import { DropzoneProps, useDropzone } from 'react-dropzone';

import { SFileUpload, SDropzone } from './styled';
import Previews from './Previews';
import Files from './Files';

interface Props extends DropzoneProps {
  type: 'previews' | 'files';
  placeholder?: string;
}

const Dropzone = (props: Props) => {
  const { type, placeholder } = props;

  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    ...props,
    onDrop: (acceptedFiles, fileRejections, e) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
      if (props.onDrop) {
        props.onDrop(acceptedFiles, fileRejections, e);
      }
    },
  });

  useEffect(
    () => () => {
      // @ts-ignore
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  return (
    <SFileUpload>
      {/* @ts-ignore */}
      <SDropzone isDragActive={isDragActive} isDragAccept={isDragAccept} isDragReject={isDragReject} {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>{placeholder || 'Vložte soubory nebo klikněte pro přidání'}</p>
      </SDropzone>
      <aside>{type === 'previews' ? <Previews files={files} /> : <Files files={files} />}</aside>
    </SFileUpload>
  );
};

export default Dropzone;

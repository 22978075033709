import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import renderHTML from 'react-render-html';
import { useMediaQuery } from 'react-responsive';

import { AppState } from '../../../redux/reducers';
import { ITournamentModel } from '../../../models/Tournament';

type Props = {
  tournamentId: string;
};

const TournamentRow = (props: Props) => {
  const { tournamentId } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const tournament = useSelector<AppState, ITournamentModel>((state) => state.frontend.tournaments.items[tournamentId]);

  return (
    <tr>
      <td className="left">
        {moment(tournament.date)
          .format(isMobile ? 'D. M.' : 'dd D. M.')
          .toLocaleUpperCase()}
      </td>
      <td className="left bold">{tournament.name}</td>
      <td className="left">{renderHTML(tournament.info)}</td>
    </tr>
  );
};

export default TournamentRow;

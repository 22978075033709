import axios from 'axios';

import { ICategoryModel } from '../../../models/Category';
import { endpoints } from '../../../constants/API';
import { IACategoriesAll, IACategoriesError, IACategoriesLoading, CATEGORIES_ALL, CATEGORIES_ERROR, CATEGORIES_LOADING } from '../types/categoriesTypes';

export const ACategoriesLoading = (name: string): IACategoriesLoading => ({
  type: CATEGORIES_LOADING,
  name,
});

const ACategoriesError = (name: string, error: Error): IACategoriesError => ({
  type: CATEGORIES_ERROR,
  name,
  error,
});

const ACategoriesAll = (categories: { [key: string]: ICategoryModel }): IACategoriesAll => ({
  type: CATEGORIES_ALL,
  categories,
});

export const getCategories = () => async (dispatch) => {
  dispatch(ACategoriesLoading('categories'));
  try {
    const response = await axios.get(endpoints.categories);
    const data = response.data;
    const categories: { [key: string]: ICategoryModel } = {};
    data.forEach((category: ICategoryModel) => {
      categories[category.id] = category;
    });
    dispatch(ACategoriesAll(categories));
  } catch (e) {
    dispatch(ACategoriesError('categories', e));
    console.error(e);
  }
};

import {
  GALLERY_ALL,
  GALLERY_CREATE,
  GALLERY_ONE,
  GALLERY_PHOTO_REMOVE,
  GALLERY_PHOTO_UPDATE,
  GALLERY_REMOVE,
  GALLERY_UPDATE,
  PHOTO_FAILURE,
  PHOTO_LOADING,
  PhotosActionTypes,
} from '../types/photosTypes';
import { IGalleryModel } from '../../../models/Photo';

export interface PhotosState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error | undefined };
  items: { [key: string]: IGalleryModel };
}

const initialState: PhotosState = {
  loadings: {},
  errors: {},
  items: {},
};

const photos = (currentState: PhotosState = initialState, action: PhotosActionTypes): PhotosState => {
  let newState;
  let photos;
  let oldPhoto;
  switch (action.type) {
    case PHOTO_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case PHOTO_FAILURE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case GALLERY_ALL:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        errors: {
          ...currentState.errors,
          all: undefined,
        },
        items: {
          ...currentState.items,
        },
      };
      for (const galleryId in action.galleries) {
        newState.items[galleryId] = {
          ...currentState.items[galleryId],
          ...action.galleries[galleryId],
        };
      }
      return newState;
    case GALLERY_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        errors: {
          ...currentState.errors,
          one: undefined,
        },
        items: {
          ...currentState.items,
          [action.gallery.id]: {
            ...currentState.items[action.gallery.id],
            ...action.gallery,
          },
        },
      };
    case GALLERY_CREATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          create: false,
        },
        errors: {
          ...currentState.errors,
          create: undefined,
        },
        items: {
          ...currentState.items,
          [action.gallery.id]: action.gallery,
        },
      };
    case GALLERY_UPDATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          update: false,
        },
        errors: {
          ...currentState.errors,
          update: undefined,
        },
        items: {
          ...currentState.items,
          [action.gallery.id]: {
            ...currentState.items[action.gallery.id],
            ...action.gallery,
          },
        },
      };
    case GALLERY_REMOVE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          remove: false,
        },
        errors: {
          ...currentState.errors,
          remove: undefined,
        },
        items: {
          ...currentState.items,
        },
      };
      if (newState.items[action.galleryId]) {
        delete newState.items[action.galleryId];
      }
      return newState;
    case GALLERY_PHOTO_UPDATE:
      photos = [];
      for (const photo of currentState.items[action.galleryId].photos) {
        if (photo.id === action.photo.id) {
          photos.push({
            ...photo,
            ...action.photo,
          });
        } else {
          photos.push(photo);
        }
      }
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          updatePhoto: false,
        },
        errors: {
          ...currentState.errors,
          updatePhoto: undefined,
        },
        items: {
          ...currentState.items,
          [action.galleryId]: {
            ...currentState.items[action.galleryId],
            photos: photos.sort((a, b) => a.order - b.order),
          },
        },
      };
      return newState;
    case GALLERY_PHOTO_REMOVE:
      photos = [];
      oldPhoto = currentState.items[action.galleryId].photos.find((p) => p.id === action.photoId);
      for (const photo of currentState.items[action.galleryId].photos) {
        if (photo.id !== action.photoId) {
          if (photo.order > oldPhoto.order) {
            photos.push({
              ...photo,
              order: photo.order - 1,
            });
          } else {
            photos.push(photo);
          }
        }
      }
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          removePhoto: false,
        },
        errors: {
          ...currentState.errors,
          removePhoto: undefined,
        },
        items: {
          ...currentState.items,
          [action.galleryId]: {
            ...currentState.items[action.galleryId],
            photos: photos.sort((a, b) => a.order - b.order),
          },
        },
      };
      return newState;
    default:
      return currentState;
  }
};

export default photos;

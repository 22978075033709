import axios from 'axios';
import { notify } from 'reapop';

import { endpoints } from '../../../constants/API';
import { IArticleModel } from '../../../models/Article';
import {
  ARTICLES_LOADING,
  ARTICLES_ERROR,
  ARTICLES_ALL,
  ARTICLES_ONE,
  ARTICLES_CREATE,
  ARTICLES_UPDATE,
  ARTICLES_REMOVE,
  ArticlesLoadingAction,
  ArticlesErrorAction,
  ArticlesCreateAction,
  ArticlesRemoveAction,
  ArticlesUpdateAction,
  ArticlesAllAction,
} from '../types/articlesTypes';
import { Category, Subcategory } from '../../../models/Category';
import { ArticleFormValues } from '../../forms/ArticleForm';

export const loadingArticles = (name: string): ArticlesLoadingAction => ({
  type: ARTICLES_LOADING,
  name,
});

const errorArticles = (name: string, error: Error): ArticlesErrorAction => ({
  type: ARTICLES_ERROR,
  name,
  error,
});

const successArticles = (articles: { [key: number]: IArticleModel }): ArticlesAllAction => ({
  type: ARTICLES_ALL,
  articles,
});

const successArticle = (article) => ({
  type: ARTICLES_ONE,
  article,
});

const successCreateArticle = (article: IArticleModel): ArticlesCreateAction => ({
  type: ARTICLES_CREATE,
  article,
});

const successUpdateArticle = (article: IArticleModel): ArticlesUpdateAction => ({
  type: ARTICLES_UPDATE,
  article,
});

const successRemoveArticle = (id): ArticlesRemoveAction => ({
  type: ARTICLES_REMOVE,
  articleId: id,
});

export const getArticles =
  (token, category: Category = 'mA', subcategory: Subcategory = undefined) =>
  async (dispatch) => {
    dispatch(loadingArticles('getArticles'));
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          category,
          subcategory,
        },
      };
      const response = await axios.get(endpoints.articles, config);
      const data = response.data;
      const articles = {};
      data.forEach((article) => {
        articles[article.id] = article;
      });
      dispatch(successArticles(articles));
    } catch (e) {
      dispatch(errorArticles('getArticles', e));
      console.error(e);
    }
  };

export const getArticle = (token: string, articleId: string) => async (dispatch) => {
  dispatch(loadingArticles('getArticleDetail'));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${endpoints.articles}/${articleId}`, config);
    const article = response.data;
    dispatch(successArticle(article));
  } catch (e) {
    dispatch(errorArticles('getArticleDetail', e));
    console.error(e);
  }
};

export const createArticle = (token: string, values: ArticleFormValues) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'create';
  dispatch(loadingArticles(actionName));
  try {
    const response = await axios.post(endpoints.articles, values, config);
    const article = response.data;
    dispatch(successCreateArticle(article));
    dispatch(notify('Článek přidán.', 'success'));
  } catch (e) {
    dispatch(errorArticles('createArticle', e));
    dispatch(notify('Článek se nepodařilo přidat.', 'error'));
  }
};

export const updateArticle = (token: string, articleId: string, values: ArticleFormValues) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'update';
  dispatch(loadingArticles(actionName));
  try {
    const response = await axios.put(`${endpoints.articles}/${articleId}`, values, config);
    const article = response.data;
    dispatch(successUpdateArticle(article));
    dispatch(notify('Článek upraven.', 'success'));
  } catch (e) {
    dispatch(errorArticles('updateArticle', e));
    dispatch(notify('Článek se nepodařilo upravit.', 'error'));
  }
};

export const setArticlePhoto = (token: string, articleId: string, photoId: number) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'update';
  dispatch(loadingArticles(actionName));
  try {
    const response = await axios.put(`${endpoints.articles}/${articleId}`, { photoId }, config);
    const article = response.data;
    dispatch(successUpdateArticle(article));
    dispatch(notify('Fotka byla k článku přiřazena.', 'success'));
  } catch (e) {
    dispatch(errorArticles('updateArticle', e));
    dispatch(notify('Fotku se k článku nepodařilo přiřadit.', 'error'));
  }
};

export const removeArticle = (token: string, articleId: string) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'remove';
  dispatch(loadingArticles(actionName));
  try {
    await axios.delete(`${endpoints.articles}/${articleId}`, config);
    dispatch(successRemoveArticle(articleId));
    dispatch(notify('Článek odstraněn.', 'success'));
  } catch (e) {
    dispatch(errorArticles('removeArticle', e));
    dispatch(notify('Článek se nepodařilo odstranit.', 'error'));
    console.error(e);
  }
};

import axios from 'axios';
import { notify } from 'reapop';

import { endpoints } from '../../../constants/API';
import { IVideoModel } from '../../../models/Video';
import {
  VideoLoadingAction,
  VideoErrorAction,
  VideoAllAction,
  VideoCreateAction,
  VideoUpdateAction,
  VideoRemoveAction,
  VideoOneAction,
  VIDEOS_LOADING,
  VIDEOS_ERROR,
  VIDEOS_ALL,
  VIDEOS_ONE,
  VIDEOS_CREATE,
  VIDEOS_UPDATE,
  VIDEOS_REMOVE,
} from '../types/videosTypes';
import { CategoryWithClub, Subcategory } from '../../../models/Category';

export const loadingVideo = (name: string): VideoLoadingAction => ({
  type: VIDEOS_LOADING,
  name,
});

const errorVideo = (name: string, error: Error): VideoErrorAction => ({
  type: VIDEOS_ERROR,
  name,
  error,
});

const successVideos = (videos: { [key: number]: IVideoModel }): VideoAllAction => ({
  type: VIDEOS_ALL,
  videos,
});

const successVideo = (video: IVideoModel): VideoOneAction => ({
  type: VIDEOS_ONE,
  video,
});

const successCreateVideo = (video: IVideoModel): VideoCreateAction => ({
  type: VIDEOS_CREATE,
  video,
});

const successUpdateVideo = (video: IVideoModel): VideoUpdateAction => ({
  type: VIDEOS_UPDATE,
  video,
});

const successRemoveVideo = (videoId: number): VideoRemoveAction => ({
  type: VIDEOS_REMOVE,
  videoId,
});

export const getVideos =
  (token, category: CategoryWithClub = 'mA', subcategory: Subcategory = undefined) =>
  async (dispatch) => {
    const actionName = 'all';
    dispatch(loadingVideo(actionName));
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          category,
          subcategory,
        },
      };
      const response = await axios.get(endpoints.videos, config);
      const data = response.data;
      const videos = {};
      data.forEach((video) => {
        videos[video.id] = video;
      });
      dispatch(successVideos(videos));
    } catch (e) {
      dispatch(errorVideo(actionName, e));
      console.error(e);
    }
  };

export const getVideo = (token: string, videoId: string) => async (dispatch) => {
  const actionName = 'one';
  dispatch(loadingVideo(actionName));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${endpoints.videos}/${videoId}`, config);
    const video = response.data;
    dispatch(successVideo(video));
  } catch (e) {
    dispatch(errorVideo(actionName, e));
    console.error(e);
  }
};

export const createVideo = (token: string, values) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'create';
  dispatch(loadingVideo(actionName));
  try {
    const response = await axios.post(endpoints.videos, values, config);
    const video = response.data;
    dispatch(successCreateVideo(video));
    dispatch(notify('Video přidáno.', 'success'));
  } catch (e) {
    dispatch(errorVideo(actionName, e));
    dispatch(notify('Video se nepodařilo přidat.', 'error'));
  }
};

export const updateVideo = (token: string, videoId: number, values) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'update';
  dispatch(loadingVideo(actionName));
  try {
    const response = await axios.put(`${endpoints.videos}/${videoId}`, values, config);
    const video = response.data;
    dispatch(successUpdateVideo(video));
    dispatch(notify('Video upraveno.', 'success'));
  } catch (e) {
    dispatch(errorVideo(actionName, e));
    dispatch(notify('Video se nepodařilo upravit.', 'error'));
  }
};

export const removeVideo = (token: string, videoId: number) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'remove';
  dispatch(loadingVideo(actionName));
  try {
    await axios.delete(`${endpoints.videos}/${videoId}`, config);
    dispatch(successRemoveVideo(videoId));
    dispatch(notify('Video odstraněno.', 'success'));
  } catch (e) {
    dispatch(errorVideo(actionName, e));
    dispatch(notify('Video se nepodařilo odstranit.', 'error'));
    console.error(e);
  }
};

import axios from 'axios';

import { MatchFormValues } from '../../forms/MatchForm';
import { IMatchDataModel } from '../../../models/Match';
import { endpoints } from '../../../constants/API';

export const processMatchFromForm = async (values: MatchFormValues): Promise<IMatchDataModel> => {
  const { part, date, time, info, homeAway, teamId, fullTimeScore, halfTimeScore, category, subcategory } = values;
  const config = {
    params: {
      category,
      subcategory,
    },
  };
  const response = await axios.get(endpoints.teams, config);
  const myTeam = response.data.find((team) => ['Dolní Újezd', 'Dolní Újezd B'].includes(team.name));

  let homeId, awayId;
  if (homeAway === 'home') {
    homeId = myTeam.id;
    awayId = teamId;
  } else {
    homeId = teamId;
    awayId = myTeam.id;
  }

  return {
    part,
    date,
    time,
    info,
    homeId,
    awayId,
    fullTimeScore,
    halfTimeScore,
    category,
    subcategory,
  };
};

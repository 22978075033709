import { IMessageModel } from '../../../models/Message';

export const MESSAGES_LOADING = 'MESSAGES_LOADING';
export const MESSAGES_ERROR = 'MESSAGES_ERROR';
export const MESSAGES_ALL = 'MESSAGES_ALL';
export const MESSAGES_ONE = 'MESSAGES_ONE';
export const MESSAGES_CREATE = 'MESSAGES_CREATE';
export const MESSAGES_UPDATE = 'MESSAGES_UPDATE';
export const MESSAGES_REMOVE = 'MESSAGES_REMOVE';

export interface MessageLoadingAction {
  type: typeof MESSAGES_LOADING;
  name: string;
}

export interface MessageErrorAction {
  type: typeof MESSAGES_ERROR;
  name: string;
  error: Error;
}

export interface MessageAllAction {
  type: typeof MESSAGES_ALL;
  messages: { [key: number]: IMessageModel };
}

export interface MessageOneAction {
  type: typeof MESSAGES_ONE;
  message: IMessageModel;
}

export interface MessageCreateAction {
  type: typeof MESSAGES_CREATE;
  message: IMessageModel;
}

export interface MessageUpdateAction {
  type: typeof MESSAGES_UPDATE;
  message: IMessageModel;
}

export interface MessageRemoveAction {
  type: typeof MESSAGES_REMOVE;
  messageId: number;
}

export type MessageActionTypes =
  | MessageErrorAction
  | MessageLoadingAction
  | MessageAllAction
  | MessageOneAction
  | MessageCreateAction
  | MessageUpdateAction
  | MessageRemoveAction;

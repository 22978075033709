import styled from 'styled-components';
import { Row } from 'react-bootstrap';

import { colors } from '../../layout/styles';
import { IPhotoModel } from '../../../models/Photo';
import { SERVER_URL } from '../../../constants';

export const SLastThreeArticles = styled(Row)`
  display: flex;
  flex: 1;
  margin: 0;

  @media screen and (min-width: 1024px) {
    height: 400px;
  }

  .one {
    height: 250px;
    margin-bottom: 20px;
  }

  .large {
    @media screen and (min-width: 768px) {
      border-right: 2px solid #dee2e6 !important;
    }
  }

  .small {
    border-top: 2px solid #dee2e6 !important;
    @media screen and (min-width: 768px) {
      &:nth-child(odd) {
        border-top: none;
        border-bottom: 1px solid #dee2e6 !important;
      }
      &:nth-child(even) {
        border-top: 1px solid #dee2e6 !important;
      }
    }
  }
`;

export const SLastArticles = styled.div`
  @media screen and (min-width: 992px) {
    padding-right: 15px;
  }
  h2 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

export const SArticlePreview = styled.div`
  margin-bottom: 20px;
  &:hover {
    cursor: pointer;
    h3 {
      color: ${colors.orange};
    }
    p {
      color: ${colors.orange};
    }
  }
  h3 {
    font-size: 24px;
    text-align: center;
    padding: 20px 0 10px;
  }

  p {
    &.header {
      margin-bottom: 0;
    }
    text-align: center;
  }
`;

export const SArticlePreviewImageView = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
`;

export const SArticleInfo = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  span {
    background-color: ${colors.orange};
    font-family: 'Nunito Sans';
    font-size: 13px;
    font-weight: 900;
    color: ${colors.blue};
    padding: 8px 12px;
  }
`;

export const SArticleTile = styled.div<{ type: 'large' | 'small'; photo?: IPhotoModel }>`
  display: flex;
  flex: 1;
  height: 100%;
  background-image: url('${(props) => (props.photo ? `${SERVER_URL}${props.photo.url}` : undefined)}');
  background-position: center center;
  background-size: cover;
  &:hover {
    cursor: pointer;
  }
`;

export const SArticleTileContent = styled.div<{ type: 'large' | 'small' }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 75%);

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 75%);
    h5 {
      color: ${colors.orange};
    }
  }

  h5 {
    font-family: 'Nunito Sans';
    font-size: ${(props) => (props.type === 'large' ? 30 : 14)}px;
    font-weight: 900;
    text-transform: uppercase;
    color: ${colors.white};
    border-left: 2px solid ${colors.orange};
    padding: 5px 10px;
  }
`;

export const SNoData = styled.div`
  display: flex;
  flex: 1;
  height: 90px;
  justify-content: center;
  align-items: center;
  font-family: Nunito Sans;
  color: ${colors.blue};
`;

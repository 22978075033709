import { ISeasonModel } from '../../../../models/Season';

export const SEASONS_LOADING = 'SEASONS_LOADING';
export const SEASONS_ERROR = 'SEASONS_ERROR';
export const SEASONS_ALL = 'SEASONS_ALL';
export const SEASONS_ONE = 'SEASONS_ONE';
export const SEASONS_CREATE = 'SEASONS_CREATE';
export const SEASONS_UPDATE = 'SEASONS_UPDATE';
export const SEASONS_REMOVE = 'SEASONS_REMOVE';
export const SEASONS_ARCHIVE = 'SEASONS_ARCHIVE';

export interface SeasonsLoadingAction {
  type: typeof SEASONS_LOADING;
  name: string;
}

export interface SeasonsErrorAction {
  type: typeof SEASONS_ERROR;
  name: string;
  error: Error;
}

export interface SeasonsAllAction {
  type: typeof SEASONS_ALL;
  seasons: { [key: number]: ISeasonModel };
}

export interface SeasonsOneAction {
  type: typeof SEASONS_ONE;
  season: ISeasonModel;
}

export interface SeasonsCreateAction {
  type: typeof SEASONS_CREATE;
  season: ISeasonModel;
}

export interface SeasonsUpdateAction {
  type: typeof SEASONS_UPDATE;
  season: ISeasonModel;
}

export interface SeasonsRemoveAction {
  type: typeof SEASONS_REMOVE;
  seasonId: string;
}
export interface SeasonsArchiveAction {
  type: typeof SEASONS_ARCHIVE;
  seasonId: string;
}

export type SeasonsActionTypes =
  | SeasonsErrorAction
  | SeasonsLoadingAction
  | SeasonsAllAction
  | SeasonsOneAction
  | SeasonsCreateAction
  | SeasonsUpdateAction
  | SeasonsRemoveAction
  | SeasonsArchiveAction;

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

import { AppState } from '../../../redux/reducers';
import { Category, Subcategory } from '../../../models/Category';
import CompetitionForm from '../../forms/CompetitionForm';
import { createCompetition, getCompetition, updateCompetition } from '../../redux/actions/competitionsActions';
import { ICompetitionModel } from '../../../models/Competition';

const CompetitionsFormContainer = () => {
  const history = useHistory();
  const match = useRouteMatch<{ id: string; category: Category; subcategory?: Subcategory }>();
  const { id } = match.params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loadingAction = useSelector<AppState, boolean>((state) => state.backend.categories.loadings.create || state.backend.categories.loadings.update);
  const error = useSelector<AppState, Error | undefined>((state) => state.backend.categories.errors.create || state.backend.categories.errors.update);
  const loading = useSelector<AppState, boolean>((state) => state.backend.categories.loadings.one);
  const competition = useSelector<AppState, ICompetitionModel>((state) => state.backend.competitions.items[id]);

  const dispatch = useDispatch();

  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (!loading && !competition) {
      dispatch(getCompetition(token, id));
    }
  }, []);

  useEffect(() => {
    if (isSent) {
      if (!loadingAction && !error) {
        setIsSent(false);
        history.push(`/admin/competitions`);
      }
    }
  }, [isSent, loadingAction, error, history]);

  const handleAdd = (values, { setSubmitting }) => {
    dispatch(createCompetition(token, values));
    setSubmitting(false);
    setIsSent(true);
  };

  const handleUpdate = (values, { setSubmitting }) => {
    dispatch(updateCompetition(token, competition.id, values));
    setSubmitting(false);
    setIsSent(true);
  };

  return (
    <Container className="p-0">
      <CompetitionForm data={competition} onSubmit={competition ? handleUpdate : handleAdd} />
    </Container>
  );
};

export default CompetitionsFormContainer;

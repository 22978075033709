import React, { useState } from 'react';
import { Button, ButtonGroup, Card, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';
import { FormikActions } from 'formik';

import { PhotoFormValues } from '../../forms/PhotoForm';
import { IPhotoModel } from '../../../models/Photo';

import PhotoGrid from './PhotoGrid';
import PhotoList from './PhotoList';

type Props = {
  photos: Array<IPhotoModel>;
  handleUpdatePhoto: (photoId: number, values: PhotoFormValues, actions: FormikActions<PhotoFormValues>) => void;
  handleUpdateOrder: (photos: Array<IPhotoModel>) => void;
};

const GalleryPhotos = (props: Props) => {
  const { photos, handleUpdatePhoto, handleUpdateOrder } = props;

  const [mode, setMode] = useState<'list' | 'grid'>('grid');

  return (
    <Container fluid className="p-0 mt-5">
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          Fotky v galerii
          <ButtonGroup>
            <Button variant={mode === 'list' ? 'dark' : 'outline-dark'} style={{ cursor: 'pointer' }} onClick={() => setMode('list')}>
              <FontAwesomeIcon icon={faList} />
            </Button>
            <Button variant={mode === 'grid' ? 'dark' : 'outline-dark'} style={{ cursor: 'pointer' }} onClick={() => setMode('grid')}>
              <FontAwesomeIcon icon={faTable} />
            </Button>
          </ButtonGroup>
        </Card.Header>
        <Card.Body className="p-0">
          {mode === 'grid' ? (
            <PhotoGrid photos={photos} handleUpdatePhoto={handleUpdatePhoto} handleUpdateOrder={handleUpdateOrder} />
          ) : (
            <PhotoList photos={photos} handleUpdatePhoto={handleUpdatePhoto} handleUpdateOrder={handleUpdateOrder} />
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default GalleryPhotos;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment/moment';

import { AppState } from '../../../redux/reducers';
import { IArticleModel } from '../../../models/Article';
import { getLastRandomPhoto } from '../../redux/actions/photosActions';
import { IPhotoModel } from '../../../models/Photo';
import { ICategoryModel } from '../../../models/Category';

import { SArticleInfo, SArticleTile, SArticleTileContent } from './styled';

interface IProps {
  articleId: string;
  type: 'large' | 'small';
}

const ArticleTile = (props: IProps) => {
  const { articleId, type } = props;

  const article = useSelector<AppState, IArticleModel>((state) => state.frontend.articles.items[articleId]);
  const category = useSelector<AppState, ICategoryModel>((state) => state.frontend.categories.items[article.category]);

  const [photo, setPhoto] = useState<IPhotoModel>();

  useEffect(() => {
    getPhoto();
  }, []);

  const getPhoto = async () => {
    let photo = article.photo;
    if (!photo) {
      photo = await getLastRandomPhoto(article.category, article.subcategory);
    }
    setPhoto(photo);
  };

  if (!article) return null;

  return (
    <LinkContainer to={`/${article.category}${article.subcategory ? `/${article.subcategory}` : ''}/articles/${articleId}`}>
      <SArticleTile type={type} photo={photo}>
        <SArticleInfo>
          <span>{category.name}</span>
          <span>{moment(article.date).fromNow()}</span>
        </SArticleInfo>
        <SArticleTileContent type={type}>
          <h5>{article.title}</h5>
        </SArticleTileContent>
      </SArticleTile>
    </LinkContainer>
  );
};

export default ArticleTile;

import { IVideoModel } from '../../../models/Video';

export const processVideos = (data: Array<IVideoModel>) => {
  const all: { [key: string]: IVideoModel } = {};
  data.forEach((video: IVideoModel) => {
    all[video.id] = video;
  });

  return {
    all,
  };
};

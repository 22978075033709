import { ICategoryModel } from '../../../models/Category';

export const CATEGORIES_LOADING = 'CATEGORIES_LOADING';
export const CATEGORIES_ERROR = 'CATEGORIES_ERROR';
export const CATEGORIES_ALL = 'CATEGORIES_ALL';
export const CATEGORIES_ONE = 'CATEGORIES_ONE';
export const CATEGORIES_CREATE = 'CATEGORIES_CREATE';
export const CATEGORIES_UPDATE = 'CATEGORIES_UPDATE';
export const CATEGORIES_REMOVE = 'CATEGORIES_REMOVE';

export interface CategoriesLoadingAction {
  type: typeof CATEGORIES_LOADING;
  name: string;
}

export interface CategoriesErrorAction {
  type: typeof CATEGORIES_ERROR;
  name: string;
  error: Error;
}

export interface CategoriesAllAction {
  type: typeof CATEGORIES_ALL;
  categories: { [key: string]: ICategoryModel };
}

export interface CategoriesOneAction {
  type: typeof CATEGORIES_ONE;
  category: ICategoryModel;
}

export interface CategoriesCreateAction {
  type: typeof CATEGORIES_CREATE;
  category: ICategoryModel;
}

export interface CategoriesUpdateAction {
  type: typeof CATEGORIES_UPDATE;
  category: ICategoryModel;
}

export interface CategoriesRemoveAction {
  type: typeof CATEGORIES_REMOVE;
  categoryId: string;
}

export type CategoriesActionTypes =
  | CategoriesErrorAction
  | CategoriesLoadingAction
  | CategoriesAllAction
  | CategoriesOneAction
  | CategoriesCreateAction
  | CategoriesUpdateAction
  | CategoriesRemoveAction;

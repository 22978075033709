import * as React from 'react';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, Container } from 'react-bootstrap';

import EventListContainer from './EventListContainer';
import EventFormContainer from './EventFormContainer';

const EventsContainer = () => {
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <Container fluid className="p-0">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item active={location.pathname === match.path}>
          {location.pathname === match.path ? 'Události' : <Link to={match.path}>Události</Link>}
        </Breadcrumb.Item>
        {location.pathname.includes('/detail') && <Breadcrumb.Item active={true}>Detail Události</Breadcrumb.Item>}
        {location.pathname.includes('/add') && <Breadcrumb.Item active={true}>Nová událost</Breadcrumb.Item>}
        {location.pathname.includes('/edit') && <Breadcrumb.Item active={true}>Úprava události</Breadcrumb.Item>}
      </Breadcrumb>
      <Switch>
        <Route exact path={`${match.path}/add`} component={EventFormContainer} />
        <Route exact path={`${match.path}/edit/:id`} component={EventFormContainer} />
        <Route exact path={match.path} component={EventListContainer} />
      </Switch>
    </Container>
  );
};

export default EventsContainer;

import React, { useEffect, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Category, Subcategory } from '../../../models/Category';
import { AppState } from '../../../redux/reducers';
import { createTeam, getTeam, updateTeam } from '../../redux/actions/teamsActions';
import TeamForm from '../../forms/TeamForm';
import { ITeamModel } from '../../../models/Team';

const TeamFormContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch<{ id: string; category: Category; subcategory?: Subcategory }>();
  const { id, category, subcategory } = params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loadingAction = useSelector<AppState, boolean>((state) => state.backend.teams.loadings.create || state.backend.teams.loadings.update);
  const error = useSelector<AppState, Error | undefined>((state) => state.backend.teams.errors.create || state.backend.teams.errors.update);
  const loading = useSelector<AppState, boolean>((state) => state.backend.teams.loadings.one);
  const team = useSelector<AppState, ITeamModel>((state) => state.backend.teams.items[id]);

  const dispatch = useDispatch();

  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (!loading && id) {
      dispatch(getTeam(token, id));
    }
  }, []);

  useEffect(() => {
    if (isSent) {
      if (!loadingAction && !error) {
        setIsSent(false);
        history.push(`/admin/teams/${category}${subcategory ? `/${subcategory}` : ''}`);
      }
    }
  }, [loadingAction, error]);

  const handleAdd = (values, { setSubmitting }) => {
    dispatch(createTeam(token, values));
    setSubmitting(false);
    setIsSent(true);
  };

  const handleEdit = (values, { setSubmitting }) => {
    dispatch(updateTeam(token, team.id, values));
    setSubmitting(false);
    setIsSent(true);
  };
  return (
    <Container className="p-0">
      <Card>
        <Card.Header>{`Formulář pro ${team ? 'úpravu' : 'přidání'} týmu`}</Card.Header>
        <Card.Body>
          <TeamForm id="team-form" data={team} onSubmit={team ? handleEdit : handleAdd} />
        </Card.Body>
        <Card.Footer>
          <Button type="submit" form="team-form" disabled={isSent} className="float-right" variant="primary">
            {`${team ? 'Upravit' : 'Přidat'} tým`}
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default TeamFormContainer;

import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

// Props
interface OwnProps {}

interface StateProps {}

interface DispatchProps {}

// State
interface State {}

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<{}>;

class UserDetailContainer extends React.Component<Props, State> {
  render() {
    return (
      <div>
        <h1>User detail container</h1>
      </div>
    );
  }
}

export default UserDetailContainer;

import styled from 'styled-components';
import { Container } from 'react-bootstrap';

import { colors } from '../../layout/styles';

export const SBanner = styled(Container)`
  padding: 1.5rem;
  background-color: ${colors.blue};
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.09);
  h2 {
    color: white;
  }
  p {
    margin: 0;
    color: white;
  }
  a {
    color: white;
    text-decoration: underline;
  }
`;

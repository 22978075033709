import { IPlayerModel } from '../../../models/Player';
import { Category, Subcategory } from '../../../models/Category';

export const FRONTEND_PLAYER_LOADING = 'FRONTEND_PLAYER_LOADING';
export const FRONTEND_PLAYER_ERROR = 'FRONTEND_PLAYER_ERROR';
export const FRONTEND_PLAYER_ALL = 'FRONTEND_PLAYER_ALL';

export interface IAPlayerLoading {
  type: typeof FRONTEND_PLAYER_LOADING;
  name: string;
}

export interface IAPlayerError {
  type: typeof FRONTEND_PLAYER_ERROR;
  name: string;
  error: Error;
}

export interface IAPlayerAll {
  type: typeof FRONTEND_PLAYER_ALL;
  category: Category;
  subcategory: Subcategory;
  players: { [key: string]: IPlayerModel };
}

export type PlayerActionsTypes = IAPlayerLoading | IAPlayerError | IAPlayerAll;

import React from 'react';

import { Category, Subcategory } from '../../../../models/Category';
import ListCategoryArticles from '../../../components/Articles/ListCategoryArticles';

import { SPageContent } from './styled';

type TProps = {
  category: Category;
  subcategory?: Subcategory;
};

const CategoryArticles = (props: TProps) => {
  const { category, subcategory } = props;

  return (
    <SPageContent className="p-0 my-5">
      <h2>Aktuálně</h2>
      <ListCategoryArticles category={category} subcategory={subcategory} />
    </SPageContent>
  );
};

export default CategoryArticles;

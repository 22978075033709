import axios from 'axios';

import { endpoints } from '../../../constants/API';
import {
  FRONTEND_MESSAGE_ERROR,
  FRONTEND_MESSAGE_LOADING,
  FRONTEND_MESSAGE_ALL,
  IAMessageAll,
  IAMessageError,
  IAMessageLoading,
  IAMessageOne,
  FRONTEND_MESSAGE_ONE,
  FRONTEND_MESSAGE_LAST,
  IAMessageLast,
} from '../types/messagesTypes';
import { processMessages } from '../helpers/messagesHelper';
import { IMessageModel } from '../../../models/Message';

export const AMessagesLoading = (name: string): IAMessageLoading => ({
  type: FRONTEND_MESSAGE_LOADING,
  name,
});

const AMessagesError = (name: string, error: Error): IAMessageError => ({
  type: FRONTEND_MESSAGE_ERROR,
  name,
  error,
});

const AMessagesAll = (messages: { [key: string]: IMessageModel }): IAMessageAll => ({
  type: FRONTEND_MESSAGE_ALL,
  messages,
});

const AMessagesOne = (message: IMessageModel): IAMessageOne => ({
  type: FRONTEND_MESSAGE_ONE,
  message,
});

const AMessagesLast = (message: IMessageModel): IAMessageLast => ({
  type: FRONTEND_MESSAGE_LAST,
  message,
});

export const getMessages = () => async (dispatch) => {
  const actionName = 'all';
  dispatch(AMessagesLoading(actionName));
  try {
    const response = await axios.get(`${endpoints.messages}`);
    const data = response.data;
    const { all } = processMessages(data);
    dispatch(AMessagesAll(all));
  } catch (e) {
    dispatch(AMessagesError(actionName, e));
    console.error(e);
  }
};

export const getMessage = (messageId: string) => async (dispatch) => {
  const actionName = 'one';
  dispatch(AMessagesLoading(actionName));
  try {
    const config = {};
    const response = await axios.get(`${endpoints.messages}/${messageId}`, config);
    const data = response.data;
    dispatch(AMessagesOne(data));
  } catch (e) {
    dispatch(AMessagesError(actionName, e));
    console.error(e);
  }
};

export const getLastPlanMessage = () => async (dispatch) => {
  const actionName = 'last';
  dispatch(AMessagesLoading(actionName));
  try {
    const config = {};
    const response = await axios.get(`${endpoints.messages}/lastPlan`, config);
    const data = response.data;
    dispatch(AMessagesLast(data));
  } catch (e) {
    dispatch(AMessagesError(actionName, e));
    console.error(e);
  }
};

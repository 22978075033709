export const loadState = (name: string): any => {
  try {
    const serializedState = localStorage.getItem(name);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (name: string, data: object): void => {
  try {
    const serializedState = JSON.stringify(data);
    localStorage.setItem(name, serializedState);
  } catch (err) {
    //
  }
};

export const removeState = (name: string): void => {
  localStorage.removeItem(name);
};

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Card, Table } from 'react-bootstrap';
import { useEffect } from 'react';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';

import { Category, Subcategory } from '../../../models/Category';
import { TableModel } from '../../../models/Team';
import { getTable } from '../../redux/actions/teamsActions';
import CategorySwitch from '../../components/CategorySwitch';
import { AppState } from '../../../redux/reducers';
import { ASSETS_URL } from '../../../constants';

const TablePreviewContainer = () => {
  const history = useHistory();
  const match = useRouteMatch<{ category: Category; subcategory: Subcategory }>();
  let { category, subcategory } = match.params;

  if (!category) category = 'mA';

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loading = useSelector<AppState, boolean>((state) => state.backend.teams.loadings.table);
  const table = useSelector<AppState, Array<TableModel>>((state) =>
    subcategory ? state.backend.teams.tables[category][subcategory] : state.backend.teams.tables[category],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getTable(token, category, subcategory));
    }
  }, [category, subcategory]);

  return (
    <Container fluid className="p-0">
      <CategorySwitch />
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <FontAwesomeIcon icon={faTable} />
              <span className="ml-2">Tabulka</span>
            </div>
            <div>
              <Button className="mr-2" color="primary" onClick={() => history.push(`/admin/tables/${category}${subcategory ? `/${subcategory}` : ''}/edit`)}>
                <FontAwesomeIcon icon={faEdit} />
                <span className="ml-2">Upravit tabulku</span>
              </Button>
              <Button disabled color="primary" onClick={() => undefined}>
                <FontAwesomeIcon icon={faSync} />
                <span className="ml-2">Načíst z FAČR</span>
              </Button>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th className="left">#</th>
                <th className="left">Název týmu</th>
                <th>Zápasy</th>
                <th>Skóre</th>
                <th>Body</th>
              </tr>
            </thead>
            <tbody>
              {table && table.length
                ? table.map((team) => (
                    <tr key={`team_${team.id}`} className={team.name.includes('Dolní Újezd') ? 'myTeam' : ''}>
                      <td className="left bold">{team.order}.</td>
                      <td className="left">
                        <img style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '10px' }} src={`${ASSETS_URL}/teams/${team.id}.png`} alt="team logo" />
                        {team.name}
                      </td>
                      <td>{team.numberOfMatches}</td>
                      <td>
                        {team.goalsScored}:{team.goalsReceived}
                      </td>
                      <td className="bold">{team.points}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer />
      </Card>
    </Container>
  );
};

export default TablePreviewContainer;

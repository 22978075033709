import { Dispatch } from 'redux';
import axios from 'axios';

import {
  FRONTEND_COMPETITIONS_ALL,
  FRONTEND_COMPETITIONS_ERROR,
  FRONTEND_COMPETITIONS_LOADING,
  IACompetitionsAll,
  IACompetitionsError,
  IACompetitionsLoading,
} from '../types/competitionsTypes';
import { endpoints } from '../../../constants/API';
import { processCompetitions } from '../helpers/competitionsHelper';
import { ICompetitionModel } from '../../../models/Competition';

const ACompetitionsLoading = (name: string): IACompetitionsLoading => ({
  type: FRONTEND_COMPETITIONS_LOADING,
  name,
});

const ACompetitionsError = (name: string, error: Error): IACompetitionsError => ({
  type: FRONTEND_COMPETITIONS_ERROR,
  name,
  error,
});

const ACompetitionsAll = (competitions: { [key: string]: ICompetitionModel }): IACompetitionsAll => ({
  type: FRONTEND_COMPETITIONS_ALL,
  competitions,
});

export const getCompetitions = () => async (dispatch: Dispatch) => {
  const actionName = 'all';
  dispatch(ACompetitionsLoading(actionName));
  try {
    const config = {};
    const response = await axios.get(endpoints.competitions, config);
    const competitions = processCompetitions(response.data);
    dispatch(ACompetitionsAll(competitions));
  } catch (e) {
    dispatch(ACompetitionsError(actionName, e));
  }
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, FormikActions } from 'formik';
import * as React from 'react';
import { Button, Col, Form, InputGroup, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import { IPhotoModel } from '../../../models/Photo';
import { AppState } from '../../../redux/reducers';
import { removePhoto } from '../../redux/actions/photosActions';

import validationSchema from './validation';

export interface PhotoFormValues {
  note: string;
}

type Props = {
  data?: IPhotoModel | null;
  onSubmit: (photoId: number, values: PhotoFormValues, actions: FormikActions<PhotoFormValues>) => void;
};

const PhotoForm = (props: Props) => {
  const { data, onSubmit } = props;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const removing = useSelector<AppState, boolean>((state) => state.backend.photos.loadings.removePhoto);

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [photoToDelete, setPhotoToDelete] = useState<IPhotoModel | undefined>(undefined);

  const handleToggleDeleteModal = (photo?: IPhotoModel) => {
    if (show) {
      setPhotoToDelete(undefined);
    } else {
      setPhotoToDelete(photo);
    }
    setShow(!show);
  };

  const handleRemove = () => {
    dispatch(removePhoto(token, photoToDelete.galleryId, photoToDelete.id));
  };

  return (
    <>
      <Formik
        initialValues={{
          note: data ? data.note : '',
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values: PhotoFormValues, actions: FormikActions<PhotoFormValues>) => {
          onSubmit(data.id, values, actions);
        }}>
        {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Group as={Col}>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="note"
                    placeholder="Zadejte popisek"
                    value={values.note}
                    disabled={isSubmitting}
                    isInvalid={errors.note && touched.note}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-describedby="basic-addon"
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.note}
                  </Form.Control.Feedback>
                  <InputGroup.Append>
                    <Button type="submit" disabled={isSubmitting || removing} variant="primary" id="button-addon">
                      <FontAwesomeIcon icon={faSave} />
                    </Button>
                    <Button type="button" disabled={isSubmitting || removing} variant="danger" id="button-addon" onClick={() => handleToggleDeleteModal(data)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Form.Row>
          </Form>
        )}
      </Formik>
      {photoToDelete && (
        <Modal show={show} onHide={handleToggleDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Smazání článku</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`Opravdu chcete smazat tuto fotku?`}</p>
            <p>Pořádně si to rozmyslete. Tato akce je nenávratná!</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleToggleDeleteModal()}>
              Zrušit
            </Button>
            <Button variant="danger" onClick={handleRemove}>
              Potvrdit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default PhotoForm;

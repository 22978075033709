import {
  MATCHES_ALL,
  MATCHES_CREATE,
  MATCHES_ERROR,
  MATCHES_LOADING,
  MATCHES_ONE,
  MATCHES_REMOVE,
  MATCHES_UPDATE,
  MatchesActionTypes,
} from '../types/matchesTypes';
import { IMatchModel } from '../../../models/Match';

export interface MatchesState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error | undefined };
  items: { [key: string]: IMatchModel };
}

const initialState: MatchesState = {
  loadings: {},
  errors: {},
  items: {},
};

const matches = (currentState: MatchesState = initialState, action: MatchesActionTypes): MatchesState => {
  let newState;
  switch (action.type) {
    case MATCHES_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case MATCHES_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case MATCHES_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        errors: {
          ...currentState.errors,
          all: undefined,
        },
        items: {
          ...currentState.items,
          ...action.matches,
        },
      };
    case MATCHES_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        errors: {
          ...currentState.errors,
          one: undefined,
        },
        items: {
          ...currentState.items,
          [action.match.id]: action.match,
        },
      };
    case MATCHES_CREATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          create: false,
        },
        errors: {
          ...currentState.errors,
          create: undefined,
        },
        items: {
          ...currentState.items,
          [action.match.id]: action.match,
        },
      };
    case MATCHES_UPDATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          update: false,
        },
        errors: {
          ...currentState.errors,
          update: undefined,
        },
        items: {
          ...currentState.items,
          [action.match.id]: {
            ...currentState.items[action.match.id],
            ...action.match,
          },
        },
      };
    case MATCHES_REMOVE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          remove: false,
        },
        errors: {
          ...currentState.errors,
          remove: undefined,
        },
        items: {
          ...currentState.items,
        },
      };
      if (newState.items[action.matchId]) {
        delete newState.items[action.matchId];
      }
      return newState;
    default:
      return currentState;
  }
};

export default matches;

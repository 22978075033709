import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../redux/reducers';
import { IArticleBaseModel } from '../../../models/Article';
import { getLastArticles } from '../../redux/actions/articlesActions';

import { SLastArticles, SNoData } from './styled';
import ArticlePreview from './ArticlePreview';

const LastArticles = () => {
  const loading = useSelector<AppState>((state) => state.frontend.articles.loadings.last);
  const lastArticles = useSelector<AppState, Array<IArticleBaseModel>>((state) => state.frontend.articles.last);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && !lastArticles) {
      dispatch(getLastArticles(null, null, 3, 2));
    }
  }, []);

  let articles = lastArticles ? Object.values(lastArticles).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) : [];

  articles = articles.length > 3 ? articles.slice(3, 5) : articles.slice(0, 2);

  return (
    <SLastArticles>
      <h2>Další aktuality</h2>
      {articles.length ? (
        articles.map((article: IArticleBaseModel) => <ArticlePreview key={`article_${article.id}`} articleId={article.id} />)
      ) : (
        <SNoData className="d-flex mt-5">
          <p>Žádné články k zobrazení</p>
        </SNoData>
      )}
    </SLastArticles>
  );
};

export default LastArticles;

import React, { useEffect } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { Category, Subcategory } from '../../../models/Category';
import { getFirstAndLastMatches } from '../../redux/actions/matchesActions';
import { AppState } from '../../../redux/reducers';

import FirstMatch from './FirstMatch';
import LastMatch from './LastMatch';

type Props = {
  category: Category;
  subcategory?: Subcategory;
};

const FirstLastMatchTabs = (props: Props) => {
  const { category, subcategory } = props;

  const loading = useSelector((state: AppState) => state.frontend.matches.loadings.lastAndFirst);
  const first = useSelector((state: AppState) => (state.frontend.matches.categories[category] ? state.frontend.matches.categories[category].first : undefined));
  const last = useSelector((state: AppState) => (state.frontend.matches.categories[category] ? state.frontend.matches.categories[category].last : undefined));
  const first_sub = useSelector((state: AppState) =>
    subcategory && state.frontend.matches.subcategories[category] && state.frontend.matches.subcategories[category][subcategory]
      ? state.frontend.matches.subcategories[category][subcategory].first
      : undefined,
  );
  const last_sub = useSelector((state: AppState) =>
    subcategory && state.frontend.matches.subcategories[category] && state.frontend.matches.subcategories[category][subcategory]
      ? state.frontend.matches.subcategories[category][subcategory].last
      : undefined,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && !first && !last) {
      dispatch(getFirstAndLastMatches(category, subcategory));
    }
  }, []);

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <Tab.Container id="firstAndLastMatch" defaultActiveKey="first">
      <Tab.Content>
        <Tab.Pane eventKey="first">
          <FirstMatch match={subcategory ? first_sub : first} />
        </Tab.Pane>
        <Tab.Pane eventKey="last">
          <LastMatch match={subcategory ? last_sub : last} />
        </Tab.Pane>
      </Tab.Content>
      <Nav variant="tabs" className="d-flex flex-row">
        <Nav.Item>
          {isMobile && (
            <Nav.Link className="tab" eventKey="first">
              Příští utkání
            </Nav.Link>
          )}
          {!isMobile && (
            <Nav.Link className="tab" eventKey="first">
              Nadcházející utkání
            </Nav.Link>
          )}
        </Nav.Item>
        <Nav.Item>
          {isMobile && (
            <Nav.Link className="tab" eventKey="last">
              Posled. utkání
            </Nav.Link>
          )}
          {!isMobile && (
            <Nav.Link className="tab" eventKey="last">
              Poslední odehrané utkání
            </Nav.Link>
          )}
        </Nav.Item>
      </Nav>
    </Tab.Container>
  );
};

export default FirstLastMatchTabs;

import React, { useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Button, ButtonGroup, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';

import { getGalleries } from '../../redux/actions/photosActions';
import { Category, Subcategory } from '../../../models/Category';
import { AppState } from '../../../redux/reducers';
import { IGalleryModel } from '../../../models/Photo';

import GridView from './GridView';
import ListView from './ListView';

type Props = {
  onSelect: (photoId: number) => void;
};

const PhotoPicker = (props: Props) => {
  const { onSelect } = props;

  const match = useRouteMatch<{ id: string; category: Category; subcategory?: Subcategory }>();
  let { category, subcategory } = match.params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const galleryItems = useSelector<AppState, { [key: string]: IGalleryModel }>((state) => state.backend.photos.items);

  const dispatch = useDispatch();

  const galleries = useMemo(
    () =>
      Object.values(galleryItems)
        .filter((gallery) => gallery.category === category)
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
    [category, subcategory, galleryItems],
  );

  const [gallery, setGallery] = useState(undefined);
  const [mode, setMode] = useState<'list' | 'grid'>('grid');

  useEffect(() => {
    dispatch(getGalleries(token, category, subcategory));
  }, []);

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        Přiřazení fotky k článku
        <ButtonGroup>
          <Button variant={mode === 'list' ? 'dark' : 'outline-dark'} style={{ cursor: 'pointer' }} onClick={() => setMode('list')}>
            <FontAwesomeIcon icon={faList} />
          </Button>
          <Button variant={mode === 'grid' ? 'dark' : 'outline-dark'} style={{ cursor: 'pointer' }} onClick={() => setMode('grid')}>
            <FontAwesomeIcon icon={faTable} />
          </Button>
        </ButtonGroup>
      </Card.Header>
      <Card.Body>
        {gallery ? (
          <Breadcrumb>
            <BreadcrumbItem onClick={() => setGallery(undefined)}>Galerie</BreadcrumbItem>
            <BreadcrumbItem active>{gallery.name}</BreadcrumbItem>
          </Breadcrumb>
        ) : null}
        {mode === 'grid' ? (
          <GridView galleries={galleries} gallery={gallery} onSelectGallery={setGallery} onSelectPhoto={onSelect} />
        ) : (
          <ListView galleries={galleries} gallery={gallery} onSelectGallery={setGallery} onSelectPhoto={onSelect} />
        )}
      </Card.Body>
    </Card>
  );
};

export default PhotoPicker;

import React, { useEffect, useState } from 'react';
import { AutoResizer, ColumnShape } from 'react-base-table';
import { Form, Image } from 'react-bootstrap';
import { FormikActions } from 'formik';

import DraggableTable from '../DraggableTable';
import { SERVER_URL } from '../../../constants';
import PhotoForm, { PhotoFormValues } from '../../forms/PhotoForm';
import { IPhotoModel } from '../../../models/Photo';

import { Container } from './styles';

type Props = {
  photos: Array<IPhotoModel>;
  handleUpdatePhoto: (photoId: number, values: PhotoFormValues, actions: FormikActions<PhotoFormValues>) => void;
  handleUpdateOrder: (sourcePhoto: Array<IPhotoModel>) => void;
};

const PhotoList = (props: Props) => {
  const { photos, handleUpdatePhoto, handleUpdateOrder } = props;

  const [tableData, setTableData] = useState(photos);

  useEffect(() => {
    setTableData(photos);
  }, [photos]);

  const handleChangeData = (data: Array<IPhotoModel>) => {
    setTableData(data);
    handleUpdateOrder(data);
  };

  const columns: ColumnShape<IPhotoModel>[] = [
    {
      key: 'id',
      dataKey: 'id',
      title: 'ID',
      width: 0,
      flexGrow: 0,
      hidden: true,
      cellRenderer: ({ cellData }) => <Form.Control type="hidden" name="id" value={cellData} />,
    },
    {
      key: 'url',
      dataKey: 'url',
      title: 'Fotka',
      width: 0,
      flexGrow: 1,
      cellRenderer: ({ cellData }) => (
        <div style={{ height: 100, padding: 10 }}>
          <Image fluid src={`${SERVER_URL}${cellData}`} alt="photo" style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </div>
      ),
    },
    {
      key: 'form',
      title: 'Popisek',
      width: 0,
      flexGrow: 1,
      cellRenderer: ({ rowData }) => (
        <div style={{ width: '100%', height: '100px', padding: '30px 0px' }}>
          <PhotoForm data={rowData} onSubmit={handleUpdatePhoto} />
        </div>
      ),
    },
  ];

  return (
    <Container count={photos ? photos.length : 0}>
      <AutoResizer>
        {({ width, height }) => (
          <DraggableTable width={width} height={height} columns={columns} data={tableData} estimatedRowHeight={100} onChange={handleChangeData} />
        )}
      </AutoResizer>
    </Container>
  );
};

export default PhotoList;

import {
  ARTICLES_ALL,
  ARTICLES_CREATE,
  ARTICLES_ERROR,
  ARTICLES_LOADING,
  ARTICLES_ONE,
  ARTICLES_REMOVE,
  ARTICLES_UPDATE,
  ArticlesActionTypes,
} from '../types/articlesTypes';
import { IArticleModel } from '../../../models/Article';

export interface ArticlesState {
  loadings: {
    all?: boolean;
    one?: boolean;
    create?: boolean;
    update?: boolean;
    remove?: boolean;
  };
  errors: {
    all?: Error;
    one?: Error;
    create?: Error;
    update?: Error;
    remove?: Error;
  };
  items: { [key: string]: IArticleModel };
}

const initialState: ArticlesState = {
  loadings: {},
  errors: {},
  items: {},
};

const articles = (currentState: ArticlesState = initialState, action: ArticlesActionTypes): ArticlesState => {
  let newState;
  switch (action.type) {
    case ARTICLES_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case ARTICLES_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case ARTICLES_ALL:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        errors: {
          ...currentState.errors,
          all: undefined,
        },
        items: {
          ...currentState.items,
          ...action.articles,
        },
      };
      for (const id in action.articles) {
        newState.items[id] = {
          ...currentState.items[id],
          ...action.articles[id],
        };
      }
      return newState;
    case ARTICLES_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        errors: {
          ...currentState.errors,
          one: undefined,
        },
        items: {
          ...currentState.items,
          [action.article.id]: action.article,
        },
      };
    case ARTICLES_CREATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          create: false,
        },
        errors: {
          ...currentState.errors,
          create: undefined,
        },
        items: {
          ...currentState.items,
          [action.article.id]: action.article,
        },
      };
    case ARTICLES_UPDATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          update: false,
        },
        errors: {
          ...currentState.errors,
          update: undefined,
        },
        items: {
          ...currentState.items,
          [action.article.id]: {
            ...currentState.items[action.article.id],
            ...action.article,
          },
        },
      };
    case ARTICLES_REMOVE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          remove: false,
        },
        errors: {
          ...currentState.errors,
          remove: undefined,
        },
        items: {
          ...currentState.items,
        },
      };
      if (newState.items[action.articleId]) {
        delete newState.items[action.articleId];
      }
      return newState;
    default:
      return currentState;
  }
};

export default articles;

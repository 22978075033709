import { TABLES_ERROR, TABLES_LOADING, TABLES_ONE, TablesActionsTypes } from '../types/tablesTypes';
import { TableModel } from '../../../models/Team';

export interface ITablesState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error };
  categories: {
    [key: string]: {
      table: Array<TableModel>;
    };
  };
  subcategories: {
    [key: string]: {
      [key: string]: {
        table: Array<TableModel>;
      };
    };
  };
}

const initialState: ITablesState = {
  loadings: {},
  errors: {},
  categories: {},
  subcategories: {},
};

const tables = (currentState: ITablesState = initialState, action: TablesActionsTypes): ITablesState => {
  let newState;
  switch (action.type) {
    case TABLES_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case TABLES_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case TABLES_ONE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          table: false,
        },
        categories: {
          ...currentState.categories,
        },
        subcategories: {
          ...currentState.subcategories,
        },
      };
      if (action.category && action.subcategory) {
        newState.subcategories[action.category] = {
          ...currentState.subcategories[action.category],
          [action.subcategory]: {
            ...(currentState.categories[action.category] ? currentState.categories[action.category][action.subcategory] : undefined),
            table: action.table,
          },
        };
      } else {
        newState.categories[action.category] = {
          ...currentState.categories[action.category],
          table: action.table,
        };
      }
      return newState;
    default:
      return currentState;
  }
};

export default tables;

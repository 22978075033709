import React, { useMemo } from 'react';
import { Nav, Tab, TabContainer } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { AppState } from '../../../redux/reducers';
import { ICategoryModel } from '../../../models/Category';

import { STablesAll } from './styled';
import TableShort from './TableShort';

const TablesAll = () => {
  const categories = useSelector<AppState, { [key: string]: ICategoryModel }>((state) => state.frontend.categories.items);

  const tabs = useMemo(
    () =>
      Object.values(categories)
        .filter((category) => category.id !== 'pr' && category.id !== 'sk')
        .map((c) => {
          const splitName = c.name.split(' ');
          return {
            ...c,
            shortName: `${splitName[0][0]}${splitName[1] ? `${splitName[1][0]}` : ''}`,
          };
        }),
    [categories],
  );

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <STablesAll>
      <h3>Tabulky</h3>
      <TabContainer id="matchOverview" defaultActiveKey="mA">
        <Nav variant="tabs" className="d-flex flex-row">
          {tabs.length
            ? tabs.map((category) => {
                if (!category.hasSubCategory) {
                  return (
                    <Nav.Item key={category.id}>
                      {isMobile && (
                        <Nav.Link className="tab" eventKey={category.id}>
                          {category.shortName}
                        </Nav.Link>
                      )}
                      {!isMobile && (
                        <Nav.Link className="tab" eventKey={category.id}>
                          {category.name}
                        </Nav.Link>
                      )}
                    </Nav.Item>
                  );
                } else {
                  return (
                    <React.Fragment key={category.id}>
                      <Nav.Item key={`st_${category.id}`}>
                        {isMobile && (
                          <Nav.Link className="tab" eventKey={`st_${category.id}`}>
                            St. {category.shortName}
                          </Nav.Link>
                        )}
                        {!isMobile && (
                          <Nav.Link className="tab" eventKey={`st_${category.id}`}>
                            St. {category.name}
                          </Nav.Link>
                        )}
                      </Nav.Item>
                      <Nav.Item key={`ml_${category.id}`}>
                        {isMobile && (
                          <Nav.Link className="tab" eventKey={`ml_${category.id}`}>
                            Ml. {category.shortName}
                          </Nav.Link>
                        )}
                        {!isMobile && (
                          <Nav.Link className="tab" eventKey={`ml_${category.id}`}>
                            Ml. {category.name}
                          </Nav.Link>
                        )}
                      </Nav.Item>
                    </React.Fragment>
                  );
                }
              })
            : null}
        </Nav>
        <Tab.Content>
          {tabs.length
            ? tabs.map((category) => {
                if (!category.hasSubCategory) {
                  return (
                    <Tab.Pane key={category.id} eventKey={category.id}>
                      <TableShort category={category.id} />
                    </Tab.Pane>
                  );
                } else {
                  return (
                    <React.Fragment key={category.id}>
                      <Tab.Pane key={`st_${category.id}`} eventKey={`st_${category.id}`}>
                        <TableShort category={category.id} subcategory="st" />
                      </Tab.Pane>
                      <Tab.Pane key={`ml_${category.id}`} eventKey={`ml_${category.id}`}>
                        <TableShort category={category.id} subcategory="ml" />
                      </Tab.Pane>
                    </React.Fragment>
                  );
                }
              })
            : null}
        </Tab.Content>
      </TabContainer>
    </STablesAll>
  );
};

export default TablesAll;

import * as React from 'react';
import { Breadcrumb, Container } from 'react-bootstrap';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';

const DashboardContainer = () => {
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <Container fluid className="p-0">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item active={location.pathname === match.path}>
          {location.pathname === match.path ? 'Přehled' : <Link to="/admin/dashboard">Přehled</Link>}
        </Breadcrumb.Item>
      </Breadcrumb>
    </Container>
  );
};

export default DashboardContainer;

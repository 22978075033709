import styled from 'styled-components';

import { colors } from '../../layout/styles';
export const STournamentsOverview = styled.div`
  display: block;
  width: 100%;
  padding: 30px;
  height: 100%;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.09);

  h3 {
    font-family: 'Nunito Sans';
    font-size: 30px;
    font-weight: 900;
    text-transform: uppercase;
    color: ${colors.blue};
    text-align: center;
  }

  .nav-tabs {
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px ${colors.grey} solid;
    .nav-item {
      justify-content: center;
      align-items: center;
    }
    .tab {
      font-family: Nunito Sans;
      font-weight: 900;
      font-size: 16px;
      color: ${colors.grey};
      border: none;
      padding: 0 0.5rem;

      &.active {
        color: ${colors.blue};
        border-bottom: 4px ${colors.orange} solid;
      }

      &:hover {
        color: ${colors.blue};
      }
    }
  }
`;
export const STournament = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: 20px;
  justify-content: space-between;
  align-items: center;

  .tournament {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .name {
      font-family: 'Nunito Sans';
      font-size: 18px;
      font-weight: 900;
      color: ${colors.blue};
      text-transform: uppercase;
      padding: 5px 0;
      @media screen and (min-width: 768px) {
        font-size: 22px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      font-family: Nunito Sans;
      font-weight: 900;
      font-size: 16px;
      text-transform: uppercase;
      color: ${colors.blue};
    }
  }

  .info {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 10px;
    }
    p {
      margin-bottom: 0;
    }
  }
`;

export const SNoTournamentData = styled.div`
  display: flex;
  flex: 1;
  min-height: 160px;
  justify-content: center;
  align-items: center;
  font-family: Nunito Sans;
  color: ${colors.blue};
`;

export const STable = styled.div`
  margin: 50px 0px;
  table {
    font-family: Nunito Sans;
    text-align: center;
    thead {
      font-weight: 900;
      color: ${colors.orange};
      th {
        border-top: none;
        border-bottom: 1px ${colors.orange} solid;
      }
    }
    tbody {
      font-weight: 600;
      color: ${colors.blue};
    }
    tr {
      &.no-border {
        td {
          border: none;
        }
      }
    }
    th,
    td {
      vertical-align: middle;
      &.left {
        text-align: left;
      }
      &.center {
        text-align: center;
      }
      &.right {
        text-align: right;
      }
      &.bold {
        font-weight: 800;
      }
    }
    p {
      padding: 0;
      margin: 0;
    }
  }
`;

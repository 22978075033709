import { IGalleryModel } from '../../../models/Photo';

export const processGalleries = (data: Array<IGalleryModel>) => {
  const all: { [key: string]: IGalleryModel } = {};
  data.forEach((gallery: IGalleryModel) => {
    all[gallery.id] = gallery;
  });

  return {
    all,
  };
};

import { Formik, FormikActions } from 'formik';
import * as React from 'react';
import { Col, Form } from 'react-bootstrap';

import { ISponsorModel } from '../../../models/Sponsor';

import validationSchema from './validation';

export interface SponsorFormValues {
  name: string;
  web: string;
  mail: string;
  phone: string;
  address: string;
}

type Props = {
  id: string;
  data?: ISponsorModel;
  onSubmit: (values: SponsorFormValues, actions: FormikActions<SponsorFormValues>) => void;
};

const PlayerForm = (props: Props) => {
  const { id, data, onSubmit } = props;

  return (
    <Formik
      initialValues={{
        name: data ? data.name : '',
        web: data ? data.web : '',
        mail: data ? data.mail : '',
        phone: data ? data.phone : '',
        address: data ? data.address : '',
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values: SponsorFormValues, actions: FormikActions<SponsorFormValues>) => {
        onSubmit(values, actions);
      }}>
      {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
        <Form id={id} noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Název sponzora</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Zadejte název sponzora"
                value={values.name}
                disabled={isSubmitting}
                isInvalid={errors.name && touched.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Web sponzora</Form.Label>
              <Form.Control
                type="text"
                name="web"
                placeholder="Zadejte web sponzora"
                value={values.web}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>E-mail sponzora</Form.Label>
              <Form.Control
                type="text"
                name="mail"
                placeholder="Zadejte e-mail sponzora"
                value={values.mail}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Telefon sponzora</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                placeholder="Zadejte telefon sponzora"
                value={values.phone}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Adresa sponzora</Form.Label>
              <Form.Control
                type="text"
                name="address"
                placeholder="Zadejte adresu sponzora"
                value={values.address}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
};

export default PlayerForm;

import { Formik, FormikActions } from 'formik';
import * as React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import { ICategoryModel } from '../../../models/Category';

import validationSchema from './validation';

export interface CategoryFormValues {
  id: string;
  name: string;
  hasSubCategory: boolean;
}

type Props = {
  data?: ICategoryModel;
  onSubmit: (values: CategoryFormValues, actions: FormikActions<CategoryFormValues>) => void;
};

const CategoryForm = (props: Props) => {
  const { data, onSubmit } = props;
  return (
    <Formik
      initialValues={{
        id: data ? data.id : '',
        name: data ? data.name : '',
        hasSubCategory: data ? data.hasSubCategory : false,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values: CategoryFormValues, actions: FormikActions<CategoryFormValues>) => {
        onSubmit(values, actions);
      }}>
      {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} controlId="ida">
              <Form.Label>ID kategorie</Form.Label>
              <Form.Control
                type="text"
                name="id"
                autoComplete="off"
                placeholder="Zadejte ID kategorie"
                value={values.id}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={errors.id && touched.id}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.id}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="name">
              <Form.Label>Název kategorie</Form.Label>
              <Form.Control
                type="text"
                name="name"
                autoComplete="off"
                placeholder="Zadejte název kategorie"
                value={values.name}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={errors.name && touched.name}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="hasSubCategory">
              <Form.Check name="hasSubCategory" label="Podkategorie" checked={values.hasSubCategory} onChange={handleChange} feedbackTooltip />
            </Form.Group>
          </Form.Row>
          <Row>
            <Col>
              <Button type="submit" disabled={isSubmitting} color="primary">
                {`${data ? 'Upravit' : 'Přidat'} kategorii`}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default CategoryForm;

import { ICategoryModel } from '../../../models/Category';
import { CATEGORIES_ALL, CATEGORIES_ERROR, CATEGORIES_LOADING, CategoriesActionsTypes } from '../types/categoriesTypes';

export interface CategoriesState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error };
  items?: { [key: string]: ICategoryModel };
}

const initialState: CategoriesState = {
  loadings: {},
  errors: {},
  items: {},
};

const categories = (currentState: CategoriesState = initialState, action: CategoriesActionsTypes): CategoriesState => {
  switch (action.type) {
    case CATEGORIES_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case CATEGORIES_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case CATEGORIES_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          categories: false,
        },
        errors: {
          ...currentState.errors,
          categories: undefined,
        },
        items: action.categories,
      };
    default:
      return currentState;
  }
};

export default categories;

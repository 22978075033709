import styled from 'styled-components';

import { colors } from '../../layout/styles';

export const SPlayer = styled.div`
  display: flex;
  margin: 15px;
  min-width: 250px;
  padding: 30px 15px;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: ${colors.blue};
  background-color: ${colors.white};
  box-shadow: 0 0 60px 3px rgba(0, 0, 0, 0.09);
  text-align: center;
`;

import { Container } from 'react-bootstrap';
import styled from 'styled-components';

import { colors } from '../../../layout/styles';

export const SPageContent = styled(Container)`
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 800px);

  h2 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  table {
    thead {
      th {
        text-align: center;
        border: 1px grey solid;
        background-color: ${colors.blue};
        color: ${colors.white};
      }
    }
    tbody {
      th,
      td {
        padding: 0;
        text-align: center;
        border: 1px grey solid;
        vertical-align: middle;
        &.home {
          background-color: ${colors.blue};
        }
        &.away {
          background-color: ${colors.white};
        }
      }
      th {
        padding: 0 0.75rem;
        background-color: ${colors.orange};
        color: ${colors.white};
      }
    }
  }
`;

export const SNoData = styled.div`
  display: flex;
  flex: 1;
  height: 90px;
  justify-content: center;
  align-items: center;
  font-family: Nunito Sans;
  color: ${colors.blue};
`;

import { FRONTEND_PLAYER_ERROR, FRONTEND_PLAYER_LOADING, FRONTEND_PLAYER_ALL, PlayerActionsTypes } from '../types/playersTypes';
import { IPlayerBaseModel, IPlayerModel } from '../../../models/Player';

export interface IPlayersState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error };
  items: { [key: string]: IPlayerModel };
  categories: {
    [key: string]: {
      [key: string]: IPlayerBaseModel;
    };
  };
  subcategories?: {
    [key: string]: {
      [key: string]: {
        [key: string]: IPlayerBaseModel;
      };
    };
  };
}

const initialState: IPlayersState = {
  loadings: {},
  errors: {},
  items: {},
  categories: {},
  subcategories: {},
};

const players = (currentState: IPlayersState = initialState, action: PlayerActionsTypes): IPlayersState => {
  let newState;
  switch (action.type) {
    case FRONTEND_PLAYER_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case FRONTEND_PLAYER_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case FRONTEND_PLAYER_ALL:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        items: {
          ...currentState.items,
          ...action.players,
        },
        categories: {
          ...currentState.categories,
        },
        subcategories: {
          ...currentState.subcategories,
        },
      };
      if (action.category && action.subcategory) {
        if (!newState.subcategories[action.category]) newState.subcategories[action.category] = {};
        newState.subcategories[action.category] = {
          ...newState.subcategories[action.category],
          [action.subcategory]: {
            ...newState.subcategories[action.category][action.subcategory],
            ...action.players,
          },
        };
      } else {
        newState.categories[action.category] = {
          ...newState.categories[action.category],
          ...action.players,
        };
      }
      return newState;
    default:
      return currentState;
  }
};

export default players;

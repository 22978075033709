import axios from 'axios';

import { endpoints } from '../../../constants/API';
import { FRONTEND_PLAYER_ALL, FRONTEND_PLAYER_ERROR, FRONTEND_PLAYER_LOADING, IAPlayerAll, IAPlayerError, IAPlayerLoading } from '../types/playersTypes';
import { Category, Subcategory } from '../../../models/Category';
import { processPlayers } from '../helpers/playersHelper';
import { IPlayerModel } from '../../../models/Player';

export const APlayersLoading = (name: string): IAPlayerLoading => ({
  type: FRONTEND_PLAYER_LOADING,
  name,
});

const APlayersError = (name: string, error: Error): IAPlayerError => ({
  type: FRONTEND_PLAYER_ERROR,
  name,
  error,
});

const APlayersAll = (category: Category, subcategory: Subcategory, players: { [key: string]: IPlayerModel }): IAPlayerAll => ({
  type: FRONTEND_PLAYER_ALL,
  category,
  subcategory,
  players,
});

export const getPlayers = (category: Category, subcategory?: Subcategory) => async (dispatch) => {
  const actionName = 'all';
  dispatch(APlayersLoading(actionName));
  try {
    const config = {
      params: {
        category,
        subcategory,
      },
    };
    const response = await axios.get(`${endpoints.players}`, config);
    const data = response.data;
    const { all } = processPlayers(data);
    dispatch(APlayersAll(category, subcategory, all));
  } catch (e) {
    dispatch(APlayersError(actionName, e));
    console.error(e);
  }
};

import { FRONTEND_PHOTO_ERROR, FRONTEND_PHOTO_LOADING, FRONTEND_PHOTO_ALL, FRONTEND_PHOTO_ONE, PhotoActionsTypes } from '../types/photosTypes';
import { IGalleryModel } from '../../../models/Photo';

export interface IPhotosState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error };
  items: { [key: string]: IGalleryModel };
  categories: {
    [key: string]: {
      [key: string]: IGalleryModel;
    };
  };
  subcategories?: {
    [key: string]: {
      [key: string]: {
        [key: string]: IGalleryModel;
      };
    };
  };
}

const initialState: IPhotosState = {
  loadings: {},
  errors: {},
  items: {},
  categories: {},
  subcategories: {},
};

const photos = (currentState: IPhotosState = initialState, action: PhotoActionsTypes): IPhotosState => {
  let newState;
  switch (action.type) {
    case FRONTEND_PHOTO_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case FRONTEND_PHOTO_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case FRONTEND_PHOTO_ALL:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        items: {
          ...currentState.items,
        },
        categories: {
          ...currentState.categories,
        },
        subcategories: {
          ...currentState.subcategories,
        },
      };
      if (action.category && action.subcategory) {
        if (!newState.subcategories[action.category]) newState.subcategories[action.category] = {};
        newState.subcategories[action.category] = {
          ...newState.subcategories[action.category],
          [action.subcategory]: {
            ...newState.subcategories[action.category][action.subcategory],
            ...action.galleries,
          },
        };
      } else {
        newState.categories[action.category] = {
          ...newState.categories[action.category],
          ...action.galleries,
        };
      }
      for (const galleryId in action.galleries) {
        if (newState.items[galleryId]) {
          newState.items[galleryId] = {
            ...newState.items[galleryId],
            ...action.galleries[galleryId],
          };
        } else {
          newState.items[galleryId] = action.galleries[galleryId];
        }
      }
      return newState;
    case FRONTEND_PHOTO_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          photos: false,
        },
        items: {
          ...currentState.items,
          [action.gallery.id]: {
            ...currentState.items[action.gallery.id],
            ...action.gallery,
          },
        },
      };
    default:
      return currentState;
  }
};

export default photos;

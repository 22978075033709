import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';

import UserForm from '../../forms/UserForm';
import { AppState } from '../../../redux/reducers';
import { createUser, getUser, updateUser } from '../../redux/actions/usersActions';
import { UserModel } from '../../../models/User';

const UserFormContainer = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();
  const history = useHistory();

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loadingAction = useSelector<AppState, boolean>((state) => state.backend.users.loadings.create || state.backend.users.loadings.update);
  const error = useSelector<AppState, Error | undefined>((state) => state.backend.users.errors.create || state.backend.users.errors.update);
  const loading = useSelector<AppState, boolean>((state) => state.backend.users.loadings.one);
  const user = useSelector<AppState, UserModel>((state) => state.backend.users.items[id]);

  const dispatch = useDispatch();

  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (!loading && id) {
      dispatch(getUser(token, id));
    }
  }, []);

  useEffect(() => {
    if (isSent) {
      if (!loadingAction && !error) {
        setIsSent(false);
        history.push(`/admin/users`);
      }
    }
  }, [isSent, loadingAction, error, history]);

  const handleAddUser = (values, { setSubmitting }) => {
    dispatch(createUser(token, values));
    setSubmitting(false);
    setIsSent(true);
  };

  const handleEditUser = (values, { setSubmitting }) => {
    dispatch(updateUser(token, id, values));
    setSubmitting(false);
    setIsSent(true);
  };

  return (
    <Container className="p-0">
      <UserForm id="user-form" data={user} onSubmit={user ? handleEditUser : handleAddUser} />
    </Container>
  );
};

export default UserFormContainer;

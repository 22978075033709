import React, { useEffect, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { arrayToChunks } from '../../../../utils';
import Gallery from '../../../components/Photos/Gallery';
import { AppState } from '../../../../redux/reducers';
import { getGalleries } from '../../../redux/actions/photosActions';

import { SPageContent, SNoData } from './styled';

const Photos = () => {
  const isTablet = useMediaQuery({ query: '(max-width: 991px)' });

  const loading = useSelector<AppState>((state) => state.frontend.photos.loadings.all);
  const galleries = useSelector<AppState>((state) => state.frontend.photos.categories['klub']);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getGalleries('klub'));
    }
  }, []);

  const galleriesArray = useMemo(
    () => (galleries ? Object.values(galleries).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) : []),
    [galleries],
  );

  return (
    <SPageContent className="p-0 my-5">
      <h2>Fotky z akcí</h2>
      <Container className="p-0">
        {galleriesArray.length ? (
          arrayToChunks(galleriesArray, isTablet ? 2 : 3).map((chunk, index) => (
            <Row key={`photos_row_${index}`}>
              {chunk.length
                ? chunk.map((gallery) => (
                    <Col md={6} lg={4} key={`photos_row_col_${gallery.id}`}>
                      <Gallery galleryId={gallery.id} />
                    </Col>
                  ))
                : null}
            </Row>
          ))
        ) : (
          <SNoData>Žádné galerie k zobrazení</SNoData>
        )}
      </Container>
    </SPageContent>
  );
};

export default Photos;

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Column } from 'react-table';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Card, Modal } from 'react-bootstrap';
import { useEffect, useMemo, useState } from 'react';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';
import moment from 'moment';

import Table from '../../components/Table';
import { Category, Subcategory } from '../../../models/Category';
import { ITournamentModel } from '../../../models/Tournament';
import { getTournaments, removeTournament } from '../../redux/actions/tournamentsActions';
import CategorySwitch from '../../components/CategorySwitch';
import { AppState } from '../../../redux/reducers';
import { IMatchModel } from '../../../models/Match';

const TournamentListContainer = () => {
  const history = useHistory();
  const match = useRouteMatch<{ category: Category; subcategory: Subcategory }>();
  let { category, subcategory } = match.params;

  if (!category) category = 'mA';

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loading = useSelector<AppState, boolean>((state) => state.backend.tournaments.loadings.all);
  const tournaments = useSelector<AppState, { [key: string]: ITournamentModel }>((state) => state.backend.tournaments.items);
  const loadingRemove = useSelector<AppState, boolean>((state) => state.backend.tournaments.loadings.remove);
  const errorRemove = useSelector<AppState, Error | undefined>((state) => state.backend.tournaments.errors.remove);

  const filteredTournaments = useMemo(
    () =>
      Object.values(tournaments).filter((tournament) =>
        subcategory ? tournament.category === category && tournament.subcategory === subcategory : tournament.category === category,
      ),
    [category, subcategory, tournaments],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getTournaments(token, category, subcategory));
    }
  }, [category, subcategory]);

  useEffect(() => {
    if (!loadingRemove && !errorRemove) {
      setShowModal(false);
    }
  }, [loadingRemove, errorRemove]);

  const handleToggleDeleteModal = (tournament?: ITournamentModel) => {
    setTournament(tournament);
    setShowModal(!showModal);
  };

  const handleRemove = () => {
    dispatch(removeTournament(token, tournament!.id));
  };

  const OptionCell = (row) => (
    <div className="d-flex justify-content-end">
      <Button
        variant="outline-dark"
        className="mr-2"
        onClick={() => history.push(`/admin/tournaments/${category}${subcategory ? `/${subcategory}` : ''}/edit/${row.original.id}`)}>
        <FontAwesomeIcon icon={faEdit} style={{ cursor: 'pointer' }} />
      </Button>
      <Button variant="danger" onClick={() => handleToggleDeleteModal(row.original)}>
        <FontAwesomeIcon icon={faTrash} style={{ cursor: 'pointer' }} />
      </Button>
    </div>
  );

  const columns: Column[] = [
    {
      id: 'date',
      Header: 'Datum turnaje',
      accessor: (d: ITournamentModel) => d.date,
      sortMethod: (a, b) => {
        const aTime = new Date(a).getTime();
        const bTime = new Date(b).getTime();
        return aTime - bTime;
      },
      Cell: (row) => {
        return `${moment(row.value).format('ddd DD.MM.YYYY').toLocaleUpperCase()} ${row.original.time}`;
      },
    },
    {
      id: 'part',
      Header: 'Část sezóny',
      accessor: (d: IMatchModel) => d.part,
    },
    {
      id: 'name',
      Header: 'Název turnaje',
      accessor: (d: ITournamentModel) => d.name,
    },
    {
      id: 'options',
      Header: 'Možnosti',
      Cell: OptionCell,
    },
  ];

  const options = {
    loadingText: 'Načítám turnaje...',
    noDataText: 'Žádné turnaje k zobrazení',
  };

  const [showModal, setShowModal] = useState(false);
  const [tournament, setTournament] = useState<ITournamentModel | undefined>();

  return (
    <Container fluid className="p-0">
      <CategorySwitch />
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <FontAwesomeIcon icon={faTable} />
              <span className="ml-2">Seznam turnajů</span>
            </div>
            <Button color="primary" onClick={() => history.push(`/admin/tournaments/${category}${subcategory ? `/${subcategory}` : ''}/add`)}>
              <FontAwesomeIcon icon={faPlus} />
              <span className="ml-2">Přidat turnaj</span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Table
            columns={columns}
            data={filteredTournaments}
            options={{
              loading,
              ...options,
            }}
          />
        </Card.Body>
        <Card.Footer />
      </Card>
      {tournament && (
        <Modal show={showModal} onHide={handleToggleDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Smazání turnaje</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`Opravdu chcete smazat turnaj: ${tournament.name}?`}</p>
            <p>Pořádně si to rozmyslete. Tato akce je nenávratná!</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleToggleDeleteModal()}>
              Zrušit
            </Button>
            <Button variant="danger" onClick={() => handleRemove()}>
              Potvrdit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default TournamentListContainer;

import { combineReducers } from 'redux';

import categories, { CategoriesState } from './categoriesReducer';
import competitions, { ICompetitionsState } from './competitionsReducer';
import articles, { IArticlesState } from './articlesReducer';
import matches, { IMatchesState } from './matchesReducer';
import messages, { IMessagesState } from './messagesReducer';
import photos, { IPhotosState } from './photosReducer';
import players, { IPlayersState } from './playersReducer';
import sponsors, { ISponsorsState } from './sponsorsReducer';
import tables, { ITablesState } from './tablesReducer';
import tournaments, { ITournamentsState } from './tournamentsReducer';
import videos, { IVideosState } from './videosReducer';

export interface FrontendState {
  articles: IArticlesState;
  categories: CategoriesState;
  competitions: ICompetitionsState;
  matches: IMatchesState;
  messages: IMessagesState;
  photos: IPhotosState;
  players: IPlayersState;
  sponsors: ISponsorsState;
  tables: ITablesState;
  tournaments: ITournamentsState;
  videos: IVideosState;
}

const frontend = combineReducers<FrontendState>({
  articles,
  categories,
  competitions,
  matches,
  messages,
  photos,
  players,
  sponsors,
  tables,
  tournaments,
  videos,
});

export default frontend;

import styled from 'styled-components';
import { Image } from 'react-bootstrap';

export const SCardImage = styled.div<{ image: string }>`
  @keyframes image_blur {
    0% {
      filter: blur(5px);
    }
    100% {
      filter: blur(0px);
    }
  }

  height: 250px;
  background-image: url('${(props) => props.image}');
  background-size: cover;
  background-position: center;
  animation: image_blur 1.5s;
`;

export const StyledPhotoPickerImage = styled(Image)`
  max-width: 90%;
  max-height: 200px;
  opacity: 0.75;
  &:hover {
    max-width: 100%;
    opacity: 1;
    cursor: pointer;
  }
`;

import { EVENTS_ALL, EVENTS_CREATE, EVENTS_ERROR, EVENTS_LOADING, EVENTS_ONE, EVENTS_REMOVE, EVENTS_UPDATE, TAEvents } from '../types/eventsTypes';

export interface EventsState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error | undefined };
  items: {};
}

const initialState: EventsState = {
  loadings: {},
  errors: {},
  items: {},
};

const events = (currentState: EventsState = initialState, action: TAEvents): EventsState => {
  let newState;
  switch (action.type) {
    case EVENTS_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case EVENTS_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case EVENTS_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        errors: {
          ...currentState.errors,
          all: undefined,
        },
        items: {
          ...currentState.items,
          ...action.events,
        },
      };
    case EVENTS_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        errors: {
          ...currentState.errors,
          one: undefined,
        },
        items: {
          ...currentState.items,
          [action.event.id]: action.event,
        },
      };
    case EVENTS_CREATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          create: false,
        },
        errors: {
          ...currentState.errors,
          create: undefined,
        },
        items: {
          ...currentState.items,
          [action.event.id]: action.event,
        },
      };
    case EVENTS_UPDATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          update: false,
        },
        errors: {
          ...currentState.errors,
          update: undefined,
        },
        items: {
          ...currentState.items,
          [action.event.id]: {
            ...currentState.items[action.event.id],
            ...action.event,
          },
        },
      };
    case EVENTS_REMOVE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          remove: false,
        },
        errors: {
          ...currentState.errors,
          remove: undefined,
        },
        items: {
          ...currentState.items,
        },
      };
      if (newState.items[action.eventId]) {
        delete newState.items[action.eventId];
      }
      return newState;
    default:
      return currentState;
  }
};

export default events;

import { combineReducers } from 'redux';

import articles, { ArticlesState } from './articlesReducer';
import categories, { CategoriesState } from './categoriesReducer';
import competitions, { CompetitionsState } from './competitionsReducer';
import events, { EventsState } from './eventsReducer';
import matches, { MatchesState } from './matchesReducer';
import meetings, { MeetingsState } from './meetingsReducer';
import messages, { MessagesState } from './messagesReducer';
import players, { PlayersState } from './playersReducer';
import sponsors, { SponsorsState } from './sponsorsReducer';
import teams, { TeamsState } from './teamsReducer';
import tournaments, { TournamentsState } from './tournamentsReducer';
import user, { UserState } from './userReducer';
import users, { UsersState } from './usersReducer';
import photos, { PhotosState } from './photosReducer';
import videos, { VideoState } from './videosReducer';
import archive from './archive';
import { SeasonsState } from './archive/seasonsReducer';

export interface BackendState {
  archive: {
    seasons: SeasonsState;
  };
  articles: ArticlesState;
  categories: CategoriesState;
  competitions: CompetitionsState;
  events: EventsState;
  matches: MatchesState;
  meetings: MeetingsState;
  messages: MessagesState;
  photos: PhotosState;
  players: PlayersState;
  sponsors: SponsorsState;
  teams: TeamsState;
  tournaments: TournamentsState;
  user: UserState;
  users: UsersState;
  videos: VideoState;
}

const backend = combineReducers<BackendState>({
  archive,
  articles,
  categories,
  competitions,
  events,
  matches,
  meetings,
  messages,
  photos,
  players,
  sponsors,
  teams,
  tournaments,
  user,
  users,
  videos,
});

export default backend;

import React, { useState } from 'react';
import { Button, Collapse, Container, Dropdown, NavItem, NavLink } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector } from 'react-redux';

import { AppState } from '../../redux/reducers';
import { ICategoryModel } from '../../models/Category';
import Icon from '../components/IcoMoon';

import { SMenu } from './styled';

const Menu = () => {
  const categories = useSelector<AppState, ICategoryModel[]>((state) => Object.values(state.frontend.categories.items));

  const [open, setOpen] = useState(false);

  return (
    <SMenu fluid className="px-0 px-lg-4">
      <Container>
        <nav className="nav navbar navbar-light navbar-expand-lg d-flex justify-content-between align-items-center">
          <LinkContainer to="/">
            <NavLink className="p-2">
              <Icon icon="home" size={30} className="menu-icon" />
            </NavLink>
          </LinkContainer>
          <Button className="navbar-toggler" aria-controls="navbarSupportedContent" aria-expanded={open} onClick={() => setOpen(!open)}>
            <Icon icon="menu" size={24} className="menu-icon" />
          </Button>
          <Collapse in={open}>
            <div className="navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto">
                <NavItem>
                  <LinkContainer to="/club">
                    <NavLink className="p-2">Klub</NavLink>
                  </LinkContainer>
                </NavItem>
                {categories.map((category) =>
                  category.hasSubCategory ? (
                    <Dropdown key={`menu_category_${category.id}`} as={NavItem}>
                      <Dropdown.Toggle as={NavLink} className="p-2">
                        {category.name}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <LinkContainer to={`/${category.id}/st`}>
                            <NavLink>Starší</NavLink>
                          </LinkContainer>
                          <LinkContainer to={`/${category.id}/ml`}>
                            <NavLink>Mladší</NavLink>
                          </LinkContainer>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <NavItem key={`menu_category_${category.id}`}>
                      <LinkContainer key={`menu_category_${category.id}`} to={`/${category.id}`}>
                        <NavLink className="p-2">{category.name}</NavLink>
                      </LinkContainer>
                    </NavItem>
                  ),
                )}
              </ul>
            </div>
          </Collapse>
          <div className="icons d-flex align-items-center">
            <NavLink className="p-2" href="https://www.facebook.com/groups/201346739902703" target="_blank">
              <Icon icon="facebook" size={20} className="menu-icon" />
            </NavLink>
            <NavLink className="p-2" href="https://www.instagram.com" target="_blank">
              <Icon icon="instagram" size={22} className="menu-icon" />
            </NavLink>
            <NavLink className="p-2" href="https://www.youtube.com/channel/UCOeWnq5UiU5XNEJG4JncuCA" target="_blank">
              <Icon icon="youtube" size={30} className="menu-icon" />
            </NavLink>
          </div>
        </nav>
      </Container>
    </SMenu>
  );
};

export default Menu;

import { Formik, FormikActions } from 'formik';
import * as React from 'react';
import { Col, Form } from 'react-bootstrap';

import { ISeasonModel } from '../../../models/Season';

import validationSchema from './validation';

export interface SeasonFormValues {
  name: string;
}

type Props = {
  id: string;
  data?: ISeasonModel;
  onSubmit: (values: SeasonFormValues, actions: FormikActions<SeasonFormValues>) => void;
};

const SeasonsForm = (props: Props) => {
  const { id, data, onSubmit } = props;
  return (
    <Formik
      initialValues={{
        name: data ? data.name : '',
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values: SeasonFormValues, actions: FormikActions<SeasonFormValues>) => {
        onSubmit(values, actions);
      }}>
      {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
        <Form id={id} noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} controlId="name">
              <Form.Label>Název sezóny</Form.Label>
              <Form.Control
                type="text"
                name="name"
                autoComplete="off"
                placeholder="Zadejte název sezóny"
                value={values.name}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={errors.name && touched.name}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
};

export default SeasonsForm;

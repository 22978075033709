import axios from 'axios';

import { endpoints } from '../../../constants/API';
import {
  FRONTEND_MATCHES_ALL,
  FRONTEND_MATCHES_ERROR,
  FRONTEND_MATCHES_FIRST_AND_LAST,
  FRONTEND_MATCHES_LOADING,
  IAMatchesAll,
  IAMatchesError,
  IAMatchesFirstAndLast,
  IAMatchesLoading,
} from '../types/matchesTypes';
import { Category, Subcategory } from '../../../models/Category';
import { IMatchCategoriesRedux, IMatchSubcategoriesRedux, IMatchModel } from '../../../models/Match';
import { processMatches } from '../helpers/matchesHelper';

export const AMatchesLoading = (name: string): IAMatchesLoading => ({
  type: FRONTEND_MATCHES_LOADING,
  name,
});

const AMatchesError = (name: string, error: Error): IAMatchesError => ({
  type: FRONTEND_MATCHES_ERROR,
  name,
  error,
});

const AMatchesAll = (all: { [key: string]: IMatchModel }, categories: IMatchCategoriesRedux, subcategories: IMatchSubcategoriesRedux): IAMatchesAll => ({
  type: FRONTEND_MATCHES_ALL,
  all,
  categories,
  subcategories,
});

const AMatchesFirstAndLast = (category: Category, subcategory: Subcategory, first: IMatchModel, last: IMatchModel): IAMatchesFirstAndLast => ({
  type: FRONTEND_MATCHES_FIRST_AND_LAST,
  category,
  subcategory,
  first,
  last,
});

export const getMatches = (category?: Category, subcategory?: Subcategory) => async (dispatch) => {
  dispatch(AMatchesLoading('all'));
  try {
    const config = {
      params: {
        category,
        subcategory,
      },
    };
    const response = await axios.get(`${endpoints.matches}`, config);
    const data = response.data;
    const { all, categories, subcategories } = processMatches(data);
    dispatch(AMatchesAll(all, categories, subcategories));
  } catch (e) {
    dispatch(AMatchesError('all', e));
    console.error(e);
  }
};

export const getFirstAndLastMatches = (category: Category, subcategory: Subcategory) => async (dispatch) => {
  dispatch(AMatchesLoading('firstAndLast'));
  try {
    const config = {
      params: {
        category,
        subcategory,
      },
    };
    const response = await axios.get(`${endpoints.matches}/firstAndLast`, config);
    const data = response.data;
    const { first, last } = data;
    dispatch(AMatchesFirstAndLast(category, subcategory, first, last));
  } catch (e) {
    dispatch(AMatchesError('firstAndLast', e));
    console.error(e);
  }
};

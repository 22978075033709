import axios from 'axios';
import { notify } from 'reapop';

import { endpoints } from '../../../constants/API';
import { ITournamentModel } from '../../../models/Tournament';
import {
  TOURNAMENTS_ALL,
  TOURNAMENTS_CREATE,
  TOURNAMENTS_ERROR,
  TOURNAMENTS_LOADING,
  TOURNAMENTS_ONE,
  TOURNAMENTS_REMOVE,
  TOURNAMENTS_UPDATE,
  TournamentsAllAction,
  TournamentsCreateAction,
  TournamentsErrorAction,
  TournamentsLoadingAction,
  TournamentsOneAction,
  TournamentsRemoveAction,
  TournamentsUpdateAction,
} from '../types/tournamentsTypes';
import { Category, Subcategory } from '../../../models/Category';

export const loadingTournaments = (name: string): TournamentsLoadingAction => ({
  type: TOURNAMENTS_LOADING,
  name,
});

const errorTournaments = (name: string, error: Error): TournamentsErrorAction => ({
  type: TOURNAMENTS_ERROR,
  name,
  error,
});

const successTournaments = (tournaments: { [key: number]: ITournamentModel }): TournamentsAllAction => ({
  type: TOURNAMENTS_ALL,
  tournaments,
});

const successTournament = (tournament: ITournamentModel): TournamentsOneAction => ({
  type: TOURNAMENTS_ONE,
  tournament,
});

const successCreateTournament = (tournament: ITournamentModel): TournamentsCreateAction => ({
  type: TOURNAMENTS_CREATE,
  tournament,
});

const successUpdateTournament = (tournament: ITournamentModel): TournamentsUpdateAction => ({
  type: TOURNAMENTS_UPDATE,
  tournament,
});

const successRemoveTournament = (id): TournamentsRemoveAction => ({
  type: TOURNAMENTS_REMOVE,
  tournamentId: id,
});

export const getTournaments =
  (token, category: Category = 'mA', subcategory: Subcategory = undefined) =>
  async (dispatch) => {
    const actionName = 'all';
    dispatch(loadingTournaments(actionName));
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          category,
          subcategory,
        },
      };
      const response = await axios.get(endpoints.tournaments, config);
      const data = response.data;
      const tournaments = {};
      data.forEach((tournament) => {
        tournaments[tournament.id] = tournament;
      });
      dispatch(successTournaments(tournaments));
    } catch (e) {
      dispatch(errorTournaments(actionName, e));
      console.error(e);
    }
  };

export const getTournament = (token: string, tournamentId: string) => async (dispatch) => {
  const actionName = 'one';
  dispatch(loadingTournaments(actionName));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${endpoints.tournaments}/${tournamentId}`, config);
    const tournament = response.data;
    dispatch(successTournament(tournament));
  } catch (e) {
    dispatch(errorTournaments(actionName, e));
    console.error(e);
  }
};

export const createTournament = (token: string, values) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'create';
  dispatch(loadingTournaments(actionName));
  try {
    const response = await axios.post(endpoints.tournaments, values, config);
    const tournament = response.data;
    dispatch(successCreateTournament(tournament));
    dispatch(notify('Turnaj přidán.', 'success'));
  } catch (e) {
    dispatch(errorTournaments(actionName, e));
    dispatch(notify('Turnaj se nepodařilo přidat.', 'error'));
  }
};

export const updateTournament = (token: string, tournamentId: number, values) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'update';
  dispatch(loadingTournaments(actionName));
  try {
    const response = await axios.put(`${endpoints.tournaments}/${tournamentId}`, values, config);
    const tournament = response.data;
    dispatch(successUpdateTournament(tournament));
    dispatch(notify('Turnaj upraven.', 'success'));
  } catch (e) {
    dispatch(errorTournaments(actionName, e));
    dispatch(notify('Turnaj se nepodařilo upravit.', 'error'));
  }
};

export const removeTournament = (token: string, tournamentId: number) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'remove';
  dispatch(loadingTournaments(actionName));
  try {
    await axios.delete(`${endpoints.tournaments}/${tournamentId}`, config);
    dispatch(successRemoveTournament(tournamentId));
    dispatch(notify('Turnaj odstraněn.', 'success'));
  } catch (e) {
    dispatch(errorTournaments(actionName, e));
    dispatch(notify('Turnaj se nepodařilo odstranit.', 'error'));
    console.error(e);
  }
};

import React from 'react';
import { Container } from 'react-bootstrap';

import { SPageContent } from './styled';

const Calendar = () => {
  return (
    <SPageContent className="p-0 my-5">
      <h2>Kalendář</h2>
      <Container className="p-0">
        <iframe
          title="calendar"
          src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FPrague&mode=WEEK&showTitle=0&showNav=1&showDate=1&showPrint=0&showTabs=1&showCalendars=0&showTz=0&src=Zm90YmFsZHVAZ21haWwuY29t&src=YjltZTB2YWJzb3Y5NGhwaGRyNmticzUzNmtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=ZzEzbmMxOWkzMnE4dTV2aGhtdWdlMGdpZTBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=cTJibTE5ZmkyYmRhb3QzcW8wdWUxOXJhdWdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=NDR0NTk5ODBrMmg0OTVyZHFiNXYxNmMxODhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=YWY0dXBoaThlMTA5MW4yMnRhbzc0Y29jYTRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=MWM3czA0a3I5bTZkOTZscjVsNmY3NHYxOXNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=YzkwamxhYmk1Z3RkOGR1c3E3MThydGJocm9AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%233F51B5&color=%23D50000&color=%23F09300&color=%237CB342&color=%237986CB&color=%23795548&color=%23F6BF26&color=%230B8043"
          width="100%"
          height="600"
          frameBorder="0"
          scrolling="no"
        />
      </Container>
    </SPageContent>
  );
};

export default Calendar;

import React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import cs from 'date-fns/locale/cs';
import { format } from 'date-fns';
import { Form } from 'react-bootstrap';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('cs', cs);

interface Props {
  name: string;
  placeholder: string;
  showTime?: boolean;
  dateFormat?: string;
  value: string;
  disabled: boolean;
  isInvalid: boolean;
  onChange: (value: Date) => void;
  onBlur: (e: React.FocusEvent) => void;
}

const DatePicker = (props: Props) => {
  const { name, placeholder, showTime, dateFormat, value, disabled, isInvalid, onChange, onBlur } = props;
  return (
    <>
      <ReactDatePicker
        customInput={
          <Form.Control
            type="text"
            value={value ? format(new Date(value), 'dd/MM/yyyy') : undefined}
            autoComplete="off"
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            isInvalid={isInvalid}
          />
        }
        locale="cs"
        autoComplete="off"
        dateFormat={dateFormat || 'dd.MM.yyyy'}
        showTimeSelect={showTime}
        name={name}
        selected={value ? new Date(value) : undefined}
        placeholderText={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />
      <Form.Control
        type="hidden"
        value={value ? format(new Date(value), 'dd/MM/yyyy') : undefined}
        autoComplete="off"
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        isInvalid={isInvalid}
      />
    </>
  );
};

export default DatePicker;

import * as React from 'react';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, Container } from 'react-bootstrap';

import TournamentListContainer from './TournamentListContainer';
import TournamentFormContainer from './TournamentFormContainer';

const TournamentsContainer = () => {
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <Container fluid className="p-0">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item active={location.pathname === match.path}>
          {location.pathname === match.path ? 'Turnaje' : <Link to={match.path}>Turnaje</Link>}
        </Breadcrumb.Item>
        {location.pathname.includes('/detail') && <Breadcrumb.Item active>Detail turnaje</Breadcrumb.Item>}
        {location.pathname.includes('/add') && <Breadcrumb.Item active>Nový turnaj</Breadcrumb.Item>}
        {location.pathname.includes('/edit') && <Breadcrumb.Item active>Úprava turnaje</Breadcrumb.Item>}
      </Breadcrumb>
      <Switch>
        <Route exact path={`${match.path}/:category/add`} component={TournamentFormContainer} />
        <Route exact path={`${match.path}/:category/edit/:id`} component={TournamentFormContainer} />
        <Route exact path={`${match.path}/:category`} component={TournamentListContainer} />
        <Route exact path={`${match.path}/:category/:subcategory/add`} component={TournamentFormContainer} />
        <Route exact path={`${match.path}/:category/:subcategory/edit/:id`} component={TournamentFormContainer} />
        <Route exact path={`${match.path}/:category/:subcategory`} component={TournamentListContainer} />
        <Route exact path={match.path} component={TournamentListContainer} />
      </Switch>
    </Container>
  );
};

export default TournamentsContainer;

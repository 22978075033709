import React from 'react';

import { Category, Subcategory } from '../../../models/Category';

import { STablesAll } from './styled';
import TableShort from './TableShort';

type Props = {
  category: Category;
  subcategory?: Subcategory;
};

const TablesCategory = (props: Props) => {
  const { category, subcategory } = props;

  return (
    <STablesAll>
      <h3>Tabulka</h3>
      <TableShort category={category} subcategory={subcategory} />
    </STablesAll>
  );
};

export default TablesCategory;

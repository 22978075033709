import React, { useEffect, useMemo } from 'react';
import { Container, Nav, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../../redux/reducers';
import { IMessageModel } from '../../../../models/Message';
import { getMessages } from '../../../redux/actions/messagesActions';
import Message from '../../../components/Messages/Message';

import { SPageContent, SNoData } from './styled';

const Messages = () => {
  const loadingMessages = useSelector<AppState, boolean>((state) => state.frontend.messages.loadings.all);
  const messages = useSelector<AppState, { [key: string]: IMessageModel }>((state) => state.frontend.messages.items);
  // const loadingReports = useSelector<AppState, boolean>(state => state.frontend.messages.loadings.all);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loadingMessages) {
      dispatch(getMessages());
    }
  }, []);

  const sortedMessages = useMemo(() => Object.values(messages).sort((a, b) => b.id - a.id), [messages]);

  return (
    <SPageContent className="p-0 my-5">
      <h2>Zprávy z týmu</h2>
      <Container className="p-0">
        <Tab.Container id="messages" defaultActiveKey="messages">
          <div className="d-flex justify-content-center align-items-center">
            <Nav variant="tabs" className="d-flex flex-row">
              <Nav.Item>
                <Nav.Link className="tab" eventKey="messages">
                  Zprávy z týmu
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="tab" eventKey="committeeReports">
                  Zprávy z výboru
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="messages">
              {sortedMessages.map((message) => (
                <Message key={`message_${message.id}`} messageId={message.id} />
              ))}
            </Tab.Pane>
            <Tab.Pane eventKey="committeeReports">
              <SNoData>Tato sekce se připravuje</SNoData>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
    </SPageContent>
  );
};

export default Messages;

import React, { useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Container, Image } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { images } from '../../constants/assets';
import ClubPage from '../containers/ClubPages/ClubPage';
import HomePage from '../containers/HomePage';
import { AppState } from '../../redux/reducers';
import { ICategoryModel } from '../../models/Category';
import { getCategories } from '../redux/actions/categoriesActions';
import CategoryPage from '../containers/CategoryPages/CategoryPage';
import { getCompetitions } from '../redux/actions/competitionsActions';
import { getSponsors } from '../redux/actions/sponsorsActions';
import Sponsors from '../components/Sponsors';

import Menu from './Menu';
import { SFooter, SHeader, SMain } from './styled';

const Frontend = () => {
  const categoryItems = useSelector<AppState, { [key: string]: ICategoryModel }>((state) => state.frontend.categories.items);
  const categories = Object.values(categoryItems);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!categories.length) {
      dispatch(getCategories());
      dispatch(getCompetitions());
      dispatch(getSponsors());
    }
  }, []);

  if (!categories.length) return null;

  const categoriesWithSubcategories = categories.filter((c) => c.hasSubCategory);
  const categoriesWithoutSubcategories = categories.filter((c) => !c.hasSubCategory);

  return (
    <div id="frontend">
      <SHeader>
        <Container fluid className="top">
          <Container className="px-0 px-lg-4">
            <header className="header py-3">
              <LinkContainer to="/">
                <Image src={images.frontend.logo} className="image" />
              </LinkContainer>
              <div className="title">
                <h1>SOKOL DOLNÍ ÚJEZD</h1>
                <h2>OFICIÁLNÍ STRÁNKY FOTBALOVÉHO KLUBU</h2>
              </div>
            </header>
          </Container>
        </Container>
        <Menu />
      </SHeader>
      <SMain role="main" className="pb-5">
        <Switch>
          <Route path="/club" component={ClubPage} />
          {categoriesWithSubcategories.map((category) => (
            <Route key={`route_${category.id}_st`} path={`/${category.id}/st`} component={() => <CategoryPage category={category.id} subcategory="st" />} />
          ))}
          {categoriesWithSubcategories.map((category) => (
            <Route key={`route_${category.id}_ml`} path={`/${category.id}/ml`} component={() => <CategoryPage category={category.id} subcategory="ml" />} />
          ))}
          {categoriesWithoutSubcategories.map((category) => (
            <Route key={`route_${category.id}`} path={`/${category.id}`} component={() => <CategoryPage category={category.id} />} />
          ))}
          <Route path="/" component={HomePage} />
        </Switch>
      </SMain>
      <Sponsors />
      <SFooter className="footer">
        <Container className="footer-content">
          <p className="copyright">TJ Sokol Dolní Újezd © {new Date().getFullYear()}. Všechna práva vyhrazena.</p>
          <p className="authors">Design by krekrrr.cz | Coding by František Rožek</p>
        </Container>
      </SFooter>
    </div>
  );
};

export default Frontend;

import { IArticleModel, IArticleBaseModel, IArticleCategoriesRedux, IArticleSubcategoriesRedux } from '../../../models/Article';

export const processArticles = (data: Array<IArticleModel>) => {
  const all: { [key: string]: IArticleModel } = {};
  const categories: IArticleCategoriesRedux = {};
  const subcategories: IArticleSubcategoriesRedux = {};
  data.forEach((article: IArticleModel) => {
    all[article.id] = article;
    if (article.category && article.subcategory) {
      if (!subcategories[article.category]) {
        subcategories[article.category] = {};
      }
      if (!subcategories[article.category][article.subcategory]) {
        subcategories[article.category][article.subcategory] = {};
      }
      subcategories[article.category][article.subcategory][article.id] = {
        id: article.id,
        date: article.date,
      };
    } else {
      if (!categories[article.category]) {
        categories[article.category] = {};
      }
      categories[article.category][article.id] = {
        id: article.id,
        date: article.date,
      };
    }
  });

  return {
    all,
    categories,
    subcategories,
  };
};

export const processLastArticles = (data: Array<IArticleModel>) => {
  const all: { [key: string]: IArticleModel } = {};
  const categories: { [key: string]: Array<IArticleBaseModel> } = {};
  const last: Array<IArticleBaseModel> = [];
  data.forEach((article: IArticleModel) => {
    all[article.id] = article;
    if (!categories[article.category]) {
      categories[article.category] = [];
    }
    categories[article.category].push({
      id: article.id,
      date: article.date,
    });
    last.push({
      id: article.id,
      date: article.date,
    });
  });

  return {
    all,
    categories,
    last,
  };
};

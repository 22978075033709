import { config } from 'dotenv';

config();

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const ASSETS_URL = process.env.REACT_APP_ASSETS_URL;
export const API_URL = process.env.REACT_APP_REST_API_URL;
export const GRAPHQL = process.env.REACT_APP_GRAPHQL_API_URL;

export const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;

import { VIDEOS_ALL, VIDEOS_ERROR, VIDEOS_LOADING, VIDEOS_ONE, VIDEOS_CREATE, VIDEOS_REMOVE, VIDEOS_UPDATE, VideoActionTypes } from '../types/videosTypes';
import { IVideoModel } from '../../../models/Video';

export interface VideoState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error | undefined };
  items: { [key: string]: IVideoModel };
}

const initialState: VideoState = {
  loadings: {},
  errors: {},
  items: {},
};

const videos = (currentState: VideoState = initialState, action: VideoActionTypes): VideoState => {
  let newState;
  switch (action.type) {
    case VIDEOS_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case VIDEOS_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case VIDEOS_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        errors: {
          ...currentState.errors,
          all: undefined,
        },
        items: {
          ...currentState.items,
          ...action.videos,
        },
      };
    case VIDEOS_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        errors: {
          ...currentState.errors,
          one: undefined,
        },
        items: {
          ...currentState.items,
          [action.video.id]: action.video,
        },
      };
    case VIDEOS_CREATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          create: false,
        },
        errors: {
          ...currentState.errors,
          create: undefined,
        },
        items: {
          ...currentState.items,
          [action.video.id]: action.video,
        },
      };
    case VIDEOS_UPDATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          update: false,
        },
        errors: {
          ...currentState.errors,
          update: undefined,
        },
        items: {
          ...currentState.items,
          [action.video.id]: {
            ...currentState.items[action.video.id],
            ...action.video,
          },
        },
      };
    case VIDEOS_REMOVE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          remove: false,
        },
        errors: {
          ...currentState.errors,
          remove: undefined,
        },
        items: {
          ...currentState.items,
        },
      };
      if (newState.items[action.videoId]) delete newState.items[action.videoId];
      return newState;
    default:
      return currentState;
  }
};

export default videos;

import React, { useEffect, useMemo } from 'react';
import { Container, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { getMatches } from '../../../redux/actions/matchesActions';
import { AppState } from '../../../../redux/reducers';
import { IMatchModel } from '../../../../models/Match';
import ScheduleMatch from '../../../components/Matches/ScheduleMatch';
import Loading from '../../../components/Loading';

import { SPageContent, SNoData } from './styled';

const Schedule = () => {
  moment.locale('cs');

  const loading = useSelector<AppState>((state) => state.frontend.matches.loadings.all);
  const matches = useSelector<AppState>((state) => state.frontend.matches.items);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getMatches());
    }
  }, []);

  const schedule: { [key: string]: any } = useMemo(() => {
    const schedule = {};
    for (const match of Object.values(matches)) {
      if (new Date(match.date) < new Date()) continue;
      if (!schedule[match.date])
        schedule[match.date] = {
          date: match.date,
          mA: undefined,
          mB: undefined,
          do: undefined,
          sz: undefined,
          mz: undefined,
          spr: undefined,
          mpr: undefined,
        };

      let category;
      switch (match.category) {
        case 'za':
          switch (match.subcategory) {
            case 'st':
              category = 'sz';
              break;
            default:
              category = 'mz';
              break;
          }
          break;
        case 'pr':
          switch (match.subcategory) {
            case 'st':
              category = 'spr';
              break;
            default:
              category = 'mpr';
              break;
          }
          break;
        default:
          category = match.category;
          break;
      }
      schedule[match.date][category] = match;
    }
    return schedule;
  }, [matches]);

  return (
    <SPageContent className="p-0 my-5">
      <h2>Rozpis zápasů</h2>
      <Container className="p-0">
        {Object.keys(schedule).length ? (
          <Table>
            <thead>
              <tr>
                <th>Datum</th>
                <th>Muži A</th>
                <th>Muži B</th>
                <th>Dorost</th>
                <th>St. žáci</th>
                <th>Ml. žáci</th>
                <th>St. přípravka</th>
                <th>Ml. přípravka</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(schedule)
                .sort((a, b) => {
                  const [aKey] = a;
                  const [bKey] = b;

                  const aDate = new Date(aKey);
                  const bDate = new Date(bKey);

                  if (aDate < bDate) return -1;
                  else if (aDate > bDate) return 1;
                  return 0;
                })
                .map(
                  ([key, value]: [
                    string,
                    { mA?: IMatchModel; mB?: IMatchModel; do?: IMatchModel; sz?: IMatchModel; mz?: IMatchModel; spr?: IMatchModel; mpr?: IMatchModel },
                  ]) => (
                    <tr key={key}>
                      <th>{moment(key).format('dd DD.MM.').toUpperCase()}</th>
                      <td className={value.mA && value.mA.home.name.includes('Dolní Újezd') ? 'home' : 'away'}>
                        {value.mA ? <ScheduleMatch match={value.mA} /> : null}
                      </td>
                      <td className={value.mB && value.mB.home.name.includes('Dolní Újezd') ? 'home' : 'away'}>
                        {value.mB ? <ScheduleMatch match={value.mB} /> : null}
                      </td>
                      <td className={value.do && value.do.home.name.includes('Dolní Újezd') ? 'home' : 'away'}>
                        {value.do ? <ScheduleMatch match={value.do} /> : null}
                      </td>
                      <td className={value.sz && value.sz.home.name.includes('Dolní Újezd') ? 'home' : 'away'}>
                        {value.sz ? <ScheduleMatch match={value.sz} /> : null}
                      </td>
                      <td className={value.mz && value.mz.home.name.includes('Dolní Újezd') ? 'home' : 'away'}>
                        {value.mz ? <ScheduleMatch match={value.mz} /> : null}
                      </td>
                      <td className={value.spr && value.spr.home.name.includes('Dolní Újezd') ? 'home' : 'away'}>
                        {value.spr ? <ScheduleMatch match={value.spr} /> : null}
                      </td>
                      <td className={value.mpr && value.mpr.home.name.includes('Dolní Újezd') ? 'home' : 'away'}>
                        {value.mpr ? <ScheduleMatch match={value.mpr} /> : null}
                      </td>
                    </tr>
                  ),
                )}
            </tbody>
          </Table>
        ) : loading ? (
          <Loading text="Načítají se data pro rozpis utkání" />
        ) : (
          <SNoData>Žádné nadcházející zápasy k dispozici</SNoData>
        )}
      </Container>
    </SPageContent>
  );
};

export default Schedule;

import * as React from 'react';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, Container } from 'react-bootstrap';

import TeamListContainer from './TeamListContainer';
import TeamFormContainer from './TeamFormContainer';
import TeamImageContainer from './TeamImageContainer';

const TeamsContainer = () => {
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <Container fluid className="p-0">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item active={location.pathname === match.path}>
          {location.pathname === match.path ? 'Týmy' : <Link to={match.path}>Týmy</Link>}
        </Breadcrumb.Item>
        {location.pathname.includes('/detail') && <Breadcrumb.Item active>Detail Týmu</Breadcrumb.Item>}
        {location.pathname.includes('/add') && <Breadcrumb.Item active>Nový tým</Breadcrumb.Item>}
        {location.pathname.includes('/edit') && <Breadcrumb.Item active>Úprava týmu</Breadcrumb.Item>}
        {location.pathname.includes('/image') && <Breadcrumb.Item active>Změna loga</Breadcrumb.Item>}
      </Breadcrumb>
      <Switch>
        <Route exact path={`${match.path}/:category/add`} component={TeamFormContainer} />
        <Route exact path={`${match.path}/:category/edit/:id`} component={TeamFormContainer} />
        <Route exact path={`${match.path}/:category/image/:id`} component={TeamImageContainer} />
        <Route exact path={`${match.path}/:category`} component={TeamListContainer} />
        <Route exact path={`${match.path}/:category/:subcategory/add`} component={TeamFormContainer} />
        <Route exact path={`${match.path}/:category/:subcategory/edit/:id`} component={TeamFormContainer} />
        <Route exact path={`${match.path}/:category/:subcategory/image/:id`} component={TeamImageContainer} />
        <Route exact path={`${match.path}/:category/:subcategory`} component={TeamListContainer} />
        <Route exact path={match.path} component={TeamListContainer} />
      </Switch>
    </Container>
  );
};

export default TeamsContainer;

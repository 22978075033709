import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

import { AppState } from '../../../redux/reducers';
import { Category, ICategoryModel, Subcategory } from '../../../models/Category';
import CategoryForm from '../../forms/CategoryForm';
import { createCategory, getCategory, updateCategory } from '../../redux/actions/categoriesActions';

const CategoriesFormContainer = () => {
  const history = useHistory();
  const match = useRouteMatch<{ id: string; category: Category; subcategory?: Subcategory }>();
  const { id } = match.params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loadingAction = useSelector<AppState, boolean>((state) => state.backend.categories.loadings.create || state.backend.categories.loadings.update);
  const error = useSelector<AppState, Error | undefined>((state) => state.backend.categories.errors.create || state.backend.categories.errors.update);
  const loading = useSelector<AppState, boolean>((state) => state.backend.categories.loadings.one);
  const category = useSelector<AppState, ICategoryModel>((state) => state.backend.categories.items[id]);

  const dispatch = useDispatch();

  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (!loading && !category && id) {
      dispatch(getCategory(token, id));
    }
  }, []);

  useEffect(() => {
    if (isSent) {
      if (!loadingAction && !error) {
        setIsSent(false);
        history.push(`/admin/categories`);
      }
    }
  }, [isSent, loadingAction, error, history]);

  const handleAdd = (values, { setSubmitting }) => {
    dispatch(createCategory(token, values));
    setSubmitting(false);
    setIsSent(true);
  };

  const handleUpdate = (values, { setSubmitting }) => {
    dispatch(updateCategory(token, category.id, values));
    setSubmitting(false);
    setIsSent(true);
  };

  return (
    <Container className="p-0">
      <CategoryForm data={category} onSubmit={category ? handleUpdate : handleAdd} />
    </Container>
  );
};

export default CategoriesFormContainer;

import React from 'react';
import moment from 'moment';
import renderHTML from 'react-render-html';

import Icon from '../IcoMoon';
import { ITournamentModel } from '../../../models/Tournament';

import { STournament, SNoTournamentData } from './styles';

type Props = {
  tournament: ITournamentModel;
};

const Tournament = (props: Props) => {
  const { tournament } = props;

  if (!tournament) return <SNoTournamentData>Bohoužel nemáme žádné informace o následujícím turnaji</SNoTournamentData>;

  return (
    <STournament>
      <div className="tournament">
        <div className="name">{tournament.name}</div>
        <div className="info">
          <div>{moment(tournament.date).format('dd')}</div>
          <div>{moment(tournament.date).format('D. M.')}</div>
          <div>{tournament.time}</div>
        </div>
      </div>
      {tournament.info && (
        <div className="info">
          <Icon icon="info" size={20} />
          {renderHTML(tournament.info)}
        </div>
      )}
    </STournament>
  );
};

export default Tournament;

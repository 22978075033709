import {
  MESSAGES_ALL,
  MESSAGES_ERROR,
  MESSAGES_LOADING,
  MESSAGES_ONE,
  MESSAGES_CREATE,
  MESSAGES_REMOVE,
  MESSAGES_UPDATE,
  MessageActionTypes,
} from '../types/messagesTypes';
import { IMessageModel } from '../../../models/Message';

export interface MessagesState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error | undefined };
  items: { [key: string]: IMessageModel };
}

const initialState: MessagesState = {
  loadings: {},
  errors: {},
  items: {},
};

const messages = (currentState: MessagesState = initialState, action: MessageActionTypes): MessagesState => {
  let newState;
  switch (action.type) {
    case MESSAGES_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case MESSAGES_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case MESSAGES_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        errors: {
          ...currentState.errors,
          all: undefined,
        },
        items: {
          ...currentState.items,
          ...action.messages,
        },
      };
    case MESSAGES_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        errors: {
          ...currentState.errors,
          one: undefined,
        },
        items: {
          ...currentState.items,
          [action.message.id]: action.message,
        },
      };
    case MESSAGES_CREATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          create: false,
        },
        errors: {
          ...currentState.errors,
          create: undefined,
        },
        items: {
          ...currentState.items,
          [action.message.id]: action.message,
        },
      };
    case MESSAGES_UPDATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          update: false,
        },
        errors: {
          ...currentState.errors,
          update: undefined,
        },
        items: {
          ...currentState.items,
          [action.message.id]: {
            ...currentState.items[action.message.id],
            ...action.message,
          },
        },
      };
    case MESSAGES_REMOVE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          remove: false,
        },
        errors: {
          ...currentState.errors,
          remove: undefined,
        },
        items: {
          ...currentState.items,
        },
      };
      if (newState.items[action.messageId]) delete newState.items[action.messageId];
      return newState;
    default:
      return currentState;
  }
};

export default messages;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../redux/reducers';
import { IArticleBaseModel } from '../../../models/Article';
import { getLastArticles } from '../../redux/actions/articlesActions';
import { Category, Subcategory } from '../../../models/Category';

import { SLastArticles, SNoData } from './styled';
import ArticlePreview from './ArticlePreview';

type Props = {
  category: Category;
  subcategory?: Subcategory;
};

const LastArticlesCategory = (props: Props) => {
  const { category, subcategory } = props;

  const loading = useSelector<AppState>((state) => state.frontend.articles.loadings.last);
  const lastArticles = useSelector<AppState, Array<IArticleBaseModel>>((state) => state.frontend.articles.categories[category]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && !lastArticles) {
      dispatch(getLastArticles(category, subcategory, 0, 2));
    }
  }, []);

  const articles = lastArticles
    ? Object.values(lastArticles)
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        .slice(0, 2)
    : [];

  return (
    <SLastArticles>
      <h2>Aktuality</h2>
      {articles.length ? (
        articles.map((article: IArticleBaseModel) => <ArticlePreview key={`article_${article.id}`} articleId={article.id} />)
      ) : (
        <SNoData className="d-flex mt-5">
          <p>Žádné články k zobrazení</p>
        </SNoData>
      )}
    </SLastArticles>
  );
};

export default LastArticlesCategory;

import React, { useEffect, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Category, Subcategory } from '../../../models/Category';
import { AppState } from '../../../redux/reducers';
import { createPlayer, getPlayer, updatePlayer } from '../../redux/actions/playersActions';
import PlayerForm from '../../forms/PlayerForm';
import { IPlayerModel } from '../../../models/Player';

const PhotoFormContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch<{ id: string; category: Category; subcategory?: Subcategory }>();
  const { id, category, subcategory } = params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loadingAction = useSelector<AppState, boolean>((state) => state.backend.players.loadings.create || state.backend.players.loadings.update);
  const error = useSelector<AppState, Error | undefined>((state) => state.backend.players.errors.create || state.backend.players.errors.update);
  const loading = useSelector<AppState, boolean>((state) => state.backend.players.loadings.one);
  const player = useSelector<AppState, IPlayerModel>((state) => state.backend.players.items[id]);

  const dispatch = useDispatch();

  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (!loading && id) {
      dispatch(getPlayer(token, id));
    }
  }, []);

  useEffect(() => {
    if (isSent) {
      if (!loadingAction && !error) {
        setIsSent(false);
        history.push(`/admin/players/${category}${subcategory ? `/${subcategory}` : ''}`);
      }
    }
  }, [isSent, loadingAction, error, category, subcategory, history]);

  const handleAdd = (values, { setSubmitting }) => {
    dispatch(createPlayer(token, values));
    setSubmitting(false);
    setIsSent(true);
  };

  const handleEdit = (values, { setSubmitting }) => {
    dispatch(updatePlayer(token, player.id, values));
    setSubmitting(false);
    setIsSent(true);
  };
  return (
    <Container className="p-0">
      <Card>
        <Card.Header>{`Formulář pro ${player ? 'úpravu' : 'přidání'} hráče`}</Card.Header>
        <Card.Body>
          <PlayerForm id="player-form" data={player} onSubmit={player ? handleEdit : handleAdd} />
        </Card.Body>
        <Card.Footer>
          <Button type="submit" form="player-form" disabled={isSent} className="float-right" variant="primary">
            {`${player ? 'Upravit' : 'Přidat'} hráče`}
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default PhotoFormContainer;

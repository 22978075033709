import styled from 'styled-components';

import { colors } from '../../layout/styles';

export const STablesAll = styled.div`
  padding: 30px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.09);

  h3 {
    font-family: 'Nunito Sans';
    font-size: 30px;
    font-weight: 900;
    text-transform: uppercase;
    color: ${colors.blue};
    text-align: center;
    margin-bottom: 30px;
  }

  .nav-tabs {
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px ${colors.grey} solid;
    .nav-item {
      justify-content: center;
      align-items: center;
    }
    .tab {
      font-family: Nunito Sans;
      font-weight: 900;
      font-size: 16px;
      color: ${colors.grey};
      border: none;
      padding: 0 0.5rem;

      &.active {
        color: ${colors.blue};
        border-bottom: 4px ${colors.orange} solid;
      }

      &:hover {
        color: ${colors.blue};
      }
    }
  }
`;

export const STable = styled.div`
  margin: 50px 0px;
  table {
    font-family: Nunito Sans;
    text-align: center;
    thead {
      font-weight: 900;
      color: ${colors.orange};
    }
    tbody {
      font-weight: 600;
      color: ${colors.blue};
    }
    tr.myTeam {
      color: ${colors.orange};
      font-weight: 800;
      border-bottom: 2px ${colors.orange} solid;
    }
    th,
    td {
      &.left {
        text-align: left;
      }
      &.bold {
        font-weight: 900;
      }
    }
  }
`;

export const SNoData = styled.div`
  display: flex;
  flex: 1;
  height: 90px;
  justify-content: center;
  align-items: center;
  font-family: Nunito Sans;
  color: ${colors.blue};
`;

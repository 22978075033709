import axios from 'axios';

import { endpoints } from '../../../constants/API';
import {
  FRONTEND_TOURNAMENTS_ALL,
  FRONTEND_TOURNAMENTS_ERROR,
  FRONTEND_TOURNAMENTS_FIRST_AND_LAST,
  FRONTEND_TOURNAMENTS_LOADING,
  IATournamentsAll,
  IATournamentsError,
  IATournamentsFirstAndLast,
  IATournamentsLoading,
} from '../types/tournamentsTypes';
import { Category, Subcategory } from '../../../models/Category';
import { ITournamentCategoriesRedux, ITournamentSubcategoriesRedux, ITournamentModel } from '../../../models/Tournament';
import { processTournaments } from '../helpers/tournamentsHelper';

export const ATournamentsLoading = (name: string): IATournamentsLoading => ({
  type: FRONTEND_TOURNAMENTS_LOADING,
  name,
});

const ATournamentsError = (name: string, error: Error): IATournamentsError => ({
  type: FRONTEND_TOURNAMENTS_ERROR,
  name,
  error,
});

const ATournamentsAll = (
  all: { [key: string]: ITournamentModel },
  categories: ITournamentCategoriesRedux,
  subcategories: ITournamentSubcategoriesRedux,
): IATournamentsAll => ({
  type: FRONTEND_TOURNAMENTS_ALL,
  all,
  categories,
  subcategories,
});

const ATournamentsFirstAndLast = (
  category: Category,
  subcategory: Subcategory,
  first: ITournamentModel,
  last: ITournamentModel,
): IATournamentsFirstAndLast => ({
  type: FRONTEND_TOURNAMENTS_FIRST_AND_LAST,
  category,
  subcategory,
  first,
  last,
});

export const getTournaments = (category?: Category, subcategory?: Subcategory) => async (dispatch) => {
  dispatch(ATournamentsLoading('all'));
  try {
    const config = {
      params: {
        category,
        subcategory,
      },
    };
    const response = await axios.get(`${endpoints.tournaments}`, config);
    const data = response.data;
    const { all, categories, subcategories } = processTournaments(data);
    dispatch(ATournamentsAll(all, categories, subcategories));
  } catch (e) {
    dispatch(ATournamentsError('all', e));
    console.error(e);
  }
};

export const getFirstAndLastTournaments = (category: Category, subcategory: Subcategory) => async (dispatch) => {
  dispatch(ATournamentsLoading('firstAndLast'));
  try {
    const config = {
      params: {
        category,
        subcategory,
      },
    };
    const response = await axios.get(`${endpoints.tournaments}/firstAndLast`, config);
    const data = response.data;
    const { first, last } = data;
    dispatch(ATournamentsFirstAndLast(category, subcategory, first, last));
  } catch (e) {
    dispatch(ATournamentsError('firstAndLast', e));
    console.error(e);
  }
};

import React, { useEffect, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Category, Subcategory } from '../../../models/Category';
import { AppState } from '../../../redux/reducers';
import { createTournament, getTournament, updateTournament } from '../../redux/actions/tournamentsActions';
import { ITournamentModel } from '../../../models/Tournament';
import TournamentForm from '../../forms/TournamentForm';

const TournamentFormContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch<{ id: string; category: Category; subcategory?: Subcategory }>();
  const { id, category, subcategory } = params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loadingAction = useSelector<AppState, boolean>((state) => state.backend.tournaments.loadings.create || state.backend.tournaments.loadings.update);
  const error = useSelector<AppState, Error | undefined>((state) => state.backend.tournaments.errors.create || state.backend.tournaments.errors.update);
  const loading = useSelector<AppState, boolean>((state) => state.backend.tournaments.loadings.one);
  const tournament = useSelector<AppState, ITournamentModel>((state) => state.backend.tournaments.items[id]);

  const dispatch = useDispatch();

  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (!loading && id) {
      dispatch(getTournament(token, id));
    }
  }, []);

  useEffect(() => {
    if (isSent) {
      if (!loadingAction && !error) {
        setIsSent(false);
        history.push(`/admin/tournaments/${category}${subcategory ? `/${subcategory}` : ''}`);
      }
    }
  }, [isSent, loadingAction, error, category, subcategory, history]);

  const handleAdd = (values, { setSubmitting }) => {
    dispatch(createTournament(token, values));
    setSubmitting(false);
    setIsSent(true);
  };

  const handleEdit = (values, { setSubmitting }) => {
    dispatch(updateTournament(token, tournament.id, values));
    setSubmitting(false);
    setIsSent(true);
  };
  return (
    <Container className="p-0">
      <Card>
        <Card.Header>{`Formulář pro ${tournament ? 'úpravu' : 'přidání'} turnaje`}</Card.Header>
        <Card.Body>
          <TournamentForm id="tournament-form" data={tournament} onSubmit={tournament ? handleEdit : handleAdd} />
        </Card.Body>
        <Card.Footer>
          <Button type="submit" form="tournament-form" disabled={isSent} className="float-right" variant="primary">
            {`${tournament ? 'Upravit' : 'Přidat'} turnaj`}
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default TournamentFormContainer;

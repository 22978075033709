import React from 'react';
import { Badge, Col, Container, ListGroup, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

import { IGalleryModel } from '../../../models/Photo';
import { arrayToChunks } from '../../../utils';

import Photo from './Photo';

type Props = {
  galleries: Array<IGalleryModel>;
  gallery?: IGalleryModel;
  onSelectGallery: (gallery: IGalleryModel) => void;
  onSelectPhoto: (photoId: number) => void;
};

const ListView = (props: Props) => {
  const { galleries, gallery, onSelectGallery, onSelectPhoto } = props;

  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const count = isBigScreen ? 6 : 4;

  return (
    <Container fluid className="p-0">
      {!gallery ? (
        <ListGroup>
          {galleries.map((gallery) => (
            <ListGroup.Item key={`gallery_${gallery.id}`} action onClick={() => onSelectGallery(gallery)}>
              <Badge variant="dark" style={{ position: 'absolute', right: '1rem' }}>
                {gallery.photos.length}
              </Badge>
              {gallery.name}
            </ListGroup.Item>
          ))}
        </ListGroup>
      ) : (
        arrayToChunks(gallery.photos, count).map((chunk, index) => (
          <Row key={`photos_row_${index}`} className="mb-4">
            {chunk.map((photo) => (
              <Col key={`photo_${photo.id}`} className="d-flex justify-content-center align-items-center" style={{ maxHeight: '200px' }}>
                <Photo photo={photo} onSelect={onSelectPhoto} />
              </Col>
            ))}
            {chunk.length < count ? [...new Array(count - chunk.length)].map((_, ei) => <Col key={`empty_col_${ei}`} />) : null}
          </Row>
        ))
      )}
    </Container>
  );
};

export default ListView;

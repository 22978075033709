import { UserModel } from '../../../models/User';
import * as localStorage from '../../../storage/localStorage';
import * as sessionStorage from '../../../storage/sessionStorage';
import { LOADING_USER, LOGIN_USER, LOGOUT_USER, USER_ME, UserActionTypes } from '../types/userTypes';

export interface UserState {
  token: string;
  userData: UserModel | null;
  loading: boolean;
}

const initialState: UserState = {
  token: sessionStorage.loadState('FDU:token') || localStorage.loadState('FDU:token'),
  userData: null,
  loading: false,
};

const user = (currentState = initialState, action: UserActionTypes): UserState => {
  let newState;
  switch (action.type) {
    case LOADING_USER:
      newState = {
        ...currentState,
        loading: true,
      };
      return newState;
    case LOGIN_USER:
      newState = {
        ...currentState,
        userData: action.userData,
        token: action.token,
        loading: false,
      };
      return newState;
    case LOGOUT_USER:
      newState = {
        ...currentState,
        token: null,
        userData: null,
      };
      return newState;
    case USER_ME:
      newState = {
        ...currentState,
        userData: {
          ...currentState.userData,
          ...action.userData,
        },
        loading: false,
      };
      return newState;
    default:
      return currentState;
  }
};

export default user;

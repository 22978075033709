import * as React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router-dom';
import {
  faBinoculars,
  faCalendar,
  faCogs,
  faEnvelope,
  faFutbol,
  faHandshake,
  faImages,
  faUser,
  faUsers,
  faUserShield,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper } from '@fortawesome/free-regular-svg-icons';
import { NavLink } from 'react-bootstrap';
import { faTrophy } from '@fortawesome/free-solid-svg-icons/faTrophy';
import { faListOl } from '@fortawesome/free-solid-svg-icons/faListOl';
import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons/faMoneyBillWave';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';

import User from '../components/User';

const Sidebar = () => {
  return (
    <div id="layoutSidenav_nav">
      <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
        <div className="sb-sidenav-menu">
          <div className="nav">
            <LinkContainer to="/admin/dashboard">
              <NavLink>
                <div className="sb-nav-link-icon">
                  <FontAwesomeIcon icon={faBinoculars} />
                </div>
                <span>Přehled</span>
              </NavLink>
            </LinkContainer>
            <div className="sb-sidenav-menu-heading">Konfigurace</div>
            <LinkContainer to="/admin/categories">
              <NavLink>
                <div className="sb-nav-link-icon">
                  <FontAwesomeIcon icon={faCogs} />
                </div>
                <span>Kategorie</span>
              </NavLink>
            </LinkContainer>
            <LinkContainer to="/admin/competitions">
              <NavLink>
                <div className="sb-nav-link-icon">
                  <FontAwesomeIcon icon={faTrophy} />
                </div>
                <span>Soutěže</span>
              </NavLink>
            </LinkContainer>
            <LinkContainer to="/admin/users">
              <NavLink>
                <div className="sb-nav-link-icon">
                  <FontAwesomeIcon icon={faUserShield} />
                </div>
                <span>Správci</span>
              </NavLink>
            </LinkContainer>
            <div className="sb-sidenav-menu-heading">Obsah - Klub</div>
            <LinkContainer to="/admin/meetings">
              <NavLink>
                <div className="sb-nav-link-icon">
                  <FontAwesomeIcon icon={faHandshake} />
                </div>
                <span>Schůze</span>
              </NavLink>
            </LinkContainer>
            <LinkContainer to="/admin/sponsors">
              <NavLink>
                <div className="sb-nav-link-icon">
                  <FontAwesomeIcon icon={faMoneyBillWave} />
                </div>
                <span>Sponzoři</span>
              </NavLink>
            </LinkContainer>
            <LinkContainer to="/admin/events">
              <NavLink>
                <div className="sb-nav-link-icon">
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </div>
                <span>Události</span>
              </NavLink>
            </LinkContainer>
            <LinkContainer to="/admin/messages">
              <NavLink>
                <div className="sb-nav-link-icon">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <span>Zprávy z týmu</span>
              </NavLink>
            </LinkContainer>
            <div className="sb-sidenav-menu-heading">Obsah - Kategorie</div>
            <LinkContainer to="/admin/articles">
              <NavLink>
                <div className="sb-nav-link-icon">
                  <FontAwesomeIcon icon={faNewspaper} />
                </div>
                <span>Články</span>
              </NavLink>
            </LinkContainer>
            <LinkContainer to="/admin/photos">
              <NavLink>
                <div className="sb-nav-link-icon">
                  <FontAwesomeIcon icon={faImages} />
                </div>
                <span>Fotky</span>
              </NavLink>
            </LinkContainer>
            <LinkContainer to="/admin/players">
              <NavLink>
                <div className="sb-nav-link-icon">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <span>Hráči</span>
              </NavLink>
            </LinkContainer>
            <LinkContainer to="/admin/tables">
              <NavLink>
                <div className="sb-nav-link-icon">
                  <FontAwesomeIcon icon={faListOl} />
                </div>
                <span>Tabulky</span>
              </NavLink>
            </LinkContainer>
            <LinkContainer to="/admin/tournaments">
              <NavLink>
                <div className="sb-nav-link-icon">
                  <FontAwesomeIcon icon={faTrophy} />
                </div>
                <span>Turnaje</span>
              </NavLink>
            </LinkContainer>
            <LinkContainer to="/admin/teams">
              <NavLink>
                <div className="sb-nav-link-icon">
                  <FontAwesomeIcon icon={faUsers} />
                </div>
                <span>Týmy</span>
              </NavLink>
            </LinkContainer>
            <LinkContainer to="/admin/videos">
              <NavLink>
                <div className="sb-nav-link-icon">
                  <FontAwesomeIcon icon={faVideo} />
                </div>
                <span>Videa</span>
              </NavLink>
            </LinkContainer>
            <LinkContainer to="/admin/matches">
              <NavLink>
                <div className="sb-nav-link-icon">
                  <FontAwesomeIcon icon={faFutbol} />
                </div>
                <span>Zápasy</span>
              </NavLink>
            </LinkContainer>
            <div className="sb-sidenav-menu-heading">Archiv</div>
            <LinkContainer to="/admin/archive/seasons">
              <NavLink>
                <div className="sb-nav-link-icon">
                  <FontAwesomeIcon icon={faCalendar} />
                </div>
                <span>Sezóny</span>
              </NavLink>
            </LinkContainer>
          </div>
        </div>
        <div className="sb-sidenav-footer">
          <div className="small">Přihlášen:</div>
          <User showName />
        </div>
      </nav>
    </div>
  );
};

export default withRouter(Sidebar);

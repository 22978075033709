import axios from 'axios';
import { notify } from 'reapop';

import { endpoints } from '../../../constants/API';
import { IEventModel } from '../../../models/Event';
import {
  EVENTS_LOADING,
  EVENTS_ERROR,
  EVENTS_ALL,
  EVENTS_ONE,
  EVENTS_REMOVE,
  EVENTS_UPDATE,
  EVENTS_CREATE,
  IAEventsLoading,
  IAEventsError,
  IAEventsAll,
  IAEventsOne,
  IAEventsCreate,
  IAEventsUpdate,
  IAEventsRemove,
} from '../types/eventsTypes';
import { ASSETS_URL } from '../../../constants';

export const AEventsLoading = (name: string): IAEventsLoading => ({
  type: EVENTS_LOADING,
  name,
});

const AEventsError = (name: string, error: Error): IAEventsError => ({
  type: EVENTS_ERROR,
  name,
  error,
});

const AEventsAll = (events: { [key: number]: IEventModel }): IAEventsAll => ({
  type: EVENTS_ALL,
  events,
});

const AEventsOne = (event: IEventModel): IAEventsOne => ({
  type: EVENTS_ONE,
  event,
});

const AEventsCreate = (event: IEventModel): IAEventsCreate => ({
  type: EVENTS_CREATE,
  event,
});

const AEventsUpdate = (event: IEventModel): IAEventsUpdate => ({
  type: EVENTS_UPDATE,
  event,
});

const AEventsRemove = (id): IAEventsRemove => ({
  type: EVENTS_REMOVE,
  eventId: id,
});

export const getEvents = (token) => async (dispatch) => {
  const actionName = 'all';
  dispatch(AEventsLoading(actionName));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(endpoints.events, config);
    const data = response.data;
    const events = {};

    for (const event of data) {
      events[event.id] = {
        ...event,
      };
    }
    dispatch(AEventsAll(events));
  } catch (e) {
    dispatch(AEventsError(actionName, e));
    console.error(e);
  }
};

export const getEvent = (token: string, eventId: string) => async (dispatch) => {
  const actionName = 'one';
  dispatch(AEventsLoading(actionName));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${endpoints.events}/${eventId}`, config);
    const event = {
      ...response.data,
      hasImage: (await fetch(`${ASSETS_URL}/events/${eventId}.png`, { method: 'HEAD' })).ok,
    };
    dispatch(AEventsOne(event));
  } catch (e) {
    dispatch(AEventsError(actionName, e));
    console.error(e);
  }
};

export const createEvent = (token: string, values) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'create';
  dispatch(AEventsLoading(actionName));
  try {
    const response = await axios.post(endpoints.events, values, config);
    const event = response.data;
    dispatch(AEventsCreate(event));
    dispatch(notify('Událost přidána.', 'success'));
  } catch (e) {
    dispatch(AEventsError(actionName, e));
    dispatch(notify('Událost se nepodařilo přidat.', 'error'));
  }
};

export const updateEvent = (token: string, eventId: number, values) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'update';
  dispatch(AEventsLoading(actionName));
  try {
    const response = await axios.put(`${endpoints.events}/${eventId}`, values, config);
    const event = response.data;
    dispatch(AEventsUpdate(event));
    dispatch(notify('Událost upravena.', 'success'));
  } catch (e) {
    dispatch(AEventsError(actionName, e));
    dispatch(notify('Událost se nepodařilo upravit.', 'error'));
  }
};

export const removeEvent = (token: string, eventId: number) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'remove';
  dispatch(AEventsLoading(actionName));
  try {
    await axios.delete(`${endpoints.events}/${eventId}`, config);
    dispatch(AEventsRemove(eventId));
    dispatch(notify('Událost odstraněna.', 'success'));
  } catch (e) {
    dispatch(AEventsError(actionName, e));
    dispatch(notify('Událost se nepodařilo odstranit.', 'error'));
    console.error(e);
  }
};

export const updateEventFile = (token: string, eventId: number, file: File) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'updateFile';
  dispatch(AEventsLoading(actionName));
  try {
    const fileUpload = new FormData();
    fileUpload.append('photo', file, file.name);
    await axios.put(`${endpoints.events}/${eventId}/photo`, fileUpload, config);
    dispatch(notify('Soubor události upraven.', 'success'));
  } catch (e) {
    dispatch(AEventsError(actionName, e));
    dispatch(notify('Soubor události se nepodařilo upravit.', 'error'));
    console.error(e);
  }
};

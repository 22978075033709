import {
  TEAMS_ALL,
  TEAMS_CREATE,
  TEAMS_ERROR,
  TEAMS_LOADING,
  TEAMS_ONE,
  TEAMS_REMOVE,
  TEAMS_TABLE,
  TEAMS_TABLE_UPDATE,
  TEAMS_UPDATE,
  TeamsActionTypes,
} from '../types/teamsTypes';
import { ITeamModel, TableModel } from '../../../models/Team';

export interface TeamsState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error | undefined };
  items: { [key: string]: ITeamModel };
  tables: { [key: string]: { [key: string]: TableModel } | TableModel };
}

const initialState: TeamsState = {
  loadings: {},
  errors: {},
  items: {},
  tables: {},
};

const teams = (currentState: TeamsState = initialState, action: TeamsActionTypes): TeamsState => {
  let newState;
  switch (action.type) {
    case TEAMS_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case TEAMS_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case TEAMS_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        errors: {
          ...currentState.errors,
          all: undefined,
        },
        items: {
          ...currentState.items,
          ...action.teams,
        },
      };
    case TEAMS_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        errors: {
          ...currentState.errors,
          one: undefined,
        },
        items: {
          ...currentState.items,
          [action.team.id]: action.team,
        },
      };
    case TEAMS_TABLE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          table: false,
        },
        errors: {
          ...currentState.errors,
          table: undefined,
        },
        tables: {
          ...currentState.tables,
        },
      };
      if (action.subcategory) {
        if (!newState.tables[action.category]) newState.tables[action.category] = {};
        newState.tables[action.category][action.subcategory] = action.table;
      } else {
        newState.tables[action.category] = action.table;
      }
      return newState;
    case TEAMS_TABLE_UPDATE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          updateTable: false,
        },
        errors: {
          ...currentState.errors,
          updateTable: undefined,
        },
        tables: {
          ...currentState.tables,
        },
      };
      if (action.subcategory) {
        if (!newState.tables[action.category]) newState.tables[action.category] = {};
        newState.tables[action.category][action.subcategory] = action.table;
      } else {
        newState.tables[action.category] = action.table;
      }
      return newState;
    case TEAMS_CREATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          create: false,
        },
        errors: {
          ...currentState.errors,
          create: undefined,
        },
        items: {
          ...currentState.items,
          [action.team.id]: action.team,
        },
      };
    case TEAMS_UPDATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          update: false,
        },
        errors: {
          ...currentState.errors,
          update: undefined,
        },
        items: {
          ...currentState.items,
          [action.team.id]: {
            ...currentState.items[action.team.id],
            ...action.team,
          },
        },
      };
    case TEAMS_REMOVE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          remove: false,
        },
        errors: {
          ...currentState.errors,
          remove: undefined,
        },
        items: {
          ...currentState.items,
        },
      };
      if (newState.items[action.teamId]) delete newState.items[action.teamId];
      return newState;
    default:
      return currentState;
  }
};

export default teams;

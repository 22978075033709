import React from 'react';
import { Route, Switch } from 'react-router-dom';

import About from '../About';
import Messages from '../Mesages';
import Schedule from '../Schedule';
import Calendar from '../Calendar';
import Events from '../Events';
import Archive from '../Archive';
import Photos from '../Photos';
import Sponsors from '../Sponsors';
import SponsorDetail from '../Sponsors/SponsorDetail';
import VideosPreview from '../VideosPreview';
import Videos from '../Videos';
import PhotosPreview from '../PhotosPreview';

import { SPageContent, SCategoryName } from './styled';
import Submenu from './Submenu';

const ClubPage = () => {
  return (
    <SPageContent className="p-0">
      <div className="d-flex py-5 justify-content-between align-items-center">
        <SCategoryName>Klub</SCategoryName>
      </div>
      <Submenu />
      <Switch>
        <Route path="/club/about" component={() => <About />} />
        <Route path="/club/messages" component={() => <Messages />} />
        <Route path="/club/schedule" component={() => <Schedule />} />
        <Route path="/club/calendar" component={() => <Calendar />} />
        <Route path="/club/events" component={() => <Events />} />
        <Route path="/club/photos/:id" component={() => <PhotosPreview />} />
        <Route path="/club/photos" component={() => <Photos />} />
        <Route path="/club/videos/:id" component={() => <VideosPreview />} />
        <Route path="/club/videos" component={() => <Videos />} />
        <Route path="/club/sponsors/:id" component={() => <SponsorDetail />} />
        <Route path="/club/sponsors" component={() => <Sponsors />} />
        <Route path="/club/archive" component={() => <Archive />} />
        <Route path="/" component={() => <About />} />
      </Switch>
    </SPageContent>
  );
};

export default ClubPage;

import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import SeasonsContainer from './SeasonsContainer';

const ArticlesContainer = () => {
  const match = useRouteMatch();

  return (
    <Container fluid className="p-0">
      <Switch>
        <Route path={`${match.path}/seasons`} component={SeasonsContainer} />
      </Switch>
    </Container>
  );
};

export default ArticlesContainer;

import { SPONSORS_ALL, SPONSORS_ERROR, SPONSORS_LOADING, SponsorsActionTypes } from '../types/sponsorsTypes';

export interface ISponsorsState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error | undefined };
  items: {};
}

const initialState: ISponsorsState = {
  loadings: {},
  errors: {},
  items: {},
};

const sponsors = (currentState: ISponsorsState = initialState, action: SponsorsActionTypes): ISponsorsState => {
  switch (action.type) {
    case SPONSORS_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case SPONSORS_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case SPONSORS_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        errors: {
          ...currentState.errors,
          all: undefined,
        },
        items: {
          ...currentState.items,
          ...action.sponsors,
        },
      };
    default:
      return currentState;
  }
};

export default sponsors;

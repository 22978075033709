import { Formik, FormikActions } from 'formik';
import * as React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { Category, Subcategory } from '../../../models/Category';
import { IPlayerModel } from '../../../models/Player';

import validationSchema from './validation';

export interface PlayerFormValues {
  firstname: string;
  surname: string;
  positionId: string;
  category: Category;
  subcategory?: Subcategory;
}

type Props = {
  id: string;
  data?: IPlayerModel;
  onSubmit: (values: PlayerFormValues, actions: FormikActions<PlayerFormValues>) => void;
};

const PlayerForm = (props: Props) => {
  const { id, data, onSubmit } = props;
  const params = useParams<{ category: Category; subcategory: Subcategory }>();

  return (
    <Formik
      initialValues={{
        firstname: data ? data.firstname : '',
        surname: data ? data.surname : '',
        positionId: data ? data.positionId.toString() : '',
        category: params.category,
        subcategory: params.subcategory,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values: PlayerFormValues, actions: FormikActions<PlayerFormValues>) => {
        onSubmit(values, actions);
      }}>
      {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
        <Form id={id} noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Jméno hráče</Form.Label>
              <Form.Control
                type="text"
                name="firstname"
                placeholder="Zadejte jméno hráče"
                value={values.firstname}
                disabled={isSubmitting}
                isInvalid={errors.firstname && touched.firstname}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.firstname}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Příjmení hráče</Form.Label>
              <Form.Control
                type="text"
                name="surname"
                placeholder="Zadejte příjmení hráče"
                value={values.surname}
                disabled={isSubmitting}
                isInvalid={errors.surname && touched.surname}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.surname}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Pozice hráče</Form.Label>
              <Form.Control
                name="positionId"
                as="select"
                value={values.positionId}
                disabled={isSubmitting}
                isInvalid={errors.positionId && touched.positionId}
                onChange={handleChange}
                onBlur={handleBlur}>
                <option value="">Vyberte pozici hráče</option>
                <option value="1">brankář</option>
                <option value="2">obránce</option>
                <option value="3">záložník</option>
                <option value="4">útočník</option>
                <option value="5">trenér</option>
                <option value="6">vedoucí mužstva</option>
                <option value="7">ostatní</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.positionId}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
};

export default PlayerForm;

import { IMatchModel } from '../../../models/Match';

export const MATCHES_LOADING = 'MATCHES_LOADING';
export const MATCHES_ERROR = 'MATCHES_ERROR';
export const MATCHES_ALL = 'MATCHES_ALL';
export const MATCHES_ONE = 'MATCHES_ONE';
export const MATCHES_CREATE = 'MATCHES_CREATE';
export const MATCHES_UPDATE = 'MATCHES_UPDATE';
export const MATCHES_REMOVE = 'MATCHES_REMOVE';

export interface MatchesLoadingAction {
  type: typeof MATCHES_LOADING;
  name: string;
}

export interface MatchesErrorAction {
  type: typeof MATCHES_ERROR;
  name: string;
  error: Error;
}

export interface MatchesAllAction {
  type: typeof MATCHES_ALL;
  matches: { [key: number]: IMatchModel };
}

export interface MatchesOneAction {
  type: typeof MATCHES_ONE;
  match: IMatchModel;
}

export interface MatchesCreateAction {
  type: typeof MATCHES_CREATE;
  match: IMatchModel;
}

export interface MatchesUpdateAction {
  type: typeof MATCHES_UPDATE;
  match: IMatchModel;
}

export interface MatchesRemoveAction {
  type: typeof MATCHES_REMOVE;
  matchId: string;
}

export type MatchesActionTypes =
  | MatchesErrorAction
  | MatchesLoadingAction
  | MatchesAllAction
  | MatchesOneAction
  | MatchesCreateAction
  | MatchesUpdateAction
  | MatchesRemoveAction;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import renderHTML from 'react-render-html';

import { AppState } from '../../../../redux/reducers';
import { IArticleModel } from '../../../../models/Article';
import { getArticle } from '../../../redux/actions/articlesActions';

import { SPageContent } from './styled';

const CategoryArticlesPreview = () => {
  const match = useRouteMatch<{ id: string }>();

  const articleId = match.params.id;

  const loading = useSelector<AppState, boolean>((state) => state.frontend.articles.loadings.one);
  const article = useSelector<AppState, IArticleModel>((state) => state.frontend.articles.items[articleId]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getArticle(articleId));
    }
  }, []);

  if (!article) return null;

  return (
    <SPageContent className="p-0 my-5">
      <h2>{article.title}</h2>
      {article.roster ? (
        <p>
          <b>Sestava: </b>
          {article.roster}
        </p>
      ) : null}
      {article.goals ? (
        <p>
          <b>Branky: </b>
          {article.goals}
        </p>
      ) : null}
      {article.content ? renderHTML(article.content) : null}
      {article.author ? <p className="text-right font-italic">{article.author}</p> : null}
    </SPageContent>
  );
};

export default CategoryArticlesPreview;

import * as React from 'react';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, Container } from 'react-bootstrap';

import { Category, Subcategory } from '../../../models/Category';

import TablePreviewContainer from './TablePreviewContainer';
import TableFormContainer from './TableFormContainer';

const TablesContainer = () => {
  const match = useRouteMatch<{ category: Category; subcategory: Subcategory }>();
  const location = useLocation();
  let { category, subcategory } = match.params;

  if (!category) category = 'mA';

  return (
    <Container fluid className="p-0">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item active={location.pathname === match.path}>
          {location.pathname === match.path ? 'Tabulky' : <Link to={match.path}>Tabulky</Link>}
        </Breadcrumb.Item>
        {location.pathname === `${match.path}/${category}${subcategory ? `/${subcategory}` : ''}/edit` && (
          <Breadcrumb.Item active={true}>Úprava tabulky</Breadcrumb.Item>
        )}
      </Breadcrumb>
      <Switch>
        <Route exact path={`${match.path}/:category/edit`} component={TableFormContainer} />
        <Route exact path={`${match.path}/:category`} component={TablePreviewContainer} />
        <Route exact path={`${match.path}/:category/:subcategory/edit`} component={TableFormContainer} />
        <Route exact path={`${match.path}/:category/:subcategory`} component={TablePreviewContainer} />
        <Route exact path={match.path} component={TablePreviewContainer} />
      </Switch>
    </Container>
  );
};

export default TablesContainer;

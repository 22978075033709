import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { useRouteMatch } from 'react-router-dom';
import moment from 'moment';

import { AppState } from '../../../redux/reducers';
import { IGalleryModel, IPhotoModel } from '../../../models/Photo';
import { getPhotos } from '../../redux/actions/photosActions';
import { SERVER_URL } from '../../../constants';
import { Category, Subcategory } from '../../../models/Category';

import { SGallery, SGalleryDate } from './styled';

type Props = {
  galleryId: string;
};

const Gallery = (props: Props) => {
  const match = useRouteMatch<{ category: Category; subcategory: Subcategory }>();

  const { galleryId } = props;

  const loading = useSelector<AppState, boolean>((state) => state.frontend.photos.loadings.photos);
  const gallery = useSelector<AppState, IGalleryModel>((state) => state.frontend.photos.items[galleryId]);
  const photos = useSelector<AppState, Array<IPhotoModel>>((state) => state.frontend.photos.items[galleryId].photos || []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && !photos.length) {
      dispatch(getPhotos(galleryId));
    }
  }, []);

  if (!gallery.photos || !gallery.photos.length) return null;

  return (
    <LinkContainer to={`${match.path}/${galleryId}`}>
      <SGallery>
        <SGalleryDate>
          <span>{moment(gallery.date).fromNow()}</span>
        </SGalleryDate>
        <div className="photo" style={{ backgroundImage: `url('${SERVER_URL}${gallery.photos[0].url}')` }} />
        <h4>{gallery.name}</h4>
      </SGallery>
    </LinkContainer>
  );
};

export default Gallery;

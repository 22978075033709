import axios from 'axios';
import { notify } from 'reapop';

import { endpoints } from '../../../constants/API';
import { IMeetingModel } from '../../../models/Meeting';
import {
  MEETINGS_LOADING,
  MEETINGS_ERROR,
  MEETINGS_ALL,
  MEETINGS_ONE,
  MEETINGS_REMOVE,
  MEETINGS_UPDATE,
  MEETINGS_CREATE,
  IAMeetingsLoading,
  IAMeetingsError,
  IAMeetingsAll,
  IAMeetingsOne,
  IAMeetingsCreate,
  IAMeetingsUpdate,
  IAMeetingsRemove,
} from '../types/meetingsTypes';
import { ASSETS_URL } from '../../../constants';

export const AMeetingsLoading = (name: string): IAMeetingsLoading => ({
  type: MEETINGS_LOADING,
  name,
});

const AMeetingsError = (name: string, error: Error): IAMeetingsError => ({
  type: MEETINGS_ERROR,
  name,
  error,
});

const AMeetingsAll = (meetings: { [key: number]: IMeetingModel }): IAMeetingsAll => ({
  type: MEETINGS_ALL,
  meetings,
});

const AMeetingsOne = (meeting: IMeetingModel): IAMeetingsOne => ({
  type: MEETINGS_ONE,
  meeting,
});

const AMeetingsCreate = (meeting: IMeetingModel): IAMeetingsCreate => ({
  type: MEETINGS_CREATE,
  meeting,
});

const AMeetingsUpdate = (meeting: IMeetingModel): IAMeetingsUpdate => ({
  type: MEETINGS_UPDATE,
  meeting,
});

const AMeetingsRemove = (id): IAMeetingsRemove => ({
  type: MEETINGS_REMOVE,
  meetingId: id,
});

export const getMeetings = (token) => async (dispatch) => {
  const actionName = 'all';
  dispatch(AMeetingsLoading(actionName));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(endpoints.meetings, config);
    const data = response.data;
    const meetings = {};

    for (const meeting of data) {
      meetings[meeting.id] = {
        ...meeting,
      };
    }
    dispatch(AMeetingsAll(meetings));
  } catch (e) {
    dispatch(AMeetingsError(actionName, e));
    console.error(e);
  }
};

export const getMeeting = (token: string, meetingId: string) => async (dispatch) => {
  const actionName = 'one';
  dispatch(AMeetingsLoading(actionName));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${endpoints.meetings}/${meetingId}`, config);
    const meeting = {
      ...response.data,
      hasImage: (await fetch(`${ASSETS_URL}/meetings/${meetingId}.png`, { method: 'HEAD' })).ok,
    };
    dispatch(AMeetingsOne(meeting));
  } catch (e) {
    dispatch(AMeetingsError(actionName, e));
    console.error(e);
  }
};

export const createMeeting = (token: string, values) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'create';
  dispatch(AMeetingsLoading(actionName));
  try {
    const response = await axios.post(endpoints.meetings, values, config);
    const meeting = response.data;
    dispatch(AMeetingsCreate(meeting));
    dispatch(notify('Schůzka přidána.', 'success'));
  } catch (e) {
    dispatch(AMeetingsError(actionName, e));
    dispatch(notify('Schůzku se nepodařilo přidat.', 'error'));
  }
};

export const updateMeeting = (token: string, meetingId: number, values) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'update';
  dispatch(AMeetingsLoading(actionName));
  try {
    const response = await axios.put(`${endpoints.meetings}/${meetingId}`, values, config);
    const meeting = response.data;
    dispatch(AMeetingsUpdate(meeting));
    dispatch(notify('Schůzka upravena.', 'success'));
  } catch (e) {
    dispatch(AMeetingsError(actionName, e));
    dispatch(notify('Schůzku se nepodařilo upravit.', 'error'));
  }
};

export const removeMeeting = (token: string, meetingId: number) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'remove';
  dispatch(AMeetingsLoading(actionName));
  try {
    await axios.delete(`${endpoints.meetings}/${meetingId}`, config);
    dispatch(AMeetingsRemove(meetingId));
    dispatch(notify('Schůzka odstraněna.', 'success'));
  } catch (e) {
    dispatch(AMeetingsError(actionName, e));
    dispatch(notify('Schůzku se nepodařilo odstranit.', 'error'));
    console.error(e);
  }
};

export const updateMeetingFile = (token: string, meetingId: number, file: File) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'updateFile';
  dispatch(AMeetingsLoading(actionName));
  try {
    const fileUpload = new FormData();
    fileUpload.append('photo', file, file.name);
    await axios.put(`${endpoints.meetings}/${meetingId}/photo`, fileUpload, config);
    dispatch(notify('Soubor ke schůzce upravena.', 'success'));
  } catch (e) {
    dispatch(AMeetingsError(actionName, e));
    dispatch(notify('Soubor ke schůzce se nepodařilo upravit.', 'error'));
    console.error(e);
  }
};

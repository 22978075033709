import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';

import { Category, Subcategory } from '../../../models/Category';
import { AppState } from '../../../redux/reducers';
import { getTable } from '../../redux/actions/tablesActions';

import { STable, SNoData } from './styled';

type Props = {
  category: Category;
  subcategory?: Subcategory;
};

const TableShort = (props: Props) => {
  const { category, subcategory } = props;

  const loading = useSelector((state: AppState) => state.frontend.tables.loadings.table);
  const table = useSelector((state: AppState) => (state.frontend.tables.categories[category] ? state.frontend.tables.categories[category].table : undefined));
  const table_sub = useSelector((state: AppState) =>
    subcategory && state.frontend.tables.subcategories[category] && state.frontend.tables.subcategories[category][subcategory]
      ? state.frontend.tables.subcategories[category][subcategory].table
      : undefined,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getTable(category, subcategory));
    }
  }, []);

  const tableToRender = subcategory ? table_sub : table;

  return (
    <STable>
      {tableToRender && tableToRender.length ? (
        <Table borderless size="sm">
          <thead>
            <tr>
              <td className="left">#</td>
              <td className="left">Tým</td>
              <td>Z</td>
              <td>SKÓRE</td>
              <td>B</td>
            </tr>
          </thead>
          <tbody>
            {tableToRender.map((team) => (
              <tr key={`team_${team.id}`} className={team.name.includes('Dolní Újezd') ? 'myTeam' : ''}>
                <td className="left bold">{team.order}.</td>
                <td className="left">{team.name}</td>
                <td>{team.numberOfMatches}</td>
                <td>
                  {team.goalsScored}:{team.goalsReceived}
                </td>
                <td className="bold">{team.points}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <SNoData>
          <p>Žádná tabulka k zobrazení</p>
        </SNoData>
      )}
    </STable>
  );
};

export default TableShort;

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Card, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useEffect, useMemo, useState } from 'react';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';
import moment from 'moment';

import Table from '../../components/Table';
import { IEventModel } from '../../../models/Event';
import { getEvents, removeEvent } from '../../redux/actions/eventsActions';
import { AppState } from '../../../redux/reducers';

const EventListContainer = () => {
  const history = useHistory();
  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const events = useSelector<AppState, { [key: string]: IEventModel }>((state) => state.backend.events.items);
  const loading = useSelector<AppState, boolean>((state) => state.backend.events.loadings.all);
  const loadingRemove = useSelector<AppState, boolean>((state) => state.backend.events.loadings.remove);
  const errorRemove = useSelector<AppState, Error | undefined>((state) => state.backend.events.errors.remove);

  const filteredEvents = useMemo(() => Object.values(events).sort((a, b) => new Date(b.start).getTime() - new Date(a.start).getTime()), [events]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getEvents(token));
    }
  }, []);

  useEffect(() => {
    if (!loadingRemove && !errorRemove) {
      setShowModal(false);
    }
  }, [loadingRemove, errorRemove]);

  const handleToggleDeleteModal = (event?: IEventModel) => {
    setEvent(event);
    setShowModal(!showModal);
  };

  const handleRemove = () => {
    dispatch(removeEvent(token, event!.id));
  };

  const OptionCell = (row) => (
    <div className="d-flex justify-content-end">
      <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-edit">Upravit událost</Tooltip>}>
        {({ ref, ...triggerHandler }) => (
          <Button ref={ref} variant="outline-dark" className="mr-2" {...triggerHandler} onClick={() => history.push(`/admin/events/edit/${row.original.id}`)}>
            <FontAwesomeIcon icon={faEdit} style={{ cursor: 'pointer' }} />
          </Button>
        )}
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-delete">Smazat událost</Tooltip>}>
        {({ ref, ...triggerHandler }) => (
          <Button ref={ref} variant="danger" {...triggerHandler} onClick={() => handleToggleDeleteModal(row.original)}>
            <FontAwesomeIcon icon={faTrash} style={{ cursor: 'pointer' }} />
          </Button>
        )}
      </OverlayTrigger>
    </div>
  );

  const columns: Column[] = [
    {
      id: 'title',
      Header: 'Název',
      accessor: (d: IEventModel) => d.title,
    },
    {
      id: 'start',
      Header: 'Začátek',
      accessor: (d: IEventModel) => d.start,
      sortMethod: (a, b) => {
        const aTime = new Date(a).getTime();
        const bTime = new Date(b).getTime();
        return aTime - bTime;
      },
      Cell: (row) => moment(row.value).format('ddd DD.MM.YYYY HH:mm').toLocaleUpperCase(),
    },
    {
      id: 'end',
      Header: 'Konec',
      accessor: (d: IEventModel) => d.end,
      sortMethod: (a, b) => {
        const aTime = new Date(a).getTime();
        const bTime = new Date(b).getTime();
        return aTime - bTime;
      },
      Cell: (row) => moment(row.value).format('ddd DD.MM.YYYY HH:mm').toLocaleUpperCase(),
    },
    {
      id: 'options',
      Header: 'Možnosti',
      Cell: OptionCell,
    },
  ];

  const options = {
    loadingText: 'Načítám události...',
    noDataText: 'Žádné události k zobrazení',
  };

  const [showModal, setShowModal] = useState(false);
  const [event, setEvent] = useState<IEventModel | undefined>();

  return (
    <Container fluid className="p-0">
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <FontAwesomeIcon icon={faTable} />
              <span className="ml-2">Seznam událostí</span>
            </div>
            <Button color="primary" onClick={() => history.push(`/admin/events/add`)}>
              <FontAwesomeIcon icon={faPlus} />
              <span className="ml-2">Přidat Událost</span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Table
            columns={columns}
            data={filteredEvents}
            options={{
              loading,
              ...options,
            }}
          />
        </Card.Body>
        <Card.Footer />
      </Card>
      {event && (
        <Modal show={showModal} onHide={handleToggleDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Smazání události</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`Opravdu chcete smazat událost: ${event.title}?`}</p>
            <p>Pořádně si to rozmyslete. Tato akce je nenávratná!</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleToggleDeleteModal()}>
              Zrušit
            </Button>
            <Button variant="danger" onClick={() => handleRemove()}>
              Potvrdit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default EventListContainer;

import React, { useEffect, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Category, Subcategory } from '../../../models/Category';
import { AppState } from '../../../redux/reducers';
import { getTable, updateTable } from '../../redux/actions/teamsActions';
import { TableModel } from '../../../models/Team';
import TableForm from '../../forms/TableForm';

const TableFormContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch<{ id: string; category: Category; subcategory?: Subcategory }>();
  const { category, subcategory } = params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loading = useSelector<AppState, boolean>((state) => state.backend.teams.loadings.table);
  const loadingAction = useSelector<AppState, boolean>((state) => state.backend.teams.loadings.updateTable);
  const error = useSelector<AppState, Error | undefined>((state) => state.backend.teams.errors.updateTable);
  const table = useSelector<AppState, Array<TableModel>>((state) =>
    subcategory ? state.backend.teams.tables[category][subcategory] : state.backend.teams.tables[category],
  );

  const dispatch = useDispatch();

  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (!loading) {
      dispatch(getTable(token, category, subcategory));
    }
  }, []);

  useEffect(() => {
    if (isSent) {
      if (!loadingAction && !error) {
        setIsSent(false);
        history.push(`/admin/tables/${category}${subcategory ? `/${subcategory}` : ''}`);
      }
    }
  }, [isSent, loadingAction, error, category, subcategory, history]);

  const handleEdit = (values, { setSubmitting }) => {
    dispatch(updateTable(token, category, subcategory, values));
    setSubmitting(false);
    setIsSent(true);
  };

  return (
    <Container className="p-0">
      <Card>
        <Card.Header>Formulář pro úpravu tabulky</Card.Header>
        <Card.Body>
          <TableForm id="table-form" data={table} onSubmit={handleEdit} />
        </Card.Body>
        <Card.Footer>
          <Button type="submit" form="table-form" disabled={isSent} className="float-right" variant="primary">
            Upravit tabulku
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default TableFormContainer;

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';

import { createArticle, getArticle, updateArticle } from '../../redux/actions/articlesActions';
import { IArticleModel } from '../../../models/Article';
import ArticleForm from '../../forms/ArticleForm';
import { AppState } from '../../../redux/reducers';
import { Category, Subcategory } from '../../../models/Category';

const ArticleFormContainer = () => {
  const history = useHistory();
  const match = useRouteMatch<{ id: string; category: Category; subcategory?: Subcategory }>();
  const { id, category, subcategory } = match.params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loadingAction = useSelector<AppState, boolean>((state) => state.backend.articles.loadings.create || state.backend.articles.loadings.update);
  const error = useSelector<AppState, Error | undefined>((state) => state.backend.articles.errors.create || state.backend.articles.errors.update);
  const loading = useSelector<AppState, boolean>((state) => state.backend.articles.loadings.one);
  const article = useSelector<AppState, IArticleModel>((state) => state.backend.articles.items[id]);

  const dispatch = useDispatch();

  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (!loading && id) {
      dispatch(getArticle(token, id));
    }
  }, []);

  useEffect(() => {
    if (isSent) {
      if (!loadingAction && !error) {
        setIsSent(false);
        history.push(`/admin/articles/${category}${subcategory ? `/${subcategory}` : ''}`);
      }
    }
  }, [isSent, loadingAction, error, category, subcategory, history]);

  const handleAdd = (values, { setSubmitting }) => {
    const data = {
      ...values,
      matchId: values.matchId !== '' ? values.matchId : undefined,
    };
    dispatch(createArticle(token, data));
    setSubmitting(false);
    setIsSent(true);
  };

  const handleEdit = (values, { setSubmitting }) => {
    const data = {
      ...values,
      matchId: values.matchId !== '' ? values.matchId : undefined,
    };
    dispatch(updateArticle(token, article.id, data));
    setSubmitting(false);
    setIsSent(true);
  };

  return (
    <Container className="p-0">
      <Card>
        <Card.Header>{`Formulář pro ${article ? 'úpravu' : 'přidání'} článku`}</Card.Header>
        <Card.Body>
          <ArticleForm id="article-form" data={article} onSubmit={article ? handleEdit : handleAdd} />
        </Card.Body>
        <Card.Footer>
          <Button type="submit" form="article-form" disabled={isSent} className="float-right" color="primary">
            {`${article ? 'Upravit' : 'Přidat'} článek`}
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default ArticleFormContainer;

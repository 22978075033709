import React, { useEffect, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Category, Subcategory } from '../../../models/Category';
import { AppState } from '../../../redux/reducers';
import { createVideo, getVideo, updateVideo } from '../../redux/actions/videosActions';
import VideoForm from '../../forms/VideoForm';
import { IVideoModel } from '../../../models/Video';

const VideoFormContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch<{ id: string; category: Category; subcategory?: Subcategory }>();
  const { id, category, subcategory } = params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loadingAction = useSelector<AppState, boolean>((state) => state.backend.videos.loadings.create || state.backend.videos.loadings.update);
  const error = useSelector<AppState, Error | undefined>((state) => state.backend.videos.errors.create || state.backend.videos.errors.update);
  const loading = useSelector<AppState, boolean>((state) => state.backend.videos.loadings.one);
  const video = useSelector<AppState, IVideoModel>((state) => state.backend.videos.items[id]);

  const dispatch = useDispatch();

  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (!loading && id) {
      dispatch(getVideo(token, id));
    }
  }, []);

  useEffect(() => {
    if (isSent) {
      if (!loadingAction && !error) {
        setIsSent(false);
        history.push(`/admin/videos/${category}${subcategory ? `/${subcategory}` : ''}`);
      }
    }
  }, [loadingAction, error]);

  const handleAdd = (values, { setSubmitting }) => {
    dispatch(createVideo(token, values));
    setSubmitting(false);
    setIsSent(true);
  };

  const handleEdit = (values, { setSubmitting }) => {
    dispatch(updateVideo(token, video.id, values));
    setSubmitting(false);
    setIsSent(true);
  };

  return (
    <Container className="p-0">
      <Card>
        <Card.Header>{`Formulář pro ${video ? 'úpravu' : 'přidání'} videa`}</Card.Header>
        <Card.Body>
          <VideoForm id="video-form" data={video} onSubmit={video ? handleEdit : handleAdd} />
        </Card.Body>
        <Card.Footer>
          <Button type="submit" form="video-form" disabled={isSent} className="float-right" variant="primary">
            {`${video ? 'Upravit' : 'Přidat'} video`}
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default VideoFormContainer;

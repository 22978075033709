import React, { useEffect, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Category, Subcategory } from '../../../models/Category';
import { AppState } from '../../../redux/reducers';
import { createMessage, getMessage, updateMessage } from '../../redux/actions/messagesActions';
import MessageForm from '../../forms/MessageForm';
import { IMessageModel } from '../../../models/Message';

const MessageFormContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch<{ id: string; category: Category; subcategory?: Subcategory }>();
  const { id } = params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loadingAction = useSelector<AppState, boolean>((state) => state.backend.messages.loadings.create || state.backend.messages.loadings.update);
  const error = useSelector<AppState, Error | undefined>((state) => state.backend.messages.errors.create || state.backend.messages.errors.update);
  const loading = useSelector<AppState, boolean>((state) => state.backend.messages.loadings.one);
  const message = useSelector<AppState, IMessageModel>((state) => state.backend.messages.items[id]);

  const dispatch = useDispatch();

  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (!loading && id) {
      dispatch(getMessage(token, id));
    }
  }, []);

  useEffect(() => {
    if (isSent) {
      if (!loadingAction && !error) {
        setIsSent(false);
        history.push(`/admin/messages`);
      }
    }
  }, [isSent, loadingAction, error, history]);

  const handleAdd = (values, { setSubmitting }) => {
    dispatch(createMessage(token, values));
    setSubmitting(false);
    setIsSent(true);
  };

  const handleEdit = (values, { setSubmitting }) => {
    dispatch(updateMessage(token, message.id, values));
    setSubmitting(false);
    setIsSent(true);
  };

  return (
    <Container className="p-0">
      <Card>
        <Card.Header>{`Formulář pro ${message ? 'úpravu' : 'přidání'} zprávy`}</Card.Header>
        <Card.Body>
          <MessageForm id="message-form" data={message} onSubmit={message ? handleEdit : handleAdd} />
        </Card.Body>
        <Card.Footer>
          <Button type="submit" form="message-form" disabled={isSent} className="float-right" variant="primary">
            {`${message ? 'Upravit' : 'Přidat'} zprávu`}
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default MessageFormContainer;

import { IPlayerModel } from '../../../models/Player';
import { Category, Subcategory } from '../../../models/Category';

export const PLAYERS_LOADING = 'PLAYERS_LOADING';
export const PLAYERS_ERROR = 'PLAYERS_ERROR';
export const PLAYERS_ALL = 'PLAYERS_ALL';
export const PLAYERS_ONE = 'PLAYERS_ONE';
export const PLAYERS_CREATE = 'PLAYERS_CREATE';
export const PLAYERS_UPDATE = 'PLAYERS_UPDATE';
export const PLAYERS_REMOVE = 'PLAYERS_REMOVE';

export interface PlayersLoadingAction {
  type: typeof PLAYERS_LOADING;
  name: string;
}

export interface PlayersErrorAction {
  type: typeof PLAYERS_ERROR;
  name: string;
  error: Error;
}

export interface PlayersAllAction {
  type: typeof PLAYERS_ALL;
  category: Category;
  subcategory: Subcategory;
  players: { [key: number]: IPlayerModel };
}

export interface PlayersOneAction {
  type: typeof PLAYERS_ONE;
  player: IPlayerModel;
}

export interface PlayersCreateAction {
  type: typeof PLAYERS_CREATE;
  player: IPlayerModel;
}

export interface PlayersUpdateAction {
  type: typeof PLAYERS_UPDATE;
  player: IPlayerModel;
}

export interface PlayersRemoveAction {
  type: typeof PLAYERS_REMOVE;
  playerId: number;
}

export type PlayersActionTypes =
  | PlayersErrorAction
  | PlayersLoadingAction
  | PlayersAllAction
  | PlayersOneAction
  | PlayersCreateAction
  | PlayersUpdateAction
  | PlayersRemoveAction;

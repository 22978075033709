import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';

import { Category, Subcategory } from '../../../../models/Category';
import { AppState } from '../../../../redux/reducers';
import { getTable } from '../../../redux/actions/tablesActions';

import { SPageContent } from './styled';

type TProps = {
  category: Category;
  subcategory?: Subcategory;
};

const CategoryTable = (props: TProps) => {
  const { category, subcategory } = props;

  const loading = useSelector((state: AppState) => state.frontend.tables.loadings.table);
  const table = useSelector((state: AppState) =>
    subcategory && state.frontend.tables.subcategories[category] && state.frontend.tables.subcategories[category][subcategory]
      ? state.frontend.tables.subcategories[category][subcategory].table
      : state.frontend.tables.categories[category]
      ? state.frontend.tables.categories[category].table
      : undefined,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getTable(category, subcategory));
    }
  }, []);

  return (
    <SPageContent className="p-0 my-5">
      <h2>Tabulka</h2>
      <div className="d-flex justify-content-center">
        <Table className="w-75">
          <thead>
            <tr>
              <td className="left">#</td>
              <td className="left">Tým</td>
              <td>Z</td>
              <td>SKÓRE</td>
              <td>B</td>
            </tr>
          </thead>
          <tbody>
            {table && table.length
              ? table.map((team) => (
                  <tr key={`team_${team.id}`} className={team.name.includes('Dolní Újezd') ? 'myTeam' : ''}>
                    <td className="left bold">{team.order}.</td>
                    <td className="left">{team.name}</td>
                    <td>{team.numberOfMatches}</td>
                    <td>
                      {team.goalsScored}:{team.goalsReceived}
                    </td>
                    <td className="bold">{team.points}</td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      </div>
    </SPageContent>
  );
};

export default CategoryTable;

import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

import { AppState } from '../../../redux/reducers';
import { Category, Subcategory } from '../../../models/Category';
import { ITournamentBaseModel } from '../../../models/Tournament';

import { STable, SNoTournamentData } from './styles';
import TournamentRow from './TournamentRow';

type Props = {
  category: Category;
  subcategory?: Subcategory;
  part: 'autumn' | 'spring';
};

const TournamentsTable = (props: Props) => {
  const { category, subcategory, part } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const tournaments = useSelector<AppState, Array<ITournamentBaseModel>>((state) =>
    subcategory && state.frontend.tournaments.subcategories[category][subcategory] && state.frontend.tournaments.subcategories[category][subcategory].parts
      ? state.frontend.tournaments.subcategories[category][subcategory].parts[part]
      : state.frontend.tournaments.categories[category] && state.frontend.tournaments.categories[category].parts
      ? state.frontend.tournaments.categories[category].parts[part]
      : undefined,
  );

  const tournamentsArray = Object.values(tournaments);

  if (!tournaments || !tournamentsArray.length)
    return (
      <SNoTournamentData className="d-flex mt-5">
        {part === 'autumn' ? <p>Rozpis turnajů na podzimní část sezóny zatím nebyl zveřejněn.</p> : null}
        {part === 'spring' ? <p>Rozpis turnajů na jarní část sezóny zatím nebyl zveřejněn.</p> : null}
      </SNoTournamentData>
    );

  return (
    <STable>
      <Table>
        <thead>
          <tr>
            <th className="left">{isMobile ? 'Datum' : 'Datum turnaje'}</th>
            <th className="left">Název</th>
            <th className="left">Informace</th>
          </tr>
        </thead>
        <tbody>
          {tournamentsArray.map((tournament) => (
            <TournamentRow key={`tournament_${tournament.id}`} tournamentId={tournament.id} />
          ))}
        </tbody>
      </Table>
    </STable>
  );
};

export default TournamentsTable;

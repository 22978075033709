import { API_URL } from './index';

export const endpoints = {
  archive: {
    archive: `${API_URL}/archive`,
    seasons: `${API_URL}/archive/seasons`,
  },
  auth: `${API_URL}/users/auth`,
  articles: `${API_URL}/articles`,
  categories: `${API_URL}/categories`,
  competitions: `${API_URL}/competitions`,
  events: `${API_URL}/events`,
  matches: `${API_URL}/matches`,
  meetings: `${API_URL}/meetings`,
  messages: `${API_URL}/messages`,
  photos: `${API_URL}/photos`,
  players: `${API_URL}/players`,
  sponsors: `${API_URL}/sponsors`,
  tables: `${API_URL}/teams/table`,
  teams: `${API_URL}/teams`,
  tournaments: `${API_URL}/tournaments`,
  users: `${API_URL}/users`,
  videos: `${API_URL}/videos`,
};

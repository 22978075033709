import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Card, Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useEffect, useMemo, useState } from 'react';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';
import { faBoxArchive } from '@fortawesome/free-solid-svg-icons/faBoxArchive';

import Table from '../../../components/Table';
import { AppState } from '../../../../redux/reducers';
import { archiveSeason, getSeasons, removeSeason } from '../../../redux/actions/archive/seasonsActions';
import { ISeasonModel } from '../../../../models/Season';

const SeasonListContainer = () => {
  const history = useHistory();

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const role = useSelector<AppState, string>((state) => state.backend.user.userData?.role);
  const loading = useSelector<AppState, boolean>((state) => state.backend.archive.seasons.loadings.all);
  const loadingRemove = useSelector<AppState, boolean>((state) => state.backend.archive.seasons.loadings.remove);
  const errorRemove = useSelector<AppState, Error | undefined>((state) => state.backend.archive.seasons.errors.remove);
  const loadingArchive = useSelector<AppState, boolean>((state) => state.backend.archive.seasons.loadings.archive);
  const errorArchive = useSelector<AppState, Error | undefined>((state) => state.backend.archive.seasons.errors.archive);
  const seasons = useSelector<AppState>((state) => state.backend.archive.seasons.items);

  const filteredSeasons = useMemo(() => Object.values(seasons).sort((a, b) => b.id - a.id), [seasons]);

  const dispatch = useDispatch();

  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [season, setSeason] = useState<ISeasonModel>();

  useEffect(() => {
    if (!loading) {
      dispatch(getSeasons(token));
    }
  }, []);

  useEffect(() => {
    if (!loadingRemove && !errorRemove) {
      setShowDeleteModal(false);
    }
  }, [loadingRemove, errorRemove]);

  useEffect(() => {
    if (!loadingArchive && !errorArchive) {
      setShowArchiveModal(false);
    }
  }, [loadingArchive, errorArchive]);

  const handleToggleDeleteModal = (season?: ISeasonModel) => {
    setSeason(season);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleRemove = () => {
    dispatch(removeSeason(token, season.id));
  };

  const handleToggleArchiveModal = (season?: ISeasonModel) => {
    setSeason(season);
    setShowArchiveModal(!showDeleteModal);
  };

  const handleArchive = () => {
    dispatch(archiveSeason(token, season.id));
  };

  const OptionCell = (row: any) => (
    <div className="d-flex justify-content-end">
      {role === 'superadmin' ? (
        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-edit">Archivovat sezónu</Tooltip>}>
          {({ ref, ...triggerHandler }) => (
            <Button ref={ref} {...triggerHandler} variant="outline-dark" className="mr-2" onClick={() => handleToggleArchiveModal(row.original)}>
              <FontAwesomeIcon icon={faBoxArchive} style={{ cursor: 'pointer' }} />
            </Button>
          )}
        </OverlayTrigger>
      ) : null}
      <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-edit">Upravit sezónu</Tooltip>}>
        {({ ref, ...triggerHandler }) => (
          <Button
            ref={ref}
            {...triggerHandler}
            variant="outline-dark"
            className="mr-2"
            onClick={() => history.push(`/admin/archive/seasons/edit/${row.original.id}`)}>
            <FontAwesomeIcon icon={faEdit} style={{ cursor: 'pointer' }} />
          </Button>
        )}
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-delete">Odstranit sezónu</Tooltip>}>
        {({ ref, ...triggerHandler }) => (
          <Button ref={ref} {...triggerHandler} variant="danger" onClick={() => handleToggleDeleteModal(row.original)}>
            <FontAwesomeIcon icon={faTrash} style={{ cursor: 'pointer' }} />
          </Button>
        )}
      </OverlayTrigger>
    </div>
  );

  const columns: Column[] = [
    {
      id: 'title',
      Header: 'Název',
      accessor: (d: ISeasonModel) => d.name,
    },
    {
      id: 'options',
      Header: 'Možnosti',
      Cell: OptionCell,
    },
  ];

  return (
    <Container fluid className="p-0">
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <FontAwesomeIcon icon={faTable} />
              <span className="ml-2">Seznam sezón</span>
            </div>
            <Button color="primary" onClick={() => history.push(`/admin/archive/seasons/add`)}>
              <FontAwesomeIcon icon={faPlus} />
              <span className="ml-2">Přidat sezónu</span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Table
            columns={columns}
            data={filteredSeasons}
            options={{
              loadingText: 'Nahrávám sezóny...',
              loading,
            }}
          />
        </Card.Body>
        <Card.Footer />
      </Card>
      {season && (
        <>
          <Modal show={showArchiveModal} onHide={handleToggleArchiveModal}>
            <Modal.Header closeButton>
              <Modal.Title>Archivace sezóny</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{`Opravdu chcete archivovat všechna data jako sezónu: ${season.name}?`}</p>
              <p>Pořádně si to rozmyslete. Tato akce je nenávratná!</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => handleToggleDeleteModal()}>
                Zrušit
              </Button>
              <Button variant="primary" onClick={handleArchive}>
                Potvrdit
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showDeleteModal} onHide={handleToggleDeleteModal}>
            <Modal.Header closeButton>
              <Modal.Title>Smazání sezóny</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{`Opravdu chcete smazat sezónu: ${season.name}?`}</p>
              <p>Pořádně si to rozmyslete. Tato akce je nenávratná!</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => handleToggleDeleteModal()}>
                Zrušit
              </Button>
              <Button variant="danger" onClick={handleRemove}>
                Potvrdit
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </Container>
  );
};

export default SeasonListContainer;

import React, { useEffect } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { Category, Subcategory } from '../../../../models/Category';
import { AppState } from '../../../../redux/reducers';
import { LeagueParts } from '../../../../models/Match';
import { getMatches } from '../../../redux/actions/matchesActions';
import MatchTable from '../../../components/Matches/MatchTable';

import { SPageContent } from './styled';

type TProps = {
  category: Category;
  subcategory?: Subcategory;
};

const CategoryMatches = (props: TProps) => {
  const { category, subcategory } = props;

  const loading = useSelector<AppState, boolean>((state) => state.frontend.matches.loadings.all);
  const parts = useSelector<AppState, LeagueParts>((state) =>
    subcategory && state.frontend.matches.subcategories[category] && state.frontend.matches.subcategories[category][subcategory]
      ? state.frontend.matches.subcategories[category][subcategory].parts
      : state.frontend.matches.categories[category]
      ? state.frontend.matches.categories[category].parts
      : undefined,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && !parts) {
      dispatch(getMatches(category, subcategory));
    }
  }, []);

  return (
    <SPageContent className="p-0 my-5">
      <h2>Rozpis zápasů</h2>
      {parts ? (
        <Tab.Container id="firstAndLastMatch" defaultActiveKey={new Date().getMonth() <= 6 ? 'spring' : 'autumn'}>
          <div className="d-flex justify-content-center align-items-center">
            <Nav variant="tabs" className="d-flex flex-row">
              <Nav.Item>
                <Nav.Link className="tab" eventKey="autumn">
                  Podzimní část
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="tab" eventKey="spring">
                  Jarní část
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="autumn">
              <MatchTable category={category} subcategory={subcategory} part="autumn" />
            </Tab.Pane>
            <Tab.Pane eventKey="spring">
              <MatchTable category={category} subcategory={subcategory} part="spring" />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      ) : null}
    </SPageContent>
  );
};

export default CategoryMatches;

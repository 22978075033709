import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { Category, ICategoryModel, Subcategory } from '../../../../models/Category';
import { AppState } from '../../../../redux/reducers';
import CategoryOverview from '../CategoryOverview';
import CategoryArticles from '../CategoryArticles';
import CategoryVideos from '../CategoryVideos';
import CategoryPhotos from '../CategoryPhotos';
import CategoryPlayers from '../CategoryPlayers';
import CategoryTable from '../CategoryTable';
import CategoryMatches from '../CategoryMatches';
import CategoryPhotosPreview from '../CategoryPhotosPreview';
import CategoryVideosPreview from '../CategoryVideosPreview';
import CategoryArticlesPreview from '../CategoryArticlesPreview';
import Competition from '../../../components/Competition';
import CategoryCalendar from '../CategoryCalendar';
import CategoryTournaments from '../CategoryTournaments';

import Submenu from './Submenu';
import { SPageContent, SCategoryName, SCategoryHeader } from './styled';

type Props = {
  category: Category;
  subcategory?: Subcategory;
};

const CategoryPage = (props: Props) => {
  const { category, subcategory } = props;

  const cat = useSelector<AppState, ICategoryModel>((state) => state.frontend.categories.items[category]);
  const subCatName = `${subcategory === 'st' ? 'Starší' : 'Mladší'} ${cat.name.toLowerCase()}`;

  return (
    <SPageContent className="p-0">
      <SCategoryHeader>
        <SCategoryName>{subcategory && ['st', 'ml'].includes(subcategory) ? subCatName : cat.name}</SCategoryName>
        <Competition category={category} subcategory={subcategory} />
      </SCategoryHeader>
      <Submenu category={category} subcategory={subcategory} />
      <Switch>
        <Route
          path={`/${category}${subcategory ? `/${subcategory}` : ''}/overview`}
          component={() => <CategoryOverview category={category} subcategory={subcategory} />}
        />
        <Route path={`/${category}${subcategory ? `/${subcategory}` : ''}/articles/:id`} component={() => <CategoryArticlesPreview />} />
        <Route
          path={`/${category}${subcategory ? `/${subcategory}` : ''}/articles`}
          component={() => <CategoryArticles category={category} subcategory={subcategory} />}
        />
        <Route
          path={`/${category}${subcategory ? `/${subcategory}` : ''}/matches`}
          component={() => <CategoryMatches category={category} subcategory={subcategory} />}
        />
        <Route
          path={`/${category}${subcategory ? `/${subcategory}` : ''}/tournaments`}
          component={() => <CategoryTournaments category={category} subcategory={subcategory} />}
        />
        <Route
          path={`/${category}${subcategory ? `/${subcategory}` : ''}/table`}
          component={() => <CategoryTable category={category} subcategory={subcategory} />}
        />
        <Route
          path={`/${category}${subcategory ? `/${subcategory}` : ''}/players`}
          component={() => <CategoryPlayers category={category} subcategory={subcategory} />}
        />
        <Route path={`/${category}${subcategory ? `/${subcategory}` : ''}/photos/:id`} component={() => <CategoryPhotosPreview />} />
        <Route
          path={`/${category}${subcategory ? `/${subcategory}` : ''}/photos`}
          component={() => <CategoryPhotos category={category} subcategory={subcategory} />}
        />
        <Route path={`/${category}${subcategory ? `/${subcategory}` : ''}/videos/:id`} component={() => <CategoryVideosPreview />} />
        <Route
          path={`/${category}${subcategory ? `/${subcategory}` : ''}/videos`}
          component={() => <CategoryVideos category={category} subcategory={subcategory} />}
        />
        <Route
          path={`/${category}${subcategory ? `/${subcategory}` : ''}/calendar`}
          component={() => <CategoryCalendar category={category} subcategory={subcategory} />}
        />
        <Route
          path={`/${category}${subcategory ? `/${subcategory}` : ''}`}
          component={() => <CategoryOverview category={category} subcategory={subcategory} />}
        />
      </Switch>
    </SPageContent>
  );
};

export default CategoryPage;

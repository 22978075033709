import * as React from 'react';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, Container } from 'react-bootstrap';

import MessageListContainer from './MessageListContainer';
import MessageFormContainer from './MessageFormContainer';
import MessageDetailContainer from './MessageDetailContainer';

const MessagesContainer = () => {
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <Container fluid className="p-0">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item active={location.pathname === match.path}>
          {location.pathname === match.path ? 'Zprávy z týmu' : <Link to={match.path}>Zprávy z týmu</Link>}
        </Breadcrumb.Item>
        {location.pathname.includes(`${match.path}/detail`) && <Breadcrumb.Item active>Detail zprávy</Breadcrumb.Item>}
        {location.pathname.includes(`${match.path}/add`) && <Breadcrumb.Item active>Nová zpráva</Breadcrumb.Item>}
        {location.pathname.includes(`${match.path}/edit`) && <Breadcrumb.Item active>Úprava zprávy</Breadcrumb.Item>}
      </Breadcrumb>
      <Switch>
        <Route exact path={`${match.path}/detail/:id`} component={MessageDetailContainer} />
        <Route exact path={`${match.path}/add`} component={MessageFormContainer} />
        <Route exact path={`${match.path}/edit/:id`} component={MessageFormContainer} />
        <Route exact path={match.path} component={MessageListContainer} />
      </Switch>
    </Container>
  );
};

export default MessagesContainer;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useRouteMatch } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import moment from 'moment';

import { getPhotos } from '../../../redux/actions/photosActions';
import { AppState } from '../../../../redux/reducers';
import { IGalleryModel } from '../../../../models/Photo';
import { SERVER_URL } from '../../../../constants';

import { SPageContent } from './styled';

const CategoryPhotosPreview = () => {
  const match = useRouteMatch<{ id: string }>();

  const galleryId = match.params.id;

  const loading = useSelector<AppState>((state) => state.frontend.photos.loadings.all);
  const gallery = useSelector<AppState, IGalleryModel>((state) => state.frontend.photos.items[galleryId]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getPhotos(galleryId));
    }
  }, []);

  if (!gallery) return null;

  return (
    <SPageContent className="p-0 my-5">
      <h2>{gallery.name}</h2>
      <p>{moment(gallery.date).format('LL')}</p>
      <Container className="p-0">
        <ImageGallery
          items={gallery.photos.map((photo) => ({
            original: `${SERVER_URL}${photo.url}`,
            thumbnail: `${SERVER_URL}${photo.url}`,
          }))}
          showIndex
        />
      </Container>
    </SPageContent>
  );
};

export default CategoryPhotosPreview;

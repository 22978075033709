import * as Yup from 'yup';

export const createUserScheme = Yup.object().shape({
  username: Yup.string()
    .min(2, 'Uživatelské jméno je příliš krátké! Musí mít alespoň 2 znaky.')
    .max(30, 'Uživatelské jméno je moc dlouhé! Musí mít méně než 30 znaků.')
    .required('Uživatelské jméno je povinné!'),
  role: Yup.string().oneOf(['superadmin', 'admin', 'žádné']).required('Role je povinná'),
  password: Yup.string()
    .required('Heslo je povinné!')
    .min(6, 'Heslo je příliš krátké! Musí mít alespoň 6 znaků.')
    .max(30, 'Heslo je příliš dlouhé! Musí mít méně než 30'),
  confirmPassword: Yup.string()
    .required('Potvrzení hesla je povinné')
    .test('passwords-match', 'Hesla se musí shodovat!', function (value) {
      return this.parent.password === value;
    }),
});

export const updateUserScheme = Yup.object().shape({
  username: Yup.string()
    .min(2, 'Uživatelské jméno je příliš krátké! Musí mít alespoň 2 znaky.')
    .max(30, 'Uživatelské jméno je moc dlouhé! Musí mít méně než 30 znaků.')
    .required('Uživatelské jméno je povinné!'),
  role: Yup.string().oneOf(['superadmin', 'admin', 'žádné']).required('Role je povinná'),
  password: Yup.string().min(6, 'Heslo je příliš krátké! Musí mít alespoň 6 znaků.').max(30, 'Heslo je příliš dlouhé! Musí mít méně než 30'),
  confirmPassword: Yup.string().test('passwords-match', 'Hesla se musí shodovat!', function (value) {
    return this.parent.password === value;
  }),
});

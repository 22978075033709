import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import BaseTable, { BaseTableProps, callOrReturn } from 'react-base-table';

import Row from './Row';

const DraggableContainer = SortableContainer(({ children }) => children);

interface Props extends BaseTableProps {}

class DraggableTable extends React.PureComponent<Props> {
  state = {
    data: this.props.data,
  };

  table = React.createRef<BaseTable>();

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.data !== this.props.data) {
      this.setState({ data: this.props.data });
    }
  }

  getContainer = (): HTMLElement => {
    return this.table.current.getDOMNode().querySelector('.BaseTable__body');
  };

  getHelperContainer = (): HTMLElement => {
    return this.table.current.getDOMNode().querySelector('.BaseTable__table');
  };

  rowProps = (args) => {
    // don't forget to passing the incoming rowProps
    const extraProps = callOrReturn(this.props.rowProps);
    return {
      ...extraProps,
      tagName: Row,
      index: args.rowIndex,
    };
  };

  handleSortEnd = ({ oldIndex, newIndex }) => {
    const data = [...this.state.data];
    const [removed] = data.splice(oldIndex, 1);
    data.splice(newIndex, 0, removed);
    this.setState({ data });
    this.props.onChange(data, oldIndex, newIndex);
  };

  render() {
    return (
      <DraggableContainer useDragHandle getContainer={this.getContainer} helperContainer={this.getHelperContainer} onSortEnd={this.handleSortEnd}>
        <BaseTable {...this.props} ref={this.table} data={this.state.data} fixed={false} rowProps={this.rowProps} />
      </DraggableContainer>
    );
  }
}

export default DraggableTable;

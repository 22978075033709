import React, { ReactChildren } from 'react';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';

import { Handle } from './styled';

const DraggableElement = SortableElement(({ children }) => children);
const DraggableHandle = SortableHandle(({ children }) => children);

type Props = {
  key: string;
  index: number;
  children: ReactChildren;
};

const Row = ({ key, index, children, ...rest }: Props) => (
  <DraggableElement key={key} index={index}>
    <div {...rest}>
      <DraggableHandle>
        <Handle
          onMouseDown={(e) => (e.currentTarget.parentElement.parentElement.style.cursor = 'grabbing')}
          onMouseUp={(e) => (e.currentTarget.parentElement.parentElement.style.cursor = 'grab')}
        />
      </DraggableHandle>
      {children}
    </div>
  </DraggableElement>
);

export default Row;

import axios from 'axios';
import { notify } from 'reapop';

import { endpoints } from '../../../constants/API';
import { ISponsorModel } from '../../../models/Sponsor';
import {
  SPONSORS_LOADING,
  SPONSORS_ERROR,
  SPONSORS_ALL,
  SPONSORS_ONE,
  SponsorsLoadingAction,
  SponsorsErrorAction,
  SponsorsAllAction,
  SponsorsCreateAction,
  SponsorsUpdateAction,
  SponsorsRemoveAction,
  SPONSORS_REMOVE,
  SPONSORS_UPDATE,
  SPONSORS_CREATE,
  SponsorsOneAction,
} from '../types/sponsorsTypes';
import { ASSETS_URL } from '../../../constants';

export const loadingSponsors = (name: string): SponsorsLoadingAction => ({
  type: SPONSORS_LOADING,
  name,
});

const errorSponsors = (name: string, error: Error): SponsorsErrorAction => ({
  type: SPONSORS_ERROR,
  name,
  error,
});

const successSponsors = (sponsors: { [key: number]: ISponsorModel }): SponsorsAllAction => ({
  type: SPONSORS_ALL,
  sponsors,
});

const successSponsor = (sponsor: ISponsorModel): SponsorsOneAction => ({
  type: SPONSORS_ONE,
  sponsor,
});

const successCreateSponsor = (sponsor: ISponsorModel): SponsorsCreateAction => ({
  type: SPONSORS_CREATE,
  sponsor,
});

const successUpdateSponsor = (sponsor: ISponsorModel): SponsorsUpdateAction => ({
  type: SPONSORS_UPDATE,
  sponsor,
});

const successRemoveSponsor = (id): SponsorsRemoveAction => ({
  type: SPONSORS_REMOVE,
  sponsorId: id,
});

export const getSponsors = (token) => async (dispatch) => {
  const actionName = 'all';
  dispatch(loadingSponsors(actionName));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(endpoints.sponsors, config);
    const data = response.data;
    const sponsors = {};

    for (const sponsor of data) {
      let hasImage = false;
      try {
        hasImage = (await fetch(`${ASSETS_URL}/sponsors/${sponsor.id}.png`, { method: 'HEAD' })).ok;
      } catch (e) {
        console.error(e);
      }
      sponsors[sponsor.id] = {
        ...sponsor,
        hasImage,
      };
    }
    dispatch(successSponsors(sponsors));
  } catch (e) {
    dispatch(errorSponsors(actionName, e));
    console.error(e);
  }
};

export const getSponsor = (token: string, sponsorId: string) => async (dispatch) => {
  const actionName = 'one';
  dispatch(loadingSponsors(actionName));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${endpoints.sponsors}/${sponsorId}`, config);
    const sponsor = {
      ...response.data,
      hasImage: (await fetch(`${ASSETS_URL}/sponsors/${sponsorId}.png`, { method: 'HEAD' })).ok,
    };
    dispatch(successSponsor(sponsor));
  } catch (e) {
    dispatch(errorSponsors(actionName, e));
    console.error(e);
  }
};

export const createSponsor = (token: string, values) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'create';
  dispatch(loadingSponsors(actionName));
  try {
    const response = await axios.post(endpoints.sponsors, values, config);
    const sponsor = response.data;
    dispatch(successCreateSponsor(sponsor));
    dispatch(notify('Sponzor přidán.', 'success'));
  } catch (e) {
    dispatch(errorSponsors(actionName, e));
    dispatch(notify('Sponzora se nepodařilo přidat.', 'error'));
  }
};

export const updateSponsor = (token: string, sponsorId: number, values) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'update';
  dispatch(loadingSponsors(actionName));
  try {
    const response = await axios.put(`${endpoints.sponsors}/${sponsorId}`, values, config);
    const sponsor = response.data;
    dispatch(successUpdateSponsor(sponsor));
    dispatch(notify('Sponzor upraven.', 'success'));
  } catch (e) {
    dispatch(errorSponsors(actionName, e));
    dispatch(notify('Sponzora se nepodařilo upravit.', 'error'));
  }
};

export const removeSponsor = (token: string, sponsorId: number) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'remove';
  dispatch(loadingSponsors(actionName));
  try {
    await axios.delete(`${endpoints.sponsors}/${sponsorId}`, config);
    dispatch(successRemoveSponsor(sponsorId));
    dispatch(notify('Sponzor odstraněn.', 'success'));
  } catch (e) {
    dispatch(errorSponsors(actionName, e));
    dispatch(notify('Sponzora se nepodařilo odstranit.', 'error'));
    console.error(e);
  }
};

export const updateSponsorPhoto = (token: string, sponsorId: number, file: File) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'updatePhoto';
  dispatch(loadingSponsors(actionName));
  try {
    const fileUpload = new FormData();
    fileUpload.append('photo', file, file.name);
    await axios.put(`${endpoints.sponsors}/${sponsorId}/photo`, fileUpload, config);
    dispatch(notify('Fotka sponzora upravena.', 'success'));
  } catch (e) {
    dispatch(errorSponsors(actionName, e));
    dispatch(notify('Fotku sponzora se nepodařilo upravit.', 'error'));
    console.error(e);
  }
};

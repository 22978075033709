import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';

import { AppState } from '../../../../redux/reducers';
import SeasonForm from '../../../forms/SeasonForm';
import { ISeasonModel } from '../../../../models/Season';
import { createSeason, getSeason, updateSeason } from '../../../redux/actions/archive/seasonsActions';

const SeasonFormContainer = () => {
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const { id } = match.params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loadingAction = useSelector<AppState, boolean>(
    (state) => state.backend.archive.seasons.loadings.create || state.backend.archive.seasons.loadings.update,
  );
  const error = useSelector<AppState, Error | undefined>((state) => state.backend.archive.seasons.errors.create || state.backend.archive.seasons.errors.update);
  const loading = useSelector<AppState, boolean>((state) => state.backend.archive.seasons.loadings.one);
  const season = useSelector<AppState, ISeasonModel>((state) => state.backend.archive.seasons.items[id]);

  const dispatch = useDispatch();

  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (!loading && id) {
      dispatch(getSeason(token, id));
    }
  }, []);

  useEffect(() => {
    if (isSent) {
      if (!loadingAction && !error) {
        setIsSent(false);
        history.push(`/admin/archive/seasons`);
      }
    }
  }, [isSent, loadingAction, error, history]);

  const handleAdd = (values, { setSubmitting }) => {
    const data = {
      ...values,
      matchId: values.matchId !== '' ? values.matchId : undefined,
    };
    dispatch(createSeason(token, data));
    setSubmitting(false);
    setIsSent(true);
  };

  const handleEdit = (values, { setSubmitting }) => {
    const data = {
      ...values,
      matchId: values.matchId !== '' ? values.matchId : undefined,
    };
    dispatch(updateSeason(token, season.id, data));
    setSubmitting(false);
    setIsSent(true);
  };

  return (
    <Container className="p-0">
      <Card>
        <Card.Header>{`Formulář pro ${season ? 'úpravu' : 'přidání'} sezóny`}</Card.Header>
        <Card.Body>
          <SeasonForm id="season-form" data={season} onSubmit={season ? handleEdit : handleAdd} />
        </Card.Body>
        <Card.Footer>
          <Button type="submit" form="season-form" disabled={isSent} className="float-right" color="primary">
            {`${season ? 'Upravit' : 'Přidat'} sezónu`}
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default SeasonFormContainer;

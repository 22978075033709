import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import renderHTML from 'react-render-html';
import { Card, Container } from 'react-bootstrap';

import { AppState } from '../../../redux/reducers';
import { IArticleModel } from '../../../models/Article';
import { getArticle } from '../../redux/actions/articlesActions';
import { Category, Subcategory } from '../../../models/Category';

const ArticleDetailContainer = () => {
  const match = useRouteMatch<{ id: string; category: Category; subcategory?: Subcategory }>();
  const { id } = match.params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loading = useSelector<AppState, boolean>((state) => state.backend.articles.loadings.one);
  const article = useSelector<AppState, IArticleModel>((state) => state.backend.articles.items[id]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && id) {
      dispatch(getArticle(token, id));
    }
  }, []);

  if (!article || !article.content) return null;

  return (
    <Container fluid className="p-0">
      <Card>
        <Card.Header>Detail článku</Card.Header>
        <Card.Body>
          <h1>{article.title}</h1>
          {renderHTML(article.content)}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ArticleDetailContainer;

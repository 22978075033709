import React from 'react';
import moment from 'moment';
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { IMatchModel } from '../../../models/Match';
import { ASSETS_URL } from '../../../constants';
import { AppState } from '../../../redux/reducers';
import { ICompetitionModel } from '../../../models/Competition';
import Icon from '../IcoMoon';

import { SMatch, SNoMatchData } from './styles';

type Props = {
  match: IMatchModel;
};

const FirstMatch = (props: Props) => {
  const { match } = props;

  const competition = useSelector<AppState, ICompetitionModel>((state) =>
    match
      ? match.subcategory
        ? state.frontend.competitions.subcategories[match.category][match.subcategory]
        : state.frontend.competitions.categories[match.category]
      : undefined,
  );

  if (!match || !match.home || !match.away) return <SNoMatchData>Bohoužel nemáme žádné informace o následujícím utkání</SNoMatchData>;

  const matchType = match.home.name.includes('Dolní Újezd') ? 'home' : 'away';

  return (
    <SMatch>
      {competition && <p className="competition">{competition.name}</p>}
      <div className="match">
        <Image fluid className="d-none d-md-block" src={`${ASSETS_URL}/teams/${matchType === 'away' ? match.home.id : match.away.id}.png`} />
        <div className="teams">
          <div className="team">{match.home.name}</div>
          <div className="delimiter" />
          <div className="team">{match.away.name}</div>
        </div>
        <div className="info">
          <div>{moment(match.date).format('dd')}</div>
          <div>{moment(match.date).format('D. M.')}</div>
          <div>{match.time}</div>
        </div>
      </div>
      {match.info && (
        <div className="info">
          <Icon icon="info" size={20} />
          <p>{match.info}</p>
        </div>
      )}
    </SMatch>
  );
};

export default FirstMatch;

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Column } from 'react-table';
import { faEdit, faEye, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Card, Modal } from 'react-bootstrap';
import { useEffect, useMemo, useState } from 'react';

import Table from '../../components/Table';
import { CategoryWithClub, Subcategory } from '../../../models/Category';
import { IVideoModel } from '../../../models/Video';
import { getVideos, removeVideo } from '../../redux/actions/videosActions';
import CategorySwitch from '../../components/CategorySwitch';
import { AppState } from '../../../redux/reducers';

const VideoListContainer = () => {
  const history = useHistory();
  const match = useRouteMatch<{ category: CategoryWithClub; subcategory: Subcategory }>();
  let { category, subcategory } = match.params;

  if (!category) category = 'klub';

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loading = useSelector<AppState, boolean>((state) => state.backend.videos.loadings.all);
  const loadingRemove = useSelector<AppState, boolean>((state) => state.backend.videos.loadings.remove);
  const errorRemove = useSelector<AppState, Error | undefined>((state) => state.backend.videos.errors.remove);
  const videos = useSelector<AppState, { [key: string]: IVideoModel }>((state) => state.backend.videos.items);

  const filteredVideos = useMemo(
    () =>
      Object.values(videos).filter((video) => (subcategory ? video.category === category && video.subcategory === subcategory : video.category === category)),
    [category, subcategory, videos],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getVideos(token, category, subcategory));
    }
  }, [category, subcategory]);

  useEffect(() => {
    if (!loadingRemove && !errorRemove) {
      setShowModal(false);
    }
  }, [loadingRemove, errorRemove]);

  const handleToggleDeleteModal = (video?: IVideoModel) => {
    setVideo(video);
    setShowModal(!showModal);
  };

  const handleRemove = () => {
    dispatch(removeVideo(token, video!.id));
  };

  const OptionCell = (row) => (
    <div className="d-flex justify-content-end">
      <Button variant="outline-dark" className="mr-2" onClick={() => history.push(`/admin/videos/detail/${row.original.id}`)}>
        <FontAwesomeIcon icon={faEye} style={{ cursor: 'pointer' }} />
      </Button>
      <Button
        variant="outline-dark"
        className="mr-2"
        onClick={() => history.push(`/admin/videos/${category}${subcategory ? `/${subcategory}` : ''}/edit/${row.original.id}`)}>
        <FontAwesomeIcon icon={faEdit} style={{ cursor: 'pointer' }} />
      </Button>
      <Button variant="danger" onClick={() => handleToggleDeleteModal(row.original)}>
        <FontAwesomeIcon icon={faTrash} style={{ cursor: 'pointer' }} />
      </Button>
    </div>
  );

  const columns: Column[] = [
    {
      id: 'name',
      Header: 'Název',
      accessor: (d: IVideoModel) => d.name,
    },
    {
      id: 'options',
      Header: 'Možnosti',
      Cell: OptionCell,
    },
  ];

  const options = {
    loadingText: 'Načítám videa...',
    noDataText: 'Žádná videa k zobrazení',
  };

  const [showModal, setShowModal] = useState(false);
  const [video, setVideo] = useState<IVideoModel | undefined>();

  return (
    <Container fluid className="p-0">
      <CategorySwitch withClub />
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <i className="fas fa-table" />
              <span className="ml-2">Seznam videí</span>
            </div>
            <Button color="primary" onClick={() => history.push(`/admin/videos/${category}${subcategory ? `/${subcategory}` : ''}/add`)}>
              <FontAwesomeIcon icon={faPlus} />
              <span className="ml-2">Přidat video</span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Table
            columns={columns}
            data={filteredVideos}
            options={{
              loading,
              ...options,
            }}
          />
        </Card.Body>
        <Card.Footer />
      </Card>
      {video && (
        <Modal show={showModal} onHide={handleToggleDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Smazání videa</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`Opravdu chcete smazat video: ${video.name}?`}</p>
            <p>Pořádně si to rozmyslete. Tato akce je nenávratná!</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleToggleDeleteModal()}>
              Zrušit
            </Button>
            <Button variant="danger" onClick={() => handleRemove()}>
              Potvrdit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default VideoListContainer;

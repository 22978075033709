import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Row, Col, FormGroup, InputGroup, Button } from 'react-bootstrap';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { Formik, FormikActions } from 'formik';

import { images } from '../../../constants/assets';

import { FormWrapper } from './styledComponents';
import validationSchema from './validation';

export interface LoginFormValues {
  username: string;
  password: string;
  persistent: boolean;
}

type Props = {
  onSubmit: (values: LoginFormValues, actions: FormikActions<LoginFormValues>) => void;
};

const LoginForm = (props: Props) => {
  const { onSubmit } = props;

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          username: '',
          password: '',
          persistent: false,
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values: LoginFormValues, actions: FormikActions<LoginFormValues>) => {
          onSubmit(values, actions);
        }}>
        {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting }) => (
          <Form className="text-center" noValidate onSubmit={handleSubmit}>
            <Row className="p-4">
              <Col>
                <img src={images.backend.sokol} alt="" width="400" className="mb-4" />
                <h1 className="h3 mb-3 font-weight-normal">Přihlášení</h1>
                <FormGroup>
                  <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      name="username"
                      id="username"
                      placeholder="Zadejte vaše uživatelské jméno"
                      autoCorrect="off"
                      value={values.username}
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.username && touched.username}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.username}
                  </Form.Control.Feedback>
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faLock} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Zadejte vaše heslo"
                      value={values.password}
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.password && touched.password}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="text-left">
                  <Form.Check inline type="checkbox" name="persistent" value="persistent" label="Zapamatovat si mě" onChange={handleChange} />
                </FormGroup>
                <Button type="submit" block color="primary">
                  Přihlásit se
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="text-muted text-center">© TJ Sokol Dolní Újezd {new Date().getFullYear()}</p>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default LoginForm;

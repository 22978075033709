import React, { useEffect } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { Category, Subcategory } from '../../../models/Category';
import { getFirstAndLastTournaments } from '../../redux/actions/tournamentsActions';
import { AppState } from '../../../redux/reducers';

import LastTournament from './LastTournament';
import NextTournament from './NextTournament';

type Props = {
  category: Category;
  subcategory?: Subcategory;
};

const FirstLastTournamentsTabs = (props: Props) => {
  const { category, subcategory } = props;

  const loading = useSelector((state: AppState) => state.frontend.tournaments.loadings.lastAndFirst);
  const first = useSelector((state: AppState) =>
    state.frontend.tournaments.categories[category] ? state.frontend.tournaments.categories[category].first : undefined,
  );
  const last = useSelector((state: AppState) =>
    state.frontend.tournaments.categories[category] ? state.frontend.tournaments.categories[category].last : undefined,
  );
  const first_sub = useSelector((state: AppState) =>
    subcategory && state.frontend.tournaments.subcategories[category] && state.frontend.tournaments.subcategories[category][subcategory]
      ? state.frontend.tournaments.subcategories[category][subcategory].first
      : undefined,
  );
  const last_sub = useSelector((state: AppState) =>
    subcategory && state.frontend.tournaments.subcategories[category] && state.frontend.tournaments.subcategories[category][subcategory]
      ? state.frontend.tournaments.subcategories[category][subcategory].last
      : undefined,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && !first && !last) {
      dispatch(getFirstAndLastTournaments(category, subcategory));
    }
  }, []);

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <Tab.Container id="firstLastTournamentsTabs" defaultActiveKey="first">
      <Tab.Content>
        <Tab.Pane eventKey="first">
          <NextTournament tournament={subcategory ? first_sub : first} />
        </Tab.Pane>
        <Tab.Pane eventKey="last">
          <LastTournament tournament={subcategory ? last_sub : last} />
        </Tab.Pane>
      </Tab.Content>
      <Nav variant="tabs" className="d-flex flex-row">
        <Nav.Item>
          {isMobile && (
            <Nav.Link className="tab" eventKey="first">
              Příští turnaj
            </Nav.Link>
          )}
          {!isMobile && (
            <Nav.Link className="tab" eventKey="first">
              Nadcházející turnaj
            </Nav.Link>
          )}
        </Nav.Item>
        <Nav.Item>
          {isMobile && (
            <Nav.Link className="tab" eventKey="last">
              Posled. turnaj
            </Nav.Link>
          )}
          {!isMobile && (
            <Nav.Link className="tab" eventKey="last">
              Poslední odehraný turnaj
            </Nav.Link>
          )}
        </Nav.Item>
      </Nav>
    </Tab.Container>
  );
};

export default FirstLastTournamentsTabs;

import * as React from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { NavDropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { colors } from '../../layout/basicStyles';
import User from '../User';
import { logOut } from '../../redux/actions/userActions';

const UserDropdown = () => {
  const dispatch = useDispatch();

  const handleLogOut = () => {
    dispatch(logOut());
  };

  return (
    <NavDropdown
      title={
        <div className="d-inline-block">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <FontAwesomeIcon icon={faUser} fixedWidth />
            <SkeletonTheme baseColor={colors.bgDark} highlightColor={colors.blue}>
              <User showName showRole inNavbar />
            </SkeletonTheme>
          </div>
        </div>
      }
      id="profileDropdown">
      <NavDropdown.Item>Můj profil</NavDropdown.Item>
      <NavDropdown.Item>Nastavení</NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item onClick={handleLogOut}>Odhlásit se</NavDropdown.Item>
    </NavDropdown>
  );
};

export default UserDropdown;

import {
  TOURNAMENTS_ALL,
  TOURNAMENTS_CREATE,
  TOURNAMENTS_ERROR,
  TOURNAMENTS_LOADING,
  TOURNAMENTS_ONE,
  TOURNAMENTS_REMOVE,
  TOURNAMENTS_UPDATE,
  TournamentsActionTypes,
} from '../types/tournamentsTypes';
import { ITournamentModel } from '../../../models/Tournament';

export interface TournamentsState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error | undefined };
  items: { [key: string]: ITournamentModel };
}

const initialState: TournamentsState = {
  loadings: {},
  errors: {},
  items: {},
};

const tournaments = (currentState: TournamentsState = initialState, action: TournamentsActionTypes): TournamentsState => {
  let newState;
  switch (action.type) {
    case TOURNAMENTS_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case TOURNAMENTS_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case TOURNAMENTS_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        errors: {
          ...currentState.errors,
          all: undefined,
        },
        items: {
          ...currentState.items,
          ...action.tournaments,
        },
      };
    case TOURNAMENTS_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        errors: {
          ...currentState.errors,
          one: undefined,
        },
        items: {
          ...currentState.items,
          [action.tournament.id]: action.tournament,
        },
      };
    case TOURNAMENTS_CREATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          create: false,
        },
        errors: {
          ...currentState.errors,
          create: undefined,
        },
        items: {
          ...currentState.items,
          [action.tournament.id]: action.tournament,
        },
      };
    case TOURNAMENTS_UPDATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          update: false,
        },
        errors: {
          ...currentState.errors,
          update: undefined,
        },
        items: {
          ...currentState.items,
          [action.tournament.id]: {
            ...currentState.items[action.tournament.id],
            ...action.tournament,
          },
        },
      };
    case TOURNAMENTS_REMOVE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          remove: false,
        },
        errors: {
          ...currentState.errors,
          remove: undefined,
        },
        items: {
          ...currentState.items,
        },
      };
      if (newState.items[action.tournamentId]) delete newState.items[action.tournamentId];
      return newState;
    default:
      return currentState;
  }
};

export default tournaments;

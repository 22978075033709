import axios from 'axios';
import { notify } from 'reapop';

import { endpoints } from '../../../constants/API';
import { ICompetitionModel } from '../../../models/Competition';
import {
  COMPETITIONS_LOADING,
  COMPETITIONS_ERROR,
  COMPETITIONS_ALL,
  COMPETITIONS_ONE,
  COMPETITIONS_CREATE,
  COMPETITIONS_UPDATE,
  COMPETITIONS_REMOVE,
  IACompetitionsLoading,
  IACompetitionsError,
  IACompetitionsAll,
  IACompetitionsCreate,
  IACompetitionsUpdate,
  IACompetitionsRemove,
} from '../types/competitionsTypes';
import { CompetitionFormValues } from '../../forms/CompetitionForm';

const ACompetitionsLoading = (name: string): IACompetitionsLoading => ({
  type: COMPETITIONS_LOADING,
  name,
});

const ACompetitionsError = (name: string, error: Error): IACompetitionsError => ({
  type: COMPETITIONS_ERROR,
  name,
  error,
});

const ACompetitionsAll = (competitions: { [key: number]: ICompetitionModel }): IACompetitionsAll => ({
  type: COMPETITIONS_ALL,
  competitions,
});

const ACompetitionsOne = (competition) => ({
  type: COMPETITIONS_ONE,
  competition,
});

const ACompetitionsCreate = (competition: ICompetitionModel): IACompetitionsCreate => ({
  type: COMPETITIONS_CREATE,
  competition,
});

const ACompetitionsUpdate = (competition: ICompetitionModel): IACompetitionsUpdate => ({
  type: COMPETITIONS_UPDATE,
  competition,
});

const ACompetitionsRemove = (competitionId): IACompetitionsRemove => ({
  type: COMPETITIONS_REMOVE,
  competitionId,
});

export const getCompetitions = (token: string) => async (dispatch) => {
  const actionName = 'one';
  dispatch(ACompetitionsLoading(actionName));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(endpoints.competitions, config);
    const data = response.data;
    const competitions = {};
    data.forEach((competition) => {
      competitions[competition.id] = competition;
    });
    dispatch(ACompetitionsAll(competitions));
  } catch (e) {
    dispatch(ACompetitionsError(actionName, e));
    console.error(e);
  }
};

export const getCompetition = (token: string, competitionId: string) => async (dispatch) => {
  const actionName = 'one';
  dispatch(ACompetitionsLoading(actionName));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${endpoints.competitions}/${competitionId}`, config);
    const competition = response.data;
    dispatch(ACompetitionsOne(competition));
  } catch (e) {
    dispatch(ACompetitionsError(actionName, e));
    console.error(e);
  }
};

export const createCompetition = (token: string, values: CompetitionFormValues) => async (dispatch) => {
  const actionName = 'create';
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };

  const data = {
    ...values,
    subcategory: values.subcategory || undefined,
  };

  dispatch(ACompetitionsLoading(actionName));
  try {
    const response = await axios.post(endpoints.competitions, data, config);
    const competition = response.data;
    dispatch(ACompetitionsCreate(competition));
    dispatch(notify({ title: 'Přidání kategorie', message: `Kategorie "${values.name}" byla přidána.`, status: 'success' }));
  } catch (e) {
    dispatch(ACompetitionsError(actionName, e));
    dispatch(notify({ title: 'Přidání kategorie', message: `Kategorii "${values.name}" se nepodařilo přidat.\n${e.response.data.message}`, status: 'error' }));
  }
};

export const updateCompetition = (token: string, competitionId: string, values: CompetitionFormValues) => async (dispatch) => {
  const actionName = 'update';
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };

  const data = {
    ...values,
    subcategory: values.subcategory || undefined,
  };

  dispatch(ACompetitionsLoading(actionName));
  try {
    const response = await axios.put(`${endpoints.competitions}/${competitionId}`, data, config);
    const competition = response.data;
    dispatch(ACompetitionsUpdate(competition));
    dispatch(notify({ title: 'Úprava kategorie', message: `Kategorie ${values.name} byla upravena.`, status: 'success' }));
  } catch (e) {
    dispatch(ACompetitionsError(actionName, e));
    dispatch(notify({ title: 'Úprava kategorie', message: `Kategorii ${values.name} se nepodařilo upravit.`, status: 'error' }));
  }
};

export const removeCompetition = (token: string, competition: ICompetitionModel) => async (dispatch) => {
  const actionName = 'remove';
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  dispatch(ACompetitionsLoading(actionName));
  try {
    await axios.delete(`${endpoints.competitions}/${competition.id}`, config);
    dispatch(ACompetitionsRemove(competition.id));
    dispatch(notify({ title: 'Odstranění kategorie', message: `Kategorie ${competition.name} byla odstraněna.`, status: 'success' }));
  } catch (e) {
    dispatch(ACompetitionsError(actionName, e));
    dispatch(notify({ title: 'Odstranění kategorie', message: `Kategorii ${competition.name} se nepodařilo odstranit.`, status: 'error' }));
  }
};

import { UserModel } from '../../../models/User';

export const LOADING_USER = 'LOADING_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const USER_ME = 'USER_ME';

export interface LoadingUserAction {
  type: typeof LOADING_USER;
}

export interface LoginUserAction {
  type: typeof LOGIN_USER;
  userData: UserModel;
  token: string;
}

export interface LogOutUserAction {
  type: typeof LOGOUT_USER;
}

export interface UserMeAction {
  type: typeof USER_ME;
  userData: UserModel;
}

export type UserActionTypes = LoginUserAction | LoadingUserAction | LogOutUserAction | UserMeAction;

import * as React from 'react';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Container, Breadcrumb } from 'react-bootstrap';

import CompetitionsListContainer from './CompetitionsListContainer';
import CompetitionsFormContainer from './CompetitionsFormContainer';

const CompetitionsContainer = () => {
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <Container fluid className="p-0">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item active={location.pathname === match.path}>
          {location.pathname === match.path ? 'Soutěže' : <Link to="/admin/competitions">Soutěže</Link>}
        </Breadcrumb.Item>
        {location.pathname.includes('/add') && <Breadcrumb.Item active>Nová soutěž</Breadcrumb.Item>}
        {location.pathname.includes('/edit') && <Breadcrumb.Item active>Úprava soutěže</Breadcrumb.Item>}
      </Breadcrumb>
      <Switch>
        <Route path={`${match.path}/add`} component={CompetitionsFormContainer} />
        <Route path={`${match.path}/edit/:id`} component={CompetitionsFormContainer} />
        <Route path={match.path} component={CompetitionsListContainer} />
      </Switch>
    </Container>
  );
};

export default CompetitionsContainer;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';

import { AppState } from '../../../redux/reducers';
import { IArticleModel } from '../../../models/Article';
import { getLastRandomPhoto } from '../../redux/actions/photosActions';
import { SERVER_URL } from '../../../constants';
import { IPhotoModel } from '../../../models/Photo';
import { ICategoryModel } from '../../../models/Category';

import { SArticlePreview, SArticlePreviewImageView, SArticleInfo } from './styled';

interface IProps {
  articleId: string;
}

const ArticlePreview = (props: IProps) => {
  const { articleId } = props;

  const article = useSelector<AppState, IArticleModel>((state) => state.frontend.articles.items[articleId]);
  const category = useSelector<AppState, ICategoryModel>((state) => state.frontend.categories.items[article.category]);

  const [photo, setPhoto] = useState<IPhotoModel>();

  useEffect(() => {
    getPhoto();
  }, []);

  const getPhoto = async () => {
    let photo = article.photo;
    if (!article.photo) {
      photo = await getLastRandomPhoto(article.category, article.subcategory);
    }
    setPhoto(photo);
  };

  if (!article) return null;

  return (
    <LinkContainer to={`/${article.category}${article.subcategory ? `/${article.subcategory}` : ''}/articles/${articleId}`}>
      <SArticlePreview>
        <SArticlePreviewImageView style={{ backgroundImage: `url("${photo ? `${SERVER_URL}${photo.url}` : undefined}")`, backgroundSize: 'cover' }}>
          <SArticleInfo>
            <span>{category.name}</span>
            <span>{moment(article.date).fromNow()}</span>
          </SArticleInfo>
        </SArticlePreviewImageView>
        <h3>{article.title}</h3>
        {article.intro ? (
          <p>{article.intro}</p>
        ) : (
          <>
            <p className="header">
              <b>Branky</b>
            </p>
            <p>{article.goals || 'Žádné vstřelené branky'}</p>
          </>
        )}
      </SArticlePreview>
    </LinkContainer>
  );
};

export default ArticlePreview;

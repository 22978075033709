import React, { useEffect, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Category, Subcategory } from '../../../models/Category';
import { AppState } from '../../../redux/reducers';
import { IGalleryModel, IPhotoModel } from '../../../models/Photo';
import { createGallery, getGallery, updateGallery, updatePhoto, updatePhotoOrder } from '../../redux/actions/photosActions';
import GalleryForm from '../../forms/GalleryForm';
import GalleryPhotos from '../../components/GalleryPhotos';
import { PhotoFormValues } from '../../forms/PhotoForm';

const PhotoFormContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch<{ id: string; category: Category; subcategory?: Subcategory }>();
  const { id, category, subcategory } = params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loadingAction = useSelector<AppState, boolean>((state) => state.backend.photos.loadings.create || state.backend.photos.loadings.update);
  const error = useSelector<AppState, Error | undefined>((state) => state.backend.photos.errors.create || state.backend.photos.errors.update);
  const loading = useSelector<AppState, boolean>((state) => state.backend.photos.loadings.one);
  const gallery = useSelector<AppState, IGalleryModel>((state) => state.backend.photos.items[id]);

  const dispatch = useDispatch();

  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (!loading && id) {
      dispatch(getGallery(token, id));
    }
  }, []);

  useEffect(() => {
    if (isSent) {
      if (!loadingAction && !error) {
        setIsSent(false);
        history.push(`/admin/photos/${category}${subcategory ? `/${subcategory}` : ''}`);
      }
    }
  }, [isSent, loadingAction, error, category, subcategory, history]);

  const handleAdd = (values, photos, { setSubmitting }) => {
    dispatch(createGallery(token, values, photos));
    setSubmitting(false);
    setIsSent(true);
  };

  const handleEdit = (values, photos, { setSubmitting }) => {
    dispatch(updateGallery(token, gallery.id, values, photos));
    setSubmitting(false);
    setIsSent(true);
  };

  const handleUpdatePhoto = (photoId: number, values: PhotoFormValues, { setSubmitting }) => {
    dispatch(updatePhoto(token, gallery.id, photoId, values));
    setSubmitting(false);
  };

  const handleUpdateOrder = (photos: Array<IPhotoModel>) => {
    dispatch(updatePhotoOrder(token, gallery.id, photos));
  };

  return (
    <Container className="p-0 mb-5">
      <Card>
        <Card.Header>{`Formulář pro ${gallery ? 'úpravu' : 'přidání'} galerie`}</Card.Header>
        <Card.Body>
          <GalleryForm id="gallery-form" data={gallery} onSubmit={gallery ? handleEdit : handleAdd} />
        </Card.Body>
        <Card.Footer>
          <Button type="submit" form="gallery-form" disabled={isSent} className="float-right" color="primary">
            {`${gallery ? 'Upravit' : 'Přidat'} galerii`}
          </Button>
        </Card.Footer>
      </Card>
      {gallery && gallery.photos && gallery.photos.length ? (
        <GalleryPhotos photos={gallery.photos} handleUpdatePhoto={handleUpdatePhoto} handleUpdateOrder={handleUpdateOrder} />
      ) : null}
    </Container>
  );
};

export default PhotoFormContainer;

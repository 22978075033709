import { ITournamentModel, ITournamentCategoriesRedux, ITournamentSubcategoriesRedux } from '../../../models/Tournament';
import { Category, Subcategory } from '../../../models/Category';

export const FRONTEND_TOURNAMENTS_LOADING = 'FRONTEND_TOURNAMENTS_LOADING';
export const FRONTEND_TOURNAMENTS_ERROR = 'FRONTEND_TOURNAMENTS_ERROR';
export const FRONTEND_TOURNAMENTS_FIRST_AND_LAST = 'FRONTEND_TOURNAMENTS_FIRST_AND_LAST';
export const FRONTEND_TOURNAMENTS_ALL = 'FRONTEND_TOURNAMENTS_ALL';

export interface IATournamentsLoading {
  type: typeof FRONTEND_TOURNAMENTS_LOADING;
  name: string;
}

export interface IATournamentsError {
  type: typeof FRONTEND_TOURNAMENTS_ERROR;
  name: string;
  error: Error;
}

export interface IATournamentsFirstAndLast {
  type: typeof FRONTEND_TOURNAMENTS_FIRST_AND_LAST;
  category: Category;
  subcategory: Subcategory;
  first: ITournamentModel;
  last: ITournamentModel;
}

export interface IATournamentsAll {
  type: typeof FRONTEND_TOURNAMENTS_ALL;
  all: { [key: string]: ITournamentModel };
  categories: ITournamentCategoriesRedux;
  subcategories: ITournamentSubcategoriesRedux;
}

export type TournamentsActionsTypes = IATournamentsLoading | IATournamentsError | IATournamentsAll | IATournamentsFirstAndLast;

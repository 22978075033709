import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const SPageContent = styled(Container)`
  min-height: calc(100vh - 800px);

  h2 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  p {
    text-align: justify;
  }
`;

import {
  ArticlesActionsTypes,
  FRONTEND_ARTICLES_ALL,
  FRONTEND_ARTICLES_ERROR,
  FRONTEND_ARTICLES_LAST,
  FRONTEND_ARTICLES_LOADING,
  FRONTEND_ARTICLES_ONE,
} from '../types/articlesTypes';
import { IArticleBaseModel, IArticleCategoriesRedux, IArticleModel, IArticleSubcategoriesRedux } from '../../../models/Article';

export interface IArticlesState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error };
  items: { [key: string]: IArticleModel };
  categories: IArticleCategoriesRedux;
  subcategories: IArticleSubcategoriesRedux;
  last?: Array<IArticleBaseModel>;
}

const initialState: IArticlesState = {
  loadings: {},
  errors: {},
  items: {},
  categories: {},
  subcategories: {},
};

const articles = (currentState: IArticlesState = initialState, action: ArticlesActionsTypes): IArticlesState => {
  let newState;
  switch (action.type) {
    case FRONTEND_ARTICLES_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case FRONTEND_ARTICLES_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case FRONTEND_ARTICLES_ALL:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        errors: {
          ...currentState.errors,
          all: undefined,
        },
        items: {
          ...currentState.items,
          ...action.all,
        },
        categories: {
          ...currentState.categories,
        },
        subcategories: {
          ...currentState.subcategories,
        },
      };
      for (const category in action.categories) {
        if (action.categories[category]) {
          newState.categories[category] = action.categories[category];
        }
      }
      for (const category in action.subcategories) {
        if (action.subcategories[category]) {
          for (const subcategory in action.subcategories[category]) {
            if (action.subcategories[category][subcategory]) {
              newState.subcategories[category][subcategory] = action.subcategories[category][subcategory];
            }
          }
        }
      }
      return newState;
    case FRONTEND_ARTICLES_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        errors: {
          ...currentState.errors,
          one: undefined,
        },
        items: {
          ...currentState.items,
          [action.article.id]: {
            ...currentState.items[action.article.id],
            ...action.article,
          },
        },
      };
    case FRONTEND_ARTICLES_LAST:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          last: false,
        },
        errors: {
          ...currentState.errors,
          last: undefined,
        },
        items: {
          ...currentState.items,
          ...action.all,
        },
        categories: {
          ...currentState.categories,
        },
        last: [...(currentState.last || []), ...action.last],
      };
      if (action.categories) {
        for (const category in action.categories) {
          if (newState.categories[category]) {
            newState.categories[category] = [...newState.categories[category], ...action.categories[category]];
          } else {
            newState.categories[category] = [...action.categories[category]];
          }
        }
      }
      return newState;
    default:
      return currentState;
  }
};

export default articles;

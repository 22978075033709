import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { arrayToChunks } from '../../../utils';
import { SNoData } from '../../containers/CategoryPages/CategoryArticles/styled';
import { IArticleBaseModel } from '../../../models/Article';
import { AppState } from '../../../redux/reducers';
import { getArticles } from '../../redux/actions/articlesActions';
import { Category, Subcategory } from '../../../models/Category';

import ArticlePreview from './ArticlePreview';

type Props = {
  category: Category;
  subcategory?: Subcategory;
};

const ListCategoryArticles = (props: Props) => {
  const { category, subcategory } = props;

  const loading = useSelector<AppState, boolean>((state) => state.frontend.articles.loadings.all);
  const articles = useSelector<AppState, Array<IArticleBaseModel>>((state) =>
    subcategory && state.frontend.articles.subcategories[category]
      ? state.frontend.articles.subcategories[category][subcategory]
      : state.frontend.articles.categories[category],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getArticles(category, subcategory));
    }
  }, []);

  const articlesArray = articles ? Object.values(articles).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) : [];

  return (
    <Container className="p-0">
      {articlesArray.length ? (
        arrayToChunks(articlesArray, 2).map((chunk, index) => (
          <Row key={`articles_row_${index}`}>
            {chunk.length
              ? chunk.map((article) => (
                  <Col md={6} key={`articles_row_col_${article.id}`}>
                    <ArticlePreview articleId={article.id} />
                  </Col>
                ))
              : null}
          </Row>
        ))
      ) : (
        <SNoData className="d-flex mt-5">
          <p>Žádné články k zobrazení</p>
        </SNoData>
      )}
    </Container>
  );
};

export default ListCategoryArticles;

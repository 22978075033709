import axios from 'axios';

import { IArticleModel, IArticleBaseModel, IArticleCategoriesRedux, IArticleSubcategoriesRedux } from '../../../models/Article';
import { endpoints } from '../../../constants/API';
import {
  IAArticlesAll,
  IAArticlesError,
  IAArticlesLoading,
  FRONTEND_ARTICLES_ALL,
  FRONTEND_ARTICLES_ERROR,
  FRONTEND_ARTICLES_LOADING,
  IAArticlesLast,
  FRONTEND_ARTICLES_LAST,
  FRONTEND_ARTICLES_ONE,
  IAArticlesOne,
} from '../types/articlesTypes';
import { Category, Subcategory } from '../../../models/Category';
import { processArticles, processLastArticles } from '../helpers/articlesHelper';

export const AArticlesLoading = (name: string): IAArticlesLoading => ({
  type: FRONTEND_ARTICLES_LOADING,
  name,
});

const AArticlesError = (name: string, error: Error): IAArticlesError => ({
  type: FRONTEND_ARTICLES_ERROR,
  name,
  error,
});

const AArticlesAll = (
  all: { [key: string]: IArticleModel },
  categories: IArticleCategoriesRedux,
  subcategories: IArticleSubcategoriesRedux,
): IAArticlesAll => ({
  type: FRONTEND_ARTICLES_ALL,
  all,
  categories,
  subcategories,
});

const AArticlesOne = (article: IArticleModel): IAArticlesOne => ({
  type: FRONTEND_ARTICLES_ONE,
  article,
});

const AArticlesLast = (
  all: { [key: string]: IArticleModel },
  categories: { [key: string]: Array<IArticleBaseModel> },
  last: Array<IArticleBaseModel>,
): IAArticlesLast => ({
  type: FRONTEND_ARTICLES_LAST,
  all,
  categories,
  last,
});

export const getArticles = (category: Category, subcategory?: Subcategory) => async (dispatch) => {
  const actionName = 'all';
  dispatch(AArticlesLoading(actionName));
  try {
    const config = {
      params: {
        category,
        subcategory,
      },
    };
    const response = await axios.get(endpoints.articles, config);
    const data = response.data;
    const { all, categories, subcategories } = processArticles(data);
    dispatch(AArticlesAll(all, categories, subcategories));
  } catch (e) {
    dispatch(AArticlesError(actionName, e));
    console.error(e);
  }
};

export const getArticle = (articleId: string) => async (dispatch) => {
  const actionName = 'one';
  dispatch(AArticlesLoading(actionName));
  try {
    const config = {};
    const response = await axios.get(`${endpoints.articles}/${articleId}`, config);
    const article = response.data;
    dispatch(AArticlesOne(article));
  } catch (e) {
    dispatch(AArticlesError(actionName, e));
    console.error(e);
  }
};

export const getLastArticles = (category?: Category, subcategory?: Subcategory, offset?: number, limit?: number) => async (dispatch) => {
  dispatch(AArticlesLoading('last'));
  try {
    const config = {
      params: {
        category,
        subcategory,
        offset,
        limit,
      },
    };
    const response = await axios.get(endpoints.articles, config);
    const data = response.data;
    const { all, categories, last } = processLastArticles(data);
    dispatch(AArticlesLast(all, categories, last));
  } catch (e) {
    dispatch(AArticlesError('last', e));
    console.error(e);
  }
};

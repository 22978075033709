import * as React from 'react';
import { Link, Route, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import { Container, Breadcrumb } from 'react-bootstrap';

import MatchListContainer from './MatchListContainer';
import MatchFormContainer from './MatchFormContainer';

const MatchesContainer = () => {
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <Container fluid className="p-0">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item active={location.pathname === match.path}>
          {location.pathname === match.path ? 'Zápasy' : <Link to={match.path}>Zápasy</Link>}
        </Breadcrumb.Item>
        {location.pathname.includes('/add') && <Breadcrumb.Item active>Nový zápas</Breadcrumb.Item>}
        {location.pathname.includes('/edit') && <Breadcrumb.Item active>Úprava zápasu</Breadcrumb.Item>}
      </Breadcrumb>
      <Switch>
        <Route exact path={`${match.path}/:category/add`} component={MatchFormContainer} />
        <Route exact path={`${match.path}/:category/edit/:id`} component={MatchFormContainer} />
        <Route exact path={`${match.path}/:category`} component={MatchListContainer} />
        <Route exact path={`${match.path}/:category/:subcategory/add`} component={MatchFormContainer} />
        <Route exact path={`${match.path}/:category/:subcategory/edit/:id`} component={MatchFormContainer} />
        <Route exact path={`${match.path}/:category/:subcategory`} component={MatchListContainer} />
        <Route exact path={match.path} component={MatchListContainer} />
      </Switch>
    </Container>
  );
};

export default MatchesContainer;

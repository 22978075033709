import { IEventModel } from '../../../models/Event';

export const EVENTS_LOADING = 'EVENTS_LOADING';
export const EVENTS_ERROR = 'EVENTS_ERROR';
export const EVENTS_ALL = 'EVENTS_ALL';
export const EVENTS_ONE = 'EVENTS_ONE';
export const EVENTS_CREATE = 'EVENTS_CREATE';
export const EVENTS_UPDATE = 'EVENTS_UPDATE';
export const EVENTS_REMOVE = 'EVENTS_REMOVE';

export interface IAEventsLoading {
  type: typeof EVENTS_LOADING;
  name: string;
}

export interface IAEventsError {
  type: typeof EVENTS_ERROR;
  name: string;
  error: Error;
}

export interface IAEventsAll {
  type: typeof EVENTS_ALL;
  events: { [key: number]: IEventModel };
}

export interface IAEventsOne {
  type: typeof EVENTS_ONE;
  event: IEventModel;
}

export interface IAEventsCreate {
  type: typeof EVENTS_CREATE;
  event: IEventModel;
}

export interface IAEventsUpdate {
  type: typeof EVENTS_UPDATE;
  event: IEventModel;
}

export interface IAEventsRemove {
  type: typeof EVENTS_REMOVE;
  eventId: number;
}

export type TAEvents = IAEventsError | IAEventsLoading | IAEventsAll | IAEventsOne | IAEventsCreate | IAEventsUpdate | IAEventsRemove;

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

import { arrayToChunks } from '../../../utils';
import { IGalleryModel } from '../../../models/Photo';

import Gallery from './Gallery';
import Photo from './Photo';

type Props = {
  galleries: Array<IGalleryModel>;
  gallery?: IGalleryModel;
  onSelectGallery: (gallery: IGalleryModel) => void;
  onSelectPhoto: (photoId: number) => void;
};

const GridView = (props: Props) => {
  const { galleries, gallery, onSelectGallery, onSelectPhoto } = props;

  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const count = isBigScreen ? 6 : 4;

  return (
    <Container fluid className="p-0">
      {!gallery
        ? arrayToChunks(galleries, count).map((chunk, index) => (
            <Row key={`gallery_chunk_${index}`} className="mb-4">
              {chunk.map((gallery) => (
                <Col key={`gallery_${gallery.id}`}>
                  <Gallery galleryId={gallery.id} onSelect={onSelectGallery} />
                </Col>
              ))}
              {chunk.length < count ? [...new Array(count - chunk.length)].map((_, ei) => <Col key={`empty_col_${ei}`} />) : null}
            </Row>
          ))
        : arrayToChunks(gallery.photos, count).map((chunk, index) => (
            <Row key={`photos_row_${index}`} className="mb-4">
              {chunk.map((photo) => (
                <Col key={`photo_${photo.id}`} className="d-flex justify-content-center align-items-center" style={{ maxHeight: '200px' }}>
                  <Photo photo={photo} onSelect={onSelectPhoto} />
                </Col>
              ))}
              {chunk.length < count ? [...new Array(count - chunk.length)].map((_, ei) => <Col key={`empty_col_${ei}`} />) : null}
            </Row>
          ))}
    </Container>
  );
};

export default GridView;

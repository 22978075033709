import axios from 'axios';
import { notify } from 'reapop';

import { endpoints } from '../../../../constants/API';
import { ISeasonModel } from '../../../../models/Season';
import {
  SEASONS_ALL,
  SEASONS_ARCHIVE,
  SEASONS_CREATE,
  SEASONS_ERROR,
  SEASONS_LOADING,
  SEASONS_ONE,
  SEASONS_REMOVE,
  SEASONS_UPDATE,
  SeasonsAllAction,
  SeasonsArchiveAction,
  SeasonsCreateAction,
  SeasonsErrorAction,
  SeasonsLoadingAction,
  SeasonsOneAction,
  SeasonsRemoveAction,
  SeasonsUpdateAction,
} from '../../types/archive/seasonsTypes';

export const loadingSeasons = (name: string): SeasonsLoadingAction => ({
  type: SEASONS_LOADING,
  name,
});

const errorSeasons = (name: string, error: Error): SeasonsErrorAction => ({
  type: SEASONS_ERROR,
  name,
  error,
});

const successSeasons = (seasons: { [key: number]: ISeasonModel }): SeasonsAllAction => ({
  type: SEASONS_ALL,
  seasons,
});

const successSeason = (season: ISeasonModel): SeasonsOneAction => ({
  type: SEASONS_ONE,
  season,
});

const successCreateSeason = (season: ISeasonModel): SeasonsCreateAction => ({
  type: SEASONS_CREATE,
  season,
});

const successUpdateSeason = (season: ISeasonModel): SeasonsUpdateAction => ({
  type: SEASONS_UPDATE,
  season,
});

const successRemoveSeason = (id): SeasonsRemoveAction => ({
  type: SEASONS_REMOVE,
  seasonId: id,
});

const successArchiveSeason = (id): SeasonsArchiveAction => ({
  type: SEASONS_ARCHIVE,
  seasonId: id,
});

export const getSeasons = (token) => async (dispatch) => {
  const actionName = 'all';
  dispatch(loadingSeasons(actionName));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(endpoints.archive.seasons, config);
    const data = response.data;
    const seasons = {};
    data.forEach((season) => {
      seasons[season.id] = season;
    });
    dispatch(successSeasons(seasons));
  } catch (e) {
    dispatch(errorSeasons(actionName, e));
    console.error(e);
  }
};

export const getSeason = (token: string, seasonId: string) => async (dispatch) => {
  const actionName = 'one';
  dispatch(loadingSeasons(actionName));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${endpoints.archive.seasons}/${seasonId}`, config);
    const season = response.data;
    dispatch(successSeason(season));
  } catch (e) {
    dispatch(errorSeasons(actionName, e));
    console.error(e);
  }
};

export const createSeason = (token: string, values) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'create';
  dispatch(loadingSeasons(actionName));
  try {
    const response = await axios.post(endpoints.archive.seasons, values, config);
    const season = response.data;
    dispatch(successCreateSeason(season));
    dispatch(notify('Sezóna přidána.', 'success'));
  } catch (e) {
    dispatch(errorSeasons(actionName, e));
    dispatch(notify('Sezónu se nepodařilo přidat.', 'error'));
  }
};

export const updateSeason = (token: string, seasonId: number, values) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'update';
  dispatch(loadingSeasons(actionName));
  try {
    const response = await axios.put(`${endpoints.archive.seasons}/${seasonId}`, values, config);
    const season = response.data;
    dispatch(successUpdateSeason(season));
    dispatch(notify('Sezóna upravena.', 'success'));
  } catch (e) {
    dispatch(errorSeasons(actionName, e));
    dispatch(notify('Sezónu se nepodařilo upravit.', 'error'));
  }
};

export const removeSeason = (token: string, seasonId: number) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'remove';
  dispatch(loadingSeasons(actionName));
  try {
    await axios.delete(`${endpoints.archive.seasons}/${seasonId}`, config);
    dispatch(successRemoveSeason(seasonId));
    dispatch(notify('Sezóna odstraněna.', 'success'));
  } catch (e) {
    dispatch(errorSeasons(actionName, e));
    dispatch(notify('Sezónu se nepodařilo odstranit.', 'error'));
    console.error(e);
  }
};

export const archiveSeason = (token: string, seasonId: number) => async (dispatch) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const actionName = 'archive';
  dispatch(loadingSeasons(actionName));
  try {
    await axios.post(`${endpoints.archive.archive}`, { seasonId }, config);
    dispatch(successArchiveSeason(seasonId));
    dispatch(notify('Sezóna archivována.', 'success'));
  } catch (e) {
    dispatch(errorSeasons(actionName, e));
    dispatch(notify('Sezónu se nepodařilo archivovat.', 'error'));
    console.error(e);
  }
};

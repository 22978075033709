import { Category, Subcategory } from '../../../models/Category';
import { TableModel } from '../../../models/Team';

export const TABLES_LOADING = 'TABLES_LOADING';
export const TABLES_ERROR = 'TABLES_ERROR';
export const TABLES_ONE = 'TABLES_ONE';

export interface IATablesLoading {
  type: typeof TABLES_LOADING;
  name: string;
}

export interface IATablesError {
  type: typeof TABLES_ERROR;
  name: string;
  error: Error;
}

export interface IATablesOne {
  type: typeof TABLES_ONE;
  category: Category;
  subcategory: Subcategory;
  table: TableModel;
}

export type TablesActionsTypes = IATablesLoading | IATablesError | IATablesOne;

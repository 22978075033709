import React from 'react';
import IcoMoon, { IconProps, iconList } from 'react-icomoon';

const iconSet = require('./selection.json');

console.info(iconList(iconSet));

const Icon = (props: IconProps) => <IcoMoon iconSet={iconSet} {...props} />;

export default Icon;

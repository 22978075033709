import { FRONTEND_VIDEO_ALL, FRONTEND_VIDEO_ERROR, FRONTEND_VIDEO_LOADING, FRONTEND_VIDEO_ONE, VideoActionsTypes } from '../types/videosTypes';
import { IVideoModel } from '../../../models/Video';

export interface IVideosState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error };
  items: { [key: string]: IVideoModel };
  categories: {
    [key: string]: {
      [key: string]: IVideoModel;
    };
  };
  subcategories?: {
    [key: string]: {
      [key: string]: {
        [key: string]: IVideoModel;
      };
    };
  };
}

const initialState: IVideosState = {
  loadings: {},
  errors: {},
  items: {},
  categories: {},
  subcategories: {},
};

const videos = (currentState: IVideosState = initialState, action: VideoActionsTypes): IVideosState => {
  let newState;
  switch (action.type) {
    case FRONTEND_VIDEO_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case FRONTEND_VIDEO_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case FRONTEND_VIDEO_ALL:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        items: {
          ...currentState.items,
          ...action.videos,
        },
        categories: {
          ...currentState.categories,
        },
        subcategories: {
          ...currentState.subcategories,
        },
      };
      if (action.category && action.subcategory) {
        if (!newState.subcategories[action.category]) newState.subcategories[action.category] = {};
        newState.subcategories[action.category] = {
          ...newState.subcategories[action.category],
          [action.subcategory]: {
            ...newState.subcategories[action.category][action.subcategory],
            ...action.videos,
          },
        };
      } else {
        newState.categories[action.category] = {
          ...newState.categories[action.category],
          ...action.videos,
        };
      }
      return newState;
    case FRONTEND_VIDEO_ONE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        items: {
          ...currentState.items,
          [action.video.id]: action.video,
        },
      };
      return newState;
    default:
      return currentState;
  }
};

export default videos;

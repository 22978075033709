import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

import { AppState } from '../../../redux/reducers';
import { IMatchBaseModel } from '../../../models/Match';
import { Category, Subcategory } from '../../../models/Category';

import { STable, SNoMatchData } from './styles';
import MatchRow from './MatchRow';

type Props = {
  category: Category;
  subcategory?: Subcategory;
  part: 'autumn' | 'spring';
};

const MatchTable = (props: Props) => {
  const { category, subcategory, part } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const matches = useSelector<AppState, Array<IMatchBaseModel>>((state) =>
    subcategory && state.frontend.matches.subcategories[category][subcategory] && state.frontend.matches.subcategories[category][subcategory].parts
      ? state.frontend.matches.subcategories[category][subcategory].parts[part]
      : state.frontend.matches.categories[category] && state.frontend.matches.categories[category].parts
      ? state.frontend.matches.categories[category].parts[part]
      : undefined,
  );

  const matchesArray = Object.values(matches);

  if (!matches || !matchesArray.length)
    return (
      <SNoMatchData className="d-flex mt-5">
        {part === 'autumn' ? <p>Rozpis zápasů na podzimní část sezóny zatím nebyl zveřejněn.</p> : null}
        {part === 'spring' ? <p>Rozpis zápasů na jarní část sezóny zatím nebyl zveřejněn.</p> : null}
      </SNoMatchData>
    );

  return (
    <STable>
      <Table>
        <thead>
          {isMobile ? (
            <tr>
              <th className="left">Datum</th>
              <th className="left">Zápas</th>
              <th className="center">Výsledek</th>
            </tr>
          ) : (
            <tr>
              <th className="left">Datum zápasu</th>
              <th className="right">Domácí</th>
              <th />
              <th className="left">Hosté</th>
              <th className="center">Výsledek</th>
              <th className="center">Poločas</th>
            </tr>
          )}
        </thead>
        <tbody>
          {matchesArray.map((match) => (
            <MatchRow key={`match_${match.id}`} matchId={match.id} />
          ))}
        </tbody>
      </Table>
    </STable>
  );
};

export default MatchTable;

import React, { useEffect, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../redux/reducers';
import { createEvent, getEvent, updateEvent } from '../../redux/actions/eventsActions';
import { IEventModel } from '../../../models/Event';
import EventForm from '../../forms/EventForm';

const EventFormContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch<{ id: string }>();
  const { id } = params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loadingAction = useSelector<AppState, boolean>((state) => state.backend.events.loadings.create || state.backend.events.loadings.update);
  const error = useSelector<AppState, Error | undefined>((state) => state.backend.events.errors.create || state.backend.events.errors.update);
  const loading = useSelector<AppState, boolean>((state) => state.backend.events.loadings.one);
  const event = useSelector<AppState, IEventModel>((state) => state.backend.events.items[id]);

  const dispatch = useDispatch();

  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (!loading && id) {
      dispatch(getEvent(token, id));
    }
  }, []);

  useEffect(() => {
    if (isSent) {
      if (!loadingAction && !error) {
        history.push(`/admin/events`);
      }
      setIsSent(false);
    }
  }, [isSent, loadingAction, error]);

  const handleAdd = (values, { setSubmitting }) => {
    dispatch(createEvent(token, values));
    setSubmitting(false);
    setIsSent(true);
  };

  const handleEdit = (values, { setSubmitting }) => {
    dispatch(updateEvent(token, event.id, values));
    setSubmitting(false);
    setIsSent(true);
  };
  return (
    <Container className="p-0">
      <Card>
        <Card.Header>{`Formulář pro ${event ? 'úpravu' : 'přidání'} události`}</Card.Header>
        <Card.Body>
          <EventForm id="event-form" data={event} onSubmit={event ? handleEdit : handleAdd} />
        </Card.Body>
        <Card.Footer>
          <Button type="submit" form="event-form" disabled={isSent} className="float-right" variant="primary">
            {`${event ? 'Upravit' : 'Přidat'} událost`}
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default EventFormContainer;

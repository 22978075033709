import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Column } from 'react-table';
import { faEdit, faEye, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Card, Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useEffect, useMemo, useState } from 'react';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage';

import Table from '../../components/Table';
import { IArticleModel } from '../../../models/Article';
import { Category, Subcategory } from '../../../models/Category';
import { getArticles, removeArticle } from '../../redux/actions/articlesActions';
import CategorySwitch from '../../components/CategorySwitch';
import { AppState } from '../../../redux/reducers';

const ArticleListContainer = () => {
  const history = useHistory();
  const match = useRouteMatch<{ category: Category; subcategory?: Subcategory }>();
  let { category, subcategory } = match.params;

  if (!category) category = 'mA';

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loading = useSelector<AppState, boolean>((state) => state.backend.articles.loadings.all);
  const loadingRemove = useSelector<AppState, boolean>((state) => state.backend.articles.loadings.remove);
  const errorRemove = useSelector<AppState, Error | undefined>((state) => state.backend.articles.errors.remove);
  const articles = useSelector<AppState>((state) => state.backend.articles.items);

  const filteredArticles = useMemo(
    () =>
      Object.values(articles)
        .filter((article) => (subcategory ? article.category === category && article.subcategory === subcategory : article.category === category))
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
    [category, subcategory, articles],
  );

  const dispatch = useDispatch();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [article, setArticle] = useState<IArticleModel>();

  useEffect(() => {
    if (!loading) {
      dispatch(getArticles(token, category, subcategory));
    }
  }, [category, subcategory]);

  useEffect(() => {
    if (!loadingRemove && !errorRemove) {
      setShowDeleteModal(false);
    }
  }, [loadingRemove, errorRemove]);

  const handleToggleDeleteModal = (article?: IArticleModel) => {
    setArticle(article);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleRemove = () => {
    dispatch(removeArticle(token, article.id));
  };

  const OptionCell = (row: any) => (
    <div className="d-flex justify-content-end">
      <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-detail">Zobrazit článek</Tooltip>}>
        {({ ref, ...triggerHandler }) => (
          <Button
            ref={ref}
            {...triggerHandler}
            variant="outline-dark"
            className="mr-2"
            onClick={() => history.push(`/admin/articles/${category}${subcategory ? `/${subcategory}` : ''}/detail/${row.original.id}`)}>
            <FontAwesomeIcon icon={faEye} style={{ cursor: 'pointer' }} />
          </Button>
        )}
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-image">Přiřadit fotku k článku</Tooltip>}>
        {({ ref, ...triggerHandler }) => (
          <Button
            ref={ref}
            {...triggerHandler}
            variant="outline-dark"
            className="mr-2"
            onClick={() => history.push(`/admin/articles/${category}${subcategory ? `/${subcategory}` : ''}/image/${row.original.id}`)}>
            <FontAwesomeIcon icon={faImage} style={{ cursor: 'pointer' }} />
          </Button>
        )}
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-edit">Upravit článek</Tooltip>}>
        {({ ref, ...triggerHandler }) => (
          <Button
            ref={ref}
            {...triggerHandler}
            variant="outline-dark"
            className="mr-2"
            onClick={() => history.push(`/admin/articles/${category}${subcategory ? `/${subcategory}` : ''}/edit/${row.original.id}`)}>
            <FontAwesomeIcon icon={faEdit} style={{ cursor: 'pointer' }} />
          </Button>
        )}
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-delete">Odstranit článek</Tooltip>}>
        {({ ref, ...triggerHandler }) => (
          <Button ref={ref} {...triggerHandler} variant="danger" onClick={() => handleToggleDeleteModal(row.original)}>
            <FontAwesomeIcon icon={faTrash} style={{ cursor: 'pointer' }} />
          </Button>
        )}
      </OverlayTrigger>
    </div>
  );

  const columns: Column[] = [
    {
      id: 'date',
      Header: 'Datum článku',
      accessor: (d: IArticleModel) => d.date,
      sortMethod: (a, b) => {
        const aTime = new Date(a).getTime();
        const bTime = new Date(b).getTime();
        return aTime - bTime;
      },
      Cell: (row) => moment(row.value).format('ddd DD.MM.YYYY').toLocaleUpperCase(),
    },
    {
      id: 'title',
      Header: 'Název',
      accessor: (d: IArticleModel) => d.title,
    },
    {
      id: 'author',
      Header: 'Autor',
      accessor: (d: IArticleModel) => d.author,
    },
    {
      id: 'options',
      Header: 'Možnosti',
      Cell: OptionCell,
    },
  ];

  return (
    <Container fluid className="p-0">
      <CategorySwitch />
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <FontAwesomeIcon icon={faTable} />
              <span className="ml-2">Seznam článků</span>
            </div>
            <Button color="primary" onClick={() => history.push(`/admin/articles/${category}${subcategory ? `/${subcategory}` : ''}/add`)}>
              <FontAwesomeIcon icon={faPlus} />
              <span className="ml-2">Přidat článek</span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Table
            columns={columns}
            data={filteredArticles}
            options={{
              loadingText: 'Nahrávám články...',
              loading,
            }}
          />
        </Card.Body>
        <Card.Footer />
      </Card>
      {article && (
        <Modal show={showDeleteModal} onHide={handleToggleDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Smazání článku</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`Opravdu chcete smazat článek: ${article.title}?`}</p>
            <p>Pořádně si to rozmyslete. Tato akce je nenávratná!</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleToggleDeleteModal()}>
              Zrušit
            </Button>
            <Button variant="danger" onClick={handleRemove}>
              Potvrdit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default ArticleListContainer;

import React from 'react';
import { useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { useRouteMatch } from 'react-router-dom';

import { AppState } from '../../../redux/reducers';
import { Category, Subcategory } from '../../../models/Category';
import { IVideoModel } from '../../../models/Video';

import { SVideo } from './styles';

type Props = {
  videoId: string;
};

const Video = (props: Props) => {
  const match = useRouteMatch<{ category: Category; subcategory: Subcategory }>();

  const { videoId } = props;

  const video = useSelector<AppState, IVideoModel>((state) => state.frontend.videos.items[videoId]);

  const videoID = video.link.split('/')[3];

  return (
    <LinkContainer to={`${match.path}/${videoId}`}>
      <SVideo>
        <div className="photo" style={{ backgroundImage: `url('http://i.ytimg.com/vi/${videoID}/0.jpg')` }} />
        <h4>{video.name}</h4>
      </SVideo>
    </LinkContainer>
  );
};

export default Video;

import React from 'react';
import { Container } from 'react-bootstrap';

import { SPageContent } from './styled';

const About = () => {
  return (
    <SPageContent className="p-0 my-5">
      <h2>O klubu</h2>
      <Container className="p-0">
        <h3>Sokol Dolní Újezd</h3>
        <p>
          TJ Sokol Dolní Újezd je organizace poskytující veřejnosti možnost zapojit se aktivně do různých sportovních aktivit. V&nbsp;současné době fungují
          oddíly aerobicu (cvičení pro ženy), fotbalu, futsalu, stolního tenisu a volejbalu. Sokol Dolní Újezd zajišťuje pro své členy ideální možnosti
          sportovního vyžití na svých sportovištích. Sokolovna je středem kulturního života, Sokol zde pořádá během roku několik tanečních zábav. Prezidentem TJ
          Sokol Dolní Újezd je Miroslav Lipavský.
        </p>
        <h3>Fotbalový oddíl</h3>
        <p>
          Fotbalový oddíl má dlouhou a pestrou historii a také nejširší členskou základnu ze všech oddílů Sokola Dolní Újezd. Hráči hrají a trénují na hřištích
          v&nbsp;Bořkově, kde kromě hlavní plochy mohou využívat tréninkové hřiště. Přes zimní období též újezdští fotbalisté využívají nově vybudované hřiště
          s&nbsp;umělým povrchem u budovy základní školy či prostornou tělocvičnu základní školy.
        </p>
        <p>
          První fotbalové krůčky sbírají mladí újezdští fotbalisté ve fotbalové školičce. Další fotbalové kategorie mládeže působící v&nbsp;Dolním Újezdě jsou:
          mladší a starší přípravka, mladší a starší žáci. Dorosteneckou kategorii v&nbsp;současnosti nemáme samostatnou. Dorostenci hrají v&nbsp;souklubí
          s&nbsp;dorostenci z&nbsp;Litomyšle. Dolní Újezd se může pyšnit dvěma týmy mužů. Muži A působí v&nbsp;1.&nbsp; A třídě Pardubického kraje a muži B
          hraji okresní přebor Svitavského okresu. Součástí újezdské fotbalové rodiny je i fotbalová garda, které je složena (nejen) z&nbsp;bývalých fotbalistů
          Dolního Újezdu.
        </p>
        <p>
          Předsedou fotbalového oddílu je pan Miloš Vrabec. Do funkce místopředsedy byl zvolen František Mokrejš. Pozici sekretáře zastává Jan Kabrhel. Členy
          výkonného výboru jsou pánové: Miloš Vrabec, Michal Jireček, Jan Sigl, Martin Vomáčka, František Mokrejš a Pavel Rejman a Jan Kabrhel.
        </p>
        <p style={{ textAlign: 'right' }}>
          <i>Petr Frank</i>
        </p>
      </Container>
    </SPageContent>
  );
};

export default About;

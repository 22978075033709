import React, { useEffect, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../redux/reducers';
import { createSponsor, getSponsor, updateSponsor } from '../../redux/actions/sponsorsActions';
import { ISponsorModel } from '../../../models/Sponsor';
import SponsorForm from '../../forms/SponsorForm';

const SponsorFormContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch<{ id: string }>();
  const { id } = params;

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loadingAction = useSelector<AppState, boolean>((state) => state.backend.sponsors.loadings.create || state.backend.sponsors.loadings.update);
  const error = useSelector<AppState, Error | undefined>((state) => state.backend.sponsors.errors.create || state.backend.sponsors.errors.update);
  const loading = useSelector<AppState, boolean>((state) => state.backend.sponsors.loadings.one);
  const sponsor = useSelector<AppState, ISponsorModel>((state) => state.backend.sponsors.items[id]);

  const dispatch = useDispatch();

  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (!loading && id) {
      dispatch(getSponsor(token, id));
    }
  }, []);

  useEffect(() => {
    if (isSent && !loadingAction && !error) {
      setIsSent(false);
      history.push(`/admin/sponsors`);
    }
  }, [isSent, loadingAction, error]);

  const handleAdd = (values, { setSubmitting }) => {
    dispatch(createSponsor(token, values));
    setSubmitting(false);
    setIsSent(true);
  };

  const handleEdit = (values, { setSubmitting }) => {
    dispatch(updateSponsor(token, sponsor.id, values));
    setSubmitting(false);
    setIsSent(true);
  };
  return (
    <Container className="p-0">
      <Card>
        <Card.Header>{`Formulář pro ${sponsor ? 'úpravu' : 'přidání'} sponzora`}</Card.Header>
        <Card.Body>
          <SponsorForm id="sponsor-form" data={sponsor} onSubmit={sponsor ? handleEdit : handleAdd} />
        </Card.Body>
        <Card.Footer>
          <Button type="submit" form="sponsor-form" disabled={isSent} className="float-right" variant="primary">
            {`${sponsor ? 'Upravit' : 'Přidat'} sponsora`}
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default SponsorFormContainer;

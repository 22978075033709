import { ICompetitionModel } from '../../../models/Competition';

export const processCompetitions = (data: Array<ICompetitionModel>) => {
  const competitions: { [key: string]: ICompetitionModel } = {};
  data.forEach((competition) => {
    competitions[competition.id] = competition;
  });

  return competitions;
};

import React from 'react';
import { Image, OverlayTrigger, Popover } from 'react-bootstrap';

import { SERVER_URL } from '../../../constants';
import { IPhotoModel } from '../../../models/Photo';

import { StyledPhotoPickerImage } from './styles';

type Props = {
  photo: IPhotoModel;
  onSelect: (photoId: number) => void;
};

const Photo = (props: Props) => {
  const { photo, onSelect } = props;

  return (
    <OverlayTrigger
      trigger="hover"
      placement="auto"
      overlay={
        <Popover id="photo-popover" style={{ maxWidth: '500px' }}>
          <Popover.Title>Náhled obrázku</Popover.Title>
          <Popover.Content>
            <Image fluid src={`${SERVER_URL}${photo.url}`} />
            {photo.note && <p className="mt-2 mb-0">{photo.note}</p>}
          </Popover.Content>
        </Popover>
      }>
      <StyledPhotoPickerImage src={`${SERVER_URL}${photo.url}`} fluid rounded className="shadow" onClick={() => onSelect(photo.id)} />
    </OverlayTrigger>
  );
};

export default Photo;

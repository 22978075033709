import * as React from 'react';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, Container } from 'react-bootstrap';

import PhotoListContainer from './PhotoListContainer';
import PhotoFormContainer from './PhotoFormContainer';

const PhotosContainer = () => {
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <Container fluid className="p-0">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item active={location.pathname === match.path}>
          {location.pathname === match.path ? 'Fotky' : <Link to={match.path}>Fotky</Link>}
        </Breadcrumb.Item>
        {location.pathname.includes('/detail') && <Breadcrumb.Item active>Detail galerie</Breadcrumb.Item>}
        {location.pathname.includes('/add') && <Breadcrumb.Item active>Nová galerie</Breadcrumb.Item>}
        {location.pathname.includes('/edit') && <Breadcrumb.Item active>Úprava galerie</Breadcrumb.Item>}
      </Breadcrumb>
      <Switch>
        <Route exact path={`${match.path}/:category/:subcategory/edit/:id`} component={PhotoFormContainer} />
        <Route exact path={`${match.path}/:category/:subcategory/add`} component={PhotoFormContainer} />
        <Route exact path={`${match.path}/:category/edit/:id`} component={PhotoFormContainer} />
        <Route exact path={`${match.path}/:category/add`} component={PhotoFormContainer} />
        <Route exact path={`${match.path}/:category/:subcategory`} component={PhotoListContainer} />
        <Route exact path={`${match.path}/:category`} component={PhotoListContainer} />
        <Route exact path={match.path} component={PhotoListContainer} />
      </Switch>
    </Container>
  );
};

export default PhotosContainer;

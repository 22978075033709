import { ITeamModel, TableModel } from '../../../models/Team';
import { Category, Subcategory } from '../../../models/Category';

export const TEAMS_LOADING = 'TEAMS_LOADING';
export const TEAMS_ERROR = 'TEAMS_ERROR';
export const TEAMS_ALL = 'TEAMS_ALL';
export const TEAMS_ONE = 'TEAMS_ONE';
export const TEAMS_CREATE = 'TEAMS_CREATE';
export const TEAMS_UPDATE = 'TEAMS_UPDATE';
export const TEAMS_UPDATE_PHOTO = 'TEAMS_UPDATE_PHOTO';
export const TEAMS_REMOVE = 'TEAMS_REMOVE';
export const TEAMS_TABLE = 'TEAMS_TABLE';
export const TEAMS_TABLE_UPDATE = 'TEAMS_TABLE_UPDATE';

export interface TeamsLoadingAction {
  type: typeof TEAMS_LOADING;
  name: string;
}

export interface TeamsErrorAction {
  type: typeof TEAMS_ERROR;
  name: string;
  error: Error;
}

export interface TeamsAllAction {
  type: typeof TEAMS_ALL;
  teams: { [key: number]: ITeamModel };
}

export interface TeamsOneAction {
  type: typeof TEAMS_ONE;
  team: ITeamModel;
}

export interface TeamsCreateAction {
  type: typeof TEAMS_CREATE;
  team: ITeamModel;
}

export interface TeamsUpdateAction {
  type: typeof TEAMS_UPDATE;
  team: ITeamModel;
}

export interface TeamsRemoveAction {
  type: typeof TEAMS_REMOVE;
  teamId: number;
}

export interface TeamsTableAction {
  type: typeof TEAMS_TABLE;
  category: Category;
  subcategory: Subcategory;
  table: Array<TableModel>;
}

export interface TeamsTableUpdateAction {
  type: typeof TEAMS_TABLE_UPDATE;
  category: Category;
  subcategory: Subcategory;
  table: Array<TableModel>;
}

export type TeamsActionTypes =
  | TeamsErrorAction
  | TeamsLoadingAction
  | TeamsAllAction
  | TeamsOneAction
  | TeamsCreateAction
  | TeamsUpdateAction
  | TeamsRemoveAction
  | TeamsTableAction
  | TeamsTableUpdateAction;

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Column } from 'react-table';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Card, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';

import Table from '../../components/Table';
import { AppState } from '../../../redux/reducers';
import { ICompetitionModel } from '../../../models/Competition';
import { getCompetitions, removeCompetition } from '../../redux/actions/competitionsActions';

const CompetitionsListContainer = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const token = useSelector<AppState, string>((state) => state.backend.user.token);
  const loading = useSelector<AppState, boolean>((state) => state.backend.categories.loadings.all);
  const competitions = useSelector<AppState, { [key: string]: ICompetitionModel }>((state) => state.backend.competitions.items);
  const categories = useSelector<AppState>((state) => state.backend.categories.items);

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [toDelete, setToDelete] = useState<ICompetitionModel>();

  useEffect(() => {
    if (!loading) {
      dispatch(getCompetitions(token));
    }
  }, []);

  const handleToggleDeleteModal = (competition?: ICompetitionModel) => {
    setShowModal(!showModal);
    setToDelete(competition);
  };

  const handleRemove = () => {
    setShowModal(false);
    dispatch(removeCompetition(token, toDelete));
  };

  if (!Object.values(categories).length) return null;

  const OptionCell = (row: any) => (
    <div className="d-flex justify-content-end">
      <Button variant="outline-dark" className="mr-2" onClick={() => history.push(`${match.path}/edit/${row.original.id}`)}>
        <FontAwesomeIcon icon={faEdit} style={{ cursor: 'pointer' }} />
      </Button>
      <Button variant="danger" onClick={() => handleToggleDeleteModal(row.original)}>
        <FontAwesomeIcon icon={faTrash} style={{ cursor: 'pointer' }} />
      </Button>
    </div>
  );

  const columns: Column[] = [
    {
      id: 'category',
      Header: 'Kategorie',
      accessor: (d: ICompetitionModel) =>
        `${d.subcategory ? `${d.subcategory === 'st' ? 'Starší' : 'Mladší'} ${categories[d.category].name.toLowerCase()}` : categories[d.category].name}`,
    },
    {
      id: 'name',
      Header: 'Název soutěže',
      accessor: (d: ICompetitionModel) => d.name,
    },
    {
      id: 'url',
      Header: 'Odkaz na FAČŘ',
      accessor: (d: ICompetitionModel) => d.url,
    },
    {
      id: 'options',
      Header: 'Možnosti',
      Cell: OptionCell,
    },
  ];

  return (
    <Container fluid className="p-0">
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <FontAwesomeIcon icon={faTable} />
              <span className="ml-2">Seznam soutěží</span>
            </div>
            <Button color="primary" onClick={() => history.push(`${match.path}/add`)}>
              <FontAwesomeIcon icon={faPlus} />
              <span className="ml-2">Přidat soutěž</span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Table
            columns={columns}
            data={Object.values(competitions)}
            options={{
              loading,
              loadingText: 'Načítám soutěže...',
              noDataText: 'Žádné soutěže k zobrazení',
            }}
          />
        </Card.Body>
        <Card.Footer />
      </Card>
      {toDelete && (
        <Modal show={showModal} onHide={handleToggleDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Smazání soutěže</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`Opravdu chcete smazat soutěž: ${toDelete.name}?`}</p>
            <p>Pořádně si to rozmyslete. Tato akce je nenávratná!</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleToggleDeleteModal()}>
              Zrušit
            </Button>
            <Button variant="primary" onClick={() => handleRemove()}>
              Potvrdit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default CompetitionsListContainer;

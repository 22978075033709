import { IPlayerModel } from '../../../models/Player';

export const processPlayers = (data: Array<IPlayerModel>) => {
  const all: { [key: string]: IPlayerModel } = {};
  data.forEach((match: IPlayerModel) => {
    all[match.id] = match;
  });

  return {
    all,
  };
};

import axios from 'axios';
import { notify } from 'reapop';

import { endpoints } from '../../../constants/API';
import { UserModel } from '../../../models/User';
import {
  USERS_ALL,
  USERS_CREATE,
  USERS_FAILURE,
  USERS_LOADING,
  USERS_REMOVE,
  USERS_UPDATE,
  IAUsersCreate,
  IAUsersFailure,
  IAUsersRemove,
  IAUsersUpdate,
  USERS_ONE,
  IAUsersLoading,
} from '../types/usersTypes';

export const AUsersLoading = (name: string): IAUsersLoading => ({
  type: USERS_LOADING,
  name,
});

const AUsersFailure = (name: string, error: Error): IAUsersFailure => ({
  type: USERS_FAILURE,
  name,
  error,
});

const AUsersAll = (users) => ({
  type: USERS_ALL,
  users,
});

const AUsersOne = (user) => ({
  type: USERS_ONE,
  user,
});

const AUsersCreate = (user: UserModel): IAUsersCreate => ({
  type: USERS_CREATE,
  user,
});

const AUsersUpdate = (user: UserModel): IAUsersUpdate => ({
  type: USERS_UPDATE,
  user,
});

const AUsersRemove = (id): IAUsersRemove => ({
  type: USERS_REMOVE,
  userId: id,
});

export const getUsers = (token) => async (dispatch) => {
  dispatch(AUsersLoading('all'));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(endpoints.users, config);
    const data = response.data;
    const users = {};
    data.forEach((user) => {
      users[user.id] = user;
    });
    dispatch(AUsersAll(users));
  } catch (e) {
    console.error(e);
  }
};

export const getUser = (token: string, userId: string) => async (dispatch) => {
  dispatch(AUsersLoading('one'));
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${endpoints.users}/${userId}`, config);
    const user = response.data;
    dispatch(AUsersOne(user));
  } catch (e) {
    console.error(e);
  }
};

export const createUser = (token: string, values) => async (dispatch) => {
  const actionName = 'create';
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  try {
    dispatch(AUsersLoading(actionName));
    const response = await axios.post(endpoints.users, values, config);
    const user = response.data;
    dispatch(AUsersCreate(user));
    dispatch(notify('Uživatel přidán.', 'success'));
  } catch (e) {
    dispatch(AUsersFailure(actionName, e));
    dispatch(notify('Uživatele se nepodařilo přidat.', 'error'));
  }
};

export const updateUser = (token: string, userId: string, values) => async (dispatch) => {
  const actionName = 'update';
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  try {
    dispatch(AUsersLoading(actionName));
    const response = await axios.put(`${endpoints.users}/${userId}`, values, config);
    const user = response.data;
    dispatch(AUsersUpdate(user));
    dispatch(notify('Uživatel upraven.', 'success'));
  } catch (e) {
    dispatch(AUsersFailure(actionName, e));
    dispatch(notify('Uživatele se nepodařilo upravit.', 'error'));
  }
};

export const removeUser = (token: string, userId: string) => async (dispatch) => {
  const actionName = 'remove';
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  try {
    dispatch(AUsersLoading(actionName));
    await axios.delete(`${endpoints.users}/${userId}`, config);
    dispatch(AUsersRemove(userId));
    dispatch(notify('Uživatel odstraněn.', 'success'));
  } catch (e) {
    dispatch(AUsersFailure(actionName, e));
    dispatch(notify('Uživatele se nepodařilo odstranit.', 'error'));
  }
};

import * as React from 'react';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, Container } from 'react-bootstrap';

import VideoListContainer from './VideoListContainer';
import VideoFormContainer from './VideoFormContainer';
import VideoDetailContainer from './VideoDetailContainer';

const VideosContainer = () => {
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <Container fluid className="p-0">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item active={location.pathname === match.path}>
          {location.pathname === match.path ? 'Videa' : <Link to={match.path}>Videa</Link>}
        </Breadcrumb.Item>
        {location.pathname.includes('/detail') && <Breadcrumb.Item active>Detail videa</Breadcrumb.Item>}
        {location.pathname.includes('/add') && <Breadcrumb.Item active>Nový video</Breadcrumb.Item>}
        {location.pathname.includes('/edit') && <Breadcrumb.Item active>Úprava videa</Breadcrumb.Item>}
      </Breadcrumb>
      <Switch>
        <Route exact path={`${match.path}/detail/:id`} component={VideoDetailContainer} />
        <Route exact path={`${match.path}/:category/add`} component={VideoFormContainer} />
        <Route exact path={`${match.path}/:category/edit/:id`} component={VideoFormContainer} />
        <Route exact path={`${match.path}/:category`} component={VideoListContainer} />
        <Route exact path={`${match.path}/:category/:subcategory/add`} component={VideoFormContainer} />
        <Route exact path={`${match.path}/:category/:subcategory/edit/:id`} component={VideoFormContainer} />
        <Route exact path={`${match.path}/:category/:subcategory`} component={VideoListContainer} />
        <Route exact path={match.path} component={VideoListContainer} />
      </Switch>
    </Container>
  );
};

export default VideosContainer;

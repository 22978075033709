import { Formik, FormikActions } from 'formik';
import * as React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

import { CategoryWithClub, Subcategory } from '../../../models/Category';
import DatePicker from '../../components/Datepicker';
import { IGalleryModel } from '../../../models/Photo';
import FileUpload from '../../components/FileUpload';

import validationSchema from './validation';

export interface GalleryFormValues {
  name: string;
  date: string;
  category: CategoryWithClub;
  subcategory?: Subcategory;
}

type Props = {
  id: string;
  data?: IGalleryModel | null;
  onSubmit: (values: GalleryFormValues, files: Array<File>, actions: FormikActions<GalleryFormValues>) => void;
};

const GalleryForm = (props: Props) => {
  const { id, data, onSubmit } = props;
  const params = useParams<{ category: CategoryWithClub; subcategory: Subcategory }>();

  const [files, setFiles] = useState([]);

  return (
    <Formik
      initialValues={{
        name: data ? data.name : '',
        date: data ? data.date : '',
        category: params.category,
        subcategory: params.subcategory,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values: GalleryFormValues, actions: FormikActions<GalleryFormValues>) => {
        onSubmit(values, files, actions);
      }}>
      {({ handleSubmit, handleChange, handleBlur, values, touched, setFieldValue, errors, isSubmitting }) => (
        <Form id={id} noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Název galerie</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Zadejte název galerie"
                value={values.name}
                disabled={isSubmitting}
                isInvalid={errors.name && touched.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Datum galerie</Form.Label>
              <DatePicker
                name="date"
                placeholder="Zadejte datum galerie"
                value={values.date}
                disabled={isSubmitting}
                isInvalid={errors.date && touched.date}
                onChange={(value) => setFieldValue('date', value)}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.date}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Fotky</Form.Label>
              <FileUpload
                type="previews"
                placeholder="Vložte fotky nebo klikněte pro přidání"
                accept={['image/jpeg']}
                onDrop={(acceptedFiles) => setFiles(acceptedFiles)}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
};

export default GalleryForm;

import { USERS_ALL, USERS_CREATE, USERS_FAILURE, USERS_LOADING, USERS_ONE, USERS_REMOVE, USERS_UPDATE, UsersActionTypes } from '../types/usersTypes';

export interface UsersState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error | undefined };
  items: object;
}

const initialState: UsersState = {
  loadings: {},
  errors: {},
  items: {},
};

const users = (currentState: UsersState = initialState, action: UsersActionTypes): UsersState => {
  let newState: UsersState;
  switch (action.type) {
    case USERS_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case USERS_FAILURE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case USERS_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        items: {
          ...currentState.items,
          ...action.users,
        },
      };
    case USERS_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        items: {
          ...currentState.items,
          [action.user.id]: action.user,
        },
      };
    case USERS_CREATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          create: false,
        },
        items: {
          ...currentState.items,
          [action.user.id]: action.user,
        },
      };
    case USERS_UPDATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          update: false,
        },
        items: {
          ...currentState.items,
          [action.user.id]: {
            ...currentState.items[action.user.id],
            ...action.user,
          },
        },
      };
    case USERS_REMOVE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          remove: false,
        },
        items: {
          ...currentState.items,
        },
      };
      if (newState.items[action.userId]) {
        delete newState.items[action.userId];
      }
      return newState;
    default:
      return currentState;
  }
};

export default users;

import React from 'react';
import { useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';

import { AppState } from '../../../redux/reducers';
import { ISponsorModel } from '../../../models/Sponsor';
import { ASSETS_URL } from '../../../constants';

import { SSponsor, SImageSponsor, SText } from './styles';

type Props = {
  sponsorId: number;
};

const Sponsor = (props: Props) => {
  const { sponsorId } = props;

  const sponsor = useSelector<AppState, ISponsorModel>((state) => state.frontend.sponsors.items[sponsorId]);

  return (
    <LinkContainer to={`/club/sponsors/${sponsorId}`}>
      <SSponsor>
        {sponsor.hasImage ? (
          <SImageSponsor key={`sponsor_${sponsorId}`} src={`${ASSETS_URL}/sponsors/${sponsorId}.png`} alt="sponsor-image" />
        ) : (
          <SText>{sponsor.name}</SText>
        )}
      </SSponsor>
    </LinkContainer>
  );
};

export default Sponsor;

import {
  PLAYERS_ALL,
  PLAYERS_CREATE,
  PLAYERS_ERROR,
  PLAYERS_LOADING,
  PLAYERS_ONE,
  PLAYERS_REMOVE,
  PLAYERS_UPDATE,
  PlayersActionTypes,
} from '../types/playersTypes';

export interface PlayersState {
  loadings: { [key: string]: boolean };
  errors: { [key: string]: Error | undefined };
  items: {};
}

const initialState: PlayersState = {
  loadings: {},
  errors: {},
  items: {},
};

const players = (currentState: PlayersState = initialState, action: PlayersActionTypes): PlayersState => {
  let newState;
  switch (action.type) {
    case PLAYERS_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
        errors: {
          ...currentState.errors,
          [action.name]: undefined,
        },
      };
    case PLAYERS_ERROR:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case PLAYERS_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          all: false,
        },
        errors: {
          ...currentState.errors,
          all: undefined,
        },
        items: {
          ...currentState.items,
          ...action.players,
        },
      };
    case PLAYERS_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          one: false,
        },
        errors: {
          ...currentState.errors,
          one: undefined,
        },
        items: {
          ...currentState.items,
          [action.player.id]: action.player,
        },
      };
    case PLAYERS_CREATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          create: false,
        },
        errors: {
          ...currentState.errors,
          create: undefined,
        },
        items: {
          ...currentState.items,
          [action.player.id]: action.player,
        },
      };
    case PLAYERS_UPDATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          update: false,
        },
        errors: {
          ...currentState.errors,
          update: undefined,
        },
        items: {
          ...currentState.items,
          [action.player.id]: {
            ...currentState.items[action.player.id],
            ...action.player,
          },
        },
      };
    case PLAYERS_REMOVE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          remove: false,
        },
        errors: {
          ...currentState.errors,
          remove: undefined,
        },
        items: {
          ...currentState.items,
        },
      };
      if (newState.items[action.playerId]) {
        delete newState.items[action.playerId];
      }
      return newState;
    default:
      return currentState;
  }
};

export default players;

import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const SPageContent = styled(Container)`
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 800px);

  h2 {
    text-align: center;
    margin-top: 30px;
  }

  p {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  div.image-gallery-slides {
    background-color: black;
  }
`;

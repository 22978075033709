import { Container } from 'react-bootstrap';
import styled from 'styled-components';

import { colors } from '../../../layout/styles';

export const SPageContent = styled(Container)`
  min-height: calc(100vh - 800px);
  h2 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

export const SNoData = styled.div`
  display: flex;
  flex: 1;
  height: 90px;
  justify-content: center;
  align-items: center;
  font-family: Nunito Sans;
  color: ${colors.blue};
`;
